/* eslint-disable jsx-a11y/no-redundant-roles */
import {
  DocumentMinusIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db, functions } from "../../../../firebase";
import { UserContext } from "../../../../contexts";
import {
  amountFormater,
  checkTransactionFeesSchedule,
  classNames,
  isNumeric,
  TransactionFees,
} from "../../../../utils";
import { Form, FormInput } from "../../../../components/FormComponents";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import {
  globalConfigCollectionsName,
  feesConfigId,
  agentTransfeesLevelSubCollectionName,
  snblPrepaidFeesLevelsDocId,
  accountsCollectionName,
  defaultAmount,
  usersCollectionName,
} from "../../../../config";
import CodePinComponent from "../../../../components/CodePinComponent";
import Modal from "../../../../components/Modal";

type RetrieveInvoiceInfo = { amount: string; metterNum: string };

type Step = {
  id: "invoiceInfos" | "paymentDetails";
  index: number;
  name: string;
  description: string;
  icon: any;
};

const steps: Step[] = [
  {
    id: "invoiceInfos",
    index: 0,
    name: "Les informations du compteur",
    description: "Renseigner les informations du compteur",
    icon: InformationCircleIcon,
  },
  {
    id: "paymentDetails",
    index: 1,
    name: "Les details du paiement",
    description: "Vérifiez les details du paiement",
    icon: DocumentMinusIcon,
  },
];

const PaidPrepaidInvoices = () => {
  const navigate = useNavigate();
  const [currentFormStep, setCurrentFormStep] = useState<Step>(steps[0]);
  const { user, setUserAccounts } = useContext(UserContext);
  const [invoice, setInvoice] = useState<any>();
  const [totalAmountWithFess, setTotalAmountWithFess] = useState(0);
  const [fees, setFees] = useState(0);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);

  const sankMoneySnblPrePaidInvoiceInfoFn = functions.httpsCallable(
    "sankMoneySnblPrePaidInvoiceInfoFn"
  );

  const retrievePrepaidInvoiceInfos = async (
    retriveInfoData: RetrieveInvoiceInfo
  ) => {
    if (!user) {
      return;
    }
    const toastId = toast.loading("Consultation en cours...");
    const data = {
      amount: retriveInfoData.amount,
      currentUserId: user?.id,
      withConnection: true,
      appVersion: "web",
      appPlatform: "web",
      meterNum: retriveInfoData.metterNum,
    };
    setTotalAmount(parseInt(retriveInfoData.amount));
    await sankMoneySnblPrePaidInvoiceInfoFn(data)
      .then((res) => {
        const response = res.data;
        if (response.status && response.status !== 200) {
          return toast.error("Pas de facture disponible", { id: toastId });
        }
        if (response.data.length <= 0) {
          return toast.error("Pas de facture disponible", { id: toastId });
        }
        const snblFees = response.data.fee[0].item.map((val: any) => {
          return {
            name: val["$"].name,
            amt: val["$"].amt,
          };
        });
        const formattedFees = snblFees.reduce((acc: any, current: any) => {
          acc[current.name] = current.amt;
          return acc;
        }, {});
        const isIncludesSeparator = (response.data.token as string).includes(
          ","
        );
        const data = {
          transId: response.data.transID,
          code: response.data.code,
          invoice: response.data.invoice,
          token: response.data.token,
          meterNum: response.data.meterNum,
          updatedToken: isIncludesSeparator
            ? (response.data.token as string).split(",")
            : undefined,
          vendQty: response.data.vendQty,
          vendAMT: response.data.vendAMT,
          customerName: response.data.customerName,
          fee: formattedFees,
          AMT: response.data.AMT,
        };
        setInvoice(data);
        setCurrentFormStep(steps[1]);
        toast.success("ok", { id: toastId });
      })
      .catch((err) => {
        toast.error("Une erreur s'est produite", { id: toastId });
      });
  };

  const onSubmitInvoices = async (pinCode: string) => {
    const sankMoneySnblPrePaidInvoicePaiementFn = functions.httpsCallable(
      "agentPaidInvoices-agentPaidSnblPrepaidInvoicesFn"
    );
    setIsCodePinBtnDesabled(true);
    if (!isNumeric(`${totalAmount}`)) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      return toast.error(`Le montant n'est pas bien formatté`);
    }
    if (`${totalAmount}` < defaultAmount) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      return toast.error(
        `Le montant doit être suppérieur à ${defaultAmount} Fcfa`
      );
    }
    setIsLoad(true);
    const data: any = {
      pinCode: pinCode,
      amount: `${totalAmount}`,
      withConnection: true,
      appVersion: "web",
      appPlatform: "web",
      meterNum: invoice.meterNum,
      metterCustomerName: invoice.customerName,
      energyQty: invoice.vendQty,
      currentUserId: user?.id,
    };
    await sankMoneySnblPrePaidInvoicePaiementFn(data)
      .then(async () => {
        setShowPinCodeModal(false);
        setIsLoad(false);
        const senderAccountRef = db
          .collection(usersCollectionName)
          .doc(user?.id)
          .collection(accountsCollectionName);
        const accounts = await senderAccountRef.get();
        setUserAccounts(accounts.docs.map((account) => account.data()));
        navigate("/dashboard");
        return toast.success("La facture a été payée avec succès.");
      })
      .catch((err: { details: any; message: any }) => {
        setShowPinCodeModal(false);
        setIsCodePinBtnDesabled(false);
        setIsLoad(false);
        toast.error(err.message);
      });
  };

  const retrieveFees = useCallback(async () => {
    const sbnlPostpaidFeeSnap = await db
      .collection(globalConfigCollectionsName)
      .doc(feesConfigId)
      .collection(agentTransfeesLevelSubCollectionName)
      .doc(snblPrepaidFeesLevelsDocId)
      .get();
    const postpaidFeesData = sbnlPostpaidFeeSnap.data();
    const amountWithFees = checkTransactionFeesSchedule(
      postpaidFeesData as TransactionFees,
      totalAmount
    ).amountWithFees;
    setTotalAmountWithFess(amountWithFees);
    const fees = checkTransactionFeesSchedule(
      postpaidFeesData as TransactionFees,
      totalAmount
    ).fees;
    setFees(fees);
  }, [totalAmount]);

  useEffect(() => {
    retrieveFees();
  }, [retrieveFees]);

  return (
    <>
      <div className="lg:border-t lg:border-b lg:border-gray-200">
        <nav
          className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
          aria-label="Progress"
        >
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
          >
            <li className="relative px-2 py-4 overflow-hidden lg:py-2">
              <button
                className="p-1 border border-transparent rounded-full hover:border-gray-200 hover:bg-gray-50"
                onClick={() => navigate("/dashboard")}
              >
                <XMarkIcon className="w-8 h-8 text-gray-700" />
              </button>
            </li>
            {steps.map((step, stepIdx) => (
              <li key={stepIdx} className="relative overflow-hidden lg:flex-1">
                <div
                  className={classNames(
                    stepIdx === 0 ? "border-b-0 rounded-t-md" : "",
                    stepIdx === steps.length - 1
                      ? "border-t-0 rounded-b-md"
                      : "",
                    "border border-gray-200 overflow-hidden lg:border-0 h-full"
                  )}
                >
                  <button
                    className={classNames(
                      "w-full group h-full cursor-not-allowed"
                    )}
                  >
                    <span
                      className={classNames(
                        step.index !== currentFormStep.index
                          ? "group-hover:bg-gray-200 bg-transparent "
                          : "bg-red-600",
                        "absolute top-0 left-0 w-1 h-full  lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "px-4 py-4 lg:py-2 flex items-start text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span
                          className={classNames(
                            step.index < currentFormStep.index
                              ? "bg-red-600"
                              : "",
                            step.index === currentFormStep.index
                              ? "bg-white border-2 border-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "bg-white border-2 border-gray-300"
                              : "",
                            "flex items-center justify-center w-10 h-10  rounded-full"
                          )}
                        >
                          <step.icon
                            className={classNames(
                              step.index < currentFormStep.index
                                ? "text-white"
                                : "",
                              step.index === currentFormStep.index
                                ? "text-red-600"
                                : "",
                              step.index > currentFormStep.index
                                ? "text-gray-500"
                                : "",
                              "w-6 h-6 "
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex-col flex  xl:flex">
                        <span
                          className={classNames(
                            step.index === currentFormStep.index
                              ? "text-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "text-gray-500"
                              : "",
                            "text-xs font-semibold tracking-wide uppercase md:block lg:hidden xl:block"
                          )}
                        >
                          {step.name}
                        </span>
                        <span className="flex items-start text-sm font-medium text-gray-500">
                          {step.description}
                        </span>
                      </span>
                    </span>
                  </button>
                  <div
                    className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="w-full h-full text-gray-300"
                      viewBox="0 0 12 82"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0.5 0V31L10.5 41L0.5 51V82"
                        stroke="currentcolor"
                        vectorEffect="non-scaling-stroke"
                      />
                    </svg>
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="items-center w-full mx-auto md:w-11/12 lg:w-9/12">
        {currentFormStep.id === "invoiceInfos" && (
          <Form<RetrieveInvoiceInfo>
            onSubmit={retrievePrepaidInvoiceInfos}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  amount: yup
                    .string()
                    .required("Le champs montant est obligatoire"),
                  metterNum: yup
                    .string()
                    .required("Le champs numéro du compteur est obligatoire"),
                })
              ),
            }}
            submitButtonLabel="Consulter"
          >
            <div className="flex flex-col content-center w-full px-5 mt-5">
              <div className="w-full mx-auto ">
                <FormInput
                  name="amount"
                  label="Montant (FCFA)"
                  placeholder="10000"
                />
                <FormInput
                  name="metterNum"
                  label="Numéro du compteur cash-power"
                  placeholder="12356589865548"
                />
              </div>
            </div>
          </Form>
        )}
        {currentFormStep.id === "paymentDetails" && (
          <div className="flex justify-center">
            <div className="w-full">
              <div className="m-3 sm:max-w-lg sm:w-full sm:mx-auto">
                <div className="relative flex flex-col bg-white shadow-lg pointer-events-auto rounded-xl dark:bg-gray-800">
                  <div className="p-4 overflow-y-auto sm:p-7">
                    <div className="text-center">
                      <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                        Facture Sonabel
                      </h3>
                      <p className="text-sm text-gray-500">
                        Facture #
                        <span className="font-bold">
                          {invoice ? invoice.transId : ""}
                        </span>
                      </p>
                    </div>

                    <div className="grid grid-cols-2 gap-5 mt-5 sm:mt-10 sm:grid-cols-2">
                      <div>
                        <span className="block text-xs text-gray-500 uppercase">
                          Nom du client:
                        </span>
                        <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                          {invoice ? invoice.customerName : ""}
                        </span>
                      </div>

                      <div>
                        <span className="block text-xs text-gray-500 uppercase">
                          N° du compteur:
                        </span>
                        <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                          {invoice ? invoice.meterNum : ""}
                        </span>
                      </div>
                    </div>

                    <div className="mt-5 sm:mt-10">
                      <h4 className="text-xs font-semibold text-gray-800 uppercase dark:text-gray-200">
                        Facture
                      </h4>

                      <ul className="flex flex-col mt-3">
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Coût de l'energie</span>
                            <span>{invoice.vendAMT}F</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Tsdaae</span>
                            <span>
                              {invoice && invoice.fee["Tsdaae"] !== undefined
                                ? invoice.fee["Tsdaae"]
                                : 0}
                              F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Tde</span>
                            <span>
                              {invoice && invoice.fee["Tde"] !== undefined
                                ? invoice.fee["Tde"]
                                : 0}
                              F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Redevance</span>
                            <span>
                              {invoice && invoice.fee["Redevance"] !== undefined
                                ? invoice.fee["Redevance"]
                                : 0}
                              F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>PRIME FIXE</span>
                            <span>
                              {invoice &&
                              invoice.fee["PRIME FIXE"] !== undefined
                                ? invoice.fee["PRIME FIXE"]
                                : 0}
                              F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>TVA</span>
                            <span>
                              {invoice && invoice.fee["TVA"] !== undefined
                                ? invoice.fee["TVA"]
                                : 0}
                              F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm font-semibold text-gray-800 border gap-x-2 bg-gray-50 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-slate-800 dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Total quantité energie</span>
                            <span>{invoice && invoice.vendQty}Kwh</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Montant</span>
                            <span>
                              {invoice &&
                                amountFormater(parseFloat(invoice.AMT))}{" "}
                              Fcfa
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Frais</span>
                            <span>{fees} Fcfa</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm font-semibold text-gray-800 border gap-x-2 bg-gray-50 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-slate-800 dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Montant total</span>
                            <span>
                              {amountFormater(totalAmountWithFess)} Fcfa
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div className="flex justify-end my-5 gap-x-2">
                      <div
                        onClick={() => setShowPinCodeModal(true)}
                        className="inline-flex items-center px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-lg cursor-pointer gap-x-2 hover:bg-red-700 focus:outline-hidden focus:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                      >
                        Payé ({amountFormater(totalAmountWithFess)} Fcfa)
                      </div>
                    </div>

                    {/* <div className="mt-5 sm:mt-10">
                      <p className="text-sm text-gray-500">
                        Si vous avez des questions, veuillez nous contacter à
                        l'adresse{" "}
                        <a
                          className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium"
                          href="mailto:contact@sankbusiness.com"
                        >
                          contact@sankbusiness.com
                        </a>{" "}
                        ou nous appeler au{" "}
                        <a
                          className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium"
                          href="tel:+22668202034"
                        >
                          +226 68-20-20-34
                        </a>
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <Modal
              isOpen={showPinCodeModal}
              maxSize="md"
              closeModal={() => setShowPinCodeModal(false)}
            >
              <div className="flex justify-center">
                <CodePinComponent
                  title={` Entrez votre code pin pour payé ${amountFormater(
                    totalAmountWithFess
                  )} FCFA`}
                  onSubmit={onSubmitInvoices}
                  disabled={isCodePinBtnDesabled}
                  isLoad={isLoad}
                />
              </div>
            </Modal>
          </div>
        )}
      </div>
    </>
  );
};

export default PaidPrepaidInvoices;
