import {
  BoltIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { Timestamp } from "firebase-admin/firestore";
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import NoContent from "../../components/TableNoContentPage/NoContent";
import { UserContext } from "../../contexts";
import { db, functions } from "../../firebase";
import { classNames, getTimestamp } from "../../utils";
import {
  gasStationMembershipCollectionName,
  transactionCollectionName,
  usersCollectionName,
} from "../../config";
import moment from "moment";
import {
  FunnelIcon,
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { TransactionType, UserRole, transactionMap } from "../../interfaces";
import SankMoneyIcon from "../../CFA.png";
import HasRoles from "../../components/HasRoles";
import { Menu, Transition } from "@headlessui/react";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import TableRowEditMenu from "../../components/TableRowEditMenu/TableRowEditMenu";
import PhoneInput from "react-phone-number-input";
import Modal from "../../components/Modal";
import toast from "react-hot-toast";

const tableHeader = [
  "N°",
  "",
  "Nom destinataire",
  "Numéro destinataire",
  "Montant",
  "Type de transaction",
  "Compte",
  "Frais",
  "Status",
  "Date de création",
  "Actions",
];

const senderOrReceiver = "sender";
function HistoriquesPage() {
  const [transactions, setTransactions] = useState<any[]>([]);
  const collectionName = transactionCollectionName;
  const [paginatdlistData, setPaginatdListData] = useState<any[]>([]);
  const [userName, setUserName] = useState<string>("");
  const [page, setPage] = useState(1);
  const { user, agencyAgent, company } = useContext(UserContext);
  const navigate = useNavigate();
  const [defaultLoadHistories, setDefaultLoadHistories] = useState<number>(10);
  const [gasStationRelationId, setGasStationRelationId] = useState("");
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [isSender, setIsSender] = useState(true);
  const [userData, setUserData] = useState<any>();
  const [transactionTotalSum, setTransactionTotalSum] = useState<number>(0);
  const [checkedValue, setCheckedValue] = useState(false);
  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );
  const getAlltransactionRef = db.collection(transactionCollectionName);
  const transctionTypeOptions = [
    TransactionType.TRANSFER,
    TransactionType.RELOAD,
    TransactionType.WITHDRAW,
    TransactionType.PAYMENT,
    TransactionType.RELOADFUELACCOUNT,
  ];

  const transactionType = [TransactionType.TRANSFER, TransactionType.RELOAD];

  const retriveGasStationId = async () => {
    if (user?.attendantRole) {
      const membershipRef = db.collection(gasStationMembershipCollectionName);
      const membershipSnaphot = await membershipRef
        .where("memberId", "==", user?.id)
        .get();
      const membershipData = membershipSnaphot.docs[0]?.data();
      if (membershipData) {
        setGasStationRelationId(membershipData?.gasStationId);
        return membershipData?.gasStationId as string;
      }
    }
  };
  const getFilterdByPhoneNumber = async (value: any) => {
    value.preventDefault();
    const phoneNumber: string = value.target.value;
    if (phoneNumber.length === 8) {
      const gasStationId = await retriveGasStationId();
      const getSenderAllTransRef = db
        .collection(collectionName)
        .orderBy("createdAt", "desc")
        .where("senderPhoneNumber", "==", `+226${phoneNumber}`)
        .limit(100);
      const getReceiverAllTransRef = db
        .collection(collectionName)
        .orderBy("createdAt", "desc")
        .where("receiverPhoneNumber", "==", `+226${phoneNumber}`)
        .limit(100);
      const senderTransRef = getAlltransactionRef
        .orderBy("createdAt", "desc")
        .where(
          "participantsIds",
          "array-contains",
          user?.userAgencySubRole
            ? agencyAgent?.id
            : user?.attendantRole
              ? gasStationId
              : company
                ? company.id
                : user?.id
        )
        .where("senderPhoneNumber", "==", `+226${phoneNumber}`)
        .limit(100);
      await db
        .collection(usersCollectionName)
        .where("phoneNumber", "==", `+226${phoneNumber}`)
        .get()
        .then((response) => {
          const user = response.docs.map((el) => {
            return { id: el.id, data: el.data() };
          });
          user.map((el) => {
            setUserName(el.data.displayName);
          });
        });
      const receiverTransRef = getAlltransactionRef
        .orderBy("createdAt", "desc")
        .where(
          "participantsIds",
          "array-contains",
          user?.userAgencySubRole
            ? agencyAgent?.id
            : user?.attendantRole
              ? gasStationId
              : company
                ? company.id
                : user?.id
        )
        .where("receiverPhoneNumber", "==", `+226${phoneNumber}`)
        .limit(100);
      isSender
        ? await (checkedValue ? getSenderAllTransRef : senderTransRef)
          .get()
          .then((response) => {
            const histories = response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            });
            setDefaultLoadHistories(histories.length);
            setTransactions(
              response.docs.map((d) => {
                return { id: d.id, data: d.data() };
              })
            );
            let sum = 0;
            const amount = response.docs.reduce(
              (accumulator, currentValue) =>
                accumulator + parseInt(currentValue.data().amount),
              sum
            );
            setTransactionTotalSum(amount);
          })
        : await (checkedValue ? getReceiverAllTransRef : receiverTransRef)
          .get()
          .then((response) => {
            const histories = response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            });
            setDefaultLoadHistories(histories.length);
            setTransactions(
              response.docs.map((d) => {
                return { id: d.id, data: d.data() };
              })
            );
            let sum = 0;
            const amount = response.docs.reduce(
              (accumulator, currentValue) =>
                accumulator + parseInt(currentValue.data().amount),
              sum
            );
            setTransactionTotalSum(amount);
          });
    }
  };
  const retrivePhoneNumber = async (phoneNumber: string) => {
    if (phoneNumber.length >= 12) {
      await db
        .collection(usersCollectionName)
        .where("phoneNumber", "==", phoneNumber)
        .get()
        .then((response) => {
          setUserData(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
        });
    } else {
      setUserData([]);
    }
  };

  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    const date = new Date(value.target.value);

    function subtractMonths(date: Date, months: number) {
      date.setMonth(date.getMonth() - months);

      return date;
    }

    const limitDate = subtractMonths(new Date(), 12);

    if (date.getTime() < limitDate.getTime()) {
      return setSetSeachWithStartDateValue(
        moment(limitDate).format("YYYY-MM-DD")
      );
    }

    setSetSeachWithStartDateValue(moment(date).format("YYYY-MM-DD"));
  };

  const handleChangeEndDateValue = (value: any) => {
    value.preventDefault();
    const date = new Date(value.target.value);

    function subtractMonths(date: Date, months: number) {
      date.setMonth(date.getMonth() - months);
      return date;
    }

    const limitDate = subtractMonths(new Date(), 12);

    if (date.getTime() < limitDate.getTime()) {
      return setSetSeachWithEndDateValue(
        moment(limitDate).format("YYYY-MM-DD")
      );
    } else if (date.getTime() > new Date().getTime()) {
      return setSetSeachWithEndDateValue(
        moment(new Date()).format("YYYY-MM-DD")
      );
    }

    setSetSeachWithEndDateValue(moment(date).format("YYYY-MM-DD"));
  };

  const getFilteredByDate = async () => {
    // const startTime = new Date(seachWithStartDateValue).getTime();
    const gasStationId = await retriveGasStationId();
    const startTime = getTimestamp(seachWithStartDateValue, true);
    const endTime = getTimestamp(seachWithEndDateValue, false);
    const getAllTransRef = db
      .collection(collectionName)
      .orderBy("createdAt", "desc")
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime);
    const userTransRef = getAlltransactionRef
      .orderBy("createdAt", "desc")
      .where(
        "participantsIds",
        "array-contains",
        user?.userAgencySubRole
          ? agencyAgent?.id
          : user?.attendantRole
            ? gasStationId
            : company
              ? company.id
              : user?.id
      )
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime);
    await (checkedValue ? getAllTransRef : userTransRef)
      .get()
      .then((response) => {
        const histories = response.docs.map((d) => {
          return { id: d.id, data: d.data() };
        });
        setDefaultLoadHistories(histories.length);
        setTransactions(
          response.docs.map((d) => {
            return { id: d.id, data: d.data() };
          })
        );
        let sum = 0;
        const amount = response.docs.reduce(
          (accumulator, currentValue) =>
            accumulator + parseInt(currentValue.data().amount),
          sum
        );
        setTransactionTotalSum(amount);
      });
  };

  const getFilteredByTransactionType = async (option: TransactionType) => {
    const gasStationId = await retriveGasStationId();
    const getAllTransRef = db
      .collection(collectionName)
      .orderBy("createdAt", "desc")
      .where("transactionType", "==", option ?? TransactionType.TRANSFER)
      .limit(100);
    const userTransRef = getAlltransactionRef
      .orderBy("createdAt", "desc")
      .where(
        "participantsIds",
        "array-contains",
        user?.userAgencySubRole
          ? agencyAgent?.id
          : user?.attendantRole
            ? gasStationId
            : company
              ? company.id
              : user?.id
      )
      .where("transactionType", "==", option ?? TransactionType.TRANSFER)
      .limit(100);
    await (checkedValue ? getAllTransRef : userTransRef)
      .get()
      .then((response) => {
        const histories = response.docs.map((d) => {
          return { id: d.id, data: d.data() };
        });
        setDefaultLoadHistories(histories.length);
        setTransactions(
          response.docs.map((d) => {
            return { id: d.id, data: d.data() };
          })
        );
        let sum = 0;
        const amount = response.docs.reduce(
          (accumulator, currentValue) =>
            accumulator + parseInt(currentValue.data().amount),
          sum
        );
        setTransactionTotalSum(amount);
      });
  };

  const getFilteredTransactionType = async (option: TransactionType) => {
    const startTime = getTimestamp(seachWithStartDateValue, true);
    const endTime = getTimestamp(seachWithEndDateValue, false);
    const getAllTransRef = db
      .collection(collectionName)
      .orderBy("createdAt", "desc")
      .where("transactionType", "==", option ?? TransactionType.TRANSFER)
      .where("participantsIds", "array-contains", company?.id)
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime);
    await getAllTransRef.get().then((trans) => {
      setTransactions(
        trans.docs.map((d) => {
          return { id: d.id, data: d.data() };
        })
      );
      let sum = 0;
      const amount = trans.docs.reduce(
        (accumulator, currentValue) =>
          accumulator + parseInt(currentValue.data().amount),
        sum
      );
      setTransactionTotalSum(amount);
    });
  };

  // const sankreportTransactionSuperDealer = functions.httpsCallable("sankreportTransactionSuperDealer")

  // const rapportToXLSXData = async () => {
  //   const startTime = getTimestamp(seachWithStartDateValue, true);
  //   const endTime = getTimestamp(seachWithEndDateValue, false);
  //   const toastId = toast.loading("Géneration de rapport...");
  //   await sankreportTransactionSuperDealer({
  //     email: user?.email,
  //     currentUserId: user?.id,
  //     startTime: startTime,
  //     endTime: endTime
  //   }).then((response) => {
  //     toast.success(`Rapport géneré et envoyé avec succès et envoyé a ${user?.email ?? ""}.`, {
  //       id: toastId,
  //     });
  //   }).catch((e) => {
  //     toast.error(e.message, {
  //       id: toastId,
  //     });
  //   })
  // }
  const getUserAllHistories = async () => {
    const gasStationId = await retriveGasStationId();
    const startTime = getTimestamp(seachWithStartDateValue, true);
    const endTime = getTimestamp(seachWithEndDateValue, false);
    await db
      .collection(collectionName)
      .orderBy("createdAt", "desc")
      .where(
        "participantsIds",
        "array-contains",
        user?.userAgencySubRole
          ? agencyAgent?.id
          : user?.attendantRole
            ? gasStationId
            : company
              ? company.id
              : user?.id
      )
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .limit(100)
      .get()
      .then((response) => {
        const histories = response.docs.map((d) => {
          return { id: d.id, data: d.data() };
        });
        setDefaultLoadHistories(histories.length);
        setTransactions(
          response.docs.map((d) => {
            return { id: d.id, data: d.data() };
          })
        );
        let sum = 0;
        const amount = response.docs.reduce(
          (accumulator, currentValue) =>
            accumulator + parseInt(currentValue.data().amount),
          sum
        );
        setTransactionTotalSum(amount);
      });
  };

  const getAllHistories = async () => {
    await db
      .collection(collectionName)
      .orderBy("createdAt", "desc")
      .limit(500)
      .get()
      .then((response) => {
        const histories = response.docs.map((d) => {
          return { id: d.id, data: d.data() };
        });
        setDefaultLoadHistories(histories.length);
        setTransactions(
          response.docs.map((d) => {
            return { id: d.id, data: d.data() };
          })
        );
        let sum = 0;
        const amount = response.docs.reduce(
          (accumulator, currentValue) =>
            accumulator + parseInt(currentValue.data().amount),
          sum
        );
        setTransactionTotalSum(amount);
      });
  };

  const handleCheckBoxChange = (checked: boolean) => {
    checked ? getAllHistories() : getUserAllHistories();
    setCheckedValue(checked);
  };

  useEffect(() => {
    if (user) {
      setCheckedValue(false);
      getUserAllHistories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageLimitChange = async (pageLimit: number) => {
    const startTime = getTimestamp(seachWithStartDateValue, true);
    const endTime = getTimestamp(seachWithEndDateValue, false);
    const gasStationId = await retriveGasStationId();
    setDefaultLoadHistories(pageLimit);
    const getAllTransRef = db
      .collection(collectionName)
      .orderBy("createdAt", "desc");
    const userTransRef = db
      .collection(collectionName)
      .orderBy("createdAt", "desc")
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .where(
        "participantsIds",
        "array-contains",
        user?.userAgencySubRole
          ? agencyAgent?.id
          : user?.attendantRole
            ? gasStationId
            : company
              ? company.id
              : user?.id
      );
    (checkedValue ? getAllTransRef : userTransRef)
      .limit(pageLimit)
      .get()
      .then((response) => {
        const data = response.docs.map((d) => {
          return { id: d.id, data: d.data() };
        });
        setDefaultLoadHistories(data.length);
        setPaginatdListData(data);
        setPage(page + 1);
        let sum = 0;
        const amount = response.docs.reduce(
          (accumulator, currentValue) =>
            accumulator + parseInt(currentValue.data().amount),
          sum
        );
        setTransactionTotalSum(amount);
      });
  };

  const onDetails = (row: any, rowId: string) => {
    if (row) {
      navigate(`/transactions/${rowId}/detail`, {
        state: { entity: row },
      });
    }
  };

  const getExportCSVData = useMemo(() => {
    const header = [
      { label: "Expéditeur", key: "senderPhoneNumber" },
      { label: "Destinataire", key: "receiverPhoneNumber" },
      { label: "Montant", key: "amount" },
      { label: "Type de Transaction", key: "transactionType" },
      { label: "Frais", key: "fees" },
      { label: "Type de compte", key: "accountType" },
      { label: "Statut", key: "status" },
      { label: "Date", key: "createdAt" },
    ];

    const data = transactions.map((transaction, index) => {
      const createdAt = new Date(
        parseInt(transaction.data.createdAt.seconds) * 1000
      );

      return {
        ...transaction.data,
        createdAt: createdAt.toISOString(),
        status: transaction.data.status ?? "---",
      };
    });

    return { header, data };
  }, [transactions]);

  const exportToXLSData = useCallback(() => {
    const worksheet = XLSX.utils.json_to_sheet(
      transactions.map((transaction) => {
        return {
          "Transaction ID": transaction.id,
          Expéditeur: transaction.data.senderPhoneNumber,
          "Nom d'Expéditeur": transaction.data.senderDisplayName,
          Destinataire: transaction.data.receiverPhoneNumber,
          "Nom du Destinataire": transaction.data.receiverDisplayName,
          Montant: transaction.data.amount,
          "Type de Transaction": transaction.data.transactionType ? transactionMap.get(transaction.data.transactionType) : "",
          Frais: transaction.data.fees,
          "Type de compte": transaction.data.accountType,
          // Statut: transaction.data.status ?? "---",
          Date: new Date(
            parseInt(transaction.data.createdAt.seconds) * 1000
          ).toISOString(),
        };
      })
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "export");

    XLSX.writeFile(workbook, "export.xlsx", { compression: true });
  }, [transactions]);

  const exportToPDFData = useCallback(() => {
    let data = (
      Object.keys(TransactionType) as Array<keyof typeof TransactionType>
    ).map((key) => {
      return {
        transactionType: key,
        data: transactions
          .filter((transaction) => transaction.data.transactionType === key)
          .map((transaction) => transaction.data),
      };
    });

    const finalData = data.map((element) => {
      let total_amount = 0;
      let total_seed = 0;
      let first_date = new Date();
      let last_date = new Date();

      element.data.forEach((el) => {
        total_amount += parseInt(el.amount);
        total_seed += parseInt(el.seed);

        const createdAt = new Date(parseInt(el.createdAt.seconds) * 1000);

        if (first_date.getTime() > createdAt.getTime()) {
          first_date = createdAt;
        }
      });

      return {
        "Type de transaction": element.transactionType.toString(),
        "Montant total": isNaN(total_amount) ? "0" : total_amount.toString(),
        "Frais total": isNaN(total_seed) ? "0" : total_seed.toString(),
        Début: first_date.toLocaleDateString(),
        Fin: last_date.toLocaleDateString(),
      };
    });

    var headers = [
      "Type de transaction",
      "Début",
      "Fin",
      "Montant total",
      "Frais total",
    ];

    var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape" });

    doc.setFontSize(32);
    doc.text("Résumer de l'historique de transaction", 35, 25);

    doc.table(20, 40, finalData, headers, { autoSize: true });
    doc.save("export.pdf");
  }, [transactions]);

  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              Mes historiques de transactions{" "}
              <span className="hidden text-xs font-medium text-red-500 md:inline-block">
                (
                {`${new Intl.NumberFormat().format(transactionTotalSum)}
                            Fcfa`}
                )
              </span>
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Vous trouverez ici l'ensemble de vos historiques de transactions.
            </p>
          </div>
          <div className="h-8" />
          <div className="flex self-end justify-between max-md:flex-col">
            <HasRoles userRole={["ADMIN"]}>
              <div className="mr-3 sm:mr-mr-3 lg:mr-mr-3">
                <div>
                  <label
                    htmlFor="checkbox"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Tout
                  </label>
                  <div className="mt-2 space-x-3 rounded-md shadow-sm">
                    <input
                      type="checkbox"
                      className="before:content[''] peer relative h-10 w-10 cursor-pointer appearance-none rounded-md border border-gray-300 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-red-gray-500 before:opacity-0 before:transition-opacity checked:border-red-500 checked:bg-red-500 checked:before:bg-red-500 hover:before:opacity-10"
                      id="checkbox"
                      checked={checkedValue}
                      onChange={(e) => handleCheckBoxChange(e.target.checked)}
                    />
                  </div>
                </div>
              </div>
            </HasRoles>
            <HasRoles userRole={["ADMIN", "AGENCYSTAFF", "TECHNICALSUPPORT"]}>
              <>
                <div className="mr-1 sm:mr-1 lg:mr-1">
                  <div>
                    <label
                      htmlFor="tel"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Téléphone
                    </label>
                    <div className="relative mt-2 space-x-3 rounded-md shadow-sm">
                      <input
                        type="tel"
                        id="tel"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="70707070"
                        onChange={getFilterdByPhoneNumber}
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center">
                        <select
                          name="currency"
                          className="h-full py-0 pl-2 text-gray-500 bg-transparent border-0 rounded-md pr-7 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm"
                          onChange={(e) => {
                            setIsSender(
                              e.target.value === senderOrReceiver ? true : false
                            );
                          }}
                        >
                          <option value={"sender"}>sender</option>
                          <option value={"receiver"}>receiver</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mr-1 sm:mr-1 lg:mr-1">
                  <label
                    htmlFor="transType"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Type de transaction
                  </label>
                  <select
                    id="transType"
                    name="currency"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                    onChange={(e) => {
                      getFilteredByTransactionType(
                        e.target.value as TransactionType
                      );
                    }}
                  >
                    {transctionTypeOptions.map((opt, index) => (
                      <option key={index} value={opt}>
                        {transactionMap.get(opt)}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            </HasRoles>
            <HasRoles userRole={["COMPANYSTAFF"]}>
              <>
                <button
                  type="button"
                  className="relative my-auto  inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 "
                  onClick={(e) => {
                    setShowPinCodeModal(true);
                  }}
                >
                  <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                    <MagnifyingGlassIcon
                      className="self-center flex-shrink-0 w-5 h-5 mr-1 text-orange-500"
                      aria-hidden="true"
                    />
                    Rechercher un bénéficiaire
                  </span>
                </button>
              </>
            </HasRoles>

            <HasRoles userRole={["COMPANYSTAFF"]}>
              <>
                <div className="mr-1 sm:mr-1 lg:mr-1">
                  <label
                    htmlFor="transType"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Type de transaction
                  </label>
                  <select
                    id="transType"
                    name="currency"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                    onChange={(e) => {
                      getFilteredTransactionType(
                        e.target.value as TransactionType
                      );
                    }}
                  >
                    {transactionType.map((opt, index) => (
                      <option key={index} value={opt}>
                        {transactionMap.get(opt)}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            </HasRoles>

            {/* Filter by date */}
            <div className="px-1 bg-white border border-gray-200 rounded-lg sm:flex-wrap md:flex-wrap lg:flex dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
              <div className="mr-1">
                <label
                  htmlFor="dateDebut"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date début
                </label>
                <input
                  type="date"
                  id="dateDebut"
                  value={seachWithStartDateValue}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  // defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeStartDateValue}
                />
              </div>
              <div className="mr-1">
                <label
                  htmlFor="dateFin"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date fin
                </label>
                <input
                  type="date"
                  id="dateFin"
                  value={seachWithEndDateValue}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  // defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeEndDateValue}
                />
              </div>
              <div className="flex items-center mt-5 h-14">
                <button
                  type="button"
                  className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  onClick={() => {
                    getFilteredByDate();
                  }}
                >
                  <FunnelIcon
                    className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                    aria-hidden="true"
                  />
                  Filtrer
                </button>
              </div>
            </div>
            {/* End Filter by date */}
            <HasRoles userRole={["ADMIN"]}>
              <div className="mx-2">
                <label
                  htmlFor="dateFin"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                 Exporter
                </label>
                <Menu as="div" className="relative flex-shrink-0">
                  <div>
                    <Menu.Button className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                      <ArrowDownTrayIcon
                        className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-20 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {
                        <Menu.Item>
                          <>
                            {
                              user?.userRole === UserRole.ADMIN && (
                                <>
                                  <div
                                    className={classNames(
                                      "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                    )}
                                  >
                                    <CSVLink
                                      headers={getExportCSVData.header}
                                      data={getExportCSVData.data}
                                    >
                                      CSV
                                    </CSVLink>
                                  </div>
                                  <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200" />
                                  <div
                                    className={classNames(
                                      "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                    )}
                                    onClick={() => {
                                      exportToPDFData();
                                    }}
                                  >
                                    PDF
                                  </div>
                                </>
                              )
                            }
                            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200" />
                            <div
                              className={classNames(
                                "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                              )}
                              onClick={() => {
                                exportToXLSData();
                              }}
                            >
                              XLS
                            </div>
                          </>
                        </Menu.Item>
                      }
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </HasRoles>

          </div>
        </div>
        <div className="flex justify-end w-full mt-2">
          <p>{user?.userRole === UserRole.COMPANYSTAFF && userName}</p>
        </div>
        <div className="flex flex-col mt-8">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm">
                <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      {tableHeader.map((th, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                        >
                          {th}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {transactions && transactions.length > 0 ? (
                      (paginatdlistData.length > 0
                        ? paginatdlistData
                        : transactions
                      ).map((transaction: any, transactionIdx: number) => (
                        <tr
                          key={transactionIdx}
                          className={
                            transactionIdx % 2 === 0
                              ? "bg-white"
                              : "bg-gray-100 cursor-pointer"
                          }
                        // onClick={() =>
                        //   onDetails(transaction.data, transaction.id)
                        // }
                        >
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(transactionIdx as number) + 1}
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(user?.userAgencySubRole
                              ? agencyAgent?.id
                              : user?.attendantRole
                                ? gasStationRelationId
                                : company
                                  ? company.id
                                  : user?.id) === transaction.data.senderID ? (
                              <img
                                className="w-10 h-10 p-1 border rounded-full"
                                src={
                                  transaction.data.receiverProfilImageUrl
                                    ? transaction.data.receiverProfilImageUrl
                                    : `https://ui-avatars.com/api/?background=random&name=${transaction.data.receiverDisplayName ||
                                    "S"
                                    }`
                                }
                                alt=""
                              />
                            ) : (
                              <img
                                className="w-10 h-10 p-1 border rounded-full lg:w-10"
                                src={
                                  transaction.data.senderProfilImageUrl
                                    ? transaction.data.senderProfilImageUrl
                                    : `https://ui-avatars.com/api/?background=random&name=${transaction.data.senderDisplayName ||
                                    "S"
                                    }`
                                }
                                alt=""
                              />
                            )}
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(user?.userAgencySubRole
                              ? agencyAgent?.id
                              : user?.attendantRole
                                ? gasStationRelationId
                                : company
                                  ? company.id
                                  : user?.id) === transaction.data.senderID
                              ? transaction.data.receiverDisplayName
                                ? transaction.data.receiverDisplayName
                                : "-"
                              : transaction.data.senderDisplayName
                                ? transaction.data.senderDisplayName
                                : "-"}
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(user?.userAgencySubRole
                              ? agencyAgent?.id
                              : user?.attendantRole
                                ? gasStationRelationId
                                : company
                                  ? company.id
                                  : user?.id) === transaction.data.senderID
                              ? (transaction.data.receiverPhoneNumber as string)
                              : (transaction.data.senderPhoneNumber as string)}
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {new Intl.NumberFormat().format(
                              transaction.data.amount
                            )}{" "}
                            Fcfa
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {transactionMap.get(
                              transaction.data.transactionType
                            )}
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {transaction.data.accountType === "MAIN" ? (
                              <img
                                className="w-auto h-5"
                                src={SankMoneyIcon}
                                alt="Sank"
                              />
                            ) : (
                              <BoltIcon className="w-6 h-6 text-red-500" />
                            )}
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {transaction.data.fees as string} Fcfa
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {transaction.data.isComplete ? (
                              <CheckCircleIcon className="w-6 h-6 text-green-500" />
                            ) : (
                              <XCircleIcon className="w-6 h-6 text-red-500" />
                            )}
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(transaction.data.createdAt as Timestamp)
                              .toDate()
                              .toLocaleString()}
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            <TableRowEditMenu
                              rowTransDetailsHandler={() =>
                                onDetails(transaction.data, transaction.id)
                              }
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoContent />
                    )}
                  </tbody>
                </table>

                {!checkedValue && transactions && transactions.length > 0 && (
                  <Pagination
                    skip={defaultLoadHistories}
                    take={defaultLoadHistories}
                    total={defaultLoadHistories}
                    collectionName={collectionName}
                    isChangedPage={true}
                    onPageChange={(pageIndex: number) => pageIndex * page}
                    onPageLimitChange={(pageLimit: number) =>
                      onPageLimitChange(pageLimit)
                    }
                    balance={transactionTotalSum}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={showPinCodeModal}
          maxSize="lg"
          closeModal={() => setShowPinCodeModal(false)}
          modalTitle="Rechercher un bénéficiaire"
        >
          <div className="flex justify-center">
            <div className="w-full mt-10">
              <PhoneInput
                international={false}
                placeholder="Entrer un numéro de téléphone"
                className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                defaultCountry="BF"
                onChange={retrivePhoneNumber}
              />
              <div className="flex justify-center w-full">
                {userData && userData.length > 0 && (
                  <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <div className="flex flex-col items-center pb-10">
                      <img
                        className="w-24 h-24 mt-2 mb-3 rounded-full shadow-lg"
                        src={
                          userData[0].data.profilImageUrl
                            ? userData[0].data.profilImageUrl
                            : `https://ui-avatars.com/api/?background=random&name=${userData[0].data.displayName || "S"
                            }`
                        }
                        alt=""
                      />
                      <h5 className="mb-1 text-xl font-medium text-center text-gray-900 dark:text-white">
                        {userData[0].data.displayName}
                      </h5>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {userData[0].data.phoneNumber}
                      </span>
                      <div className="flex mt-4 space-x-3 md:mt-5">
                        <div
                          className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                          onClick={() =>
                            navigate(
                              `/transactions/${userData[0].id}/details`,
                              {
                                state: {
                                  entity: userData[0].data,
                                  id: userData[0].id,
                                  isSearch: true,
                                  user: company ?? company?.id,
                                },
                              }
                            )
                          }
                        >
                          Details
                        </div>
                        <div
                          className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                          onClick={() => {
                            setShowPinCodeModal(false);
                            setUserData([]);
                          }}
                        >
                          Annuler
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default HistoriquesPage;
