/* eslint-disable jsx-a11y/no-redundant-roles */
import {
  InformationCircleIcon,
  DocumentMinusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../contexts";
import {
  amountFormater,
  checkTransactionFeesSchedule,
  classNames,
  isNumeric,
  TransactionFees,
} from "../../../../utils";
import { Form, FormInput } from "../../../../components/FormComponents";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { db, functions } from "../../../../firebase";
import { penalityCalculation, sortInvoicesByDeadline } from "./utils";
import {
  globalConfigCollectionsName,
  feesConfigId,
  agentTransfeesLevelSubCollectionName,
  oneaPostPaidFeesLevelsDocId,
  accountsCollectionName,
  defaultAmount,
  usersCollectionName,
} from "../../../../config";
import CodePinComponent from "../../../../components/CodePinComponent";
import Modal from "../../../../components/Modal";

type RetrieveInvoiceInfo = { metterNum: string };
type unPaindInvoiceType = {
  id: string;
  custumerName: string;
  periode: string;
  metterNum: string;
  amount: number;
  order: number;
  invoiceType: any;
  selected: boolean;
};

type Step = {
  id: "invoiceInfos" | "paymentDetails";
  index: number;
  name: string;
  description: string;
  icon: any;
};

const steps: Step[] = [
  {
    id: "invoiceInfos",
    index: 0,
    name: "Les informations du compteur",
    description: "Renseigner les informations du compteur",
    icon: InformationCircleIcon,
  },
  {
    id: "paymentDetails",
    index: 1,
    name: "Les details du paiement",
    description: "Vérifiez les details du paiement",
    icon: DocumentMinusIcon,
  },
];

const PaidOneaInvoicesPage = () => {
  const navigate = useNavigate();
  const [currentFormStep, setCurrentFormStep] = useState<Step>(steps[0]);
  const { user, setUserAccounts } = useContext(UserContext);
  const [invoices, setInvoices] = useState<any>();
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountWithFess, setTotalAmountWithFess] = useState(0);
  const [fees, setFees] = useState(0);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [penality, setPenality] = useState(0);
  const [invoicesIds, setInvoicesIds] = useState<string[]>();

  const retrievePostPaidInvoiceInfos = async (
    retriveInfoData: RetrieveInvoiceInfo
  ) => {
    const agentPaidONEAPostpaidInvoicesFn = functions.httpsCallable(
      "sankMoneyFactureONEA-retrieveInvoices"
    );
    if (!user) {
      return;
    }
    const toastId = toast.loading("Consultation en cours...");
    const data = {
      currentUserId: user?.id,
      withConnection: true,
      appVersion: "web",
      appPlatform: "web",
      metterNum: retriveInfoData.metterNum,
    };
    await agentPaidONEAPostpaidInvoicesFn(data).then((res) => {
      if (res.data.statusCode && res.data.statusCode !== 200) {
        return toast.error("Pas de facture disponible", { id: toastId });
      }
      if (res.data.data.length <= 0) {
        return toast.error("Pas de facture disponible", { id: toastId });
      }

      const metterInvoices: any[] = [];
      if (res.data.data && res.data.data.length > 0) {
        const sortedInv = sortInvoicesByDeadline(res.data.data);
        const sortedInvWithXP = penalityCalculation(res.data.data);
        let i = 0;
        const ids: any[] = [];
        for (const invoice of sortedInv) {
          ids.push(invoice.id);
          const vals: unPaindInvoiceType = {
            periode: `${invoice.dateofindexsamplingpart2}/${invoice.dateofindexsamplingpart1}`,
            amount: parseFloat(`${invoice.amount}`),
            invoiceType: invoice.invoicetype,
            selected: false,
            id: invoice.id,
            custumerName: invoice.subscribername,
            order: i++,
            metterNum: retriveInfoData.metterNum,
          };
          metterInvoices.push(vals);
        }
        setInvoicesIds(ids);
        const penalityAmount = sortedInvWithXP.reduce(
          (amount, inv) => amount + parseFloat(inv.amount),
          0
        );
        setPenality(penalityAmount);
      }

      setInvoices(metterInvoices);
      setCurrentFormStep(steps[1]);
      toast.success("ok", { id: toastId });
    });
  };

  const handleSelectedPeriodeToPay = (
    index: number,
    inv: unPaindInvoiceType,
    checked: boolean
  ) => {
    const data: any[] = [];
    for (const invoice of invoices) {
      data.push({
        ...invoice,
        selected: invoice.order <= inv.order ? true : false,
      });
    }
    const total = (data as [])
      .filter((inv: any) => inv.selected === true)
      .reduce((amount, inv: any) => amount + inv.amount, 0);

    const ids = (data as [])
      .filter((inv: any) => inv.selected === true)
      .map((inv: unPaindInvoiceType) => {
        return inv.id;
      });

    setInvoicesIds(ids);
    setTotalAmount(total);
    setInvoices(data);
  };

  const onSubmitInvoices = async (pinCode: string) => {
    const agentPaidOneaPostpaidInvoicesFn = functions.httpsCallable(
      "agentPaidInvoices-agentPaidOneaPrepaidInvoicesFn"
    );
    setIsCodePinBtnDesabled(true);
    if (invoices && invoices.length <= 0) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      return toast.error("Veuillez selectionner au moins une facture à payer");
    }
    if (!isNumeric(`${totalAmount}`)) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      return toast.error(`Le montant n'est pas bien formatté`);
    }
    if (`${totalAmount}` < defaultAmount) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      return toast.error(
        `Le montant doit être suppérieur à ${defaultAmount} Fcfa`
      );
    }

    setIsLoad(true);
    const data: any = {
      pinCode: pinCode,
      amount: `${totalAmount}`,
      withConnection: true,
      appVersion: "web",
      appPlatform: "web",
      oneaInvoiceIds: invoicesIds,
      metterNum:
        invoices && invoices.length > 0 ? invoices[0].metterNum : undefined,
      currentUserId: user?.id,
    };
    await agentPaidOneaPostpaidInvoicesFn(data)
      .then(async () => {
        setShowPinCodeModal(false);
        setIsLoad(false);
        const senderAccountRef = db
          .collection(usersCollectionName)
          .doc(user?.id)
          .collection(accountsCollectionName);
        const accounts = await senderAccountRef.get();
        setUserAccounts(accounts.docs.map((account) => account.data()));
        navigate("/dashboard");
        return toast.success("La facture a été payée avec succès.");
      })
      .catch((err: { details: any; message: any }) => {
        setShowPinCodeModal(false);
        setIsCodePinBtnDesabled(false);
        setIsLoad(false);
        toast.error(err.message);
      });
  };

  const retrieveFees = useCallback(async () => {
    const sbnlPostpaidFeeSnap = await db
      .collection(globalConfigCollectionsName)
      .doc(feesConfigId)
      .collection(agentTransfeesLevelSubCollectionName)
      .doc(oneaPostPaidFeesLevelsDocId)
      .get();
    const postpaidFeesData = sbnlPostpaidFeeSnap.data();
    const amountWithFees = checkTransactionFeesSchedule(
      postpaidFeesData as TransactionFees,
      totalAmount
    ).amountWithFees;
    setTotalAmountWithFess(amountWithFees + penality);
    const fees = checkTransactionFeesSchedule(
      postpaidFeesData as TransactionFees,
      totalAmount
    ).fees;
    setFees(fees);
  }, [penality, totalAmount]);

  useEffect(() => {
    retrieveFees();
  }, [retrieveFees]);

  return (
    <>
      <div className="lg:border-t lg:border-b lg:border-gray-200">
        <nav
          className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
          aria-label="Progress"
        >
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
          >
            <li className="relative px-2 py-4 overflow-hidden lg:py-2">
              <button
                className="p-1 border border-transparent rounded-full hover:border-gray-200 hover:bg-gray-50"
                onClick={() => navigate("/dashboard")}
              >
                <XMarkIcon className="w-8 h-8 text-gray-700" />
              </button>
            </li>
            {steps.map((step, stepIdx) => (
              <li key={stepIdx} className="relative overflow-hidden lg:flex-1">
                <div
                  className={classNames(
                    stepIdx === 0 ? "border-b-0 rounded-t-md" : "",
                    stepIdx === steps.length - 1
                      ? "border-t-0 rounded-b-md"
                      : "",
                    "border border-gray-200 overflow-hidden lg:border-0 h-full"
                  )}
                >
                  <button
                    className={classNames(
                      "w-full group h-full cursor-not-allowed"
                    )}
                  >
                    <span
                      className={classNames(
                        step.index !== currentFormStep.index
                          ? "group-hover:bg-gray-200 bg-transparent "
                          : "bg-red-600",
                        "absolute top-0 left-0 w-1 h-full  lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "px-4 py-4 lg:py-2 flex items-start text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span
                          className={classNames(
                            step.index < currentFormStep.index
                              ? "bg-red-600"
                              : "",
                            step.index === currentFormStep.index
                              ? "bg-white border-2 border-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "bg-white border-2 border-gray-300"
                              : "",
                            "flex items-center justify-center w-10 h-10  rounded-full"
                          )}
                        >
                          <step.icon
                            className={classNames(
                              step.index < currentFormStep.index
                                ? "text-white"
                                : "",
                              step.index === currentFormStep.index
                                ? "text-red-600"
                                : "",
                              step.index > currentFormStep.index
                                ? "text-gray-500"
                                : "",
                              "w-6 h-6 "
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex-col flex  xl:flex">
                        <span
                          className={classNames(
                            step.index === currentFormStep.index
                              ? "text-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "text-gray-500"
                              : "",
                            "text-xs font-semibold tracking-wide uppercase md:block lg:hidden xl:block"
                          )}
                        >
                          {step.name}
                        </span>
                        <span className="flex items-start text-sm font-medium text-gray-500">
                          {step.description}
                        </span>
                      </span>
                    </span>
                  </button>
                  <div
                    className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="w-full h-full text-gray-300"
                      viewBox="0 0 12 82"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0.5 0V31L10.5 41L0.5 51V82"
                        stroke="currentcolor"
                        vectorEffect="non-scaling-stroke"
                      />
                    </svg>
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="items-center w-full mx-auto md:w-11/12 lg:w-9/12">
          {currentFormStep.id === "invoiceInfos" && (
            <Form<RetrieveInvoiceInfo>
              onSubmit={retrievePostPaidInvoiceInfos}
              form={{
                resolver: yupResolver(
                  yup.object().shape({
                    metterNum: yup
                      .string()
                      .required("Le champs numéro de compteur est obligatoire"),
                  })
                ),
              }}
              submitButtonLabel="Consulter"
            >
              <div className="flex flex-col content-center w-full px-5 mt-5">
                <div className="w-full mx-auto ">
                  <FormInput
                    name="metterNum"
                    label="Numéro d'abonné"
                    placeholder="11016915181001"
                  />
                </div>
              </div>
            </Form>
          )}
          {currentFormStep.id === "paymentDetails" && (
            <div className="my-6 border border-red-300 rounded-lg shadow-lg">
              <div className="p-4 overflow-y-auto sm:p-7">
                <div className="text-center">
                  <h3
                    id="hs-ai-modal-label"
                    className="text-lg font-semibold text-gray-800 dark:text-neutral-200"
                  >
                    Factures ONEA
                  </h3>
                  <div className="overflow-auto md:overflow-scroll">
                    <span className="text-sm text-gray-500 dark:text-neutral-500">
                      #{invoicesIds?.join("#")}
                    </span>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-5 mt-5 sm:mt-10 sm:grid-cols-2">
                  <div>
                    <span className="block text-xs text-gray-500 uppercase dark:text-neutral-500">
                      Nom du client:
                    </span>
                    <span className="block text-sm font-medium text-gray-800 dark:text-neutral-200">
                      {invoices &&
                      invoices.length > 0 &&
                      invoices[0].custumerName
                        ? invoices[0].custumerName
                        : ""}
                    </span>
                  </div>

                  <div>
                    <span className="block text-xs text-gray-500 uppercase dark:text-neutral-500">
                      N° d'abonné:
                    </span>
                    <span className="block text-sm font-medium text-gray-800 dark:text-neutral-200">
                      {invoices && invoices.length > 0 && invoices[0].metterNum
                        ? invoices[0].metterNum
                        : ""}
                    </span>
                  </div>
                </div>

                <div className="mt-5 sm:mt-10">
                  <h4 className="text-xs font-semibold text-gray-800 uppercase dark:text-neutral-200">
                    Périodes de consommation
                  </h4>

                  <ul className="flex flex-col mt-3">
                    <li className="flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border border-gray-200 gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200">
                      <div className="w-full">
                        <span></span>
                      </div>
                      <div className="w-full">
                        <span>N°</span>
                      </div>
                      <div className="w-full">
                        <span>Date</span>
                      </div>
                      <div className="w-full">
                        <span>Type</span>
                      </div>
                      <div className="w-full">
                        <span>Montant</span>
                      </div>
                    </li>
                    {invoices && invoices ? (
                      invoices.map((inv: unPaindInvoiceType, index: number) => (
                        <li
                          key={index}
                          className="flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border border-gray-200 gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200"
                        >
                          <div className="flex items-center w-full">
                            <input
                              id="default-checkbox"
                              type="checkbox"
                              checked={inv.selected}
                              onChange={(e) =>
                                handleSelectedPeriodeToPay(
                                  index,
                                  inv,
                                  e.target.checked
                                )
                              }
                              className="w-4 h-4 text-red-600 bg-gray-100 border-gray-300 rounded-sm focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>
                          <div className="flex items-center w-full">
                            <span>{inv.order + 1}</span>
                          </div>
                          <div className="flex items-center w-full">
                            <span>{inv.periode}</span>
                          </div>
                          <div className="flex items-center w-full">
                            <span>{inv.invoiceType}</span>
                          </div>
                          <div className="flex items-center w-full">
                            <span>{inv.amount}</span>
                          </div>
                        </li>
                      ))
                    ) : (
                      <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border border-gray-200 gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200">
                        <div className="flex items-center justify-between w-full">
                          <span>Pas de factures</span>
                        </div>
                      </li>
                    )}
                    <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border border-gray-200 gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200">
                      <div className="flex items-center justify-between w-full">
                        <span>Montant total sélectionné</span>
                        <span>{amountFormater(totalAmount)} Fcfa</span>
                      </div>
                    </li>
                    <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border border-gray-200 gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200">
                      <div className="flex items-center justify-between w-full">
                        <span>Penalité</span>
                        <span>{amountFormater(penality)} Fcfa</span>
                      </div>
                    </li>
                    <li className="inline-flex items-center px-4 py-3 -mt-px text-sm font-semibold text-gray-800 border border-gray-200 gap-x-2 bg-gray-50 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200">
                      <div className="flex items-center justify-between w-full">
                        <span>Frais</span>
                        <span>{fees} Fcfa</span>
                      </div>
                    </li>
                    <li className="inline-flex items-center px-4 py-3 -mt-px text-sm font-semibold text-gray-800 bg-gray-100 border border-gray-200 gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200">
                      <div className="flex items-center justify-between w-full">
                        <span>Net à payé</span>
                        <span>{amountFormater(totalAmountWithFess)} Fcfa</span>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="flex justify-end my-5 gap-x-2">
                  <div
                    onClick={() => setShowPinCodeModal(true)}
                    className="inline-flex items-center px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-lg cursor-pointer gap-x-2 hover:bg-red-700 focus:outline-hidden focus:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                  >
                    Payé ({amountFormater(totalAmountWithFess)} Fcfa)
                  </div>
                </div>

                {/* <div className="mt-5 sm:mt-10">
                  <div className="text-sm text-gray-500 dark:text-neutral-500">
                    Si vous avez des questions, veuillez nous contacter à
                    l'adresse{" "}
                    <div className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline focus:outline-hidden focus:underline font-medium dark:text-blue-500">
                      contact@sankbusiness.com
                    </div>{" "}
                    ou nous appeler au{" "}
                    <a
                      className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline focus:outline-hidden focus:underline font-medium dark:text-blue-500"
                      href="tel:+22668202034"
                    >
                      +226 68-20-20-34
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          )}
        </div>
        <Modal
          isOpen={showPinCodeModal}
          maxSize="md"
          closeModal={() => setShowPinCodeModal(false)}
        >
          <div className="flex justify-center">
            <CodePinComponent
              title={` Entrez votre code pin pour payé ${amountFormater(
                totalAmountWithFess
              )} FCFA`}
              onSubmit={onSubmitInvoices}
              disabled={isCodePinBtnDesabled}
              isLoad={isLoad}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default PaidOneaInvoicesPage;
