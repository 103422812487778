import { classNames } from "../../utils";

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames(
        "animate-pulse rounded-md bg-red-500/30",
        className ?? ""
      )}
      {...props}
    />
  );
}

export { Skeleton };