/* eslint-disable jsx-a11y/no-redundant-roles */
import {
  mapUserAvailableZone,
  userAvailableZonesOptions,
  UserInterface,
  UserRole,
} from "../../../interfaces";
import { useEffect, useState } from "react";
import {
  Form,
  FormInput,
  FormSelect,
} from "../../../components/FormComponents";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { db, functions } from "../../../firebase";
import { classNames } from "../../../utils";
import {
  AtSymbolIcon,
  XMarkIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { usersCollectionName } from "../../../config";
import PhoneInput from "react-phone-number-input";

const mapsGender = new Map<string, string>([
  ["MALE", "Male"],
  ["FEMALE", "Female"],
  ["NONBINARY", "Non binaire"],
  ["UNKNOWN", "Inconnu"],
]);
type AdditionnalInfos = Pick<
  UserInterface,
  | "displayName"
  | "email"
  | "active"
  | "agence"
  | "city"
  | "adresse"
  | "cnibNumber"
  | "gender"
  | "phoneNumber"
  | "profession"
  | "userRole"
  | "createdAt"
  | "updatedAt"
  | "availableZone"
>;

const collectionName = usersCollectionName;
type Step = {
  id: "additionnalInfo" | "affiliateDealer";
  index: number;
  name: string;
  description: string;
  icon: any;
};
const steps: Step[] = [
  {
    id: "additionnalInfo",
    index: 0,
    name: "Informations additionnelles",
    description: "Renseigner les informations additionnelles de l'utilisateur",
    icon: AtSymbolIcon,
  },
  {
    id: "affiliateDealer",
    index: 1,
    name: "Affiliation",
    description: "Affilier le distributeur à un superviseur",
    icon: UserCircleIcon,
  },
];

const EditDealer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = location.state?.entity;
  const isAffiliated = location.state?.affiliate;
  const [currentFormStep, setCurrentFormStep] = useState<Step>(steps[0]);
  const [supervisorToAffiliate, setSupervisorToAffiliate] = useState<any>(null);
  const userRef = db.collection(usersCollectionName);

  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );

  const onSubmitAdditionnalInfo = (data: any) => {
    if (currentUser) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: collectionName,
        documentData: {
          id: currentUser.id,
          ...data,
        },
        documentId: currentUser.id,
      })
        .then(() => {
          navigate("/dealers");
        })
        .catch((err: { details: any; message: any }) =>
          toast.error(err.details.message)
        );
      toast.promise(response, {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success:
          "Les informations du distributeur a été mis a jour avec succès",
        loading: "chargement...",
      });
    } else
      toast.error(
        `L'indentifiant du dealer est incorrect veuiller réessayer de nouveau !`
      );
  };

  const getSupervisorToAffiliate = async (phoneNumber: string) => {
    if (phoneNumber.length >= 12) {
      const supervisor = await userRef
        .where("userRole", "==", UserRole.SUPERVISOR)
        .where("phoneNumber", "==", phoneNumber)
        .get();
      if (supervisor.docs.length > 0) {
        setSupervisorToAffiliate(supervisor.docs[0].data());
      } else {
        toast.error("Le superviseur n'existe pas !");
      }
    }
  };

  const onSubmitAffiliateDealer = (id: string) => {
    if (currentUser) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: collectionName,
        documentData: {
          supervisorId: id,
        },
        documentId: currentUser.id,
      })
        .then(() => {
          navigate("/dealers");
        })
        .catch((err: { details: any; message: any }) =>
          toast.error(err.details.message)
        );
      toast.promise(response, {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success:
          "Les informations du distributeur a été mis a jour avec succès",
        loading: "chargement...",
      });
    } else
      toast.error(
        `L'indentifiant du dealer est incorrect veuiller réessayer de nouveau !`
      );
  };

  useEffect(() => {
    currentUser && isAffiliated && setCurrentFormStep(steps[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="lg:border-t lg:border-b lg:border-gray-200">
        <nav
          className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
          aria-label="Progress"
        >
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
          >
            <li className="relative px-2 py-4 overflow-hidden lg:py-2">
              <button
                className="p-1 border border-transparent rounded-full hover:border-gray-200 hover:bg-gray-50"
                onClick={() => navigate("/dealers")}
              >
                <XMarkIcon className="w-8 h-8 text-gray-700" />
              </button>
            </li>
            {steps.map((step, stepIdx) => (
              <li key={stepIdx} className="relative overflow-hidden lg:flex-1">
                <div
                  className={classNames(
                    stepIdx === 0 ? "border-b-0 rounded-t-md" : "",
                    stepIdx === steps.length - 1
                      ? "border-t-0 rounded-b-md"
                      : "",
                    "border border-gray-200 overflow-hidden lg:border-0 h-full"
                  )}
                >
                  <button
                    className={classNames(
                      !currentUser ? "cursor-not-allowed" : "",
                      currentUser && step.index === 2 ? "" : "",
                      "w-full group h-full"
                    )}
                  >
                    <span
                      className={classNames(
                        step.index !== currentFormStep.index
                          ? "group-hover:bg-gray-200 bg-transparent "
                          : "bg-red-600",
                        "absolute top-0 left-0 w-1 h-full  lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "px-4 py-4 lg:py-2 flex items-start text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span
                          className={classNames(
                            step.index < currentFormStep.index
                              ? "bg-red-600"
                              : "",
                            step.index === currentFormStep.index
                              ? "bg-white border-2 border-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "bg-white border-2 border-gray-300"
                              : "",
                            "flex items-center justify-center w-10 h-10  rounded-full"
                          )}
                        >
                          <step.icon
                            className={classNames(
                              step.index < currentFormStep.index
                                ? "text-white"
                                : "",
                              step.index === currentFormStep.index
                                ? "text-red-600"
                                : "",
                              step.index > currentFormStep.index
                                ? "text-gray-500"
                                : "",
                              "w-6 h-6 "
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex-col flex  xl:flex">
                        <span
                          className={classNames(
                            step.index === currentFormStep.index
                              ? "text-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "text-gray-500"
                              : "",
                            "text-xs font-semibold tracking-wide uppercase md:block lg:hidden xl:block"
                          )}
                        >
                          {step.name}
                        </span>
                        <span className="flex items-start text-sm font-medium text-gray-500">
                          {step.description}
                        </span>
                      </span>
                    </span>
                  </button>
                  <div
                    className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="w-full h-full text-gray-300"
                      viewBox="0 0 12 82"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0.5 0V31L10.5 41L0.5 51V82"
                        stroke="currentcolor"
                        vectorEffect="non-scaling-stroke"
                      />
                    </svg>
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="items-center w-full mx-auto md:w-11/12 lg:w-9/12">
        {currentFormStep.id === "additionnalInfo" && (
          <Form<AdditionnalInfos>
            onSubmit={onSubmitAdditionnalInfo}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  displayName: yup
                    .string()
                    .required("Le champs est obligatoire"),
                  email: yup.string().required("Le champs est obligatoire"),
                  city: yup.string().optional(),
                  profession: yup.string().optional(),
                  adresse: yup.string().optional(),
                  cnibNumber: yup.string().optional(),
                  phoneNumber: yup.string().optional(),
                  availableZone: yup.string().optional(),
                })
              ),
              defaultValues: {
                displayName: currentUser ? currentUser.displayName : "",
                city: currentUser ? currentUser.city : "",
                adresse: currentUser ? currentUser.adresse : "",
                profession: currentUser ? currentUser.profession : "",
                phoneNumber: currentUser ? currentUser.phoneNumber : "",
                cnibNumber: currentUser ? currentUser.cnibNumber : "",
                email: currentUser ? currentUser.email : "",
              },
            }}
            submitButtonLabel={`${
              currentUser ? "Ajouter le" : "Editer le"
            } distributeur`}
          >
            <div className="flex flex-col content-center w-full px-5 mt-5 align-top md:flex-row">
              <div className="w-full mx-auto md:w-1/2">
                <FormInput
                  name="displayName"
                  label="Nom/Prénom"
                  placeholder="John Doe"
                />
                <FormInput
                  name="city"
                  label="Ville"
                  optional
                  placeholder="Ouagadougou"
                />
                <FormInput
                  name="adresse"
                  label="Adresse"
                  placeholder="Rue 10.34, secteur 30, Ouaga2000"
                />
                <FormInput
                  name="email"
                  label="E-mail"
                  placeholder="@exemple.com"
                />
                <FormInput
                  name="profession"
                  label="Profession"
                  optional
                  placeholder="Agent"
                />
              </div>
              <div className="w-10 h-5"></div>
              <div className="w-full mx-auto md:w-1/2">
                <FormInput
                  name="phoneNumber"
                  label="Numéro de téléphone"
                  placeholder="7X XX XX XX"
                  disabled
                />
                <FormInput
                  name="cnibNumber"
                  label="Numéro de CNIB"
                  placeholder="BXXXXXXX"
                />
                <FormSelect<string>
                  label={"Zone"}
                  name="availableZone"
                  selectedOption={
                    currentUser ? currentUser.availableZone : "Choisir..."
                  }
                  options={userAvailableZonesOptions}
                  optionLabel={(option) =>
                    (mapUserAvailableZone.get(option) as string) ?? "Choisir..."
                  }
                />
                <FormSelect<string>
                  label={"Genre"}
                  name="gender"
                  selectedOption={
                    (currentUser?.gender as string) ?? "Choisir..."
                  }
                  options={["FEMALE", "MALE", "NONBINARY", "UNKNOWN"]}
                  optionLabel={(option) =>
                    (mapsGender.get(option) as string) ?? "Choisir..."
                  }
                />
              </div>
            </div>
          </Form>
        )}
        {currentFormStep.id === "affiliateDealer" && (
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="lg:border-t lg:border-b lg:border-gray-200">
              <div className="items-center w-full mx-auto md:w-11/12 lg:w-9/12">
                <div className="mt-3 main-form">
                  <div className="items-center justify-between ">
                    <div className="items-center justify-between flex-1 max-w-sm">
                      <PhoneInput
                        international={false}
                        placeholder="Entrer un numéro de téléphone"
                        className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                        defaultCountry="BF"
                        onChange={getSupervisorToAffiliate}
                      />
                    </div>
                    {supervisorToAffiliate && (
                      <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <div className="flex flex-col items-center pb-10">
                          <img
                            className="w-24 h-24 mb-3 rounded-full shadow-lg"
                            src={
                              supervisorToAffiliate.profilImageUrl
                                ? supervisorToAffiliate.profilImageUrl
                                : `https://ui-avatars.com/api/?background=random&name=${
                                    supervisorToAffiliate?.displayName || "S"
                                  }`
                            }
                            alt=""
                          />
                          <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                            {supervisorToAffiliate.displayName}
                          </h5>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {supervisorToAffiliate.phoneNumber}
                          </span>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {supervisorToAffiliate.email}
                          </span>
                          <div className="flex mt-4 space-x-3 md:mt-6">
                            <div
                              className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                              onClick={() =>
                                onSubmitAffiliateDealer(
                                  supervisorToAffiliate.id
                                )
                              }
                            >
                              Soumettre
                            </div>
                            <div
                              className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg cursor-pointer hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                              onClick={() => setSupervisorToAffiliate(null)}
                            >
                              Annuler
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditDealer;
