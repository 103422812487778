import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowDownTrayIcon,
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { FunnelIcon } from "@heroicons/react/20/solid";
import {
  amountFormater,
  classNames,
  getTimestamp,
  parseDate,
} from "../../utils";
import moment from "moment";
import PhoneInput from "react-phone-number-input";
import { Timestamp } from "firebase-admin/firestore";
import NoContent from "../../components/TableNoContentPage/NoContent";
import Pagination from "../../components/Pagination/Pagination";
import {
  PhoneNumberDefaultLength,
  referralsCollectionName,
  usersCollectionName,
} from "../../config";
import * as XLSX from "xlsx";
import toast from "react-hot-toast";
import { db } from "../../firebase";
import TableRowEditMenu from "../../components/TableRowEditMenu/TableRowEditMenu";
import { UserContext } from "../../contexts";
import { AgencyStaffRole, UserRole } from "../../interfaces";
import { useNavigate } from "react-router-dom";

const tableHeader = [
  "N°",
  "Date de création",
  "Nom de l'affiliant",
  "Numéros de l'affiliant",
  "Total première recharge",
  "Montant total de la première recharge",
  "Total des utilisateurs affiliés",
  "Tag",
  "Actions",
];
const tableHeaders = [
  "N°",
  "Date de création",
  "Nom/prénom",
  "Téléphone",
  "Email",
  "Ville",
  "prémière recharge",
  "Active",
];

const supervisorTableHeader = [
  "N°",
  "",
  "Nom/prénom",
  "Email",
  "Ville",
  "Téléphone",
  "Active",
  "Date de création",
  "Actions",
];

const ReferralHistoriesPage = () => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [reverrals, setReverrals] = useState<any[]>([]);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [startTime, setStartTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  );
  const [endTime, setEndTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, false)
  );
  const [defaultLimitQuery, setDefaultLimitQuery] = useState(10);
  const [filterPhoneNumber, setFilterPhoneNumber] = useState(false);
  const [filterDate, setFilterDate] = useState(false);
  const [firstRecharge, setFirstRecharge] = useState(0);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [affiliatedUsers, setAffiliatedUsers] = useState<any[]>([]);
  const [affiliatedToSupervisor, setAffiliatedToSupervisor] = useState<any[]>(
    []
  );
  const [isSeller, setIsSeller] = useState<boolean>(false);

  const userRef = db.collection(usersCollectionName);

  const getFilterdByPhoneNumber = async (phoneNumber: any) => {
    if (phoneNumber !== undefined) {
      if (phoneNumber?.length >= PhoneNumberDefaultLength + 4) {
        setUserPhoneNumber(phoneNumber);
      }
    } else {
      setUserPhoneNumber("");
      setReverrals([]);
    }
  };

  const handleChangeStartDateValue = (value: any) => {
    const startTime = getTimestamp(value.target.value, true);
    setStartTime(startTime);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    const endTime = getTimestamp(value.target.value, false);
    setEndTime(endTime);
  };

  const filter = async () => {
    if (userPhoneNumber && filterPhoneNumber) {
      if (startTime > endTime) {
        toast.error(
          "Il est préférable de changer la date avant de réessayer, car la date de début ne doit pas être supérieur à la date de fin."
        );
      } else
        await db
          .collection(referralsCollectionName)
          .where("referralPhoneNumber", "==", userPhoneNumber)
          .where("createdAt", ">=", startTime)
          .where("createdAt", "<=", endTime)
          .get()
          .then((res) => {
            retriveData(res);
          });
    } else if (
      !userPhoneNumber &&
      !filterPhoneNumber &&
      firstRecharge &&
      startTime &&
      endTime
    ) {
      if (startTime > endTime) {
        toast.error(
          "Il est préférable de changer la date avant de réessayer, car la date de début ne doit pas être supérieur à la date de fin."
        );
      } else
        await db
          .collection(referralsCollectionName)
          .where("amount", ">=", firstRecharge)
          .where("firstReload", "==", true)
          .limit(defaultLimitQuery)
          .get()
          .then((res) => {
            retriveData(res);
          });
    } else {
      if (filterDate) {
        retriveAffiliatedSupervisor();
      }
      retrieveRefData(user?.userRole as string);
    }
  };

  const retriveReferrals = useCallback(async () => {
    if (startTime > endTime) {
      toast.error(
        "Il est préférable de changer la date avant de réessayer, car la date de début ne doit pas être supérieur à la date de fin."
      );
    } else
      await db
        .collection(referralsCollectionName)
        .where("createdAt", ">=", startTime)
        .where("createdAt", "<=", endTime)
        .limit(defaultLimitQuery)
        .get()
        .then((res) => {
          retriveData(res);
        });
  }, [defaultLimitQuery, endTime, startTime]);

  const retriveAffiliated = async () => {
    if (startTime > endTime) {
      return toast.error(
        "Il est préférable de changer la date avant de réessayer, car la date de début ne doit pas être supérieur à la date de fin."
      );
    }
    const res = await db
      .collection(referralsCollectionName)
      .where("referralPhoneNumber", "==", user?.phoneNumber)
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .limit(defaultLimitQuery)
      .get();
    if (res.empty) {
      setAffiliatedUsers([]);
    } else {
      let result: any[] = [];
      for (const snap of res.docs) {
        const affiliation: any = { id: snap.id, ...snap.data() };
        const data = await userRef.doc(affiliation.userId).get();
        if (data.exists) {
          result.push({
            ...data.data(),
            amount: parseInt(affiliation?.amount ?? "0"),
          });
        }
      }
      setAffiliatedUsers(result);
    }
  };

  const retriveAffiliatedSupervisor = async () => {
    if (startTime > endTime) {
      return toast.error(
        "Il est préférable de changer la date avant de réessayer, car la date de début ne doit pas être supérieur à la date de fin."
      );
    }
    let res;
    if (filterDate) {
      res = await db
        .collection(usersCollectionName)
        .where("supervisorId", "==", user?.id)
        .where("isSeller", "==", true)
        .where("createdAt", ">=", startTime)
        .where("createdAt", "<=", endTime)
        .limit(defaultLimitQuery)
        .get();
    } else
      res = await db
        .collection(usersCollectionName)
        .where("supervisorId", "==", user?.id)
        .where("isSeller", "==", true)
        .limit(defaultLimitQuery)
        .get();
    if (res.empty) {
      setAffiliatedToSupervisor([]);
    } else {
      let result: any[] = [];
      for (const snap of res.docs) {
        result.push({ id: snap.id, ...snap.data() });
      }
      setAffiliatedToSupervisor(result);
    }
  };

  const exportToXLSDataSupervisor = useCallback(async () => {
    const worksheet = XLSX.utils.json_to_sheet(
      affiliatedToSupervisor.map((reverral, index: number) => {
        return {
          "N°": index + 1,
          "Nom/Prénom": reverral?.displayName ?? "-",
          Email: reverral?.email ?? "-",
          Ville: reverral?.city ?? "-",
          Téléphone: reverral?.phoneNumber ?? "-",
          "Date de création": (reverral?.createdAt as Timestamp)
            .toDate()
            .toLocaleString(),
        };
      })
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "export");

    XLSX.writeFile(
      workbook,
      `Comptes-affiliés-aux-superviseurs-${new Date().toLocaleDateString()}.xlsx`,
      { compression: true }
    );
  }, [affiliatedToSupervisor]);

  const onPageLimitChange = async (pageLimit: number) => {
    setDefaultLimitQuery(pageLimit);
    if (startTime > endTime) {
      toast.error(
        "Il est préférable de changer la date avant de réessayer, car la date de début ne doit pas être supérieur à la date de fin."
      );
    } else {
      if (isSeller) {
        retriveAffiliated();
      } else {
        if (user?.userRole === UserRole.SUPERVISOR) {
          retriveAffiliatedSupervisor();
        } else
          db.collection(referralsCollectionName)
            .orderBy("createdAt", "desc")
            .where("createdAt", ">=", startTime)
            .where("createdAt", "<=", endTime)
            .limit(pageLimit)
            .get()
            .then((res) => {
              retriveData(res);
            });
      }
    }
  };

  const exportToXLSData = useCallback(async () => {
    const filterWithAmountRef = db
      .collection(referralsCollectionName)
      .where("amount", ">=", firstRecharge)
      .where("firstReload", "==", true);
    const filterWithDate = db
      .collection(referralsCollectionName)
      .orderBy("createdAt", "desc")
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime);
    const reverralsSnap = await (firstRecharge
      ? filterWithAmountRef.get()
      : filterWithDate.get());
    if (reverralsSnap.empty) {
      setAffiliatedUsers([]);
      setReverrals([]);
    } else {
      if (filterPhoneNumber) {
        toast.error("Veuillez désactiver le filtre par numéro de téléphone.");
      } else {
        const result: any[] = [];
        reverralsSnap.docs.forEach((snap: any) => {
          const affiliation = { id: snap.id, ...snap.data() };
          const existingAffiliate = result.find(
            (a) => a.referralPhoneNumber === affiliation.referralPhoneNumber
          );

          if (existingAffiliate) {
            existingAffiliate.allAffiliate++;
            const id: any = affiliation.userId;
            (existingAffiliate.userIds as any).push(id);
            existingAffiliate.totalAmount =
              existingAffiliate.totalAmount +
              parseInt(`${affiliation.amount ?? 0}`);
            if (affiliation.firstReload) {
              existingAffiliate.firstReloadNumber++;
            }
          } else {
            const newAffiliate = {
              createdAt: affiliation.createdAt,
              id: affiliation.id,
              referralPhoneNumber: affiliation.referralPhoneNumber,
              referralDisplayName: affiliation.referralDisplayName,
              firstReloadNumber: affiliation.firstReload ? 1 : 0,
              allAffiliate: 1,
              sellerTag: affiliation.sellerTag ?? "-",
              totalAmount: parseInt(`${affiliation.amount ?? 0}`),
              userIds: [affiliation.userId],
            };
            result.push(newAffiliate);
          }
        });
        const worksheet = XLSX.utils.json_to_sheet(
          result.map((reverral) => {
            return {
              "Date de création": (reverral.createdAt as Timestamp)
                .toDate()
                .toLocaleString(),
              "Nom de l'affiliant": reverral.referralDisplayName ?? "-",
              "Numéros du commerciale": reverral.referralPhoneNumber,
              "Total compte creer avec premiere recharge ":
                reverral.firstReloadNumber,
              "Total non première recharge":
                reverral.allAffiliate - reverral.firstReloadNumber,
              "Montant total de la première recharge": reverral.totalAmount,
              "Total des utilisateurs affiliés": reverral.allAffiliate,
              Tag: reverral.sellerTag ?? "-",
            };
          })
        );
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "export");

        XLSX.writeFile(
          workbook,
          `Rapport-commerciales-${new Date().toLocaleDateString()}.xlsx`,
          { compression: true }
        );
      }
    }
  }, [endTime, filterPhoneNumber, firstRecharge, startTime]);

  const retriveData = (res: any) => {
    const result: any[] = [];
    res.docs.forEach((snap: any) => {
      const affiliation = { id: snap.id, ...snap.data() };
      const existingAffiliate = result.find(
        (a) => a.referralPhoneNumber === affiliation.referralPhoneNumber
      );

      if (existingAffiliate) {
        existingAffiliate.allAffiliate++;
        const id: any = affiliation.userId;
        (existingAffiliate.userIds as any).push(id);
        existingAffiliate.totalAmount =
          existingAffiliate.totalAmount +
          parseInt(`${affiliation.amount ?? 0}`);
        if (affiliation.firstReload) {
          existingAffiliate.firstReloadNumber++;
        }
      } else {
        const newAffiliate = {
          createdAt: affiliation.createdAt,
          id: affiliation.id,
          referralPhoneNumber: affiliation.referralPhoneNumber,
          referralDisplayName: affiliation.referralDisplayName,
          firstReloadNumber: affiliation.firstReload ? 1 : 0,
          totalAmount: parseInt(`${affiliation.amount ?? 0}`),
          sellerTag: affiliation.sellerTag ?? "-",
          allAffiliate: 1,
          userIds: [affiliation.userId],
        };
        result.push(newAffiliate);
      }
    });
    setReverrals(result);
  };

  const exportAffiliatedData = useCallback(async () => {
    const worksheet = XLSX.utils.json_to_sheet(
      affiliatedUsers.map((reverral: any, index: number) => {
        return {
          "N°": index + 1,
          "Nom/Prénom": reverral.displayName ? reverral.displayName : "-",
          Email: reverral.email ? reverral.email : "-",
          Ville: reverral.city ? reverral.city : "-",
          Téléphone: reverral.phoneNumber ? reverral.phoneNumber : "-",
          Montant: reverral.amount ? reverral.amount : "0" + " FCFA",
          "Date de création": (reverral.createdAt as Timestamp)
            .toDate()
            .toLocaleString(),
        };
      })
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "affiliate-liste");

    XLSX.writeFile(
      workbook,
      `Comptes-affiliate-aux-commerciaux${new Date().toLocaleDateString()}.xlsx`,
      { compression: true }
    );
  }, [affiliatedUsers]);

  const onRowDetails = (entity: any) => {
    if (entity) {
      navigate(`/affiliations/${entity.id}/details`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };

  const onRowSellerDetails = (entity: any) => {
    if (entity) {
      navigate(`/affiliations/${entity.id}/seller_details`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };

  const retrieveRefData = async (userRole: string) => {
    switch (userRole) {
      case UserRole.USER:
        setIsSeller(true);
        retriveAffiliated();

        break;

      case UserRole.SUPERVISOR:
        setIsSeller(false);
        retriveAffiliatedSupervisor();

        break;

      default:
        setIsSeller(false);
        retriveReferrals();

        break;
    }
  };

  useEffect(() => {
    user && retrieveRefData(user?.userRole);
  }, []);

  return (
    <div className="bg-white">
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative flex flex-col w-full h-full max-w-xs px-4 py-4 pb-12 ml-auto overflow-y-auto bg-white shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filters
                    </h2>
                    <button
                      type="button"
                      className="flex items-center justify-center w-10 h-10 p-2 -mr-2 text-gray-400 bg-white rounded-md"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="mt-4 border-t border-gray-200">
                    {/* <div className="mt-4"> */}
                    <div className="justify-between mt-2 sm:flex-wrap lg:flex">
                      <div className="px-1 bg-white dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
                        <div className="mr-1">
                          <label
                            htmlFor="dateDebut"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Date début
                          </label>
                          <input
                            type="date"
                            id="dateDebut"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            defaultValue={`${moment(Date.now()).format(
                              "YYYY-MM-DD"
                            )}`}
                            onChange={handleChangeStartDateValue}
                          />
                        </div>
                        <div className="mr-1">
                          <label
                            htmlFor="dateFin"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Date fin
                          </label>
                          <input
                            type="date"
                            id="dateFin"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            defaultValue={`${moment(Date.now()).format(
                              "YYYY-MM-DD"
                            )}`}
                            onChange={handleChangeEndDtaeValue}
                          />
                        </div>
                      </div>
                    </div>
                    {user?.userRole === UserRole.SUPERVISOR && (
                      <>
                        <hr className="my-4" />
                        <label className="relative flex justify-between cursor-pointer">
                          <span className="text-sm font-medium text-gray-900 ms-3 dark:text-gray-300">
                            Filtrer par date
                          </span>
                          <input
                            type="checkbox"
                            value=""
                            className="sr-only peer"
                            checked={filterDate}
                            onChange={(e) => setFilterDate(e.target.checked)}
                          />
                          <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
                        </label>
                      </>
                    )}
                    {user?.userRole === UserRole.ADMIN ||
                    user?.userRole === UserRole.TECHNICALSUPPORT ||
                    user?.userAgencySubRole === AgencyStaffRole.MANAGER ? (
                      <>
                        <div>
                          <hr className="my-4" />
                          <label className="relative flex justify-between cursor-pointer">
                            <span className="text-sm font-medium text-gray-900 ms-3 dark:text-gray-300">
                              Filtrer par numéro de téléphone
                            </span>
                            <input
                              type="checkbox"
                              value=""
                              className="sr-only peer"
                              checked={filterPhoneNumber}
                              onChange={(e) =>
                                setFilterPhoneNumber(e.target.checked)
                              }
                            />
                            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
                          </label>
                          <hr className="my-4" />
                          <div className="mt-4 leading-7 text-gray-600">
                            <PhoneInput
                              international={false}
                              placeholder="Entrez le téléphone de l'affiliant"
                              className="block w-full px-3 py-2 placeholder-gray-400 shadow-sm appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                              defaultCountry="BF"
                              onChange={getFilterdByPhoneNumber}
                            />
                          </div>
                          <hr className="my-4" />
                        </div>
                        <label className="relative flex justify-between cursor-pointer">
                          <span className="text-sm font-medium text-gray-900 ms-3 dark:text-gray-300">
                            Montant de prémière recharge
                          </span>
                        </label>
                        <hr className="my-4" />
                        <input
                          type="number"
                          value={firstRecharge}
                          onChange={(e: any) => {
                            setFirstRecharge(parseInt(e.target.value));
                          }}
                          className="rounded-md"
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="flex mt-10">
                      <button
                        type="button"
                        className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        onClick={() => {
                          filter();
                          setMobileFiltersOpen(false);
                        }}
                      >
                        <FunnelIcon
                          className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                          aria-hidden="true"
                        />
                        Executer le filtre
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <main className="px-4 max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-baseline justify-between pt-10 pb-6 border-b border-gray-200">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900">
              Les commerciaux
            </h1>

            <div className="flex items-center">
              <button
                type="button"
                className={classNames(
                  reverrals.length > 0 ||
                    affiliatedUsers.length > 0 ||
                    affiliatedToSupervisor.length
                    ? "text-green-700 border-green-300 hover:bg-green-50 focus:ring-green-500"
                    : "text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-gray-500 cursor-not-allowed",
                  "inline-flex items-center h-10 px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                )}
                onClick={() => {
                  // eslint-disable-next-line no-lone-blocks
                  {
                    if (!isSeller && reverrals.length > 0) {
                      exportToXLSData();
                    } else if (
                      user?.userRole === UserRole.SUPERVISOR &&
                      affiliatedToSupervisor.length
                    ) {
                      exportToXLSDataSupervisor();
                    } else {
                      exportAffiliatedData();
                    }
                  }
                }}
                disabled={
                  reverrals.length > 0 ||
                  affiliatedUsers.length > 0 ||
                  affiliatedToSupervisor.length > 0
                    ? false
                    : true
                }
              >
                <ArrowDownTrayIcon
                  className={classNames(
                    reverrals.length > 0 ? "text-green-500" : "text-gray-500",
                    "self-center flex-shrink-0 w-5 h-5 mr-1"
                  )}
                  aria-hidden="true"
                />
                <span className="sr-only">Exporter en XLS</span>
              </button>
              <button
                type="button"
                className="inline-flex items-center h-10 px-4 py-2 ml-4 -m-2 text-sm font-medium text-gray-400 bg-white border rounded-md shadow-sm hover:text-gray-500 sm:ml-6 lg:hidden focus:outline-none focus:ring-2 focus:ring-offset-2"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <FunnelIcon className="w-5 h-5" aria-hidden="true" />
                <span className="sr-only">Filters</span>
              </button>
            </div>
          </div>

          <section aria-labelledby="referral-heading" className="pt-6 pb-24">
            <h2 id="referral-heading" className="sr-only">
              Referrals
            </h2>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              {/* Filters */}
              <form className="hidden lg:block">
                <div>
                  <div className="justify-between mt-2 sm:flex-wrap lg:flex">
                    {(user?.userRole === UserRole.ADMIN ||
                      user?.userRole === UserRole.TECHNICALSUPPORT ||
                      user?.userAgencySubRole === AgencyStaffRole.MANAGER ||
                      user?.userRole === UserRole.SUPERVISOR) && (
                      <div className="px-1 bg-white dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
                        <div className="mr-1">
                          <label
                            htmlFor="dateDebut"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Date début
                          </label>
                          <input
                            type="date"
                            id="dateDebut"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            defaultValue={`${moment(Date.now()).format(
                              "YYYY-MM-DD"
                            )}`}
                            onChange={handleChangeStartDateValue}
                          />
                        </div>
                        <div className="mr-1">
                          <label
                            htmlFor="dateFin"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Date fin
                          </label>
                          <input
                            type="date"
                            id="dateFin"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            defaultValue={`${moment(Date.now()).format(
                              "YYYY-MM-DD"
                            )}`}
                            onChange={handleChangeEndDtaeValue}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {user?.userRole === UserRole.SUPERVISOR && (
                    <>
                      <hr className="my-4" />
                      <label className="relative flex justify-between cursor-pointer">
                        <span className="text-sm font-medium text-gray-900 ms-3 dark:text-gray-300">
                          Filtrer par date
                        </span>
                        <input
                          type="checkbox"
                          value=""
                          className="sr-only peer"
                          checked={filterDate}
                          onChange={(e) => setFilterDate(e.target.checked)}
                        />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
                      </label>
                    </>
                  )}
                  {(user?.userRole === UserRole.ADMIN ||
                    user?.userRole === UserRole.TECHNICALSUPPORT ||
                    user?.userAgencySubRole === AgencyStaffRole.MANAGER) && (
                    <>
                      <hr className="my-4" />
                      <label className="relative flex justify-between cursor-pointer">
                        <span className="text-sm font-medium text-gray-900 ms-3 dark:text-gray-300">
                          Filtrer par numéro de téléphone
                        </span>
                        <input
                          type="checkbox"
                          value=""
                          className="sr-only peer"
                          checked={filterPhoneNumber}
                          onChange={(e) =>
                            setFilterPhoneNumber(e.target.checked)
                          }
                        />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
                      </label>
                      <hr className="my-4" />
                      <div className="mt-4 leading-7 text-gray-600">
                        <PhoneInput
                          international={false}
                          placeholder="Entrez le téléphone de l'affiliant"
                          className="block w-full px-3 py-2 placeholder-gray-400 shadow-sm appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                          defaultCountry="BF"
                          onChange={getFilterdByPhoneNumber}
                        />
                      </div>
                      <hr className="my-4" />
                      <label className="relative flex justify-between cursor-pointer">
                        <span className="text-sm font-medium text-gray-900 ms-3 dark:text-gray-300">
                          Montant de prémière recharge
                        </span>
                      </label>
                      <hr className="my-4" />
                      <input
                        type="number"
                        value={firstRecharge}
                        onChange={(e: any) => {
                          setFirstRecharge(parseInt(e.target.value));
                        }}
                        className="rounded-md"
                      />
                    </>
                  )}
                </div>
                {(user?.userRole === UserRole.ADMIN ||
                  user?.userRole === UserRole.TECHNICALSUPPORT ||
                  user?.userAgencySubRole === AgencyStaffRole.MANAGER ||
                  user?.userRole === UserRole.SUPERVISOR) && (
                  <div className="flex mt-10">
                    <button
                      type="button"
                      className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      onClick={() => {
                        filter();
                      }}
                    >
                      <FunnelIcon
                        className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                        aria-hidden="true"
                      />
                      Executer le filtre
                    </button>
                  </div>
                )}
              </form>

              {/* Product grid */}
              <div className="grid grid-cols-3 gap-6 mt-6 ml-5 sm:grid-cols-2 lg:col-span-1 lg:gap-8 scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-100">
                <div className="flex flex-col mt-8">
                  <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle">
                      <div className="shadow-sm lg:ml-2 ring-1 ring-black ring-opacity-5">
                        {!isSeller ? (
                          <>
                            {user?.userRole === UserRole.SUPERVISOR ? (
                              <table
                                className="min-w-full border-separate"
                                style={{ borderSpacing: 0 }}
                              >
                                <thead className="bg-gray-50">
                                  <tr>
                                    {supervisorTableHeader.map((th, index) => (
                                      <th
                                        key={index}
                                        scope="col"
                                        className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                      >
                                        {th}
                                      </th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody className="bg-white">
                                  {affiliatedToSupervisor &&
                                  affiliatedToSupervisor.length > 0 ? (
                                    affiliatedToSupervisor.map(
                                      (user: any, userIdx: number) => (
                                        <tr key={userIdx}>
                                          <td
                                            className={classNames(
                                              userIdx !==
                                                affiliatedToSupervisor.length -
                                                  1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                          >
                                            {(userIdx as number) + 1}
                                          </td>
                                          <td
                                            className={classNames(
                                              userIdx !==
                                                affiliatedToSupervisor.length -
                                                  1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                          >
                                            <img
                                              className="w-10 h-10 p-1 border rounded-full"
                                              src={
                                                user?.profilImageUrl
                                                  ? user?.profilImageUrl
                                                  : `https://ui-avatars.com/api/?background=random&name=${
                                                      user?.displayName || "S"
                                                    }`
                                              }
                                              alt=""
                                            />
                                          </td>
                                          <td
                                            className={classNames(
                                              userIdx !==
                                                affiliatedToSupervisor.length -
                                                  1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                          >
                                            {user.displayName
                                              ? user.displayName
                                              : "-"}
                                          </td>
                                          <td
                                            className={classNames(
                                              userIdx !==
                                                affiliatedToSupervisor.length -
                                                  1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                          >
                                            {user.email ? user.email : "-"}
                                          </td>
                                          <td
                                            className={classNames(
                                              userIdx !==
                                                affiliatedToSupervisor.length -
                                                  1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                          >
                                            {user.city ? user.city : "-"}
                                          </td>
                                          <td
                                            className={classNames(
                                              userIdx !==
                                                affiliatedToSupervisor.length -
                                                  1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                          >
                                            {user.phoneNumber ?? "-"}
                                          </td>
                                          <td
                                            className={classNames(
                                              userIdx !==
                                                affiliatedToSupervisor.length -
                                                  1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                          >
                                            {user.active ? (
                                              <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                            ) : (
                                              <XCircleIcon className="w-6 h-6 text-red-500" />
                                            )}
                                          </td>
                                          <td
                                            className={classNames(
                                              userIdx !==
                                                affiliatedToSupervisor.length -
                                                  1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                          >
                                            {parseDate(
                                              user?.createdAt as Timestamp
                                            )}
                                          </td>
                                          <td
                                            className={classNames(
                                              userIdx !==
                                                affiliatedToSupervisor.length -
                                                  1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                          >
                                            <TableRowEditMenu
                                              rowDetailsHandler={() =>
                                                onRowSellerDetails(user)
                                              }
                                            />
                                          </td>
                                        </tr>
                                      )
                                    )
                                  ) : (
                                    <NoContent />
                                  )}
                                </tbody>
                              </table>
                            ) : (
                              <table
                                className="min-w-full border-separate"
                                style={{ borderSpacing: 0 }}
                              >
                                <thead className="bg-gray-50">
                                  <tr>
                                    {tableHeader.map((th, index) => (
                                      <th
                                        key={index}
                                        scope="col"
                                        className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                      >
                                        {th}
                                      </th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody className="bg-white">
                                  {reverrals && reverrals.length > 0 ? (
                                    reverrals.map(
                                      (reverral: any, referralIdx: number) => (
                                        <tr key={referralIdx}>
                                          <td
                                            className={classNames(
                                              referralIdx !==
                                                reverrals.length - 1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                          >
                                            {(referralIdx as number) + 1}
                                          </td>
                                          <td
                                            className={classNames(
                                              referralIdx !==
                                                reverrals.length - 1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                          >
                                            {new Date(
                                              reverral.createdAt.seconds *
                                                1000 +
                                                reverral.createdAt.nanoseconds /
                                                  1000000
                                            ).toLocaleDateString()}
                                          </td>
                                          <td
                                            className={classNames(
                                              referralIdx !==
                                                reverrals.length - 1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                          >
                                            {reverral.referralDisplayName ??
                                              "-"}
                                          </td>
                                          <td
                                            className={classNames(
                                              referralIdx !==
                                                reverrals.length - 1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                          >
                                            {reverral.referralPhoneNumber}
                                          </td>
                                          <td
                                            className={classNames(
                                              referralIdx !==
                                                reverrals.length - 1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                          >
                                            {reverral.firstReloadNumber}
                                          </td>
                                          <td
                                            className={classNames(
                                              referralIdx !==
                                                reverrals.length - 1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                          >
                                            {amountFormater(
                                              reverral.totalAmount
                                            )}{" "}
                                            Fcfa
                                          </td>
                                          <td
                                            className={classNames(
                                              referralIdx !==
                                                reverrals.length - 1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                          >
                                            {reverral.allAffiliate}
                                          </td>
                                          <td
                                            className={classNames(
                                              referralIdx !==
                                                reverrals.length - 1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                          >
                                            {reverral.sellerTag}
                                          </td>
                                          <td
                                            className={classNames(
                                              referralIdx !==
                                                reverrals.length - 1
                                                ? "border-b border-gray-200"
                                                : "",
                                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                          >
                                            <TableRowEditMenu
                                              rowDetailsHandler={() =>
                                                onRowDetails(reverral)
                                              }
                                            />
                                          </td>
                                        </tr>
                                      )
                                    )
                                  ) : (
                                    <NoContent />
                                  )}
                                </tbody>
                              </table>
                            )}
                          </>
                        ) : (
                          <table
                            className="min-w-full border-separate"
                            style={{ borderSpacing: 0 }}
                          >
                            <thead className="bg-gray-50">
                              <tr>
                                {tableHeaders.map((th, index) => (
                                  <th
                                    key={index}
                                    scope="col"
                                    className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                  >
                                    {th}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody className="bg-white">
                              {affiliatedUsers && affiliatedUsers.length > 0 ? (
                                affiliatedUsers.map(
                                  (user: any, userIdx: number) => (
                                    <tr key={userIdx}>
                                      <td
                                        className={classNames(
                                          userIdx !== affiliatedUsers.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {(userIdx as number) + 1}
                                      </td>
                                      <td
                                        className={classNames(
                                          userIdx !== affiliatedUsers.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {parseDate(
                                          user?.createdAt as Timestamp
                                        )}
                                      </td>
                                      <td
                                        className={classNames(
                                          userIdx !== affiliatedUsers.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {user.displayName ?? "-"}
                                      </td>
                                      <td
                                        className={classNames(
                                          userIdx !== affiliatedUsers.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {user.phoneNumber}
                                      </td>
                                      <td
                                        className={classNames(
                                          userIdx !== affiliatedUsers.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {user.email}
                                      </td>
                                      <td
                                        className={classNames(
                                          userIdx !== affiliatedUsers.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {user.city}
                                      </td>
                                      <td
                                        className={classNames(
                                          userIdx !== affiliatedUsers.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {amountFormater(user.amount)} Fcfa
                                      </td>
                                      <td
                                        className={classNames(
                                          userIdx !== affiliatedUsers.length - 1
                                            ? "border-b border-gray-200"
                                            : "",
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                        )}
                                      >
                                        {user.active ? (
                                          <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                        ) : (
                                          <XCircleIcon className="w-6 h-6 text-red-500" />
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <NoContent />
                              )}
                            </tbody>
                          </table>
                        )}
                        <Pagination
                          skip={
                            !isSeller
                              ? reverrals.length
                              : user?.userRole === UserRole.SUPERVISOR
                              ? affiliatedToSupervisor.length
                              : affiliatedUsers.length
                          }
                          take={defaultLimitQuery}
                          total={
                            !isSeller
                              ? reverrals.length
                              : user?.userRole === UserRole.SUPERVISOR
                              ? affiliatedToSupervisor.length
                              : affiliatedUsers.length
                          }
                          collectionName={referralsCollectionName}
                          onPageChange={(pageIndex: number) => pageIndex}
                          onPageLimitChange={(pageLimit: number) =>
                            onPageLimitChange(pageLimit)
                          }
                          isChangedPage={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default ReferralHistoriesPage;
