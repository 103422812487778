import React from "react";
import { UserIcon, CurrencyDollarIcon, ArrowTrendingUpIcon } from "@heroicons/react/24/outline";
import { DisplayPrice } from "../PriceDisplay";

interface StatCardProps {
  title: string;
  devise?: string;
  value: number;
  icon: React.ReactElement;
  isLoad?: boolean;
}

export const StatCard: React.FC<StatCardProps> = ({ title, value, icon, devise, isLoad }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 flex items-center gap-4 border border-red-300">
      {/* Conteneur de l'icône */}
      <div className="bg-red-100 text-red-500 w-8 h-8 flex items-center justify-center rounded-full">
        {icon && React.isValidElement(icon) ? React.cloneElement(icon as any, { className: "w-6 h-6" }) : null}
      </div>
      <div>
        <p className="text-gray-600 text-sm">{title}</p>
        <p className="text-lg font-semibold text-gray-900">
          {
            isLoad ? (
            <>
            <p>Chargement....</p>
            </>
            ) : 
            <DisplayPrice price={value ?? 0} />
          } {devise}
        </p>
      </div>
    </div>
  );
};

interface ProgressBarProps {
  label: string;
  value: number;
  percentage: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ label, value, percentage }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 border border-red-300">
      <div className="flex justify-between items-center mb-2">
        <p className="text-gray-700 font-semibold">{label}</p>
        <ArrowTrendingUpIcon className="text-red-500 w-6 h-6" />
      </div>
      <p className="text-red-500 text-lg font-semibold">{value} FCFA</p>
      <div className="h-2 w-full bg-gray-200 rounded-full mt-2">
        {/* <div
          className="h-full bg-red-500 rounded-full"
          style={{ width: `${percentage}%` }}
        ></div> */}
      </div>
      {/* <p className="text-gray-500 text-sm mt-1 text-right">{percentage}%</p> */}
    </div>
  );
};

const DashboardRewiew = (props: {
  balance?: number,
  totalCommission?: number,
  totalDeposit?: number,
  totalWithdrawal?: number,
  totalReload?: number,
  dealersNumber?: number
  superDealerNumber?: number,
  displayName?: string,
  date?: string,
  isLoading?: boolean;
}) => {
  const {
    balance,
    totalCommission,
    totalDeposit,
    totalWithdrawal,
    totalReload,
    dealersNumber,
    superDealerNumber,
    displayName,
    date,
    isLoading
  } = props
  return (
    <div className="max-w-full mx-auto p-6">
      {/* Header */}
      <h2 className="text-xl font-bold text-red-600">Tableau de {displayName}</h2>
      <p className="text-gray-600">Vue d’ensemble des SuperDealers - {date}</p>

      {/* Statistiques */}
      <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-4 mt-4">
        <StatCard title="SuperDealers" value={superDealerNumber ?? 0} isLoad={isLoading} icon={<UserIcon className="w-6 h-6" />} />
        <StatCard title="Solde Cumulé" value={balance ?? 0} isLoad={isLoading} icon={<CurrencyDollarIcon className="w-6 h-6" />}/>
        <StatCard title="Commission en cours" value={totalCommission ?? 0} isLoad={isLoading} icon={<ArrowTrendingUpIcon className="w-6 h-6" />}/>
        <StatCard title="Dealers Affiliés" value={dealersNumber ?? 0} isLoad={isLoading} icon={<UserIcon className="w-6 h-6" />} />
      </div>

      {/* Transactions */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
        <ProgressBar label="Dépôt du jour" value={totalDeposit ?? 0} percentage={65} />
        <ProgressBar label="Retrait du jour" value={totalWithdrawal ?? 0} percentage={40} />
        <ProgressBar label="Appro du jour" value={totalReload ?? 0} percentage={80} />
      </div>
    </div>
  );
};

export default DashboardRewiew;
