/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { db } from "../../firebase";
import { referralsCollectionName, usersCollectionName, transactionCollectionName } from "../../config";
import NoContent from "../../components/TableNoContentPage/NoContent";
import { ArrowDownTrayIcon, BoltIcon, CheckCircleIcon, FunnelIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";
import { amountFormater, classNames, getTimestamp, parseDate } from "../../utils";
import * as XLSX from "xlsx";
import { UserRole } from "../../interfaces";
import SankMoneyIcon from "../../CFA.png";
import moment from "moment";
import Pagination from "../../components/Pagination/Pagination";

const tableHeader = [
  "N°",
  "",
  "Nom/prénom",
  "Email",
  "Ville",
  "Téléphone",
  "Prémière recharge",
  "Active",
  "Date de création",
];

const tableHeaderTranssactions = [
  "N°",
  "",
  "Nom destinataire",
  "Numéro destinataire",
  "Montant",
  "Type de transaction",
  "Compte",
  "Frais",
  "Status",
  "Date de création",
];

const SellerReferralDetailsPage = () => {
  const location = useLocation();
  const currentUser = location.state?.entity;
  const [userAffiliatedToSeller, setUserAffiliatedToSeller] = useState<any[]>([]);
  const [affiliatedUsers, setAffiliatedUsers] = useState<any[]>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const [firstReloadNumber, setFirstReloadNumber] = useState<number>(0)
  const [notFirstReloadNumber, setNotFirstReloadNumber] = useState<number>(0)
  const [transactionByDealer, setTransactionByDealer] = useState<any[]>([])
  const [dealerTotalTransactionAmount, setDealerTotalTransactionAmount] = useState<number>(0)
  const [defaultLimitQuery, setDefaultLimitQuery] = useState(10);

  const [startTime, setStartTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  );
  const [endTime, setEndTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, false)
  );
  const handleChangeStartDateValue = (value: any) => {
    const startTime = getTimestamp(value.target.value, true);
    setStartTime(startTime);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    const endTime = getTimestamp(value.target.value, false);
    setEndTime(endTime);
  };

  const retriveAffiliatedSupervisor = useCallback(async (pageLimit: number) => {
    setDefaultLimitQuery(pageLimit);
    const res = await db
      .collection(referralsCollectionName)
      .where("referralPhoneNumber", "==", currentUser?.phoneNumber)
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .limit(pageLimit)
      .get();
    if (res.empty) {
      setUserAffiliatedToSeller([])
    } else {
      let result: any[] = [];
      for (const snap of res.docs) {
        result.push(
          { id: snap.id, ...snap.data() }
        )
      }
      let sum: number = 0;
      let firstReloadNumber: number = 0;
      let notFirstReloadNumber: number = 0;
      result.map((el) => {
        sum = sum + parseInt(el?.amount);
        setTotalAmount(sum)
        if (el?.firstReload) {
          firstReloadNumber++;
        } else notFirstReloadNumber++;
        setFirstReloadNumber(firstReloadNumber)
        setNotFirstReloadNumber(notFirstReloadNumber)
      })
      //   result.reduce((acc, element)=> {
      //     return console.log(acc + parseInt(element?.amount));
      //   }, 0)
      setUserAffiliatedToSeller(result);
      retriveAffiliatedUser(result)
    }
  }, [currentUser?.phoneNumber, endTime, startTime])

  const retriveAffiliatedUser = useCallback(async (dataVal: any) => {
    let data: any[] = [];
    for (const referral of dataVal) {
      const snap = await db.collection(usersCollectionName).doc(referral?.userId).get();
      if (snap.exists) {
        data.push({
          ...snap.data(),
          amount: parseInt(referral?.amount ? referral?.amount : "0"),
        });
      }
    }
    setAffiliatedUsers(data);
  }, []);

  const getDealerTransactionByDate = useCallback(async (pageLimit: number) => {
    setDefaultLimitQuery(pageLimit)
    const res = await db
      .collection(transactionCollectionName)
      .where("participantsIds", "array-contains", currentUser?.id)
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .limit(pageLimit)
      .get();
    if (res.empty) {
      setTransactionByDealer([]);
    } else {
      let result: any[] = [];
      for (const snap of res.docs) {
        result.push(
          { id: snap.id, ...snap.data() }
        )
      }
      let sum: number = 0;
      result.map((transaction) => {
        const amount = parseInt(transaction?.amount);
        sum += isNaN(amount) ? 0 : amount
      })
      setDealerTotalTransactionAmount(sum);
      setTransactionByDealer(result);
    }
  }, [currentUser?.id, endTime, startTime])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const exportToXLSData = useCallback(async () => {
    const worksheet = XLSX.utils.json_to_sheet(
      affiliatedUsers.map((reverral, index: number) => {
        return {
          "N°": index + 1,
          "Nom/Prénom": reverral?.displayName ?? "-",
          "Email": reverral?.email ?? "-",
          "Ville": reverral?.city ?? "-",
          "Téléphone": reverral?.phoneNumber ?? "-",
          "Montant": reverral?.amount ? reverral?.amount + " FCFA" : 0,
          "Date de création": (reverral?.createdAt as Timestamp)
            .toDate()
            .toLocaleString(),

        };
      })
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "export");

    XLSX.writeFile(
      workbook,
      `Détails-comptes-users-affiliate-${new Date().toLocaleDateString()}.xlsx`,
      { compression: true }
    );
  }, [affiliatedUsers])

  const exportToXLSDealerData = useCallback(async () => {
    const worksheet = XLSX.utils.json_to_sheet(
      transactionByDealer.map((reverral, index: number) => {
        return {
          "N°": index + 1,
          "Nom destinataire": reverral?.receiverDisplayName ?? "-",
          "N° du destinataire": reverral?.receiverPhoneNumber ?? "-",
          "Montant": reverral?.amount ?? 0,
          "Type de transaction": reverral?.transactionType ?? "-",
          "Frais": reverral?.fees ? reverral?.fees : 0,
          "Date de création": (reverral?.createdAt as Timestamp)
            .toDate()
            .toLocaleString(),

        };
      })
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "export");

    XLSX.writeFile(
      workbook,
      `Détails-comptes-dealers-affiliate-${new Date().toLocaleDateString()}.xlsx`,
      { compression: true }
    );
  }, [transactionByDealer])


  useEffect(() => {
    if (currentUser) {
      if (currentUser?.userRole === UserRole.DEALER) {
        getDealerTransactionByDate(25)
      } else
        retriveAffiliatedSupervisor(25);
    }
  }, [currentUser]);

  return (
    <section className="flex items-center py-16 bg-gray-100 md:py-20 font-poppins dark:bg-gray-800 ">
      <div className="justify-center flex-1 max-w-6xl px-4 py-4 mx-auto bg-white border rounded-md dark:border-gray-900 dark:bg-gray-900 md:py-10 md:px-10">
        <div>
          <div className="flex items-stretch justify-start w-full h-full px-4 mb-8 border-b border-gray-200 dark:border-gray-700 md:flex-row xl:flex-col md:space-x-6 lg:space-x-8 xl:space-x-0">
            <div className="flex items-start justify-start flex-shrink-0">
              <div className="flex items-center justify-center w-full pb-6 space-x-4 md:justify-start">
                <img
                  src={
                    currentUser.profilImageUrl
                      ? currentUser.profilImageUrl
                      : `https://ui-avatars.com/api/?background=random&name=${currentUser.displayName || "S"
                      }`
                  }
                  className="object-cover w-16 h-16 rounded-md"
                  alt="avatar"
                />
                <div className="flex flex-col items-start justify-start space-y-2">
                  <p className="text-lg font-semibold leading-4 text-left text-gray-800 dark:text-gray-400">
                    {currentUser.displayName}
                  </p>
                  <p className="text-sm leading-4 text-gray-600 dark:text-gray-400">
                    {currentUser.city}
                    {currentUser.sellerTag && (
                      <span className="text-xs">
                        ; {currentUser.sellerTag}
                      </span>
                    )}
                  </p>
                  <p className="text-sm leading-4 cursor-pointer dark:text-gray-400">
                    {currentUser.email}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center pb-4 mb-3 border-b border-gray-200 dark:border-gray-700">
            <div className="w-full px-4 mb-4 md:w-1/4">
              <p className="mb-2 text-sm leading-5 text-gray-600 dark:text-gray-400 ">
                Téléphone:{" "}
              </p>
              <p className="text-base font-semibold leading-4 text-gray-800 dark:text-gray-400">
                {currentUser?.phoneNumber}
              </p>
            </div>
            {
              currentUser?.userRole === UserRole.USER && currentUser?.isSeller && (
                <>
                  <div className="w-full px-4 mb-4 md:w-1/4">
                    <p className="mb-2 text-sm leading-5 text-gray-600 dark:text-gray-400 ">
                      Première recharge:{" "}
                    </p>
                    <p className="text-base font-semibold leading-4 text-gray-800 dark:text-gray-400">
                      {firstReloadNumber ?? 0}
                    </p>
                  </div>
                  <div className="w-full px-4 mb-4 md:w-1/4">
                    <p className="mb-2 text-sm font-medium leading-5 text-gray-600 dark:text-gray-400 ">
                      Sans première recharge:{" "}
                    </p>
                    <p className="text-base font-semibold leading-4 text-gray-800 dark:text-gray-400">
                      {notFirstReloadNumber ?? 0}
                    </p>
                  </div>
                </>
              )
            }
            <div className="w-full px-4 mb-4 md:w-1/4">
              <p className="mb-2 text-sm font-medium leading-5 text-gray-600 dark:text-gray-400 ">
                Montant total recharge:{" "}
              </p>
              <p className="text-base font-semibold leading-4 text-gray-800 dark:text-gray-400">
                {currentUser?.userRole === UserRole.DEALER ? amountFormater(dealerTotalTransactionAmount) : amountFormater(totalAmount) ?? 0} Fcfa
              </p>
            </div>
            {
              currentUser?.userRole === UserRole.USER && currentUser?.isSeller && (
                <div className="w-full px-4 mb-4 md:w-1/4">
                  <p className="mb-2 text-sm font-medium leading-5 text-gray-600 dark:text-gray-400 ">
                    Total:{" "}
                  </p>
                  <p className="text-base font-semibold leading-4 text-blue-600 dark:text-gray-400">
                    {userAffiliatedToSeller.length}
                  </p>
                </div>
              )
            }
          </div>
          <div className="px-4 mb-10">
            <div className="h-4" />
            <div className="flex self-end justify-between max-md:flex-col">
              <div className="flex px-1 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
                <div className="mr-1">
                  <label
                    htmlFor="dateDebut"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Date début
                  </label>
                  <input
                    type="date"
                    id="dateDebut"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    defaultValue={`${moment(Date.now()).format(
                      "YYYY-MM-DD"
                    )}`}
                    onChange={handleChangeStartDateValue}
                  />
                </div>
                <div className="mr-1">
                  <label
                    htmlFor="dateFin"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Date fin
                  </label>
                  <input
                    type="date"
                    id="dateFin"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    defaultValue={`${moment(Date.now()).format(
                      "YYYY-MM-DD"
                    )}`}
                    onChange={handleChangeEndDtaeValue}
                  />
                </div>
                <div className="flex items-center justify-center mt-5 h-14">
                  <div className="flex">
                    <button
                      type="button"
                      className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      onClick={() => {
                        currentUser?.userRole === UserRole.DEALER ? getDealerTransactionByDate(25) : retriveAffiliatedSupervisor(25);
                      }}
                    >
                      <FunnelIcon
                        className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                        aria-hidden="true"
                      />
                      Filtrer
                    </button>
                  </div>
                  <button
                    type="button"
                    className={classNames(
                      affiliatedUsers.length > 0 || transactionByDealer.length > 0
                        ? "text-green-700 border-green-300 hover:bg-green-50 focus:ring-green-500 mb-2"
                        : "text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-gray-500 cursor-not-allowed mb-2",
                      "inline-flex items-center ml-3 h-10 px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                    )}
                    onClick={() => {
                      currentUser?.userRole === UserRole.DEALER ? exportToXLSDealerData() : exportToXLSData();
                    }}
                    disabled={affiliatedUsers.length > 0 || transactionByDealer.length > 0 ? false : true}
                  >
                    <ArrowDownTrayIcon
                      className={classNames(
                        affiliatedUsers.length > 0 || transactionByDealer.length > 0 ? "text-green-500" : "text-gray-500",
                        "self-center flex-shrink-0 w-5 h-5 mr-1"
                      )}
                      aria-hidden="true"
                    />
                    <span className="sr-only">Exporter en XLS</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-stretch mt-6 justify-center w-full space-y-4 md:flex-row md:space-y-0 md:space-x-8">
              {
                currentUser?.userRole === UserRole.USER && currentUser?.isSeller ? (
                  <>
                    <table
                      className="min-w-full border-separate"
                      style={{ borderSpacing: 0 }}
                    >
                      <thead className="bg-gray-50">
                        <tr>
                          {tableHeader.map((th, index) => (
                            <th
                              key={index}
                              scope="col"
                              className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                            >
                              {th}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {affiliatedUsers && affiliatedUsers.length > 0 ? (
                          affiliatedUsers.map((user: any, userIdx: number) => (
                            <tr key={userIdx}>
                              <td
                                className={classNames(
                                  userIdx !== affiliatedUsers.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {(userIdx as number) + 1}
                              </td>
                              <td
                                className={classNames(
                                  userIdx !== affiliatedUsers.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                <img
                                  className="w-10 h-10 p-1 border rounded-full"
                                  src={
                                    user?.profilImageUrl
                                      ? user?.profilImageUrl
                                      : `https://ui-avatars.com/api/?background=random&name=${user?.displayName || "S"
                                      }`
                                  }
                                  alt=""
                                />
                              </td>
                              <td
                                className={classNames(
                                  userIdx !== affiliatedUsers.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {user.displayName ? user.displayName : "-"}
                              </td>
                              <td
                                className={classNames(
                                  userIdx !== affiliatedUsers.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {user.email ? user.email : "-"}
                              </td>
                              <td
                                className={classNames(
                                  userIdx !== affiliatedUsers.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {user.city ? user.city : "-"}
                              </td>
                              <td
                                className={classNames(
                                  userIdx !== affiliatedUsers.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {user.phoneNumber ?? "-"}
                              </td>
                              <td
                                className={classNames(
                                  userIdx !== affiliatedUsers.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {amountFormater(user?.amount)} Fcfa
                              </td>
                              <td
                                className={classNames(
                                  userIdx !== affiliatedUsers.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {user.active ? (
                                  <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                ) : (
                                  <XCircleIcon className="w-6 h-6 text-red-500" />
                                )}
                              </td>
                              <td
                                className={classNames(
                                  userIdx !== affiliatedUsers.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {parseDate(user?.createdAt as Timestamp)}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <NoContent />
                        )}
                      </tbody>
                    </table>
                    
                  </>
                ) : (
                  <>
                    <table
                      className="min-w-full border-separate"
                      style={{ borderSpacing: 0 }}
                    >
                      <thead className="bg-gray-50">
                        <tr>
                          {tableHeaderTranssactions.map((th, index) => (
                            <th
                              key={index}
                              scope="col"
                              className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                            >
                              {th}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {transactionByDealer && transactionByDealer.length > 0 ? (
                          transactionByDealer.map((user: any, userIdx: number) => (
                            <tr key={userIdx} className={userIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100 cursor-pointer'}>
                              <td
                                className={classNames(
                                  userIdx !== transactionByDealer.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {(userIdx as number) + 1}
                              </td>
                              <td
                                className={classNames(
                                  userIdx !== transactionByDealer.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                <img
                                  className="w-10 h-10 p-1 border rounded-full"
                                  src={
                                    user?.profilImageUrl
                                      ? user?.profilImageUrl
                                      : `https://ui-avatars.com/api/?background=random&name=${user?.receiverDisplayName || "S"
                                      }`
                                  }
                                  alt=""
                                />
                              </td>
                              <td
                                className={classNames(
                                  userIdx !== transactionByDealer.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {user?.receiverDisplayName ? user?.receiverDisplayName : "-"}
                              </td>
                              <td
                                className={classNames(
                                  userIdx !== transactionByDealer.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {user?.receiverPhoneNumber ? user?.receiverPhoneNumber : "-"}
                              </td>
                              <td
                                className={classNames(
                                  userIdx !== transactionByDealer.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {user?.amount ? amountFormater(user?.amount) : "-"} Fcfa
                              </td>
                              <td
                                className={classNames(
                                  userIdx !== transactionByDealer.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {user?.transactionType ?? "-"}
                              </td>
                              <td
                                className={classNames(
                                  userIdx !== transactionByDealer.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {user.accountType === "MAIN" ? (
                                  <img
                                    className="w-auto h-5"
                                    src={SankMoneyIcon}
                                    alt="Sank"
                                  />
                                ) : (
                                  <BoltIcon className="w-6 h-6 text-red-500" />
                                )}
                              </td>
                              <td
                                className={classNames(
                                  userIdx !== transactionByDealer.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {user?.fees as number}
                              </td>
                              <td
                                className={classNames(
                                  userIdx !== transactionByDealer.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {user?.isComplete ? (
                                  <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                ) : (
                                  <XCircleIcon className="w-6 h-6 text-red-500" />
                                )}
                              </td>
                              <td
                                className={classNames(
                                  userIdx !== transactionByDealer.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {(user?.createdAt as Timestamp)
                                  .toDate()
                                  .toLocaleString()}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <NoContent />
                        )}
                      </tbody>
                    </table>
                  </>
                )
              }
            </div>
            <Pagination
                skip={currentUser?.userRole === UserRole.SUPERVISOR ? affiliatedUsers.length : transactionByDealer.length}
                take={defaultLimitQuery}
                total={currentUser?.userRole === UserRole.SUPERVISOR ? affiliatedUsers.length : transactionByDealer.length}
                collectionName={currentUser?.userRole === UserRole.SUPERVISOR ? referralsCollectionName : transactionCollectionName}
                onPageChange={(pageIndex: number) => pageIndex}
                onPageLimitChange={(pageLimit: number) => currentUser?.userRole === UserRole.SUPERVISOR ?
                  retriveAffiliatedSupervisor(pageLimit) : getDealerTransactionByDate(pageLimit)
                }
                isChangedPage={false}
              />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SellerReferralDetailsPage;
