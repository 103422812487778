import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Form,
  FormInput,
  FormSelect,
} from "../../../components/FormComponents";
import {
  AgencyStaffRole,
  UserInterface,
  UserRole,
  mapsGender,
} from "../../../interfaces";
import { functions, storage } from "../../../firebase";
import { usersCollectionName } from "../../../config";
import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormCheckToggle from "../../../components/FormComponents/FormCheckToggle";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { UserContext } from "../../../contexts";
import { getDateTimeWithTimestamp, getTimestamp } from "../../../utils";
import moment from "moment";

type InfosUsers = Pick<
  UserInterface,
  | "lastName"
  | "email"
  | "active"
  | "city"
  | "firstName"
  | "cnibNumber"
  | "gender"
  | "phoneNumber"
  | "profession"
  | "updatedAt"
  | "cnibImageUrl"
  | "isCertify"
  | "availableZone"
  | "personToNotifyName"
  | "personToNotifyPhone"
  | "personToNotify"
  | "documentIssueDate"
  | "expirationDate"
  | "dayOfBirth"
  | "adresse"
>;

const CertifyUserPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUser = location.state?.entity;
  const { user } = useContext(UserContext);

  const [imageUrl, setImageUrl] = useState<string>();
  const [imageUrlVerso, setImageUrlVerso] = useState<string>();
  const [selectedOption, setSelectedOption] = useState(
    currentUser ? currentUser?.documentType : "CNIB"
  );
  const [type, setType] = useState("text");

  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );

  const onSubmitInfosUser = (data: any) => {
    if (currentUser) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: usersCollectionName,
        documentData: {
          isCertify: data.isCertify,
          lastName: data.lastName,
          firstName: data.firstName,
          email: data.email,
          city: data.city,
          profession: data.profession,
          cnibNumber: data.cnibNumber,
          phoneNumber: data.phoneNumber,
          cnibImageUrl: imageUrl ? imageUrl : currentUser?.cnibImageUrl,
          displayName: data.lastName + " " + data.firstName,
          adresse: data.adresse,
          gender: data.gender,
          dayOfBirth: dayOfBirth,
          documentIssueDate: documentIssueDate,
          expirationDate: expirationDate,
          documentType: selectedOption,
          personToNotify: `${data.personToNotifyName}  ${data.personToNotifyPhone}`,
          documentVerso: imageUrlVerso
            ? imageUrlVerso
            : currentUser?.documentVerso,
        },
        documentId: currentUser.id,
      }).then((result) => {
        navigate("/users");
      });
      toast.promise(response, {
        error: "La certificationƒ a échoué",
        success: "L'utilisateur a été certifier avec succès",
        loading: "chargement...",
      });
    } else
      toast.error(
        `L'indentifiant de l'agent est incorrect veuiller réessayer de nouveau !`
      );
  };

  const handlechange = (value: any) => {
    const image = value.target.files[0];
    if (image && currentUser) {
      const imageRef = ref(
        storage,
        `users/${currentUser?.id}/cnibImage/${image.lastModified}`
      );
      uploadBytes(imageRef, image)
        .then(() => {
          getDownloadURL(imageRef)
            .then((url) => {
              setImageUrl(url);
            })
            .catch((error) =>
              toast.error(
                `Une erreur s'est produit lors du chargement de l'url du fichier ${error.message}`
              )
            );
          setImageUrl("");
        })
        .catch((error) =>
          toast.error(
            `Une erreur s'est produit lors de l'upload du fichier ${error.message}`
          )
        );
    }
  };

  const handlechangeVerso = (value: any) => {
    const image = value.target.files[0];
    if (image && currentUser) {
      const imageRef = ref(
        storage,
        `users/${currentUser?.id}/cnibImage/${image.lastModified}`
      );
      uploadBytes(imageRef, image)
        .then(() => {
          getDownloadURL(imageRef)
            .then((url) => {
              setImageUrlVerso(url);
            })
            .catch((error) =>
              toast.error(
                `Une erreur s'est produit lors du chargement de l'url du fichier ${error.message}`
              )
            );
          setImageUrlVerso("");
        })
        .catch((error) =>
          toast.error(
            `Une erreur s'est produit lors de l'upload du fichier ${error.message}`
          )
        );
    }
  };

  const [expirationDate, setExpirationDate] = useState<any>(
    currentUser && currentUser?.expirationDate
      ? currentUser?.expirationDate
      : "Non_defini"
  );

  const [dayOfBirth, setDayOfBirth] = useState<any>(
    currentUser && currentUser?.dayOfBirth
      ? currentUser?.dayOfBirth
      : "Non_defini"
  );
  const [documentIssueDate, setDocumentIssueDate] = useState<any>(
    currentUser && currentUser?.documentIssueDate
      ? currentUser?.documentIssueDate
      : "Non_defini"
  );

  const handleDayOfBirth = (value: any) => {
    const dayOfBirth = getTimestamp(value.target.value, true);
    setDayOfBirth(dayOfBirth);
  };
  const handleEndDate = (value: any) => {
    const expirationDate = getTimestamp(value.target.value, true);
    setExpirationDate(expirationDate);
  };
  const handleDocumentIssueDate = (value: any) => {
    const documentIssueDate = getTimestamp(value.target.value, true);
    setDocumentIssueDate(documentIssueDate);
  };

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="relative flex justify-center bg-white isolate">
      <div className="grid grid-cols-1 mx-auto max-w-7xl lg:grid-cols-1">
        <div className="relative px-6 pt-24 pb-6 sm:pt-32 lg:static lg:px-8 lg:py-6">
          <div className="max-w-xl mx-auto lg:mx-0 lg:max-w-lg">
            <div className="">
              <svg
                className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                    width={200}
                    height={200}
                    x="100%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M130 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <rect width="100%" height="100%" strokeWidth={0} fill="white" />
                <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                  <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                </svg>
                <rect
                  width="100%"
                  height="100%"
                  strokeWidth={0}
                  fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
                />
              </svg>
            </div>
            <div>
              <Form<InfosUsers>
                onSubmit={onSubmitInfosUser}
                form={{
                  mode: "onChange",
                  resolver: yupResolver(
                    yup.object().shape({
                      isCertify: yup
                        .boolean()
                        .equals([true], "Veuillez certifier l'utilisateur"),
                      lastName: yup
                        .string()
                        .required("Le champs est obligatoire"),
                      firstName: yup
                        .string()
                        .required("Le champs est obligatoire"),
                      email: yup.string().optional(),
                      city: yup.string().required("Le champs est obligatoire"),
                      profession: yup.string().optional(),
                      adresse: yup.string().optional(),
                      availableZone: yup.string().optional(),
                      cnibNumber: yup
                        .string()
                        .required("Le champs est obligatoire"),
                      phoneNumber: yup
                        .string()
                        .required("Le champs est obligatoire"),
                      personToNotifyName: yup.string().optional(),
                      personToNotifyPhone: yup.string().optional(),
                      dayOfBirth: yup.string().optional(),
                      documentIssueDate: yup.string().optional(),
                      expirationDate: yup.string().optional(),
                    })
                  ),
                  defaultValues: {
                    isCertify: currentUser ? currentUser?.isCertify : false,
                    lastName: currentUser?.lastName
                      ? currentUser?.lastName
                      : currentUser?.displayName
                      ? (currentUser?.displayName as string).split(" ")[0]
                      : "-",

                    firstName: currentUser?.firstName
                      ? currentUser?.firstName
                      : currentUser?.displayName
                      ? (currentUser?.displayName as string).split(" ")[1]
                      : "-",

                    personToNotifyName:
                      currentUser && currentUser?.personToNotify
                        ? currentUser?.personToNotify.split(" ").length > 2
                          ? (currentUser?.personToNotify as string)
                              .split(" ")
                              .slice(0, 2)
                              .join(" ")
                          : (currentUser?.personToNotify as string).split(" ")
                              .length <= 1
                          ? (currentUser?.personToNotify as string)
                              .split(" ")[0]
                              .toString()
                          : "-"
                        : "-",

                    personToNotifyPhone:
                      currentUser && currentUser?.personToNotifyPhone
                        ? currentUser?.personToNotifyPhone
                        : currentUser?.personToNotify
                        ? (currentUser?.personToNotify as string)
                            .split(" ")
                            .slice(-1)
                            .toString()
                        : !currentUser?.personToNotify
                        ? "-"
                        : "-",

                    city: currentUser ? currentUser.city : "-",
                    profession: currentUser ? currentUser?.profession : "-",
                    phoneNumber: currentUser ? currentUser?.phoneNumber : "-",
                    cnibNumber: currentUser ? currentUser?.cnibNumber : "-",
                    email: currentUser ? currentUser?.email : "-",
                    gender: currentUser?.gender
                      ? mapsGender.get(currentUser?.gender)
                      : "",
                    adresse: currentUser ? currentUser?.adresse : "-",
                    dayOfBirth:
                      currentUser && currentUser?.dayOfBirth // moment().format("YYYY-MM-DD")
                        ? moment(
                            getDateTimeWithTimestamp(currentUser?.dayOfBirth)
                          ).format("YYYY-MM-DD")
                        : "Non_defini",
                    documentIssueDate:
                      currentUser && currentUser?.documentIssueDate
                        ? moment(
                            getDateTimeWithTimestamp(
                              currentUser?.documentIssueDate
                            )
                          ).format("YYYY-MM-DD")
                        : "Non_defini",
                    expirationDate:
                      currentUser && currentUser?.expirationDate
                        ? moment(
                            getDateTimeWithTimestamp(
                              currentUser?.expirationDate
                            )
                          ).format("YYYY-MM-DD")
                        : "Non_defini",
                  },
                }}
                submitButtonLabel={"Certifier"}
                isSubmitBtnDisabled={
                  !(
                    user?.userRole === UserRole.ADMIN ||
                    user?.userAgencySubRole === AgencyStaffRole.MANAGER ||
                    user?.userRole === UserRole.TECHNICALSUPPORT
                  )
                    ? true
                    : false
                }
              >
                <h2 className="absolute text-3xl font-bold tracking-tight text-gray-900 top-5">
                  Informations de l'utilisateur
                </h2>

                <div className="max-w-xl mx-auto lg:mr-0 lg:max-w-lg">
                  <div className="grid grid-cols-1 gap-x-8 sm:grid-cols-2">
                    <div>
                      <FormInput
                        name="lastName"
                        label="Nom"
                        placeholder=""
                        disabled={
                          !(
                            user?.userRole === UserRole.ADMIN ||
                            user?.userRole === UserRole.TECHNICALSUPPORT
                          )
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div>
                      <FormInput
                        name="firstName"
                        label="Prénom"
                        placeholder=""
                        disabled={
                          !(
                            user?.userRole === UserRole.ADMIN ||
                            user?.userRole === UserRole.TECHNICALSUPPORT
                          )
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="">
                      <FormInput
                        name="email"
                        label="Email"
                        placeholder=""
                        disabled={
                          !(
                            user?.userRole === UserRole.ADMIN ||
                            user?.userAgencySubRole ===
                              AgencyStaffRole.MANAGER ||
                            user?.userRole === UserRole.TECHNICALSUPPORT
                          )
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="">
                      <FormInput
                        name="cnibNumber"
                        label={`Numéro de ${selectedOption ?? "CNIB"}`}
                        placeholder=""
                        disabled={
                          !(
                            user?.userRole === UserRole.ADMIN ||
                            user?.userAgencySubRole ===
                              AgencyStaffRole.MANAGER ||
                            user?.userRole === UserRole.TECHNICALSUPPORT
                          )
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="">
                      <FormInput
                        name="phoneNumber"
                        label="Numero Téléphone"
                        placeholder=""
                        disabled={true}
                      />
                    </div>
                    <div className="">
                      <FormInput
                        name="profession"
                        label="Profession"
                        placeholder=""
                        disabled={
                          !(
                            user?.userRole === UserRole.ADMIN ||
                            user?.userAgencySubRole ===
                              AgencyStaffRole.MANAGER ||
                            user?.userRole === UserRole.TECHNICALSUPPORT
                          )
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="">
                      <FormInput
                        name="city"
                        label="Ville"
                        placeholder=""
                        disabled={
                          !(
                            user?.userRole === UserRole.ADMIN ||
                            user?.userAgencySubRole ===
                              AgencyStaffRole.MANAGER ||
                            user?.userRole === UserRole.TECHNICALSUPPORT
                          )
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="">
                      <FormInput
                        name="adresse"
                        label="Adresse"
                        placeholder=""
                        disabled={
                          !(
                            user?.userRole === UserRole.ADMIN ||
                            user?.userAgencySubRole ===
                              AgencyStaffRole.MANAGER ||
                            user?.userRole === UserRole.TECHNICALSUPPORT
                          )
                            ? true
                            : false
                        }
                      />
                    </div>

                    <div className="">
                      <FormInput
                        name="personToNotifyName"
                        label="Nom/Prénoms(Personne à prévenir)"
                        placeholder="John Doe"
                      />
                    </div>

                    <div className="">
                      <FormInput
                        name="personToNotifyPhone"
                        label="Téléphone (Personne à prévenir)"
                        placeholder="+226 85 85 85 85"
                      />
                    </div>

                    <div className="">
                      <FormSelect<string>
                        label={"Genre"}
                        name="gender"
                        selectedOption={
                          currentUser ? currentUser?.gender : "Choisir..."
                        }
                        options={["FEMALE", "MALE", "NONBINARY", "UNKNOWN"]}
                        optionLabel={(option) =>
                          (mapsGender.get(option) as string) ?? "Choisir..."
                        }
                      />
                    </div>
                    <div className="">
                      <FormInput
                        type="date"
                        label="Date de naissance"
                        name="dayOfBirth"
                        onChange={handleDayOfBirth}
                      />
                    </div>
                    <div className="">
                      <FormInput
                        type="date"
                        label="Date de delivrance du document"
                        name="documentIssueDate"
                        onChange={handleDocumentIssueDate}
                      />
                    </div>
                    <div className="">
                      <FormInput
                        type="date"
                        label="Date d'expiration"
                        name="expirationDate"
                        onChange={handleEndDate}
                      />
                    </div>
                  </div>
                </div>
                <div className="relative flex w-full max-w-[550px] flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
                  <nav className="flex min-w-[240px] flex-row gap-1 p-2 font-sans text-base font-normal text-blue-gray-700">
                    <div
                      role="button"
                      className="flex items-center w-full p-0 leading-tight transition-all rounded-lg outline-none text-start hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
                    >
                      <label
                        htmlFor="horizontal-list-react"
                        className="flex items-center w-full px-3 py-2 cursor-pointer"
                      >
                        <div className="grid mr-3 place-items-center">
                          <div className="inline-flex items-center">
                            <label
                              className="relative flex items-center p-0 rounded-full cursor-pointer"
                              htmlFor="horizontal-list-react"
                            >
                              <input
                                name="horizontal-list"
                                id="horizontal-list-react"
                                type="radio"
                                value="CNIB"
                                checked={selectedOption === "CNIB"}
                                onChange={handleOptionChange}
                                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-blue-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                              />
                              <span className="absolute text-blue-500 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-3.5 w-3.5"
                                  viewBox="0 0 16 16"
                                  fill="currentColor"
                                >
                                  <circle
                                    data-name="ellipse"
                                    cx="8"
                                    cy="8"
                                    r="8"
                                  ></circle>
                                </svg>
                              </span>
                            </label>
                          </div>
                        </div>
                        <p className="block font-sans text-base antialiased font-medium leading-relaxed text-blue-gray-400">
                          CNIB
                        </p>
                      </label>
                    </div>
                    <div
                      role="button"
                      className="flex items-center w-full p-0 leading-tight transition-all rounded-lg outline-none text-start hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
                    >
                      <label
                        htmlFor="horizontal-list-vue"
                        className="flex items-center w-full px-3 py-2 cursor-pointer"
                      >
                        <div className="grid mr-3 place-items-center">
                          <div className="inline-flex items-center">
                            <label
                              className="relative flex items-center p-0 rounded-full cursor-pointer"
                              htmlFor="horizontal-list-vue"
                            >
                              <input
                                name="horizontal-list"
                                id="horizontal-list-vue"
                                type="radio"
                                value="PERMIS"
                                checked={selectedOption === "PERMIS"}
                                onChange={handleOptionChange}
                                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-blue-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                              />
                              <span className="absolute text-blue-500 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-3.5 w-3.5"
                                  viewBox="0 0 16 16"
                                  fill="currentColor"
                                >
                                  <circle
                                    data-name="ellipse"
                                    cx="8"
                                    cy="8"
                                    r="8"
                                  ></circle>
                                </svg>
                              </span>
                            </label>
                          </div>
                        </div>
                        <p className="block font-sans text-base antialiased font-medium leading-relaxed text-blue-gray-400">
                          PERMIS
                        </p>
                      </label>
                    </div>
                    <div
                      role="button"
                      className="flex items-center w-full p-0 leading-tight transition-all rounded-lg outline-none text-start hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
                    >
                      <label
                        htmlFor="horizontal-list-svelte"
                        className="flex items-center w-full px-3 py-2 cursor-pointer"
                      >
                        <div className="grid mr-3 place-items-center">
                          <div className="inline-flex items-center">
                            <label
                              className="relative flex items-center p-0 rounded-full cursor-pointer"
                              htmlFor="horizontal-list-svelte"
                            >
                              <input
                                name="horizontal-list"
                                id="horizontal-list-svelte"
                                type="radio"
                                value="PASSPORT"
                                checked={selectedOption === "PASSPORT"}
                                onChange={handleOptionChange}
                                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-blue-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                              />
                              <span className="absolute text-blue-500 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-3.5 w-3.5"
                                  viewBox="0 0 16 16"
                                  fill="currentColor"
                                >
                                  <circle
                                    data-name="ellipse"
                                    cx="8"
                                    cy="8"
                                    r="8"
                                  ></circle>
                                </svg>
                              </span>
                            </label>
                          </div>
                        </div>
                        <p className="block font-sans text-base antialiased font-medium leading-relaxed text-blue-gray-400">
                          PASSPORT
                        </p>
                      </label>
                    </div>
                    <div
                      role="button"
                      className="flex items-center w-full p-0 leading-tight transition-all rounded-lg outline-none text-start hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
                    >
                      <label
                        htmlFor="horizontal-list-react"
                        className="flex items-center w-full px-3 py-2 cursor-pointer"
                      >
                        <div className="grid mr-3 place-items-center">
                          <div className="inline-flex items-center">
                            <label
                              className="relative flex items-center p-0 rounded-full cursor-pointer"
                              htmlFor="horizontal-list-react"
                            >
                              <input
                                name="horizontal-list"
                                id="horizontal-list-react"
                                type="radio"
                                value="AUTRE"
                                checked={selectedOption === "AUTRE"}
                                onChange={handleOptionChange}
                                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-blue-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                              />
                              <span className="absolute text-blue-500 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-3.5 w-3.5"
                                  viewBox="0 0 16 16"
                                  fill="currentColor"
                                >
                                  <circle
                                    data-name="ellipse"
                                    cx="8"
                                    cy="8"
                                    r="8"
                                  ></circle>
                                </svg>
                              </span>
                            </label>
                          </div>
                        </div>
                        <p className="block font-sans text-base antialiased font-medium leading-relaxed text-blue-gray-400">
                          AUTRE
                        </p>
                      </label>
                    </div>
                  </nav>
                </div>
                <div className="flex items-center justify-center p-6">
                  <div className="mx-auto w-full max-w-[550px] bg-white">
                    <div className="pt-2 mb-4">
                      <label className="mb-5 block text-xl font-semibold text-[#07074D]">
                        <FormCheckToggle
                          name="isCertify"
                          label="Certifier le compte"
                        />
                      </label>

                      <div className="mb-4">
                        <input
                          type="file"
                          id="file"
                          className="sr-only"
                          onChange={handlechange}
                        />
                        <label
                          htmlFor="file"
                          className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                        >
                          <div>
                            {currentUser?.cnibImageUrl || imageUrl ? (
                              <img
                                className="object-cover object-center mx-auto"
                                src={imageUrl || currentUser?.cnibImageUrl}
                                alt={imageUrl || currentUser?.displayName}
                              />
                            ) : (
                              <span className="inline-flex rounded border border-[#e0e0e0] cursor-pointer py-2 px-7 text-base font-medium text-[#07074D]">
                                Choisir une image(Recto)
                              </span>
                            )}
                          </div>
                        </label>
                      </div>
                      <div className="mb-4">
                        <input
                          type="file"
                          id="files"
                          className="sr-only"
                          onChange={handlechangeVerso}
                        />
                        <label
                          htmlFor="files"
                          className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                        >
                          <div>
                            {currentUser?.documentVerso || imageUrlVerso ? (
                              <img
                                className="object-cover object-center mx-auto"
                                src={
                                  imageUrlVerso || currentUser?.documentVerso
                                }
                                alt={imageUrlVerso || currentUser?.displayName}
                              />
                            ) : (
                              <span className="inline-flex rounded border border-[#e0e0e0] cursor-pointer py-2 px-7 text-base font-medium text-[#07074D]">
                                Choisir une image(Verso)
                              </span>
                            )}
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertifyUserPage;
