import React from "react";
import { PhoneIcon } from "@heroicons/react/24/outline";
import { DisplayPrice } from "../PriceDisplay";

const Button: React.FC<{ children: React.ReactNode; className?: string, onClick?: () => void; }> = ({ children, className, onClick }) => (
  <button className={`px-4 py-2 rounded-lg ${className}`} onClick={onClick}>{children} </button>
);


const CardContent: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="p-4">{children}</div>
);

interface InfosData {
  displayName: string,
  phoneNumber: string,
  balance: number,
  totalCommission: number,
  totalDeposit: number,
  totalWithdrawal: number,
  totalReload: number,
  dealersNumber: number,
  totalWithdraw: number,
}

const DashboardCard = (props: { info: InfosData, onManage: () => void, dealers: () => void }) => {
  const { info, onManage, dealers } = props
  return (
    <div className="w-full mx-auto bg-white shadow-lg rounded-lg overflow-hidden border">
      <div className="bg-red-600 text-white p-4 flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <img
            className="w-10 h-10 p-1 border rounded-full"
            src={
             `https://ui-avatars.com/api/?background=random&name=${info?.displayName} || PM`
            }
            alt=""
          />
          {/* <div className="bg-white text-red-600 w-10 h-10 flex items-center justify-center rounded-full font-bold">
            {
              
            }
          </div> */}
          <span className="font-semibold">{info?.displayName}</span>
        </div>
        <div className="cursor-pointer">...</div>
      </div>

      <CardContent>
        <div className="flex items-center justify-between text-gray-700">
          <div>
            <PhoneIcon className="text-red-600 w-5 h-6" />
            <span className="font-medium">Téléphone</span>
          </div>
          <span className="font-semibold">{info?.phoneNumber}</span>
        </div>

        <div className="bg-gray-100 p-4 rounded-lg">
          <h2 className="text-sm text-gray-600">Solde</h2>
          <p className="text-2xl font-bold text-red-600">{<DisplayPrice price={info?.balance ?? 0} />} </p>
        </div>

        <div className="flex justify-between text-sm text-gray-600">
          <span>Commission en cours</span>
          <span className="text-red-600 font-semibold">{<DisplayPrice price={info?.totalCommission ?? 0} />} </span>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="border p-3 rounded-lg">
            <h3 className="text-xs text-gray-600">Dépôt</h3>
            <p className="text-lg font-semibold">{<DisplayPrice price={info?.totalDeposit ?? 0} />}</p>
          </div>
          <div className="border p-3 rounded-lg">
            <h3 className="text-xs text-gray-600">Retrait</h3>
            <p className="text-lg font-semibold">{<DisplayPrice price={info?.totalWithdrawal ?? 0} />}</p>
          </div>
        </div>

        <div className="flex justify-between text-sm text-gray-600">
          <span>Appro</span>
          <span className="font-semibold">{<DisplayPrice price={info?.totalReload ?? 0} />}</span>
        </div>

        <div className="flex justify-between text-sm text-gray-600">
          <span>Remboursement</span>
          <span className="font-semibold">{<DisplayPrice price={info?.totalWithdraw ?? 0} />}</span>
        </div>

        <div className="flex justify-between text-sm text-gray-600">
          <span>Dealers affiliés</span>
          <span className="font-semibold">{info?.dealersNumber}</span>
        </div>
      </CardContent>

      <div className="flex justify-between p-4 border-t">
        <Button className="bg-red-600 text-white" onClick={onManage}>Gérer</Button>
        <Button className="bg-gray-200 text-red-600" onClick={dealers}>Ses Dealers</Button>
      </div>
    </div>
  );
};

export default DashboardCard;
