/* eslint-disable react/jsx-no-useless-fragment */
import { Fragment } from "react";
import useHasRoles from "../../hooks/useHasRoles";

/* eslint-disable-next-line */
export interface HasRolesProps {
  children: JSX.Element;
  userRole?: (
    | "SUDO"
    | "ADMIN"
    | "MERCHANT"
    | "DEALER"
    | "USER"
    | "EMPLOYMENT"
    | "AGENT"
    | "GASSTATIONSTAFF"
    | "AGENCYSTAFF"
    | "SUPERVISOR"
    | "SUPERMERCHANT"
    | "SUPERSTATION"
    | "COMPANY"
    | "COMPANYSTAFF"
    | "TECHNICALSUPPORT"
    | "COLLECTOR"
    | "COLLECTIONPOINT"
    | "RESPONSABLEZONE"
    | "SUPERDEALER"
    | "SUPERVISEURSUPERDEALER"
  )[];
}

export function HasRoles(props: HasRolesProps) {
  const { isAllowed } = useHasRoles();

  return isAllowed(props.userRole) ? props.children : <Fragment />;
}

export default HasRoles;
