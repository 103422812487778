import {
  CheckCircleIcon,
  FunnelIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { db, functions } from "../../../firebase";
import {
  responsableZoneReferralsCollection,
  usersCollectionName,
} from "../../../config";
import { useCallback, useContext, useEffect, useState } from "react";
import { Timestamp } from "firebase-admin/firestore";
import moment from "moment";
import { parseDate, getTimestamp, classNames } from "../../../utils";
import { UserContext } from "../../../contexts";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import TableRowEditMenu from "../../../components/TableRowEditMenu/TableRowEditMenu";
import Pagination from "../../../components/Pagination/Pagination";

const tableHeader = [
  "N°",
  "Date de création",
  "Profil",
  "Nom/prénom",
  // "Téléphone",
  "Ville",
  "Role",
  "Email",
  "Active",
  // "Certifié",
  "Actions",
];

const ResponsableZonePage = () => {
  // let refreshDataFn: (() => void) | undefined = undefined;
  const navigate = useNavigate();

  const onShowSellers = (id: any, entity: any) => {
    if (entity) {
      navigate(
        `/responsable_zone/superviseurs-affiliate/${entity.id}/details`,
        {
          state: { entity: entity, id: entity.id },
        }
      );
    }
  };
  const onShowDealers = (id: any, entity: any) => {
    if (entity) {
      navigate(
        `/responsable_zone/superviseurs-affiliate/${entity.id}/dealers`,
        {
          state: { entity: entity, id: entity.id },
        }
      );
    }
  };
  // const [startTime, setStartTime] = useState<any>(
  //   getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  // );
  // const [endTime, setEndTime] = useState<any>(
  //   getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, false)
  // );
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  // const handleChangeStartDateValue = (value: any) => {
  //   const startTime = getTimestamp(value.target.value, true);
  //   setStartTime(startTime);
  // };
  // const handleChangeEndDtaeValue = (value: any) => {
  //   const endTime = getTimestamp(value.target.value, false);
  //   setEndTime(endTime);
  // };
  const [affiliatedUsers, setAffiliatedUsers] = useState<any[]>([]);
  const [defaultLimitQuery, setDefaultLimitQuery] = useState(10);

  const getSupervisorAffiliated = useCallback(
    async (pageLimit: number) => {
      setLoading(true);
      setDefaultLimitQuery(pageLimit);
      await db
        .collection(responsableZoneReferralsCollection)
        .where("responsableZoneId", "==", user?.id)
        .limit(pageLimit)
        .get()
        .then((response) => {
          let data: any[] = [];
          response.docs.map((d) => {
            data.push({ id: d.id, ...d.data() });
          });
          retriveAffiliatedUser(data);
          setLoading(false);
        });
    },
    [user?.id]
  );

  const retriveAffiliatedUser = useCallback(async (dataVal: any[]) => {
    let data: any[] = [];
    for (const referral of dataVal) {
      const snap = await db
        .collection(usersCollectionName)
        .doc(referral?.supervisorId)
        .get();
      if (snap.exists) {
        data.push({
          ...snap.data(),
        });
      }
    }
    setAffiliatedUsers(data);
  }, []);

  useEffect(() => {
    getSupervisorAffiliated(25);
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col mt-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="flex flex-col items-center justify-end p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
              {/* <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                <div className="flex items-center w-full space-x-3 md:w-auto">
                  <input
                    type="date"
                    id="dateDebut"
                    className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                    onChange={handleChangeStartDateValue}
                  />
                  <input
                    type="date"
                    id="dateDebut"
                    className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                    onChange={handleChangeEndDtaeValue}
                  />
                </div>
                <button
                  type="button"
                  className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  onClick={() => {
                    getSupervisorAffiliated(25);
                  }}
                >
                  <FunnelIcon
                    className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                    aria-hidden="true"
                  />
                  Filtrer
                </button>
              </div> */}
            </div>
            <div className="h-4" />
            {loading ? (
              <>
                <div className="p-4 animate-pulse">
                  <div className="h-4 mt-3 mb-6 bg-gray-400 rounded"></div>
                  {[...Array(5)].map((e, i) => (
                    <div key={i}>
                      <div className="h-4 mb-6 bg-gray-200 rounded"></div>
                      <div className="h-4 mb-6 bg-gray-300 rounded"></div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="shadow-sm">
                <div className="flex flex-col items-stretch justify-center w-full space-y-4 md:flex-row md:space-y-0 md:space-x-8">
                  <table
                    className="min-w-full border-separate"
                    style={{ borderSpacing: 0 }}
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        {tableHeader.map((th, index) => (
                          <th
                            key={index}
                            scope="col"
                            className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                          >
                            {th}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {affiliatedUsers && affiliatedUsers.length > 0 ? (
                        affiliatedUsers.map((user: any, userIdx: number) => (
                          <tr key={userIdx}>
                            <td
                              className={classNames(
                                userIdx !== affiliatedUsers.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(userIdx as number) + 1}
                            </td>
                            <td
                              className={classNames(
                                userIdx !== affiliatedUsers.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {parseDate(user?.createdAt as Timestamp)}
                            </td>
                            <td
                              className={classNames(
                                userIdx !== affiliatedUsers.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              <img
                                className="w-10 h-10 p-1 border rounded-full"
                                src={
                                  user?.profilImageUrl
                                    ? user?.profilImageUrl
                                    : `https://ui-avatars.com/api/?background=random&name=${
                                        user?.displayName || "S"
                                      }`
                                }
                                alt=""
                              />
                            </td>
                            <td
                              className={classNames(
                                userIdx !== affiliatedUsers.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user.displayName ? user.displayName : "-"}
                            </td>
                            {/* <td
                                className={classNames(
                                  userIdx !== affiliatedUsers.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {user.phoneNumber ? user.phoneNumber : "-"}
                              </td> */}
                            <td
                              className={classNames(
                                userIdx !== affiliatedUsers.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user.city ? user.city : "-"}
                            </td>
                            <td
                              className={classNames(
                                userIdx !== affiliatedUsers.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user?.userRole ?? "-"}
                            </td>
                            <td
                              className={classNames(
                                userIdx !== affiliatedUsers.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user?.email ?? "-"}
                            </td>
                            <td
                              className={classNames(
                                userIdx !== affiliatedUsers.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user.active ? (
                                <CheckCircleIcon className="w-6 h-6 text-green-500" />
                              ) : (
                                <XCircleIcon className="w-6 h-6 text-red-500" />
                              )}
                            </td>
                            {/* <td
                                className={classNames(
                                  userIdx !== affiliatedUsers.length - 1
                                    ? "border-b border-gray-200"
                                    : "",
                                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                )}
                              >
                                {user.isCertify ? (
                                  <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                ) : (
                                  <XCircleIcon className="w-6 h-6 text-red-500" />
                                )}
                              </td> */}
                            <td
                              className={classNames(
                                userIdx !== affiliatedUsers.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              <TableRowEditMenu
                                rowDealerHandler={() =>
                                  onShowDealers(user?.id, user)
                                }
                                rowSellerHandler={() =>
                                  onShowSellers(user?.id, user)
                                }
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoContent />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <Pagination
              skip={affiliatedUsers.length}
              take={defaultLimitQuery}
              total={affiliatedUsers.length}
              collectionName={responsableZoneReferralsCollection}
              onPageChange={(pageIndex: number) => pageIndex}
              onPageLimitChange={(pageLimit: number) =>
                getSupervisorAffiliated(pageLimit)
              }
              isChangedPage={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsableZonePage;
