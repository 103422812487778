export const penalityCalculation = (invoices: any[]) => {
  const penalitiesData: any[] = [];
  const currentDate = new Date();
  // sort invoices by DESC
  invoices.sort((a, b) => {
    const dateA = a.invoicedeadline;
    const dateB = b.invoicedeadline;
    return dateB?.localeCompare(dateA);
  });
  let numberOfInvoicesPenalized = 0;
  for (const invoice of invoices) {
    if (formatStringToCustomDate(invoice.invoicedeadline) < currentDate) {
      numberOfInvoicesPenalized++;
    }
  }
  if (numberOfInvoicesPenalized > 0) {
    const antInvoice = invoices.filter(
      (inv) => formatStringToCustomDate(inv.invoicedeadline) < currentDate
    );
    antInvoice.sort((a, b) => {
      const dateA = a.invoicedeadline;
      const dateB = b.invoicedeadline;
      return dateA?.localeCompare(dateB);
    });
    penalitiesData.push({
      id: transformString(antInvoice[0].id, "99"), // transforme date like 633TP9905323
      subscribernumber: antInvoice[0].subscribernumber,
      subscribername: antInvoice[0].subscribername,
      invoicetype: "XP",
      subscribercategory: antInvoice[0].subscribercategory,
      amount: numberOfInvoicesPenalized >= 2 ? "4000" : "2000",
      invoicedeadline: antInvoice[0].invoicedeadline,
    });
  }

  return [...penalitiesData];
};

const transformString = (inputString: string, replacement: string): string => {
  const invoiceIdWithInvoiceTypeValue = inputString.split("_");
  const id =
    invoiceIdWithInvoiceTypeValue.length > 1
      ? invoiceIdWithInvoiceTypeValue[1]
      : inputString;
  const parts = id.split(/[A-Za-z]+/);
  const matches = id.match(/[A-Za-z]+/);

  if (parts.length >= 2 && matches) {
    const secondPart = parts[1];
    const transformedSecondPart = replacement + secondPart.substring(2);
    return parts[0] + matches[0] + transformedSecondPart;
  }

  return id;
};

const formatStringToCustomDate = (dateString: string): Date => {
  const y = dateString.slice(0, 4);
  const m = dateString.slice(4, 6);
  const d = dateString.slice(6, 8);
  const date = new Date(
    parseInt(y),
    parseInt(m) - 1,
    parseInt(d),
    23,
    59,
    59,
    999
  );
  return date;
};

export const sortInvoicesByDeadline = (invoices: any[]) => {
  const invoicesValideDate = invoices.map((invoice) => {
    const invoicedeadline = formatStringToCustomDate(
      String(invoice.invoicedeadline)
    );
    const year = parseInt(invoice.dateofindexsamplingpart1);
    const mounth = parseInt(invoice.dateofindexsamplingpart2);
    const consumption = new Date(year, mounth);

    return { ...invoice, invoicedeadline, consumption };
  });

  invoicesValideDate.sort((a, b) => invoicesSort(a, b));
  return invoicesValideDate;
};

const invoicesSort = (a: any, b: any) => {
  if (a.invoicedeadline === b.invoicedeadline) {
    return a.consumption - b.consumption;
  } else return a.invoicedeadline - b.invoicedeadline;
};
