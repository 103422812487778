import { useCallback, useEffect, useState } from "react";
import {
  ArrowDownTrayIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { FunnelIcon } from "@heroicons/react/20/solid";
import { classNames, getTimestamp, parseDate } from "../../../utils";
import moment from "moment";
import { Timestamp } from "firebase-admin/firestore";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import Pagination from "../../../components/Pagination/Pagination";
import { referralsCollectionName, usersCollectionName } from "../../../config";
import * as XLSX from "xlsx";
import toast from "react-hot-toast";
import { db } from "../../../firebase";
import TableRowEditMenu from "../../../components/TableRowEditMenu/TableRowEditMenu";
import { UserRole } from "../../../interfaces";
import { useLocation, useNavigate } from "react-router-dom";
import HasRoles from "../../../components/HasRoles";

const supervisorTableHeader = [
  "N°",
  "",
  "Nom/prénom",
  "Email",
  "Ville",
  "Téléphone",
  "Active",
  "Date de création",
  "Actions",
];

const ReferralDealerDetailsPage = () => {
  const location = useLocation();
  const currentUser = location.state?.entity;
  const [startTime, setStartTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  );
  const [endTime, setEndTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, false)
  );
  const [defaultLimitQuery, setDefaultLimitQuery] = useState(100);
  const [filterDate, setFilterDate] = useState(false);

  const navigate = useNavigate();
  const [affiliatedDealers, setAffiliatedDealers] = useState<any[]>([]);

  const handleChangeStartDateValue = (value: any) => {
    const startTime = getTimestamp(value.target.value, true);
    setStartTime(startTime);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    const endTime = getTimestamp(value.target.value, false);
    setEndTime(endTime);
  };

  const filter = async () => {
    if (filterDate) {
      retriveAffiliatedDealer();
    }
  };

  const retriveAffiliatedDealer = async () => {
    if (startTime > endTime) {
      return toast.error(
        "Il est préférable de changer la date avant de réessayer, car la date de début ne doit pas être supérieur à la date de fin."
      );
    }
    let res;
    if (filterDate) {
      res = await db
        .collection(usersCollectionName)
        .where("supervisorId", "==", currentUser?.id)
        .where("userRole", "==", UserRole.DEALER)
        .where("createdAt", ">=", startTime)
        .where("createdAt", "<=", endTime)
        .limit(defaultLimitQuery)
        .get();
    } else
      res = await db
        .collection(usersCollectionName)
        .where("supervisorId", "==", currentUser?.id)
        .where("userRole", "==", UserRole.DEALER)
        .limit(defaultLimitQuery)
        .get();
    if (res.empty) {
      setAffiliatedDealers([]);
    } else {
      let result: any[] = [];
      for (const snap of res.docs) {
        result.push({ id: snap.id, ...snap.data() });
      }
      setAffiliatedDealers(result);
    }
  };

  const exportToXLSDataSupervisor = useCallback(async () => {
    const worksheet = XLSX.utils.json_to_sheet(
      affiliatedDealers.map((reverral, index: number) => {
        return {
          "N°": index + 1,
          "Nom/Prénom": reverral?.displayName ?? "-",
          Email: reverral?.email ?? "-",
          Ville: reverral?.city ?? "-",
          Téléphone: reverral?.phoneNumber ?? "-",
          "Date de création": (reverral?.createdAt as Timestamp)
            .toDate()
            .toLocaleString(),
        };
      })
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "export");

    XLSX.writeFile(
      workbook,
      `Comptes-affiliés-aux-superviseurs-${new Date().toLocaleDateString()}.xlsx`,
      { compression: true }
    );
  }, [affiliatedDealers]);

  const onPageLimitChange = async (pageLimit: number) => {
    setDefaultLimitQuery(pageLimit);
    if (startTime > endTime) {
      toast.error(
        "Il est préférable de changer la date avant de réessayer, car la date de début ne doit pas être supérieur à la date de fin."
      );
    } else {
      if (currentUser?.userRole === UserRole.SUPERVISOR) {
        retriveAffiliatedDealer();
      }
    }
  };

  const onRowDealerDetails = (entity: any) => {
    if (entity) {
      navigate(`/dealers/${entity.id}/details`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };

  const onShowDealerAgent = (entity: any) => {
    if (entity) {
      navigate(`/dealers/${entity.id}/agents`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };

  // Start Function to redirect "SUPERVISOR" toward activities page
  const onShowDealerActivities = (entity: any) => {
    if (entity) {
      navigate(`/dealers/${entity.id}/activities`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  // End Function to redirect "SUPERVISOR" toward activities page

  useEffect(() => {
    currentUser && retriveAffiliatedDealer();
  }, []);

  return (
    <div className="bg-white px-4 mt-10 sm:px-6 lg:px-8">
      <div>
        <div className="flex flex-col mt-8">
          {/* Start Title section */}
          <div className="sm:flex-auto">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              Les dealers
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Vous trouverez ci-dessous tous les dealers affiliés Superviserur{" "}
              <span className="text-red-500 text-base">
                {currentUser && currentUser?.displayName}
              </span>
              .
            </p>
          </div>
          {/* end Title section */}

          {/* start 8px of height between title section and next section */}
          <div className="h-8" />
          {/* end 8px of height between title section and next section */}

          {/* start search section  */}
          <div className="flex self-end max-md:flex-wrap">
            <div className="flex mx-1 flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
              {/* Search agent button */}
              <div className="w-36">
                <label className="relative flex justify-between cursor-pointer">
                  <span className="text-sm font-medium text-gray-900 ms-3 dark:text-gray-300">
                    Filtrer par date
                  </span>
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={filterDate}
                    onChange={(e) => setFilterDate(e.target.checked)}
                  />
                  <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
                </label>
              </div>

              <div className="flex items-center w-full space-x-3 md:w-auto">
                {/* start date */}
                <input
                  type="date"
                  id="dateDebut"
                  className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeStartDateValue}
                />

                {/* end date */}
                <input
                  type="date"
                  id="dateDebut"
                  className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeEndDtaeValue}
                />
              </div>
              {/* End filter by date */}
              <div className="flex mt-10">
                <button
                  type="button"
                  className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  onClick={() => {
                    filter();
                  }}
                >
                  <FunnelIcon
                    className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                    aria-hidden="true"
                  />
                  Executer le filtre
                </button>
              </div>
              <div className="mx-2">
                {/* <label
                  htmlFor="dateFin"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Exporter
                </label> */}
                <button
                  type="button"
                  className={classNames(
                    affiliatedDealers.length > 0
                      ? "text-green-700 border-green-300 hover:bg-green-50 focus:ring-green-500"
                      : "text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-gray-500 cursor-not-allowed",
                    "inline-flex items-center h-10 px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                  )}
                  onClick={() => {
                    affiliatedDealers.length > 0 && exportToXLSDataSupervisor();
                  }}
                  disabled={affiliatedDealers.length > 0 ? false : true}
                >
                  <ArrowDownTrayIcon
                    className={classNames(
                      affiliatedDealers.length > 0
                        ? "text-green-500"
                        : "text-gray-500",
                      "self-center flex-shrink-0 w-5 h-5 mr-1"
                    )}
                    aria-hidden="true"
                  />
                  <span className="sr-only">Exporter en XLS</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <HasRoles userRole={["SUPERVISOR", "DEALER", "RESPONSABLEZONE"]}>
          <div className="flex flex-col mt-8">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="shadow-sm">
                  <table
                    className="min-w-full border-separate"
                    style={{ borderSpacing: 0 }}
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        {supervisorTableHeader.map((th, index) => (
                          <th
                            key={index}
                            scope="col"
                            className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                          >
                            {th}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {affiliatedDealers && affiliatedDealers.length > 0 ? (
                        affiliatedDealers.map((user: any, userIdx: number) => (
                          <tr key={userIdx}>
                            <td
                              className={classNames(
                                userIdx !== affiliatedDealers.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(userIdx as number) + 1}
                            </td>
                            <td
                              className={classNames(
                                userIdx !== affiliatedDealers.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              <img
                                className="w-10 h-10 p-1 border rounded-full"
                                src={
                                  user?.profilImageUrl
                                    ? user?.profilImageUrl
                                    : `https://ui-avatars.com/api/?background=random&name=${
                                        user?.displayName || "S"
                                      }`
                                }
                                alt=""
                              />
                            </td>
                            <td
                              className={classNames(
                                userIdx !== affiliatedDealers.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user.displayName ? user.displayName : "-"}
                            </td>
                            <td
                              className={classNames(
                                userIdx !== affiliatedDealers.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user.email ? user.email : "-"}
                            </td>
                            <td
                              className={classNames(
                                userIdx !== affiliatedDealers.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user.city ? user.city : "-"}
                            </td>
                            <td
                              className={classNames(
                                userIdx !== affiliatedDealers.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user.phoneNumber ?? "-"}
                            </td>
                            <td
                              className={classNames(
                                userIdx !== affiliatedDealers.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user.active ? (
                                <CheckCircleIcon className="w-6 h-6 text-green-500" />
                              ) : (
                                <XCircleIcon className="w-6 h-6 text-red-500" />
                              )}
                            </td>
                            <td
                              className={classNames(
                                userIdx !== affiliatedDealers.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {parseDate(user?.createdAt as Timestamp)}
                            </td>
                            <td
                              className={classNames(
                                userIdx !== affiliatedDealers.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              <TableRowEditMenu
                                rowDetailsHandler={() =>
                                  onRowDealerDetails(user)
                                }
                                rowShowDealerAgentHandler={() =>
                                  onShowDealerAgent(user)
                                }
                                rowShowActivitiesHandler={() =>
                                  onShowDealerActivities(user)
                                }
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoContent />
                      )}
                    </tbody>
                  </table>
                  {affiliatedDealers && affiliatedDealers.length > 0 && (
                    <Pagination
                      skip={affiliatedDealers.length}
                      take={defaultLimitQuery}
                      total={affiliatedDealers.length}
                      collectionName={referralsCollectionName}
                      onPageChange={(pageIndex: number) => pageIndex}
                      onPageLimitChange={(pageLimit: number) =>
                        onPageLimitChange(pageLimit)
                      }
                      isChangedPage={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </HasRoles>
      </div>
    </div>
  );
};

export default ReferralDealerDetailsPage;
