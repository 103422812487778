import {
    BoltIcon,
    CheckCircleIcon,
    XCircleIcon,
    ArrowDownTrayIcon,
    ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";
import moment from "moment";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import SankMoneyIcon from "../../../CFA.png";
import toast from "react-hot-toast";
import Pagination from "../../../components/Pagination/Pagination";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import { transactionCollectionName } from "../../../config";
import { UserContext } from "../../../contexts";
import { db, functions } from "../../../firebase";
import {transactionMap, TransactionType } from "../../../interfaces";
import {
    getTimestamp,
    parseDate,
    amountFormater,
    classNames,
} from "../../../utils";
import { Menu, Transition } from "@headlessui/react";
// import { jsPDF } from "jspdf";
import "jspdf-autotable";
// import autoTable from "jspdf-autotable";
// import Modal from "../../../components/Modal";
// import { FormInput, Form } from "../../../components/FormComponents";
// import * as yup from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";
// import SankMoneyLogo from "../../../Sank_red.png"

const tableHeader = [
    "N°",
    "",
    "Nom destinataire",
    "Numéro destinataire",
    "Nom Expéditeur",
    "Numéro Expéditeur",
    "Montant",
    "Type de transaction",
    "Compte",
    "Frais",
    "Status",
    "Date de création",
];

const transactionTypes = [TransactionType.RELOAD, TransactionType.WITHDRAW];

const SuperDealerTransactionsHistories = () => {
    const { user } = useContext(UserContext);
    const [transactions, setTransactions] = useState<any[]>([]);

    const [defaultLoadHistories, setDefaultLoadHistories] = useState<number>(10);
    const [transactionTotalSum, setTransactionTotalSum] = useState<number>(0);
    const [transDataLimitLength, setTransDataLimitLength] = useState<number>(100);
    // const [showModalToSendEmail, setShowModalToSendEmail] = useState<boolean>(false)
    const [selectedTransactionsTypeVal, setSelectedTransactionsTypeVal] =
        useState<TransactionType>(TransactionType.RELOAD);
    const [searchWithStartDateValue, setSetsearchWithStartDateValue] =
        useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
    const [searchWithEndDateValue, setSetsearchWithEndDateValue] =
        useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);

    const handleChangeStartDateValue = (value: any) => {
        setSetsearchWithStartDateValue(value.target.value);
    };
    const handleChangeEndDtaeValue = (value: any) => {
        setSetsearchWithEndDateValue(value.target.value);
    };

    const retrieveFilteredTransactions = useCallback(async () => {
        const startTime = getTimestamp(searchWithStartDateValue, true);
        const endTime = getTimestamp(searchWithEndDateValue, false);
        if (startTime > endTime) {
            return toast.error(
                "La date de fin ne doit pas etre inférieur à la date de début."
            );
        }
        if (!user) {
            return;
        }
        const transSnap = await db
            .collection(transactionCollectionName)
            .orderBy("createdAt", "desc")
            .where("participantsIds", "array-contains", user?.id)
            .where("transactionType", "==", selectedTransactionsTypeVal.toString())
            .where("createdAt", ">=", startTime)
            .where("createdAt", "<=", endTime)
            .get();
        if (transSnap.empty) {
            setDefaultLoadHistories(0);
            setTransactionTotalSum(0);
            setTransactions([]);
        } else {
            const trans = transSnap.docs.map((doc) => {
                return { id: doc.id, data: doc.data() };
            });
            let sum = 0;
            transSnap.forEach((doc) => (sum = sum + parseFloat(doc.data()?.amount)));
            setDefaultLoadHistories(transSnap.size);
            setTransactionTotalSum(sum);
            setTransactions(trans);
        }
    }, [searchWithStartDateValue, searchWithEndDateValue, user, selectedTransactionsTypeVal]);

    const onPagination = useCallback(async (limit: number) => {
        const startTime = getTimestamp(searchWithStartDateValue, true);
        const endTime = getTimestamp(searchWithEndDateValue, false);
        if (startTime > endTime) {
            return toast.error(
                "La date de fin ne doit pas etre inférieur à la date de début."
            );
        }
        if (!user) {
            return;
        }
        const transSnap = await db
            .collection(transactionCollectionName)
            .orderBy("createdAt", "desc")
            .where("participantsIds", "array-contains", user?.id)
            .where("transactionType", "==", selectedTransactionsTypeVal.toString())
            .where("createdAt", ">=", startTime)
            .where("createdAt", "<=", endTime)
            .limit(limit)
            .get();
        if (transSnap.empty) {
            setDefaultLoadHistories(0);
            setTransactionTotalSum(0);
            setTransactions([]);
        } else {
            const trans = transSnap.docs.map((doc) => {
                return { id: doc.id, data: doc.data() };
            });
            let sum = 0;
            transSnap.forEach((doc) => (sum = sum + parseFloat(doc.data()?.amount)));
            setDefaultLoadHistories(transSnap.size);
            setTransactionTotalSum(sum);
            setTransactions(trans);
        }
    }, [searchWithStartDateValue, searchWithEndDateValue, user, selectedTransactionsTypeVal]);

    // const sankMoneycreateTransactionsReportFn = functions.httpsCallable(
    //     "sankMoneycreateTransactionsReportFn"
    // );

    const filterWithPhoneNumber = useCallback(
        (value: string) => {
            const filteredTrans = transactions.filter((doc) => {
                if (user?.id === doc.data.senderID) {
                    return (doc.data.receiverPhoneNumber as string).includes(value);
                }
                return (doc.data.senderPhoneNumber as string).includes(value);
            });
            const trans = filteredTrans.slice(0, transDataLimitLength);
            let sum = 0;
            trans.forEach((doc) => (sum = sum + parseFloat(doc.data?.amount)));
            setTransactionTotalSum(sum);
            setTransactions(trans);
        },
        [transDataLimitLength, transactions, user]
    );

    const exportToXLSData = useCallback(() => {
        const worksheet = XLSX.utils.json_to_sheet(
            transactions.map((transaction) => {
                return {
                    id: transaction?.id,
                    "Nom d'Expediteur": transaction.data.senderDisplayName,
                    "Numero d'Expediteur": transaction.data.senderPhoneNumber,
                    "Nom du Destoinataire": transaction.data.receiverDisplayName,
                    "Numero du Destoinataire": transaction.data.receiverPhoneNumber,
                    Montant: transaction.data.amount,
                    "Type de transaction": transaction.data.transactionType,
                    Frais: transaction.data.fees,
                    "Type de compte": transaction.data.accountType,
                    // Statut: transaction.data.status ?? "---",
                    Date: parseDate(transaction.data?.createdAt as Timestamp),
                };
            })
        );

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "export");

        XLSX.writeFile(
            workbook,
            `transactions_du_${searchWithStartDateValue}_au_${searchWithEndDateValue}.xlsx`,
            { compression: true }
        );
    }, [searchWithEndDateValue, searchWithStartDateValue, transactions]);

    // const exportToPDFData = useCallback(async (email: string) => {
    //     let data = transactions;

    //     const finalData = data.map((trans) => {
    //         return {
    //             "Nom du client": trans.data.senderDisplayName
    //                 ? trans.data.senderDisplayName
    //                 : "-",
    //             "Numero du client": trans.data.senderPhoneNumber ?? "-",
    //             Station: trans.data.receiverPhoneNumber ?? "-",
    //             Montant: `${amountFormater(parseFloat(trans.data.amount ?? "0"))} FCFA`,
    //             "Date de Paiement":
    //                 (trans.data.createdAt as Timestamp).toDate().toLocaleString() ?? "-",
    //         };
    //     });

    //     // Calcul du montant total
    //     // Calcul du montant total et du nombre total de transactions
    //     const montantTotal = transactions.reduce((acc, trans) => {
    //         const montant = parseFloat(trans.data?.amount);
    //         return acc + montant;
    //     }, 0);
    //     // const totalTransactions = finalData.length;

    //     // Entête des colonnes
    //     const headers = [
    //         "Nom du client",
    //         "Numero du client",
    //         "Station",
    //         "Montant",
    //         "Date de Paiement",
    //     ];

    //     // Création du document PDF
    //     var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "portrait" });

    //     doc.addImage(SankMoneyLogo, "PNG", 15, 30, 30, 30);

    //     doc.setFontSize(18);
    //     doc.setFont("helvetica", "bold");
    //     doc.setTextColor(255, 0, 0);

    //     doc.text(`RAPPORT DES PAIEMENTS`, 70, 30);

    //     // Informations supplémentaires
    //     doc.setFontSize(12);
    //     doc.setFont("helvetica", "normal");
    //     doc.setTextColor(0, 0, 0);
    //     doc.text(
    //         `Station: ${transactions[0].data.receiverDisplayName ?? "-"} - Code: ${transactions[0].data.receiverPhoneNumber ?? "-"}`, 70, 40);
    //     doc.text(
    //         `Généré le: ${new Date().toLocaleString()}`,
    //         70, 50);

    //     const pageWidth = doc.internal.pageSize.width; // Largeur de la page
    //     const summaryStartY = 60;

    //     doc.setTextColor(255, 0, 0);
    //     doc.setFont("helvetica", "bold");
    //     doc.setFontSize(16);
    //     doc.text(`${finalData.length}`, pageWidth / 2 - 10, summaryStartY + 5); // Nombre de transactions
    //     doc.text(`${montantTotal}`, pageWidth / 2 + 40, summaryStartY + 5); // Montant total
    //     doc.setFontSize(10);
    //     doc.setFont("helvetica", "normal");
    //     doc.setTextColor(0, 0, 0);
    //     doc.text("TRANSACTIONS TOTALES", pageWidth / 2 - 10, summaryStartY + 15, { align: "center" });
    //     doc.text("MONTANT TOTAL (FCFA)", pageWidth / 2 + 50, summaryStartY + 15, { align: "center" });

    //     // Tableau
    //     autoTable(doc, {
    //         startY: summaryStartY + 20,
    //         head: [headers],
    //         body: finalData.map(Object.values),
    //         styles: {
    //             font: "helvetica",
    //             fontSize: 10,
    //             cellPadding: 5,
    //             valign: "middle",
    //             halign: "center",
    //         },
    //         headStyles: {
    //             fillColor: [255, 0, 0], // Gris clair pour l'en-tête
    //             textColor: [255, 255, 255],
    //             fontStyle: "bold",
    //         },
    //         columnStyles: {
    //             3: { halign: "right" }, // Alignement à droite pour les montants
    //             4: { halign: "center" }, // Centrer les dates
    //         },
    //     });

    //     // Ajoutez le footer
    //     const footerText =
    //         `Ce document est généré automatiquement par le système de reporting SankMoney\nSankMoney - ${transactions[0].data.receiverDisplayName}`;
    //     const pageHeight = doc.internal.pageSize.height; // Hauteur de la page
    //     const margin = 10; // Marge pour le footer

    //     // Positionnez le footer
    //     doc.setFontSize(9);
    //     doc.setFont("helvetica", "normal");
    //     doc.text(footerText, 10, pageHeight - margin);

    //     // Enregistrement du fichier PDF
    //     doc.save(
    //         `rapports_des_transactions_du_${searchWithStartDateValue}_au_${searchWithEndDateValue}.pdf`
    //     );
    //     const pdfBlob = doc.output("blob");

    //     // Convertir le PDF Blob en base64
    //     const base64 = await new Promise<string>((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.onloadend = () => resolve(reader.result as string);
    //         reader.onerror = reject;
    //         reader.readAsDataURL(pdfBlob);
    //     });

    //     const base64Data = base64.split(",")[1]; // Retirer le préfixe Base64

    //     try {
    //         const response = await sankMoneycreateTransactionsReportFn({
    //             email: email,
    //             subject: "Rapport de Transactions",
    //             text: `Voici le rapport de transactions de la station : ${user?.displayName ? user?.displayName : ""}.`,
    //             pdf: base64Data,
    //         });

    //         if (response.data.success) {
    //             toast.success("Rapport envoyé par e-mail !");
    //         }
    //     } catch (error) {
    //         console.log("Erreur lors de l'envoi de l'email", error);
    //         // toast.error("Erreur lors de l'envoi de l'email");
    //     }
    // }, [sankMoneycreateTransactionsReportFn, searchWithEndDateValue, searchWithStartDateValue, transactions, user?.displayName]);

    // const onSubmit = (data: { email: string }) => {
    //     if (data.email) {
    //         setShowModalToSendEmail(false);
    //         exportToPDFData(data.email);
    //     }
    // }

    useEffect(() => {
        retrieveFilteredTransactions();
    }, [retrieveFilteredTransactions]);

    return (
        <>
            <div className="px-4 mt-10 sm:px-6 lg:px-8">
                <div className="flex flex-col">
                    <div className="flex">
                        <div>
                            <h1 className="text-xl font-semibold text-gray-900">
                                Mes historiques de transactions{" "}
                            </h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Vous trouverez ici l'ensemble de vos historiques de
                                transactions.
                            </p>
                        </div>
                        <span className="hidden text-xs font-medium text-red-500 md:inline-block">
                            ({`${amountFormater(transactionTotalSum)} Fcfa`})
                        </span>
                    </div>

                    <div className="h-8" />
                    <div className="flex flex-col items-end justify-end py-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                        <div className="w-full md:w-1/2">
                            <form className="flex items-center">
                                <label htmlFor="simple-search" className="sr-only">
                                    rechercher par telephone
                                </label>
                                <div className="relative w-full">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg
                                            aria-hidden="true"
                                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                    <input
                                        onChange={(e) => filterWithPhoneNumber(e.target.value)}
                                        type="text"
                                        id="simple-search"
                                        className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                                        placeholder="Rechercher par téléphone"
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                            <button
                                type="button"
                                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-700"
                                onClick={() => retrieveFilteredTransactions()}
                            >
                                <ArrowPathIcon
                                    className="self-center flex-shrink-0 w-5 h-5 mr-1"
                                    aria-hidden="true"
                                />
                                Refresh
                            </button>
                            <select
                                id="countries"
                                className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-36 focus:outline-none hover:bg-gray-100 hover:text-red-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                onChange={(e) =>
                                    setSelectedTransactionsTypeVal(e.target.value as any)
                                }
                            >
                                {transactionTypes.map((value, index) => (
                                    <option key={index} value={value}>
                                        {transactionMap.get(value)}
                                    </option>
                                ))}
                            </select>
                            <div className="flex items-center w-full space-x-3 md:w-auto">
                                <input
                                    type="date"
                                    id="dateDebut"
                                    className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                                    onChange={handleChangeStartDateValue}
                                />
                                <input
                                    type="date"
                                    id="dateDebut"
                                    className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                                    onChange={handleChangeEndDtaeValue}
                                />
                            </div>
                            <div className="flex justify-end">
                                <Menu as="div" className="relative flex-shrink-0">
                                    <div>
                                        <Menu.Button
                                            className={classNames(
                                                transactions && transactions.length > 0
                                                    ? "text-red-700 border-red-300 hover:bg-red-50 focus:ring-red-500"
                                                    : "text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-gray-500 cursor-not-allowed",
                                                "inline-flex items-center h-10 px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                                            )}
                                            disabled={
                                                transactions && transactions.length > 0 ? false : true
                                            }
                                        >
                                            <ArrowDownTrayIcon
                                                className={classNames(
                                                    transactions && transactions.length > 0
                                                        ? "text-red-500"
                                                        : "text-gray-500",
                                                    "self-center flex-shrink-0 w-5 h-5"
                                                )}
                                                aria-hidden="true"
                                            />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-20 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {
                                                <Menu.Item>
                                                    <>
                                                        {/* <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200" />
                                                        <div
                                                            className={classNames(
                                                                "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                                            )}
                                                            onClick={() => {
                                                                setShowModalToSendEmail(true);
                                                            }}
                                                        >
                                                            PDF
                                                        </div> */}
                                                        <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200" />
                                                        <div
                                                            className={classNames(
                                                                "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                                            )}
                                                            onClick={() => {
                                                                exportToXLSData();
                                                            }}
                                                        >
                                                            XLS
                                                        </div>
                                                    </>
                                                </Menu.Item>
                                            }
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-8 overflow-x-auto">
                    <table
                        className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                        style={{ borderSpacing: 0 }}
                    >
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                {tableHeader.map((th, index) => (
                                    <th key={index} scope="col" className="px-4 py-3">
                                        {th}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="items-center">
                            {transactions && transactions.length > 0 ? (
                                transactions.map((transaction: any, transactionIdx: number) => (
                                    <tr
                                        key={transactionIdx}
                                        className={
                                            transactionIdx % 2 === 0 ? "bg-white" : "bg-gray-100"
                                        }
                                    >
                                        <th
                                            className={classNames(
                                                transactionIdx !== transactions.length - 1
                                                    ? "border-b border-gray-200"
                                                    : "",
                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                        >
                                            {(transactionIdx as number) + 1}
                                        </th>
                                        <td
                                            className={classNames(
                                                transactionIdx !== transactions.length - 1
                                                    ? "border-b border-gray-200"
                                                    : "",
                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                        >
                                            {user?.id === transaction.data.senderID ? (
                                                <img
                                                    className="w-10 h-10 p-1 border rounded-full"
                                                    src={
                                                        transaction.data.receiverProfilImageUrl
                                                            ? transaction.data.receiverProfilImageUrl
                                                            : `https://ui-avatars.com/api/?background=random&name=${transaction.data.receiverDisplayName || "S"
                                                            }`
                                                    }
                                                    alt=""
                                                />
                                            ) : (
                                                <img
                                                    className="w-10 h-10 p-1 border rounded-full lg:w-10"
                                                    src={
                                                        transaction.data.senderProfilImageUrl
                                                            ? transaction.data.senderProfilImageUrl
                                                            : `https://ui-avatars.com/api/?background=random&name=${transaction.data.senderDisplayName || "S"
                                                            }`
                                                    }
                                                    alt=""
                                                />
                                            )}
                                        </td>
                                        <td
                                            className={classNames(
                                                transactionIdx !== transactions.length - 1
                                                    ? "border-b border-gray-200"
                                                    : "",
                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                        >
                                            {user?.id === transaction.data.senderID
                                                ? transaction.data.receiverDisplayName
                                                    ? transaction.data.receiverDisplayName
                                                    : "-"
                                                : transaction.data.receiverDisplayName
                                                    ? transaction.data.receiverDisplayName
                                                    : "-"}
                                        </td>
                                        <td
                                            className={classNames(
                                                transactionIdx !== transactions.length - 1
                                                    ? "border-b border-gray-200"
                                                    : "",
                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                        >
                                            {user?.id === transaction.data.senderID
                                                ? transaction.data.receiverPhoneNumber
                                                : transaction.data.senderPhoneNumber}
                                        </td>
                                        <td
                                            className={classNames(
                                                transactionIdx !== transactions.length - 1
                                                    ? "border-b border-gray-200"
                                                    : "",
                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                        >
                                            {user?.id === transaction.data.senderID
                                                ? transaction.data.senderDisplayName
                                                    ? transaction.data.senderDisplayName
                                                    : "-"
                                                : transaction.data.senderDisplayName
                                                    ? transaction.data.senderDisplayName
                                                    : "-"}
                                        </td>
                                        <td
                                            className={classNames(
                                                transactionIdx !== transactions.length - 1
                                                    ? "border-b border-gray-200"
                                                    : "",
                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                        >
                                            {user?.id === transaction.data.receiverID
                                                ? transaction.data.receiverPhoneNumber
                                                : transaction.data.senderPhoneNumber}
                                        </td>
                                        <td
                                            className={classNames(
                                                transactionIdx !== transactions.length - 1
                                                    ? "border-b border-gray-200"
                                                    : "",
                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                        >
                                            {new Intl.NumberFormat().format(transaction.data.amount)}{" "}
                                            Fcfa
                                        </td>
                                        <td
                                            className={classNames(
                                                transactionIdx !== transactions.length - 1
                                                    ? "border-b border-gray-200"
                                                    : "",
                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                        >
                                            {transactionMap.get(transaction.data.transactionType)}
                                        </td>
                                        <td
                                            className={classNames(
                                                transactionIdx !== transactions.length - 1
                                                    ? "border-b border-gray-200"
                                                    : "",
                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                        >
                                            {transaction.data.accountType === "MAIN" ? (
                                                <img
                                                    className="w-auto h-5"
                                                    src={SankMoneyIcon}
                                                    alt="Sank"
                                                />
                                            ) : (
                                                <BoltIcon className="w-6 h-6 text-red-500" />
                                            )}
                                        </td>
                                        <td
                                            className={classNames(
                                                transactionIdx !== transactions.length - 1
                                                    ? "border-b border-gray-200"
                                                    : "",
                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                        >
                                            {transaction.data.fees as string} Fcfa
                                        </td>
                                        <td
                                            className={classNames(
                                                transactionIdx !== transactions.length - 1
                                                    ? "border-b border-gray-200"
                                                    : "",
                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                        >
                                            {transaction.data.isComplete ? (
                                                <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                            ) : (
                                                <XCircleIcon className="w-6 h-6 text-red-500" />
                                            )}
                                        </td>
                                        <td
                                            className={classNames(
                                                transactionIdx !== transactions.length - 1
                                                    ? "border-b border-gray-200"
                                                    : "",
                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                            )}
                                        >
                                            {(transaction.data.createdAt as Timestamp)
                                                .toDate()
                                                .toLocaleString()}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <NoContent />
                            )}
                        </tbody>
                    </table>

                    <Pagination
                        skip={defaultLoadHistories}
                        take={defaultLoadHistories}
                        total={defaultLoadHistories}
                        collectionName={transactionCollectionName}
                        isChangedPage={true}
                        onPageChange={(pageIndex: number) => pageIndex}
                        onPageLimitChange={(pageLimit: number) =>
                            onPagination(pageLimit)
                        }
                        balance={transactionTotalSum ? transactionTotalSum : undefined}
                    />
                </div>
            </div>
            {/* <Modal
                isOpen={showModalToSendEmail}
                maxSize="md"
                closeModal={() => setShowModalToSendEmail(false)}
                modalTitle="Entrer un mail pour recevoir le rapport"
            >
                <Form<{ email: string }>
                    onSubmit={onSubmit}
                    form={{
                        resolver: yupResolver(
                            yup.object().shape({
                                email: yup.string().required("Le champs est obligatoire"),
                            })
                        ),
                    }}
                    submitButtonFullWidth
                    submitButtonLabel="Exporter"
                >
                    <div className="space-y-4 text-black md:space-y-6">
                        <FormInput name="email" label="Email" />
                    </div>
                </Form>
            </Modal> */}
        </>
    );
};

export default SuperDealerTransactionsHistories;
