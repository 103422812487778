import { ArrowTrendingUpIcon, BanknotesIcon, TicketIcon } from "@heroicons/react/24/outline";
import { DashboardReport } from "../DashboardReport";
import { DisplayPrice } from "../../PriceDisplay";
import HasRoles from "../../HasRoles";

const TransactionsOverview = (props: {
  recoverDeposit?: number;
  recoverAmountDeposit?: number;
  recoverWithDraw?: number;
  recoverAmountWithDraw?: number;
  recoverCommission?: number;
  recoverBalance?: number;
  displayCommissionBtn?: boolean;
}) => {
  const {
    recoverDeposit,
    recoverAmountDeposit,
    recoverWithDraw,
    recoverAmountWithDraw,
    recoverCommission,
    recoverBalance,
    displayCommissionBtn,
  } = props;

  return (
    <>
      <div className="m-0 font-sans text-base antialiased font-normal leading-default bg-gray-50 text-slate-500">
        <div className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200">
          <div className="w-full py-0 mx-auto">
            <div className="flex flex-wrap max-md:mx-5 ">
              {/* Nombre de dépôts effectués */}
              <DashboardReport
                className="m-3 max-md:m-2 max-md:w-full"
                rightIcon={
                  <TicketIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                }
                iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-red-700 to-red-500"
                title="Nombre de depôts effectués"
                value={recoverDeposit}
              />

              {/* Montant total de depôts effectués */}
              <DashboardReport
                className="m-3 max-md:m-2 max-md:w-full"
                rightIcon={
                  <BanknotesIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                }
                iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-red-700 to-red-500"
                title="Montant Total des depôts effectués"
                value={`${new Intl.NumberFormat().format(
                  recoverAmountDeposit as number
                )} fcfa`}
              />

              {/* Nombre de retraits effectués */}
              <DashboardReport
                className="m-3 max-md:m-2 max-md:w-full"
                rightIcon={
                  <TicketIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                }
                iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-red-700 to-red-500"
                title="Nombre de retraits effectués"
                value={recoverWithDraw}
              />

              {/* Montant total de retraits effectués */}
              <DashboardReport
                className="m-3 max-md:m-2 max-md:w-full"
                rightIcon={
                  <BanknotesIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                }
                iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-red-700 to-red-500"
                title="Montant Total des retraits effectués"
                value={`${new Intl.NumberFormat().format(
                  recoverAmountWithDraw as number
                )} fcfa`}
              />

              <HasRoles userRole={["SUPERDEALER", "DEALER"]}>
                {
                  displayCommissionBtn ? (
                    <>
                      <DashboardReport
                        className="m-3 max-md:m-2 max-md:w-full"
                        iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-red-700 to-red-500"
                        rightIcon={
                          <ArrowTrendingUpIcon
                            className="w-6 h-6 text-white"
                            aria-hidden="true"
                          />
                        }
                        title="Commissions"
                        value={<DisplayPrice price={recoverCommission ?? 0} />}
                      // value={`${new Intl.NumberFormat().format(
                      //   recoverCommission as number
                      // )} fcfa`}
                      />
                    </>
                  ) : (<></>)
                }
              </HasRoles>
              <HasRoles userRole={["SUPERVISEURSUPERDEALER"]}>
                <>
                  <DashboardReport
                    className="m-3 max-md:m-2 max-md:w-full"
                    rightIcon={
                      <BanknotesIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                    }
                    iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-red-700 to-red-500"
                    title="Compte principal"
                    value={`${new Intl.NumberFormat().format(
                      recoverBalance as number ?? 0
                    )} fcfa`}
                  />
                </>
              </HasRoles>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionsOverview;
