import { FunnelIcon } from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../../components/FirestoreTable";
import {
  transactionCollectionName,
  usersCollectionName,
} from "../../../config";
import { transactionMap, TransactionType } from "../../../interfaces";
import { parseDate, getTimestamp, amountFormater } from "../../../utils";
import { db } from "../../../firebase";
import Modal from "../../../components/Modal";
import { useNavigate } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import toast from "react-hot-toast";
import DashboardComponent from "../../../components/DashboardComponents/InvoiceManagmentComponent";

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "senderPhoneNumber",
    label: "Expéditeur",
  },
  {
    field: "oneaMeterNumber",
    label: "N abonne",
  },
  {
    field: "amount",
    label: "Montant",
  },
  {
    field: "penality",
    label: "Penalité",
  },
  // {
  //   field: "oneaInvoiceIds",
  //   label: "ID factures payé",
  // },
  {
    field: "transactionType",
    label: "Type d'opération",
  },
  {
    field: "oneaInvoicePeriodes",
    label: "Periodes",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return parseDate(value as Timestamp);
    case "oneaInvoicePeriodes":
      return value ?? "-";
    case "senderPhoneNumber":
      return <span>{value}</span>;
    case "oneaMeterNumber":
      return <span>{value}</span>;
    case "amount":
      return <span>{new Intl.NumberFormat().format(value)} Fcfa</span>;
    case "penality":
      return <span>{new Intl.NumberFormat().format(value ?? 0)} Fcfa</span>;
    // case "oneaInvoiceIds":
    //   return value !== "" ? value?.join(",") : "-";
    case "transactionType":
      return <span>{transactionMap.get(value as string)} </span>;
    default:
      return value as string;
  }
};

const ManageOneaInvoicesPage = () => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(moment(Date.now()).startOf("hour").format("YYYY-MM-DD"));
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [retriveUserByPhoneNumber, setRetriveUserByPhoneNumber] =
    useState<any>();
  const [metterNum, setMetterNum] = useState<string>("");
  const [invoice, setInvoice] = useState<any>();
  const [invoicesPaid, setInvoicesPaid] = useState<number>(0);
  const [amountInvoicesPaid, setAmountInvoicesPaid] = useState<number>(0);
  const [invoices, setInvoices] = useState<any[]>([]);

  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithEndDateValue(value.target.value);
  };

  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);

  const handleInputChange = (event: any) => {
    const { value } = event.target;
    setMetterNum(value);
  };

  const onRowDetails = (id: any, entity: any) => {
    if (entity) {
      setInvoice(entity);
      setShowDetailsModal(true);
    }
  };

  const retrivePhoneNumber = async (phoneNumber: string) => {
    if (phoneNumber && isValidPhoneNumber(phoneNumber, "BF")) {
      const userSnap = await db
        .collection(usersCollectionName)
        .where("phoneNumber", "==", phoneNumber)
        .get();

      !userSnap.empty
        ? setRetriveUserByPhoneNumber(phoneNumber)
        : toast.error("Utilisateur introuvable/Numéro incorrect.");
    } else setRetriveUserByPhoneNumber("");
  };

  const firestoreTableQueriesWhere = useMemo(() => {
    let queries: {
      fieldPath: string;
      opStr: string;
      value: string | any;
    }[] = [];

    if (startTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: ">=",
        value: startTime,
      });
    }

    if (endTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: "<=",
        value: endTime,
      });
    }

    if (metterNum) {
      queries = [
        {
          fieldPath: "receiverPhoneNumber",
          opStr: "==",
          value: metterNum,
        },
      ];
    }
    if (retriveUserByPhoneNumber) {
      queries = [
        {
          fieldPath: "senderPhoneNumber",
          opStr: "==",
          value: retriveUserByPhoneNumber,
        },
      ];
    }

    return queries;
  }, [startTime, endTime, metterNum, retriveUserByPhoneNumber]);

  const getFilteredByDate = async () => {
    if (seachWithStartDateValue) {
      setStartTime(getTimestamp(seachWithStartDateValue, true));
    }

    if (seachWithEndDateValue) {
      setEndTime(getTimestamp(seachWithEndDateValue, false));
    }
  };

  const InvoicesManagment = async (data: any) => {
      let totalAmount: number = 0;
      data.reduce((acc:number, invoice:any) => totalAmount = acc + parseInt(invoice?.amount),0)
      setAmountInvoicesPaid(totalAmount);
      setInvoicesPaid(data.length);
  };

  useEffect(() => {
    getFilteredByDate();
  }, []);

  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              L'historiques des factures Onea
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Vous trouverez ci-dessous tous l'historiques des factures Onea.
            </p>
          </div>
          <div className="h-4" />
          <DashboardComponent
            retrieveInvoicesPaid={invoicesPaid}
            retrieveAmountInvoices={amountInvoicesPaid}
          />
          <div className="h-6" />
          <div className="flex items-center self-end max-md:flex-col">
            <div className="mt-4 mr-2 sm:mt-0 sm:ml-1 sm:flex-none">
              <div className="my-auto">
                <PhoneInput
                  international={false}
                  placeholder="Numéro de téléphone"
                  className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                  defaultCountry="BF"
                  onChange={retrivePhoneNumber}
                />
              </div>
            </div>
            <div className="mt-4 mr-2 sm:mt-0 sm:ml-1 sm:flex-none">
              <input
                type="search"
                id="search"
                className="block w-full p-4 pl-10 mb-2 text-sm text-gray-900 border border-gray-300 rounded-lg md:mb-0 bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                placeholder="N° Abonné"
                onChange={handleInputChange}
                value={metterNum}
              />
            </div>
            <div className="flex px-1 bg-white dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
              <div className="mr-1">
                <label
                  htmlFor="dateDebut"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date début
                </label>
                <input
                  type="date"
                  id="dateDebut"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={seachWithStartDateValue}
                  onChange={handleChangeStartDateValue}
                />
              </div>
              <div className="mr-1">
                <label
                  htmlFor="dateFin"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date fin
                </label>
                <input
                  type="date"
                  id="dateFin"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={seachWithEndDateValue}
                  onChange={handleChangeEndDtaeValue}
                />
              </div>
              <div className="flex items-center justify-center mt-5 h-14">
                <div className="flex">
                  <button
                    type="button"
                    className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    onClick={() => 
                       { 
                        getFilteredByDate();
                      }
                    }
                  >
                    <FunnelIcon
                      className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                      aria-hidden="true"
                    />
                    Filtrer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-6">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm">
                <FirestoreTable
                  collectionRef={db.collection(transactionCollectionName)}
                  condensed
                  collectionQueriesWhere={[
                    {
                      fieldPath: "transactionType",
                      opStr: "==",
                      value: TransactionType.WATER_BILL_POSTPAID,
                    },
                    ...firestoreTableQueriesWhere,
                  ]}
                  columns={columns}
                  rowRender={renderRow}
                  rowDetailsHandler={onRowDetails}
                  retrieveData={(data) => InvoicesManagment(data)}
                  displayDetails
                  shouldAddEditColumn
                  paginate={{
                    pageIndex: 0,
                    limit: 100,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showDetailsModal}
        maxSize="md"
        closeModal={() => setShowDetailsModal(false)}
      >
        {invoice && (
          <div className="flex justify-center">
            <div className="w-full">
              <div className="m-3 sm:max-w-lg sm:w-full sm:mx-auto">
                <div className="relative flex flex-col bg-white shadow-lg pointer-events-auto rounded-xl dark:bg-gray-800">
                  <div className="p-4 overflow-y-auto sm:p-7">
                    <div className="text-center">
                      <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                        Facture Onea
                      </h3>
                      <p className="overflow-scroll text-sm text-gray-500 ">
                        Facture #
                        {invoice?.oneaInvoiceIds ? (
                          <span className="font-bold">
                            {invoice?.oneaInvoiceIds.join("#")}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>

                    <div className="grid grid-cols-2 gap-5 mt-5 sm:mt-10 sm:grid-cols-2">
                      <div>
                        <span className="block text-xs text-gray-500 uppercase">
                          Nom du client:
                        </span>
                        <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                          {invoice.senderDisplayName}
                        </span>
                      </div>

                      <div>
                        <span className="block text-xs text-gray-500 uppercase">
                          N° du compteur:
                        </span>
                        <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                          {invoice.oneaMeterNumber}
                        </span>
                      </div>
                    </div>

                    <div className="mt-5 sm:mt-10">
                      <h4 className="text-xs font-semibold text-gray-800 uppercase dark:text-gray-200">
                        Facture
                      </h4>
                      <ul className="flex flex-col mt-3">
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Montant</span>
                            <span>{amountFormater(invoice.amount)} Fcfa</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Penalité</span>
                            <span>
                              {amountFormater(invoice.penality ?? 0)}F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span className="mr-1">Periodes</span>
                            <span>{invoice.oneaInvoicePeriodes ?? "-"}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ManageOneaInvoicesPage;
