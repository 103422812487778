/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { db, functions } from "../../firebase";
import {
  ArrowPathIcon,
  CheckCircleIcon,
  PlusIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { UserRole } from "../../interfaces";
import { usersCollectionName } from "../../config";
import Moment from "react-moment";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../components/FirestoreTable";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Modal from "../../components/Modal";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import HasRoles from "../../components/HasRoles";
// import { UserContext } from "../../contexts";
import moment from "moment";
import { getTimestamp } from "../../utils";
import { Form, FormInput } from "../../components/FormComponents";
import { yupResolver } from "@hookform/resolvers/yup";
import FormCheckToggle from "../../components/FormComponents/FormCheckToggle";
import * as yup from "yup";
import toast from "react-hot-toast";
import { useConfirm } from "../../hooks/useConfirm";
import { UserContext } from "../../contexts";

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "displayName",
    label: "Nom/prénom",
  },
  {
    field: "phoneNumber",
    label: "Téléphone",
  },
  {
    field: "merchantCode",
    label: "Code marchand",
  },
  {
    field: "adresse",
    label: "Adresse",
  },
  {
    field: "city",
    label: "Ville",
  },
  {
    field: "active",
    label: "Active",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "displayName":
      return <span>{value ? value : "-"}</span>;
    case "adresse":
      return <span>{value ? value : "-"}</span>;
    case "city":
      return <span>{value ? value : "-"}</span>;
    case "phoneNumber":
      return <span>{value ?? "-"}</span>;
    case "merchantCode":
      return <span>{value ?? "-"}</span>;
    case "active":
      return (
        <span>
          {" "}
          {value ? (
            <CheckCircleIcon className="w-6 h-6 text-green-500" />
          ) : (
            <XCircleIcon className="w-6 h-6 text-red-500" />
          )}
        </span>
      );
    default:
      return value ? value : "-";
  }
};

const MerchantsPage = () => {
  const navigate = useNavigate();
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [showUpdateFeeModal, setShowUpdateFeeModal] = useState(false);
  const [merchantId, setMerchantId] = useState("");
  const [merchant, setMerchant] = useState<any>();
  const [userData, setUserData] = useState<any>();
  // const [showDetailsModal, setShowDetailsModal] = useState(false);
  const { user } = useContext(UserContext);

  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [merchantCodeSearch, setMerchantCodeSearch] = useState<
    string | undefined
  >();
  const [phoneNumberSearch, setPhoneNumberSearch] = useState<
    string | undefined
  >();

  const { isConfirmModal } = useConfirm();

  const agentAndMerchantReturnToUserStateFn = functions.httpsCallable(
    "agentAndMerchantReturnToUserStateFn"
  );

  const onEditRow = (id: number, entity: any) => {
    if (entity) {
      navigate(`/merchants/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  const onRowDetails = (id: number, entity: any) => {
    if (entity) {
      navigate(`/merchants/${entity.id}/details`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };

  // Start Function to redirect "SUPERVISOR" toward activities page
  const onShowMerchantActivities = (id: number, entity: any) => {
    if (entity) {
      navigate(`/merchants/${entity.id}/activities`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  // End Function to redirect "SUPERVISOR" toward activities page

  const retrivePhoneNumber = async (phoneNumber: string) => {
    if (phoneNumber && isValidPhoneNumber(phoneNumber, "BF")) {
      const res = await db
        .collection(usersCollectionName)
        .where("userRole", "==", UserRole.USER)
        .where("phoneNumber", "==", phoneNumber)
        .get();

      if (res.empty) {
        setUserData(undefined);
        return;
      }
      setUserData(
        res.docs.map((d) => {
          return { id: d.id, data: d.data() };
        })
      );
    } else {
      setUserData(undefined);
      return;
    }
  };

  const retriveMerchantByPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber && isValidPhoneNumber(phoneNumber, "BF")) {
      setPhoneNumberSearch(phoneNumber);
    }
  };

  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );
  const handleChangeStartDateValue = (value: any) => {
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    setSetSeachWithEndDateValue(value.target.value);
  };

  // function to filter merchants by date
  const getFilteredByDate = useCallback(async () => {
    if (seachWithStartDateValue) {
      setStartTime(getTimestamp(seachWithStartDateValue, true));
    }

    if (seachWithEndDateValue) {
      setEndTime(getTimestamp(seachWithEndDateValue, false));
    }
  }, [seachWithEndDateValue, seachWithStartDateValue]);

  const handleChangeMerchantCodeSearch = (event: any) => {
    const code = event.target.value;
    if (code.length === 7) {
      setMerchantCodeSearch(event.target.value);
    }
  };

  const onRowChangeFees = (id: number, entity: any) => {
    if (entity) {
      setMerchantId(entity.id);
      setMerchant(entity);
      setShowUpdateFeeModal(true);
    }
  };
  const onRetractAgentToUser = async (id: number, entity: any) => {
    if (entity) {
      const isConfirm = await isConfirmModal(
        "Opération irréversible",
        "Voulez-vous retracter l'agent en utilisateur ?",
        "Oui valider",
        true
      );
      if (isConfirm) {
        const response = agentAndMerchantReturnToUserStateFn({
          userId: entity?.id,
        }).then(() => {
          navigate("/agents");
        });
        toast.promise(response, {
          error: "La retraction a échouer",
          success: `L'agent a été retracté en utilisateur avec succès.`,
          loading: "Retraction encours...",
        });
      }
    }
  };

  const firestoreTableQueriesWhere = useMemo(() => {
    const queries = [];

    if (startTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: ">=",
        value: startTime,
      });
    }

    if (endTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: "<=",
        value: endTime,
      });
    }

    if (phoneNumberSearch) {
      queries.push({
        fieldPath: "phoneNumber",
        opStr: "==",
        value: phoneNumberSearch,
      });
    }

    if (merchantCodeSearch) {
      queries.push({
        fieldPath: "merchantCode",
        opStr: "==",
        value: merchantCodeSearch,
      });
    }
    if (!user) {
      return queries
    } else {
      if (user?.userRole === UserRole.RESPONSABLEZONE || user?.userRole === UserRole.SUPERVISOR) {
        queries.push({
          fieldPath: "supervisorId",
          opStr: "==",
          value: user?.id,
        });
      }
    }

    return queries;
  }, [startTime, endTime, phoneNumberSearch, merchantCodeSearch, user]);

  useEffect(() => {
    getFilteredByDate();
  }, [getFilteredByDate]);

  const onSubmitFees = (data: any) => {
    const sankmoneyUpdateAccountFn = functions.httpsCallable(
      "sankmoneyUpdateAccountFn"
    );
    const feeData = {
      applyFee: data.applyFee,
      fee: parseFloat(data.fee),
    };
    const response = sankmoneyUpdateAccountFn({
      collectionName: usersCollectionName,
      documentData: feeData,
      documentId: merchantId,
    });
    toast.promise(
      response.then(() => {
        navigate("/merchants");
        setShowUpdateFeeModal(false);
      }),
      {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success: "Les informations des frais on été mise à jour avec succès.",
        loading: "chargement...",
      }
    );
  };

  return (
    <section className="p-3 dark:bg-gray-900 sm:p-5">
      {/* </div> */}
      <div className="relative overflow-hidden bg-white shadow-sm dark:bg-gray-800 sm:rounded-md">
        <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
          {/* start search by phone */}
          <div className="w-full md:w-1/3">
            <PhoneInput
              international={false}
              placeholder="Numéro de téléphone"
              className="block px-3 py-2 placeholder-gray-400 border rounded-lg appearance-none w-ful focus:border-emerald-500 focus:outline-none sm:text-sm"
              defaultCountry="BF"
              onChange={retriveMerchantByPhoneNumber}
            />
          </div>
          {/* end search by phone */}

          {/* search by code */}
          <div className="w-full md:w-1/3">
            <input
              type="text"
              id="merchantCode"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
              placeholder="Code de marchand"
              maxLength={7}
              onChange={handleChangeMerchantCodeSearch}
            />
          </div>
          {/* search by code */}

          <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
            {/* refresh button */}
            <button
              type="button"
              className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-700"
              onClick={() => {
                // setSetSeachWithStartDateValue(
                //   `${moment(Date.now()).format("YYYY-MM-DD")}`
                // );
                // setSetSeachWithEndDateValue(
                //   `${moment(Date.now()).format("YYYY-MM-DD")}`
                // );
                setMerchantCodeSearch(undefined);
                setPhoneNumberSearch(undefined);
              }}
            >
              <ArrowPathIcon
                className="self-center flex-shrink-0 w-5 h-5 mr-1"
                aria-hidden="true"
              />
              Refresh
            </button>
            {/* end refresh button */}

            {/* start add button */}
            <HasRoles
              userRole={[
                "ADMIN",
                "AGENCYSTAFF",
                "SUPERVISOR",
                "TECHNICALSUPPORT",
                "RESPONSABLEZONE"
              ]}
            >
              <button
                type="button"
                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-700"
                onClick={() => setShowPinCodeModal(true)}
              >
                <PlusIcon
                  className="self-center flex-shrink-0 w-5 h-5 mr-1"
                  aria-hidden="true"
                />
                Ajouter
              </button>
            </HasRoles>
            {/* end add button */}

            {/* Filter by date */}
            <div className="flex items-center w-full space-x-3 md:w-auto">
              {/* start date */}
              <input
                type="date"
                id="dateDebut"
                className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                onChange={handleChangeStartDateValue}
              />

              {/* end date */}
              <input
                type="date"
                id="dateDebut"
                className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                onChange={handleChangeEndDtaeValue}
              />
            </div>
            {/* End filter by date */}
          </div>
        </div>

        <FirestoreTable
          collectionRef={db.collection(usersCollectionName)}
          collectionQueriesWhere={[
            ...firestoreTableQueriesWhere,
            {
              fieldPath: "userRole",
              opStr: "==",
              value: UserRole.MARCHAND,
            },
          ]}
          condensed
          columns={columns}
          rowRender={renderRow}
          rowEditHandler={onEditRow}
          rowDetailsHandler={onRowDetails}
          rowAddCompanyFeeHandler={onRowChangeFees}
          rowRetractToUserHandler={onRetractAgentToUser}
          rowShowActivitiesHandler={onShowMerchantActivities}
          displayShowActivitiesBtn
          displayRetractToUserBtn
          displayDetails
          shouldAddEditColumn
          displayEditBtn
          displayAddCompanyFeeBtn
          paginate={{ pageIndex: 0, limit: 100 }}
        />
        <Modal
          isOpen={showPinCodeModal}
          maxSize="lg"
          closeModal={() => setShowPinCodeModal(false)}
          modalTitle="Renseigner le numéro de téléphone"
        >
          <div className="flex justify-center">
            <div className="w-full mt-10">
              <PhoneInput
                international={false}
                placeholder="Entrer un numéro de téléphone"
                className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                defaultCountry="BF"
                onChange={retrivePhoneNumber}
              />
              <div className="flex justify-center w-full">
                {userData && userData.length > 0 && (
                  <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <div className="flex flex-col items-center pb-10">
                      <img
                        className="w-24 h-24 mt-2 mb-3 rounded-full shadow-lg"
                        src={
                          userData[0].data.profilImageUrl
                            ? userData[0].data.profilImageUrl
                            : `https://ui-avatars.com/api/?background=random&name=${
                                userData[0].data.displayName || "S"
                              }`
                        }
                        alt=""
                      />
                      <h5 className="mb-1 text-xl font-medium text-center text-gray-900 dark:text-white">
                        {userData[0].data.displayName}
                      </h5>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {userData[0].data.phoneNumber}
                      </span>
                      <div className="flex mt-4 space-x-3 md:mt-6">
                        <div
                          className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                          onClick={() =>
                            navigate(`/merchants/new`, {
                              state: {
                                entity: userData[0].data,
                                id: userData[0].id,
                              },
                            })
                          }
                        >
                          Continuer
                        </div>
                        <div
                          className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                          onClick={() => {
                            setShowPinCodeModal(false);
                            setUserData([]);
                          }}
                        >
                          Annuler
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={showUpdateFeeModal}
          maxSize="md"
          closeModal={() => setShowUpdateFeeModal(false)}
        >
          <Form<{ applyFee: boolean; fee: string }>
            onSubmit={onSubmitFees}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  applyFee: yup.boolean().required("Le champs est obligatoire"),
                  fee: yup.string().required("Le champs est obligatoire"),
                })
              ),
              defaultValues: {
                applyFee: merchant ? merchant?.applyFee : "",
                fee: merchant ? merchant?.fee : "0.0",
              },
            }}
            submitButtonFullWidth
            submitButtonLabel="Modifier"
          >
            <div className="space-y-4 text-black md:space-y-6">
              <h2 className="mb-2 text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                #{merchant?.displayName}
              </h2>
              <FormCheckToggle name="applyFee" label="Appliquer des frais" />
              <FormInput
                name="fee"
                label="Frais"
                helpLabel="Les frais sont en pourcentage (%). Exemple: 0.01 => 1%"
              />
            </div>
          </Form>
        </Modal>
        {/* <Modal
          isOpen={showDetailsModal}
          maxSize="md"
          closeModal={() => setShowDetailsModal(false)}
          modalTitle="Rechercher un marchand"
        >
          <div className="flex justify-center">
            <div className="w-full mt-10">
              <div className="">
                <PhoneInput
                  international={false}
                  placeholder="Entrer un numéro de téléphone"
                  className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                  defaultCountry="BF"
                  value={phoneNumberSearch}
                  onChange={handleChangePhoneNumberSearch}
                />

                <p className="my-3 font-semibold text-center">OU</p>

                <div className="mr-1">
                  <input
                    type="text"
                    id="merchantCode"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Entrer un code de marchand"
                    // value={merchantCodeSearch}
                    onChange={handleChangeMerchantCodeSearch}
                  />
                </div>
              </div>
              <div className="flex justify-center w-full">
                {userData && userData.length > 0 && (
                  <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <div className="flex flex-col items-center pb-10">
                      <img
                        className="w-24 h-24 mt-2 mb-3 rounded-full shadow-lg"
                        src={
                          userData[0].data.profilImageUrl
                            ? userData[0].data.profilImageUrl
                            : `https://ui-avatars.com/api/?background=random&name=${
                                userData[0].data.displayName || "S"
                              }`
                        }
                        alt=""
                      />
                      <h5 className="mb-1 text-xl font-medium text-center text-gray-900 dark:text-white">
                        {userData[0].data.displayName}
                      </h5>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {userData[0].data.phoneNumber}
                      </span>
                      <div className="flex mt-4 space-x-3 md:mt-6">
                        <div
                          className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                          onClick={() =>
                            navigate(`/users/${userData[0].id}/details`, {
                              state: {
                                entity: userData[0].data,
                                id: userData[0].id,
                                isSearch: true,
                              },
                            })
                          }
                        >
                          Details
                        </div>
                        <div
                          className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                          onClick={() => {
                            setShowDetailsModal(false);
                            setUserData([]);
                          }}
                        >
                          Annuler
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal> */}
      </div>
    </section>
  );
};

export default MerchantsPage;
