import React, { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../contexts";
import toast from "react-hot-toast";
import { snblUsersInvoicesCollectionName } from "../../../../config";
import { db } from "../../../../firebase";
import moment from "moment";
import {
  amountFormater,
  classNames,
  getTimestamp,
  parseDate,
} from "../../../../utils";
import {
  FunnelIcon,
  ArrowPathIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";
import DashboardComponent from "../../../../components/DashboardComponents/InvoiceManagmentComponent";
import Modal from "../../../../components/Modal";
import Pagination from "../../../../components/Pagination/Pagination";
import NoContent from "../../../../components/TableNoContentPage/NoContent";
import TableRowEditMenu from "../../../../components/TableRowEditMenu/TableRowEditMenu";
import * as htmlToImage from "html-to-image";

const tableHeader = [
  "N°",
  "Date de création",
  "Compteur N°",
  "Montant Payer",
  "Nom/Prénom",
  "TOKEN",
  "Action",
];

const AgentPaidSnblPrepaidHistories = () => {
  const { user } = useContext(UserContext);
  const [invoice, setInvoice] = useState<any>();
  const [invoices, setInvoices] = useState<any[]>([]);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [pageLimit, setPageLimit] = useState(100);
  const [invoicesPaid, setInvoicesPaid] = useState<number>(0);
  const [amountInvoicesPaid, setAmountInvoicesPaid] = useState<number>(0);
  const [isBtnDesabled, setIsBtnDesabled] = useState(false);

  const [searchWithStartDateValue, setSetsearchWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [searchWithEndDateValue, setSetsearchWithEndDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);

  const handleChangeStartDateValue = (value: any) => {
    setSetsearchWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    setSetsearchWithEndDateValue(value.target.value);
  };

  // const handleInputChange = (event: any) => {
  //   setMeterNumber(event);
  // };

  const retrieveSecifiqueInvoices = useCallback(
    async (meterNumber: string) => {
      if (user?.id) {
        const invoicesSnap = await db
          .collection(snblUsersInvoicesCollectionName)
          .orderBy("createdAt", "desc")
          .where("meterNum", "==", meterNumber)
          .where("userId", "==", user?.id)
          .limitToLast(pageLimit)
          .get();
        if (!invoicesSnap.empty) {
          const data = invoicesSnap.docs.map((d) => {
            const invData = d.data()?.data;
            const snblFees = invData.fee[0].item.map((val: any) => {
              return {
                name: val["$"].name,
                amt: val["$"].amt,
              };
            });

            const formattedFees = snblFees.reduce((acc: any, current: any) => {
              acc[current.name] = current.amt;
              return acc;
            }, {});
            const data = {
              id: d.id,
              transId: invData.transID,
              invoice: invData.invoice,
              code: invData.code,
              token: invData.token,
              meterNum: invData.meterNum,
              vendQty: invData.vendQty,
              vendAMT: invData.vendAMT,
              customerName: invData.customerName,
              fee: formattedFees,
              AMT: invData.AMT,
              createdAt: d.data()?.createdAt,
            };
            return { id: d.id, data };
          });
          let totalAmount: number = 0;
          data.reduce(
            (acc: number, invoice: any) =>
              (totalAmount = acc + parseInt(invoice.data?.AMT)),
            0
          );
          setAmountInvoicesPaid(totalAmount);
          setInvoicesPaid(data.length);
          setInvoices(data);
        } else setInvoices([]);
      } else toast.error("Veuillez saisir un numéro de téléphone valide.");
    },
    [pageLimit, user]
  );

  const retriveInvoices = useCallback(async () => {
    const startTime = getTimestamp(searchWithStartDateValue, true);
    const endTime = getTimestamp(searchWithEndDateValue, false);
    if (startTime > endTime) {
      return toast.error(
        "La date de fin ne doit pas etre inférieur à la date de début."
      );
    }
    if (user) {
      const invoicesSnap = await db
        .collection(snblUsersInvoicesCollectionName)
        .orderBy("createdAt", "desc")
        .where("createdAt", ">=", startTime)
        .where("createdAt", "<=", endTime)
        .where("userId", "==", user?.id)
        .limitToLast(pageLimit)
        .get();
      if (!invoicesSnap.empty) {
        const data = invoicesSnap.docs.map((d) => {
          const invData = d.data()?.data;
          const snblFees = invData.fee[0].item.map((val: any) => {
            return {
              name: val["$"].name,
              amt: val["$"].amt,
            };
          });

          const formattedFees = snblFees.reduce((acc: any, current: any) => {
            acc[current.name] = current.amt;
            return acc;
          }, {});
          const data = {
            id: d.id,
            transId: invData.transID,
            invoice: invData.invoice,
            code: invData.code,
            token: invData.token,
            meterNum: invData.meterNum,
            vendQty: invData.vendQty,
            vendAMT: invData.vendAMT,
            customerName: invData.customerName,
            fee: formattedFees,
            AMT: invData.AMT,
            sankFees: invData.sankFees,
            createdAt: d.data()?.createdAt,
          };
          return { id: d.id, data };
        });
        let totalAmount: number = 0;
        data.reduce(
          (acc: number, invoice: any) =>
            (totalAmount = acc + parseInt(invoice.data?.AMT)),
          0
        );
        setAmountInvoicesPaid(totalAmount);
        setInvoicesPaid(data.length);
        setInvoices(data);
      } else setInvoices([]);
    } else toast.error("Veuillez saisir un numéro de téléphone valide.");
  }, [pageLimit, searchWithEndDateValue, searchWithStartDateValue, user]);

  const downloadImage = (customerName: string) => {
    const fileName = `${customerName.split(" ").join("_")}.jpeg`;
    const node = document.getElementById("download-invoice");
    htmlToImage
      .toJpeg(node as HTMLElement, { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = fileName;
        link.href = dataUrl;
        link.click();
      });
  };

  useEffect(() => {
    retriveInvoices();
  }, [retriveInvoices]);

  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              Gestions des factures prépayé.
            </h1>
          </div>
          <div className="h-4" />
          <DashboardComponent
            retrieveInvoicesPaid={invoicesPaid}
            retrieveAmountInvoices={amountInvoicesPaid}
          />
          <div className="h-8" />
          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="flex flex-col items-end justify-between py-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
              <div className="w-full md:w-1/2">
                <form className="flex items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    rechercher par telephone
                  </label>
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      onChange={(e) => {
                        retrieveSecifiqueInvoices(e.target.value);
                      }}
                      type="text"
                      id="simple-search"
                      className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                      placeholder="Numéro compteur"
                    />
                  </div>
                </form>
              </div>
              <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                <button
                  type="button"
                  className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-700"
                  onClick={() => retriveInvoices()}
                >
                  <ArrowPathIcon
                    className="self-center flex-shrink-0 w-5 h-5 mr-1"
                    aria-hidden="true"
                  />
                  Refresh
                </button>
                <div className="flex justify-between md:space-x-3 md:w-auto">
                  <input
                    type="date"
                    id="dateDebut"
                    className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                    onChange={handleChangeStartDateValue}
                  />
                  <input
                    type="date"
                    id="dateDebut"
                    className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                    onChange={handleChangeEndDtaeValue}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-8 overflow-x-auto">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm">
                <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      {tableHeader.map((th, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                        >
                          {th}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {invoices && invoices.length > 0 ? (
                      invoices.map((invoice: any, invoiceIdx: number) => (
                        <tr key={invoiceIdx}>
                          <td
                            className={classNames(
                              invoiceIdx !== invoices.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(invoiceIdx as number) + 1}
                          </td>
                          <td
                            className={classNames(
                              invoiceIdx !== invoices.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {parseDate(invoice.data?.createdAt as Timestamp)}
                          </td>

                          <td
                            className={classNames(
                              invoiceIdx !== invoices.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {invoice.data.meterNum
                              ? invoice.data.meterNum
                              : "-"}
                          </td>
                          <td
                            className={classNames(
                              invoiceIdx !== invoices.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {invoice.data.AMT
                              ? `${amountFormater(
                                  parseFloat(invoice.data.AMT)
                                )} FCFA`
                              : "-"}
                          </td>
                          <td
                            className={classNames(
                              invoiceIdx !== invoices.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {invoice.data.customerName
                              ? invoice.data.customerName
                              : "-"}
                          </td>
                          <td
                            className={classNames(
                              invoiceIdx !== invoices.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {invoice.data.updatedToken ? (
                              <div>
                                {invoice.data.updatedToken.map(
                                  (val: any, indx: number) => (
                                    <>
                                      <span key={indx}>{val}</span>
                                      <br />
                                    </>
                                  )
                                )}
                              </div>
                            ) : invoice.data ? (
                              invoice.data.token
                            ) : (
                              "-"
                            )}
                          </td>

                          <td
                            className={classNames(
                              invoiceIdx !== invoices.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                            )}
                          >
                            <TableRowEditMenu
                              rowDetailsHandler={() => {
                                setInvoice(invoice.data);
                                setShowDetailsModal(true);
                              }}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoContent />
                    )}
                  </tbody>
                </table>
                {invoices && invoices.length > 0 && (
                  <Pagination
                    skip={invoices.length}
                    take={invoices.length}
                    total={invoices.length}
                    collectionName={snblUsersInvoicesCollectionName}
                    onPageChange={(pageIndex: number) => pageIndex}
                    onPageLimitChange={(pageLimit: number) =>
                      setPageLimit(pageLimit)
                    }
                    isChangedPage={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showDetailsModal}
        maxSize="md"
        closeModal={() => setShowDetailsModal(false)}
      >
        {invoice && (
          <div className="flex justify-center">
            <div className="w-full">
              <div className="m-3 sm:max-w-lg sm:w-full sm:mx-auto">
                <div
                  id="download-invoice"
                  className="relative flex flex-col bg-white pointer-events-auto rounded-xl"
                >
                  <div className="p-4 overflow-y-auto sm:p-7">
                    <div className="text-center">
                      <h3 className="text-lg font-semibold text-gray-800">
                        Facture Sonabel
                      </h3>
                      <p className="text-sm text-gray-500">
                        Facture #
                        <span className="font-bold">{invoice.invoice}</span>
                      </p>
                    </div>

                    <div className="grid grid-cols-1 gap-5 mt-5 sm:mt-10 sm:grid-cols-1">
                      <div>
                        <span className="block text-xs text-gray-500 uppercase">
                          Nom du client:
                        </span>
                        <span className="block text-sm font-medium text-gray-800">
                          {invoice.customerName}
                        </span>
                      </div>

                      <div>
                        <span className="block text-xs text-gray-500 uppercase">
                          N° du compteur:
                        </span>
                        <span className="block text-sm font-medium text-gray-800">
                          {invoice.meterNum}
                        </span>
                      </div>
                    </div>

                    <div className="mt-5 sm:mt-10">
                      <h4 className="text-xs font-semibold text-gray-800 uppercase">
                        Facture
                      </h4>

                      <ul className="flex flex-col mt-3">
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg">
                          <div className="flex items-center justify-between w-full">
                            <span>Transaction ID</span>
                            <span>{invoice.transId}</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg">
                          <div className="flex items-center justify-between w-full">
                            <span>Token</span>
                            <span className="font-bold">
                              {" "}
                              {invoice.updatedToken ? (
                                <div>
                                  {invoice.updatedToken.map(
                                    (val: any, indx: number) => (
                                      <>
                                        <span key={indx}>{val}</span>
                                        <br />
                                      </>
                                    )
                                  )}
                                </div>
                              ) : invoice ? (
                                invoice.token
                              ) : (
                                "-"
                              )}
                            </span>
                          </div>
                        </li>

                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg">
                          <div className="flex items-center justify-between w-full">
                            <span>Coût de l'energie</span>
                            <span>{invoice.vendAMT}F</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg">
                          <div className="flex items-center justify-between w-full">
                            <span>Tsdaae</span>
                            <span>
                              {invoice.fee["Tsdaae"] !== undefined
                                ? invoice.fee["Tsdaae"]
                                : 0}
                              F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg">
                          <div className="flex items-center justify-between w-full">
                            <span>Tde</span>
                            <span>
                              {invoice.fee["Tde"] !== undefined
                                ? invoice.fee["Tde"]
                                : 0}
                              F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg">
                          <div className="flex items-center justify-between w-full">
                            <span>Redevance</span>
                            <span>
                              {invoice.fee["Redevance"] !== undefined
                                ? invoice.fee["Redevance"]
                                : 0}
                              F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg">
                          <div className="flex items-center justify-between w-full">
                            <span>PRIME FIXE</span>
                            <span>
                              {invoice.fee["PRIME FIXE"] !== undefined
                                ? invoice.fee["PRIME FIXE"]
                                : 0}
                              F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg">
                          <div className="flex items-center justify-between w-full">
                            <span>TVA</span>
                            <span>
                              {invoice.fee["TVA"] !== undefined
                                ? invoice.fee["TVA"]
                                : 0}
                              F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg">
                          <div className="flex items-center justify-between w-full">
                            <span>Montant total</span>
                            <span>{invoice.AMT}F</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg">
                          <div className="flex items-center justify-between w-full">
                            <span>Frais</span>
                            <span>{invoice.sankFees ?? 0}F</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm font-semibold text-gray-800 border gap-x-2 bg-gray-50 first:rounded-t-lg first:mt-0 last:rounded-b-lg">
                          <div className="flex items-center justify-between w-full">
                            <span>Total quantité energie</span>
                            <span>{invoice.vendQty}Kwh</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-5 mr-8 gap-x-2">
                  <button
                    className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-red-600 border border-transparent rounded-lg gap-x-2 hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    onClick={() => downloadImage(invoice.customerName)}
                    disabled={isBtnDesabled}
                  >
                    {isBtnDesabled ? (
                      <div className="flex items-center justify-center">
                        <button
                          type="button"
                          className="w-full text-white bg-transparent border border-red-500 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-transparent dark:focus:ring-red-800"
                          disabled={true}
                        >
                          <svg
                            className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          <span className="text-white">
                            Téléchargement en cours...
                          </span>
                        </button>
                      </div>
                    ) : (
                      <>
                        <ArrowDownTrayIcon
                          className="self-center flex-shrink-0 w-6 h-6 mr-1 text-white"
                          aria-hidden="true"
                        />
                        Télécharger
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default AgentPaidSnblPrepaidHistories;
