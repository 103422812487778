/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  CheckCircleIcon,
  UserIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import Moment from "react-moment";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../../components/FirestoreTable";
import { usersCollectionName } from "../../../config";
import { db, functions } from "../../../firebase";
import useCrudDocument from "../../../hooks/useCrudDocument";
import { UserRole, userRoleMap } from "../../../interfaces";
import HasRoles from "../../../components/HasRoles";
import { useEffect, useMemo, useState } from "react";
import Modal from "../../../components/Modal";
import PhoneInput from "react-phone-number-input";
import { classNames } from "../../../utils";

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "profilImageUrl",
    label: "Profil",
  },
  {
    field: "displayName",
    label: "Nom/prénom",
  },
  {
    field: "email",
    label: "Email",
  },
  {
    field: "phoneNumber",
    label: "Téléphone",
  },
  {
    field: "city",
    label: "Ville",
  },
  {
    field: "active",
    label: "Active",
  },
  {
    field: "userAgencySubRole",
    label: "Role",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "displayName":
      return <span>{value ? value : "-"}</span>;
    case "profilImageUrl":
      return (
        <span>
          {" "}
          {value ? (
            <img
              className="w-10 h-10 p-1 border rounded-full"
              src={
                value
                  ? value
                  : `https://ui-avatars.com/api/?background=random&name=${
                      value || "S"
                    }`
              }
              alt=""
            />
          ) : (
            <UserIcon className="w-10 h-10 p-1 border rounded-full" />
          )}
        </span>
      );
    case "email":
      return <span>{value ? value : "-"}</span>;
    case "city":
      return <span>{value ? value : "-"}</span>;
    case "phoneNumber":
      return <span>{value ? value : "-"}</span>;
    case "userAgencySubRole":
    case "userRole":
      return <span>{userRoleMap.get(value)}</span>;
    case "active":
      return (
        <span>
          {" "}
          {value ? (
            <CheckCircleIcon className="w-6 h-6 text-green-500" />
          ) : (
            <XCircleIcon className="w-6 h-6 text-red-500" />
          )}
        </span>
      );
    default:
      return value as string;
  }
};
const AllSankStaffPage = () => {
  const [selectedVal, setSelectedVal] = useState<UserRole>(
    UserRole.AGENCYSTAFF
  );
  const navigate = useNavigate();
  const { deleteDocument } = useCrudDocument();
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [responsableData, setResponsableData] = useState<any>();
  const [supervisorId, setSupervisorId] = useState<string>("");
  const [resposableZoneId, setResposableZoneId] = useState<string>("");
  const [isResponsableOrSupervisor, setIsResponsableOrSupervisor] =
    useState(true);

  const adminResetPasswordFn = functions.httpsCallable("adminResetPasswordFn");
  const sankMoneyReferralResponsableZoneFn = functions.httpsCallable(
    "sankMoneyReferralResponsableZoneFn"
  );

  const onEditRow = (id: number, entity: any) => {
    if (entity) {
      navigate(`/sank_staff/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };

  const onAffiliateToSupervisorSuperDealer = (id: number, entity: any) => {
    if (entity) {
      navigate(`/sank_staff/${entity.id}/affiliate`, {
        state: { entity: entity, id: entity.id, affiliate: true },
      });
    }
  };
  const onReaffected = (id: number, entity: any) => {
    if (entity) {
      navigate(`/sank_staff/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id, isReaffected: true },
      });
    }
  };
  const onRowDetails = (id: number, entity: any) => {
    if (entity) {
      navigate(`/sank_staff/${entity.id}/details`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };

  const onResetPassword = (id: number, entity: any) => {
    if (entity) {
      const response = adminResetPasswordFn({
        email: entity.email,
        phoneNumber: entity.phoneNumber,
      });
      toast.promise(response, {
        error: (err) => `${err}`,
        success: "Le mot de passe a été reinitialiser avec succès.",
        loading: "chargement...",
      });
    }
  };
  const onDeleteRow = (row: any) => {
    if (row) {
      toast.promise(deleteDocument(row.id, usersCollectionName), {
        loading: "Suppression...",
        success: "Le dealer a été supprimé avec succès.",
        error: "Échec ! Veuillez réessayer.",
      });
    }
  };
  const onAffiliatedToResponsable = async (id: string, entity: any) => {
    if (entity) {
      if (entity?.userRole === UserRole.RESPONSABLEZONE) {
        setResposableZoneId(entity?.id);
      }
      setSupervisorId(entity?.id);
      setShowPinCodeModal(true);
    }
  };

  const affiliateSupervisorToResponsable = async (id: string) => {
    const toastId = toast.loading("Affiliation...");
    sankMoneyReferralResponsableZoneFn({
      supervisorId:
        selectedVal === UserRole.RESPONSABLEZONE ? id : supervisorId,
      resposableZoneId:
        selectedVal === UserRole.RESPONSABLEZONE ? resposableZoneId : id,
    })
      .then(() => {
        setShowPinCodeModal(false);
        navigate("/sank_staff");
        toast.success("L'affiliation a été effectuée avec success.", {
          id: toastId,
        });
      })
      .catch((e) => {
        toast.error(e.message, {
          id: toastId,
        });
      });
  };

  const retrivePhoneNumber = async (phoneNumber: string) => {
    if (phoneNumber.length >= 12) {
      await db
        .collection(usersCollectionName)
        .where("phoneNumber", "==", phoneNumber)
        .get()
        .then((response) => {
          response.docs.map((el) => {
            if (
              el?.data().userRole === UserRole.RESPONSABLEZONE ||
              el?.data().userRole === UserRole.SUPERVISOR
            ) {
              setIsResponsableOrSupervisor(!isResponsableOrSupervisor);
            }
          });
          setResponsableData(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
        });
    } else {
      setResponsableData([]);
    }
  };

  useEffect(() => {
    let index = columns.findIndex((item) => item.label === "Role");

    selectedVal === UserRole.AGENCYSTAFF
      ? (columns[index].field = "userAgencySubRole")
      : (columns[index].field = "userRole");
  }, [selectedVal]);

  const firestoreTableQueriesWhere = useMemo(() => {
    return {
      fieldPath: "userRole",
      opStr: "==",
      value: selectedVal,
    };
  }, [selectedVal]);

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Le personnel de sank
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ci-dessous tous le personnel de sank.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 border focus:ring-4 focus:outline-none focus:ring-purple-200 "
            onClick={() => {
              navigate("/sank_staff/new");
            }}
          >
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
              Ajouter un collaborateur
            </span>
          </button>
        </div>
        <HasRoles userRole={["ADMIN"]}>
          <div className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200">
            <select
              id="countries"
              className="block w-48 px-5 py-2.5 text-sm text-gray-900 border-none rounded-lg bg-gray-50"
              onChange={(e) => setSelectedVal(e.target.value as any)}
            >
              {[
                UserRole.AGENCYSTAFF,
                UserRole.ADMIN,
                UserRole.SUPERVISOR,
                UserRole.SUPERVISEURSUPERDEALER,
                UserRole.SUPERDEALER,
                UserRole.TECHNICALSUPPORT,
                UserRole.ONEAAGENT,
                UserRole.RESPONSABLEZONE,
              ].map((value, index) => (
                <option
                  key={index}
                  selected={value === UserRole.AGENCYSTAFF && true}
                  value={value}
                >
                  {userRoleMap.get(value)}
                </option>
              ))}
            </select>
          </div>
        </HasRoles>
      </div>
      <div className="shadow-sm">
        <FirestoreTable
          collectionRef={db.collection(usersCollectionName)}
          collectionQueriesWhere={[firestoreTableQueriesWhere]}
          condensed
          columns={columns}
          rowRender={renderRow}
          rowEditHandler={onEditRow}
          // rowDisableHandler={onDeleteRow}
          rowChangeAgencyHandler={onReaffected}
          rowDetailsHandler={onRowDetails}
          rowResetPasswordHandler={onResetPassword}
          rowAffiliateHandler={onAffiliatedToResponsable}
          rowAffectedSupervisorHandler={onAffiliatedToResponsable}
          rowAffiliateUserHandler={onAffiliateToSupervisorSuperDealer}
          displayAffectedSupervisorBtn={
            selectedVal === UserRole.RESPONSABLEZONE
          }
          displayDetails
          displayChangeAgencyBtn={
            selectedVal === UserRole.AGENCYSTAFF ||
            selectedVal === UserRole.SUPERVISOR ||
            selectedVal === UserRole.TECHNICALSUPPORT
          }
          displayAffiliateBtn={selectedVal === UserRole.SUPERVISOR}
          shouldAddEditColumn
          displayEditBtn
          displayAffiliateUserBtn={selectedVal === UserRole.SUPERDEALER}
          displayResetPasswordBtn
          paginate={{ pageIndex: 0, limit: 100, sort: "createdAt:desc" }}
        />
      </div>
      <Modal
        isOpen={showPinCodeModal}
        maxSize="lg"
        closeModal={() => setShowPinCodeModal(false)}
        modalTitle={`Rechercher le ${
          selectedVal === UserRole.SUPERVISOR
            ? "responsable Zone"
            : "superviseur"
        }`}
      >
        <div className="flex justify-center">
          <div className="w-full mt-10">
            <PhoneInput
              international={false}
              placeholder="Entrer un numéro de téléphone"
              className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
              defaultCountry="BF"
              onChange={retrivePhoneNumber}
            />
            <div className="flex justify-center w-full">
              {responsableData && responsableData.length > 0 && (
                <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="flex flex-col items-center pb-10">
                    <img
                      className="w-24 h-24 mt-2 mb-3 rounded-full shadow-lg"
                      src={
                        responsableData[0].data.profilImageUrl
                          ? responsableData[0].data.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${
                              responsableData[0].data.displayName || "S"
                            }`
                      }
                      alt=""
                    />
                    <h5 className="mb-1 text-xl font-medium text-center text-gray-900 dark:text-white">
                      {responsableData[0].data.displayName}
                    </h5>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {responsableData[0].data.phoneNumber}
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Membre SankMoney depuis :{" "}
                      {new Date(
                        responsableData[0].data.createdAt.seconds * 1000 +
                          responsableData[0].data.createdAt.nanoseconds /
                            1000000
                      ).toLocaleDateString()}
                    </span>
                    <div className="flex mt-4 space-x-3 md:mt-5">
                      <button
                        className={classNames(
                          isResponsableOrSupervisor
                            ? "disabled cursor-not-allowed bg-gray-50"
                            : "cursor-pointer",
                          "inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        )}
                        disabled={isResponsableOrSupervisor}
                        onClick={() =>
                          affiliateSupervisorToResponsable(
                            responsableData[0].id
                          )
                        }
                      >
                        Affilier
                      </button>
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                        onClick={() => {
                          setShowPinCodeModal(false);
                          setResponsableData([]);
                        }}
                      >
                        Annuler
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AllSankStaffPage;
