import React, { useState } from "react";

interface CodePinInterface {
  onSubmit: (value: string) => void;
  disabled: boolean;
  isLoad: boolean;
  title?: string;
}
const CodePinComponent = ({
  onSubmit,
  disabled,
  isLoad,
  title,
}: CodePinInterface) => {
  const [codePin, setcodePin] = useState<String>();
  const handleChange = (codepinValue: string) => {
    if (codepinValue.length >= 4) {
      setcodePin(codepinValue);
    }
  };
  const onSubmitOperation = () => {
    onSubmit(codePin as string);
  };
  return (
    <div className="relative w-full max-w-md max-h-full mt-2">
      <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div className="px-6 py-6 lg:px-8">
          <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
            {title ? title : " Entrez votre code pin"}
          </h3>
          <div className="space-y-6">
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Veillez entrer un code pin de quatre (04) chiffres.
              </label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••"
                autoComplete="off"
                autoFocus={true}
                maxLength={4}
                onChange={(e) => handleChange(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
              />
            </div>
            {isLoad ? (
              <div className="flex items-center justify-center">
                <button
                  type="button"
                  className="w-full text-white bg-transparent border border-red-500 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-transparent dark:focus:ring-red-800"
                  disabled={disabled}
                >
                  <svg
                    className="w-5 h-5 mr-3 -ml-1 text-red-500 animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span className="text-red-500">Recharge en cours...</span>
                </button>
              </div>
            ) : (
              <button
                type="submit"
                className="w-full text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                onClick={onSubmitOperation}
                disabled={disabled}
              >
                Valider la transaction
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodePinComponent;
