/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { db, functions } from "../../firebase";
import moment from "moment";
import {
  ArrowDownOnSquareIcon,
  ArrowDownTrayIcon,
  CurrencyDollarIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { AccountType, TransactionType, UserRole } from "../../interfaces";
import { accountsCollectionName, commissionsCollectionsName, transactionCollectionName, usersCollectionName } from "../../config";
import HasRoles from "../../components/HasRoles";
import { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts";
import Modal from "../../components/Modal";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import * as XLSX from "xlsx";
import { classNames, getTimestamp } from "../../utils";
import DashboardCard from "../../components/Card";
import { Skeleton } from "../../components/LoadingSkeleton/skeleton";
import { Stats } from "../Dashboard";
import { StatCard } from "../../components/DashboardComponents/DashboardReview";
import toast from "react-hot-toast";


const SuperDealersPage = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [userData, setUserData] = useState<any>();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [superDealersData, setSuperDealersData] = useState<any>();
  const [superDealers, setSuperDealers] = useState<any[]>([]);
  const [filter, setFilter] = useState<any[]>([]);
  const [stats, setStats] = useState<Stats>({})
  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [startTime, setStartTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  );
  const [endTime, setEndTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, false)
  );
  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithEndDateValue(value.target.value);
  };
  const getFilteredByDate = useCallback(async () => {
    if (seachWithStartDateValue) {
      setStartTime(getTimestamp(seachWithStartDateValue, true));
    }

    if (seachWithEndDateValue) {
      setEndTime(getTimestamp(seachWithEndDateValue, false));
    }
  }, [seachWithEndDateValue, seachWithStartDateValue]);

  useEffect(() => {
    getFilteredByDate();
  }, [getFilteredByDate]);

  const onRowDetails = (id: number, entity: any) => {
    if (entity) {
      navigate(`/superdealers/${entity.id}/details`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };

  const onShowSuperDealerActivities = (entity: any) => {
    if (entity) {
      navigate(`/superdealers/${entity.id}/activities`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };

  const onShowSuperDealerDealers = (id: number, entity: any) => {
    if (entity) {
      navigate(`/superdealers/${entity.id}/dealers`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };

  const retriveSuperDealerPhoneNumber = async (phoneNumber: string) => {
    if (phoneNumber.length >= 12) {
      await db
        .collection(usersCollectionName)
        .where("userRole", "==", UserRole.SUPERDEALER)
        .where("phoneNumber", "==", phoneNumber)
        .get()
        .then((response) => {
          setUserData(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
        });
    } else {
      setUserData([]);
    }
  };
  const superDealerDataFetch = useCallback(async () => {
    const superDealer = await db
      .collection(usersCollectionName)
      .where("supervisorSuperDealerId", "==", user?.id)
      .where("userRole", "==", UserRole.SUPERDEALER)
      .get();

    let superDealersData: any;
    if (superDealer.empty) {

      superDealersData = []
    }
    superDealersData = superDealer.docs.map((el) => {
      return { id: el.id, data: el.data() };
    })
    const superDealersArray: any[] = [];
    let allBalance: number = 0;
    let allCommission: number = 0;
    let allReloadAmount: number = 0;
    let allWithdrawAmount: number = 0;
    let allSupplySuperviseur: number = 0;
    let allSuperDealerWithdraw: number = 0
    setLoadingData(true)
    for (const element of superDealersData) {
      const userRef = db.collection(usersCollectionName).doc(element.id);
      const sankAcountRef = userRef
        .collection(accountsCollectionName)
        .where("accountType", "==", AccountType.MAIN);

      const sankAcountsData = await sankAcountRef.get();
      const sankAccountData: any = sankAcountsData.docs[0].data();
      const balance: number = sankAccountData.balance;
      allBalance += balance;

      const commissionRef = await db
        .collection(commissionsCollectionsName)
        .orderBy("createdAt", "desc")
        .where("userId", "==", element?.id)
        .where("commissionPaid", "==", false)
        .get()

      const totalCommission = commissionRef.docs.reduce((sum, commission) => {
        return sum + (commission.data()?.amount ? parseInt(commission.data()?.amount) : 0);
      }, 0);

      allCommission += totalCommission;

      const DepositRef = db
        .collection(transactionCollectionName)
        .where("senderID", "==", element?.id)
        .where("transactionType", "==", TransactionType.RELOAD)
        .where("createdAt", ">=", getTimestamp(startTime, true))
        .where("createdAt", "<=", getTimestamp(endTime, false));

      const depositSnapshot = await DepositRef.get();
      let totalDeposit = 0;
      depositSnapshot.forEach((doc) => {
        totalDeposit += parseInt(doc.data().amount || "0");
      });

      allReloadAmount += totalDeposit

      const WithdrawalRef = db
        .collection(transactionCollectionName)
        .where("receiverID", "==", element?.id)
        .where("transactionType", "==", TransactionType.WITHDRAW)
        .where("createdAt", ">=", getTimestamp(startTime, true))
        .where("createdAt", "<=", getTimestamp(endTime, false));

      const withdrawalSnapshot = await WithdrawalRef.get();
      let totalWithdrawal = 0;
      withdrawalSnapshot.forEach((doc) => {
        totalWithdrawal += parseInt(doc.data().amount || "0");
      });

      allWithdrawAmount += totalWithdrawal;

      const DepositSuperDealerAccount = db
        .collection(transactionCollectionName)
        .where("receiverID", "==", element?.id)
        .where("transactionType", "==", TransactionType.RELOAD)
        .where("createdAt", ">=", getTimestamp(startTime, true))
        .where("createdAt", "<=", getTimestamp(endTime, false));

      const depositSuperDealerAccount = await DepositSuperDealerAccount.get();
      let totalReload = 0;
      depositSuperDealerAccount.forEach((doc) => {
        totalReload += parseInt(doc.data().amount || "0");
      });

      allSuperDealerWithdraw += totalReload;

      const withdrawSuperDealerAccount = db
        .collection(transactionCollectionName)
        .where("senderID", "==", element?.id)
        .where("transactionType", "==", TransactionType.WITHDRAW)
        .where("createdAt", ">=", getTimestamp(startTime, true))
        .where("createdAt", "<=", getTimestamp(endTime, false));

      const WidthrawSuperDealerAccount = await withdrawSuperDealerAccount.get();
      let totalWithdraw = 0;
      WidthrawSuperDealerAccount.forEach((doc) => {
        totalWithdraw += parseInt(doc.data().amount || "0");
      });

      allSupplySuperviseur += totalWithdraw;

      const dealer = await db
        .collection(usersCollectionName)
        .where("superDealerId", "==", element?.id)
        .where("userRole", "==", UserRole.DEALER)
        .get();

      const dealersNumber = dealer.size

      superDealersArray.push({
        user: element.data,
        displayName: element.data?.displayName ?? "",
        phoneNumber: element.data?.phoneNumber ?? "",
        balance,
        totalCommission,
        totalDeposit,
        totalWithdrawal,
        totalReload,
        dealersNumber,
        totalWithdraw
      });
    }
    setStats({
      allBalance,
      allCommission,
      allReloadAmount,
      allWithdrawAmount,
      allSuperDealerWithdraw,
      allSupplySuperviseur
    })
    setSuperDealers(superDealersArray);
    setFilter(superDealersArray)
    setLoadingData(false)
  }, [endTime, startTime, user?.id])

  const retriveMerchantByPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber && isValidPhoneNumber(phoneNumber, "BF")) {

      const filteredSuperDealers = superDealers.filter((val: any) =>
        val.phoneNumber.includes(phoneNumber)
      );

      setSuperDealers(filteredSuperDealers);
    } else {
      setSuperDealers(filter)
    }
  };

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        const superDealer = await db
          .collection(usersCollectionName)
          .where("supervisorSuperDealerId", "==", user?.id)
          .where("userRole", "==", UserRole.SUPERDEALER)
          .get();
        if (!superDealer.empty) {
          setSuperDealersData(
            superDealer.docs.map((el) => {
              return { id: el.id, data: el.data() };
            })
          );
        } else {
          setSuperDealersData([]);
        }
      };
      fetchData();
      superDealerDataFetch()
    }
  }, [superDealerDataFetch]);

  const exportToDealerData = useCallback(async () => {
    const worksheet = XLSX.utils.json_to_sheet(
      await Promise.all(
        (superDealersData as [])?.map(async (user: any) => {
          const userRef = db.collection(usersCollectionName).doc(user.id);
          const sankAcountRef = userRef
            .collection(accountsCollectionName)
            .where("accountType", "==", "MAIN");
          const sankAcountsData = await sankAcountRef.get();
          const sankAccountData: any = sankAcountsData.docs[0].data();
          const balance: number = sankAccountData.balance;

          const commissionRef = await db
            .collection(commissionsCollectionsName)
            .orderBy("createdAt", "desc")
            .where("userId", "==", user?.id)
            .where("commissionPaid", "==", false)
            .get()

          const totalCommission = commissionRef.docs.reduce((sum, commission) => {
            return sum + (commission.data()?.amount ? parseInt(commission.data()?.amount) : 0);
          }, 0);

          return {
            "Nom/prénom": user.data.displayName ?? "-",
            Email: user.data.email ?? "-",
            Ville: user.data.city ?? "-",
            Téléphone: user.data.phoneNumber ?? "-",
            // @ts-ignores
            Solde: balance ?? "0",
            commissions: totalCommission
          };
        })
      )
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "super-dealers-liste");
    XLSX.writeFile(
      workbook,
      `La-liste-de-${superDealersData?.length
      }-super-dealers-${new Date().toLocaleDateString()}.xlsx`,
      { compression: true }
    );
  }, [superDealersData]);

  const sankreportTransactionSuperDealer = functions.httpsCallable("sankreportTransactionSuperDealer")

  const rapportToXLSXData = async () => {
    const startTime = getTimestamp(seachWithStartDateValue, true);
    const endTime = getTimestamp(seachWithEndDateValue, false);
    const toastId = toast.loading("Géneration de rapport...");
    await sankreportTransactionSuperDealer({
      email: user?.email,
      currentUserId: user?.id,
      startTime: startTime,
      endTime: endTime
    }).then((response) => {
      toast.success(`Rapport géneré et envoyé avec succès et envoyé a ${user?.email ?? ""}.`, {
        id: toastId,
      });
    }).catch((e) => {
      toast.error(e.message, {
        id: toastId,
      });
    })
  }

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      {/* Start section */}
      <div className="flex flex-col mt-8">
        {/* Start Title section */}
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les Supers Dealers
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ci-dessous tous les Supers Dealers affiliés à votre
            compte.
          </p>
          <br className="mt-4" />
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-4 p-4">
            <StatCard title="Solde Cumulé" value={stats?.allBalance ?? 0} isLoad={loadingData} icon={<CurrencyDollarIcon className="w-6 h-6" />} />
            <StatCard title="Commission Cumulé" value={stats?.allCommission ?? 0} isLoad={loadingData} icon={<CurrencyDollarIcon className="w-6 h-6" />} />
            <StatCard title="Depôt Cumulé" value={stats?.allReloadAmount ?? 0} isLoad={loadingData} icon={<CurrencyDollarIcon className="w-6 h-6" />} />
            <StatCard title="Rétrait Cumulé" value={stats?.allWithdrawAmount ?? 0} isLoad={loadingData} icon={<CurrencyDollarIcon className="w-6 h-6" />} />
            <StatCard title="Appro Cumulé" value={stats?.allSupplySuperviseur ?? 0} isLoad={loadingData} icon={<CurrencyDollarIcon className="w-6 h-6" />} />
            <StatCard title="Remboursement Cumulé" value={stats?.allSuperDealerWithdraw ?? 0} isLoad={loadingData} icon={<CurrencyDollarIcon className="w-6 h-6" />} />
          </div>
        </div>

        <div className="h-8" />
        {/* start search section  */}
        <div className="lg:w-1/3 md:w-1/2 sm:w-full">
          <PhoneInput
            international={false}
            placeholder="Numéro de téléphone"
            className="block px-3 py-2 placeholder-gray-400 border rounded-lg appearance-none w-ful focus:border-emerald-500 focus:outline-none sm:text-sm"
            defaultCountry="BF"
            onChange={retriveMerchantByPhoneNumber}
          />
        </div>
        <div className="flex self-end max-md:flex-wrap">
          <div>
            {/* Search agent button */}
            <button
              type="button"
              className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-700"
              onClick={(e) => {
                e.preventDefault();
                setShowDetailsModal(true);
              }}
            >
              <MagnifyingGlassIcon
                className="self-center flex-shrink-0 w-5 h-5 mr-1 "
                aria-hidden="true"
              />
              Rechercher un Super Dealer
            </button>
          </div>

          <div className="flex mx-1 flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
            {/* Filter reloads by date */}
            <div className="flex items-center w-full space-x-3 md:w-auto">
              {/* start date */}
              <input
                type="date"
                id="dateDebut"
                className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                onChange={handleChangeStartDateValue}
              />

              {/* end date */}
              <input
                type="date"
                id="dateDebut"
                className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                onChange={handleChangeEndDtaeValue}
              />
            </div>
            {/* End filter reloads by date */}
          </div>

          {/* Export Icon */}
          <HasRoles userRole={["SUPERVISEURSUPERDEALER"]}>
            <div className="mx-2">
              <button
                type="button"
                className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                onClick={() => {
                  rapportToXLSXData();
                }}
              >
                <ArrowDownTrayIcon
                  className={classNames(
                    "self-center flex-shrink-0 w-5 h-5 mr-1"
                  )}
                  aria-hidden="true"
                />
                <span className="">Rapport</span>
              </button>
            </div>
          </HasRoles>
          <HasRoles userRole={["SUPERVISEURSUPERDEALER"]}>
            <div className="mx-2">
              <button
                type="button"
                className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                onClick={() => {
                  exportToDealerData();
                }}
              >
                <ArrowDownOnSquareIcon
                  className={classNames(
                    "self-center flex-shrink-0 w-5 h-5 mr-1"
                  )}
                  aria-hidden="true"
                />
                <span className="">Comptes</span>
              </button>
            </div>
          </HasRoles>
        </div>
        {/* end search section */}
      </div>
      {/* End section  */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-4 p-4">
        {
          loadingData ? (
            Array.from({ length: 5 }).map((_, i) => (
              <Skeleton key={i} className="w-full h-[300px]" />
            ))
          ) : superDealers && superDealers.length > 0 ? (
            superDealers.map((superdealer, superdealerIndex) => (
              <DashboardCard info={superdealer} key={superdealerIndex}
                onManage={() => onShowSuperDealerActivities(superdealer?.user)}
                dealers={() => onShowSuperDealerDealers(superdealerIndex, superdealer?.user)}
              />
            ))
          ) : (<>
            <p className="items-center text-base text-red-500">Vous n'avez aucun compte affilié a votre compte</p>
          </>)
        }
      </div>

      <Modal
        isOpen={showDetailsModal}
        maxSize="md"
        closeModal={() => setShowDetailsModal(false)}
        modalTitle="Rechercher un super dealer"
      >
        <div className="flex justify-center">
          <div className="w-full mt-10">
            <PhoneInput
              international={false}
              placeholder="Entrer un numéro de téléphone"
              className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
              defaultCountry="BF"
              onChange={retriveSuperDealerPhoneNumber}
            />
            <div className="flex justify-center w-full">
              {userData && userData.length > 0 && (
                <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="flex flex-col items-center pb-10">
                    <img
                      className="w-24 h-24 mt-2 mb-3 rounded-full shadow-lg"
                      src={
                        userData[0].data.profilImageUrl
                          ? userData[0].data.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${userData[0].data.displayName || "S"
                          }`
                      }
                      alt=""
                    />
                    <h5 className="mb-1 text-xl font-medium text-center text-gray-900 dark:text-white">
                      {userData[0].data.displayName}
                    </h5>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {userData[0].data.phoneNumber}
                    </span>
                    <div className="flex mt-4 space-x-3 md:mt-6">
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        onClick={() =>
                          navigate(`/users/${userData[0].id}/details`, {
                            state: {
                              entity: userData[0].data,
                              id: userData[0].id,
                              isSearch: true,
                            },
                          })
                        }
                      >
                        Details
                      </div>
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                        onClick={() => {
                          setShowDetailsModal(false);
                          setUserData([]);
                        }}
                      >
                        Annuler
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SuperDealersPage;
