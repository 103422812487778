/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Form, FormInput } from "../../../../components/FormComponents";
import * as yup from "yup";
import {
  DocumentMinusIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../contexts";
import { db, functions } from "../../../../firebase";
import {
  amountFormater,
  checkTransactionFeesSchedule,
  classNames,
  isNumeric,
  TransactionFees,
} from "../../../../utils";
import {
  globalConfigCollectionsName,
  feesConfigId,
  agentTransfeesLevelSubCollectionName,
  snblPostPaidFeesLevelsDocId,
  defaultAmount,
  accountsCollectionName,
  usersCollectionName,
} from "../../../../config";
import CodePinComponent from "../../../../components/CodePinComponent";
import Modal from "../../../../components/Modal";

type RetrieveInvoiceInfo = { codeExpl: string; metterPolice: string };
type unPaindInvoiceType = {
  periode: string;
  amount: number;
  order: number;
  invoiceType: any;
  selected: boolean;
};

type Step = {
  id: "invoiceInfos" | "paymentDetails";
  index: number;
  name: string;
  description: string;
  icon: any;
};

const steps: Step[] = [
  {
    id: "invoiceInfos",
    index: 0,
    name: "Les informations du compteur",
    description: "Renseigner les informations du compteur",
    icon: InformationCircleIcon,
  },
  {
    id: "paymentDetails",
    index: 1,
    name: "Les details du paiement",
    description: "Vérifiez les details du paiement",
    icon: DocumentMinusIcon,
  },
];

const PaidPostPaidInvoices = () => {
  const navigate = useNavigate();
  const [currentFormStep, setCurrentFormStep] = useState<Step>(steps[0]);
  const { user, setUserAccounts } = useContext(UserContext);
  const [invoices, setInvoices] = useState<any>();
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountWithFess, setTotalAmountWithFess] = useState(0);
  const [fees, setFees] = useState(0);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [periodes, setPeriodes] = useState<string[]>();
  const [penality, setPenality] = useState(0);

  const agentPaidSnblPostpaidInvoicesFn = functions.httpsCallable(
    "agentPaidInvoices-agentPaidSnblPostpaidInvoicesFn"
  );
  const sankMoneyRetriveSnblUnPaidInvoiceFn = functions.httpsCallable(
    "sankMoneyRetriveSnblUnPaidInvoiceFn"
  );

  const retrievePostPaidInvoiceInfos = async (
    retriveInfoData: RetrieveInvoiceInfo
  ) => {
    if (!user) {
      return;
    }
    const toastId = toast.loading("Consultation en cours...");
    const data = {
      currentUserId: user?.id,
      withConnection: true,
      appVersion: "web",
      appPlatform: "web",
      CUSTOMER_EXPL: retriveInfoData.codeExpl,
      CUSTOMER_POLICE: retriveInfoData.metterPolice,
    };
    await sankMoneyRetriveSnblUnPaidInvoiceFn(data).then((res) => {
      if (res.data.data && res.data.data.CODE[0] !== "200") {
        return toast.error(res.data.data.MESSAGE[0], { id: toastId });
      }
      const invoiceTransInfos: any = {
        transId: res.data.data.SNBL_TRANSACTION_ID[0],
        ownerName:
          res.data.data.liste_factures &&
          res.data.data.liste_factures.length > 0
            ? res.data.data.liste_factures[0][0].NomClient[0]
            : "",
        codeExpl: retriveInfoData.codeExpl,
        metterPolice: retriveInfoData.metterPolice,
        unpaidInvoices: [],
      };
      if (
        res.data.data.liste_factures &&
        res.data.data.liste_factures.length > 0
      ) {
        const ownerUnPaidInvoices: any[] = [];
        const penalityInvoice = res.data.data.liste_factures[0].find(
          (invoice: any) => invoice.TypeFacture[0] === "Z"
        );
        for (const invoice of res.data.data.liste_factures[0]) {
          if (invoice.TypeFacture[0] === "N") {
            const vals: unPaindInvoiceType = {
              periode: `${invoice.MoisFActure[0]}/${invoice.AnneeFActure[0]}`,
              amount: parseFloat(`${invoice.MontantFActure[0]}`),
              order: parseInt(`${invoice.NumOrdre[0]}`),
              invoiceType: invoice.TypeFacture[0],
              selected: false,
            };
            ownerUnPaidInvoices.push(vals);
          }
        }
        invoiceTransInfos.unpaidInvoices = ownerUnPaidInvoices;
        const penaltyAmount = penalityInvoice
          ? parseFloat(`${penalityInvoice.MontantFActure[0]}`)
          : 0;
        setPenality(penaltyAmount);
      }
      setInvoices(invoiceTransInfos);
      setCurrentFormStep(steps[1]);
      toast.success("ok", { id: toastId });
    });
  };

  const handleSelectedPeriodeToPay = (
    index: number,
    inv: unPaindInvoiceType,
    checked: boolean
  ) => {
    const data: any[] = [];
    for (const invoice of invoices.unpaidInvoices) {
      data.push({
        ...invoice,
        selected: invoice.order <= inv.order ? true : false,
      });
    }
    const val = {
      ...invoices,
      unpaidInvoices: data,
    };
    const total = (val.unpaidInvoices as [])
      .filter((inv: any) => inv.selected === true)
      .reduce((amount, inv: any) => amount + inv.amount, 0);

    const p = (val.unpaidInvoices as [])
      .filter((inv: any) => inv.selected === true)
      .map((inv: unPaindInvoiceType) => {
        return inv.periode;
      });
    setPeriodes(p);
    setTotalAmount(total);
    setInvoices(val);
  };

  const onSubmitInvoices = async (pinCode: string) => {
    setIsCodePinBtnDesabled(true);
    if (!isNumeric(`${totalAmount}`)) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      return toast.error(`Le montant n'est pas bien formatté`);
    }
    if (`${totalAmount}` < defaultAmount) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      return toast.error(
        `Le montant doit être suppérieur à ${defaultAmount} Fcfa`
      );
    }
    setIsLoad(true);
    const data: any = {
      pinCode: pinCode,
      amount: `${totalAmount + penality}`,
      withConnection: true,
      appVersion: "web",
      appPlatform: "web",
      CUSTOMER_EXPL: invoices.codeExpl,
      CUSTOMER_POLICE: invoices.metterPolice,
      SNBLTRANSID: invoices.transId,
      metterCustomerName: invoices.ownerName,
      INVOICEPERIOD: periodes,
      currentUserId: user?.id,
    };
    await agentPaidSnblPostpaidInvoicesFn(data)
      .then(async () => {
        setShowPinCodeModal(false);
        setIsLoad(false);
        const senderAccountRef = db
          .collection(usersCollectionName)
          .doc(user?.id)
          .collection(accountsCollectionName);
        const accounts = await senderAccountRef.get();
        setUserAccounts(accounts.docs.map((account) => account.data()));
        navigate("/dashboard");
        return toast.success("La facture a été payée avec succès.");
      })
      .catch((err: { details: any; message: any }) => {
        setShowPinCodeModal(false);
        setIsCodePinBtnDesabled(false);
        setIsLoad(false);
        toast.error(err.message);
      });
  };

  const retrieveFees = useCallback(async () => {
    const sbnlPostpaidFeeSnap = await db
      .collection(globalConfigCollectionsName)
      .doc(feesConfigId)
      .collection(agentTransfeesLevelSubCollectionName)
      .doc(snblPostPaidFeesLevelsDocId)
      .get();
    const postpaidFeesData = sbnlPostpaidFeeSnap.data();
    const amountWithFees = checkTransactionFeesSchedule(
      postpaidFeesData as TransactionFees,
      totalAmount
    ).amountWithFees;
    setTotalAmountWithFess(amountWithFees + penality);
    const fees = checkTransactionFeesSchedule(
      postpaidFeesData as TransactionFees,
      totalAmount
    ).fees;
    setFees(fees);
  }, [penality, totalAmount]);

  useEffect(() => {
    retrieveFees();
  }, [retrieveFees]);

  return (
    <>
      <div className="lg:border-t lg:border-b lg:border-gray-200">
        <nav
          className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
          aria-label="Progress"
        >
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
          >
            <li className="relative px-2 py-4 overflow-hidden lg:py-2">
              <button
                className="p-1 border border-transparent rounded-full hover:border-gray-200 hover:bg-gray-50"
                onClick={() => navigate("/dashboard")}
              >
                <XMarkIcon className="w-8 h-8 text-gray-700" />
              </button>
            </li>
            {steps.map((step, stepIdx) => (
              <li key={stepIdx} className="relative overflow-hidden lg:flex-1">
                <div
                  className={classNames(
                    stepIdx === 0 ? "border-b-0 rounded-t-md" : "",
                    stepIdx === steps.length - 1
                      ? "border-t-0 rounded-b-md"
                      : "",
                    "border border-gray-200 overflow-hidden lg:border-0 h-full"
                  )}
                >
                  <button
                    className={classNames(
                      "w-full group h-full cursor-not-allowed"
                    )}
                  >
                    <span
                      className={classNames(
                        step.index !== currentFormStep.index
                          ? "group-hover:bg-gray-200 bg-transparent "
                          : "bg-red-600",
                        "absolute top-0 left-0 w-1 h-full  lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "px-4 py-4 lg:py-2 flex items-start text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span
                          className={classNames(
                            step.index < currentFormStep.index
                              ? "bg-red-600"
                              : "",
                            step.index === currentFormStep.index
                              ? "bg-white border-2 border-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "bg-white border-2 border-gray-300"
                              : "",
                            "flex items-center justify-center w-10 h-10  rounded-full"
                          )}
                        >
                          <step.icon
                            className={classNames(
                              step.index < currentFormStep.index
                                ? "text-white"
                                : "",
                              step.index === currentFormStep.index
                                ? "text-red-600"
                                : "",
                              step.index > currentFormStep.index
                                ? "text-gray-500"
                                : "",
                              "w-6 h-6 "
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex-col flex  xl:flex">
                        <span
                          className={classNames(
                            step.index === currentFormStep.index
                              ? "text-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "text-gray-500"
                              : "",
                            "text-xs font-semibold tracking-wide uppercase md:block lg:hidden xl:block"
                          )}
                        >
                          {step.name}
                        </span>
                        <span className="flex items-start text-sm font-medium text-gray-500">
                          {step.description}
                        </span>
                      </span>
                    </span>
                  </button>
                  <div
                    className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="w-full h-full text-gray-300"
                      viewBox="0 0 12 82"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0.5 0V31L10.5 41L0.5 51V82"
                        stroke="currentcolor"
                        vectorEffect="non-scaling-stroke"
                      />
                    </svg>
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="items-center w-full mx-auto md:w-11/12 lg:w-9/12">
        {currentFormStep.id === "invoiceInfos" && (
          <Form<RetrieveInvoiceInfo>
            onSubmit={retrievePostPaidInvoiceInfos}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  codeExpl: yup
                    .string()
                    .required(
                      "Le champs numéro d'exploitation est obligatoire"
                    ),
                  metterPolice: yup
                    .string()
                    .required("Le champs numéro de police est obligatoire"),
                })
              ),
            }}
            submitButtonLabel="Consulter"
          >
            <div className="flex flex-col content-center w-full px-5 mt-5">
              <div className="w-full mx-auto ">
                <FormInput
                  name="codeExpl"
                  label="Code d'exploitation"
                  placeholder="302"
                />
                <FormInput
                  name="metterPolice"
                  label="Numéro du police"
                  placeholder="304934"
                />
              </div>
            </div>
          </Form>
        )}
        {currentFormStep.id === "paymentDetails" && (
          <div className="my-6 border border-red-300 rounded-lg shadow-lg">
            <div className="p-4 overflow-y-auto sm:p-7">
              <div className="text-center">
                <h3
                  id="hs-ai-modal-label"
                  className="text-lg font-semibold text-gray-800 dark:text-neutral-200"
                >
                  Factures SONABEL
                </h3>
                <span className="text-sm text-gray-500 dark:text-neutral-500">
                  #{invoices?.transId ? invoices.transId : ""}
                </span>
              </div>

              <div className="grid grid-cols-2 gap-5 mt-5 sm:mt-10 sm:grid-cols-3">
                <div>
                  <span className="block text-xs text-gray-500 uppercase dark:text-neutral-500">
                    Nom du client:
                  </span>
                  <span className="block text-sm font-medium text-gray-800 dark:text-neutral-200">
                    {invoices?.ownerName ? invoices.ownerName : ""}
                  </span>
                </div>

                <div>
                  <span className="block text-xs text-gray-500 uppercase dark:text-neutral-500">
                    N° d'exploitation:
                  </span>
                  <span className="block text-sm font-medium text-gray-800 dark:text-neutral-200">
                    {invoices?.codeExpl ? invoices.codeExpl : ""}
                  </span>
                </div>
                <div>
                  <span className="block text-xs text-gray-500 uppercase dark:text-neutral-500">
                    N° de Police:
                  </span>
                  <span className="block text-sm font-medium text-gray-800 dark:text-neutral-200">
                    {invoices?.metterPolice ? invoices.metterPolice : ""}
                  </span>
                </div>
              </div>

              <div className="mt-5 sm:mt-10">
                <h4 className="text-xs font-semibold text-gray-800 uppercase dark:text-neutral-200">
                  Périodes de consommation
                </h4>

                <ul className="flex flex-col mt-3">
                  <li className="flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border border-gray-200 gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200">
                    <div className="w-full">
                      <span></span>
                    </div>
                    <div className="w-full">
                      <span>N°</span>
                    </div>
                    <div className="w-full">
                      <span>Date</span>
                    </div>
                    <div className="w-full">
                      <span>Type</span>
                    </div>
                    <div className="w-full">
                      <span>Montant</span>
                    </div>
                  </li>
                  {invoices && invoices?.unpaidInvoices ? (
                    invoices?.unpaidInvoices.map(
                      (inv: unPaindInvoiceType, index: number) => (
                        <li
                          key={index}
                          className="flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border border-gray-200 gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200"
                        >
                          <div className="flex items-center w-full">
                            <input
                              id="default-checkbox"
                              type="checkbox"
                              checked={inv.selected}
                              onChange={(e) =>
                                handleSelectedPeriodeToPay(
                                  index,
                                  inv,
                                  e.target.checked
                                )
                              }
                              className="w-4 h-4 text-red-600 bg-gray-100 border-gray-300 rounded-sm focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>
                          <div className="flex items-center w-full">
                            <span>{inv.order + 1}</span>
                          </div>
                          <div className="flex items-center w-full">
                            <span>{inv.periode}</span>
                          </div>
                          <div className="flex items-center w-full">
                            <span>{inv.invoiceType}</span>
                          </div>
                          <div className="flex items-center w-full">
                            <span>{inv.amount}</span>
                          </div>
                        </li>
                      )
                    )
                  ) : (
                    <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border border-gray-200 gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200">
                      <div className="flex items-center justify-between w-full">
                        <span>Pas de factures</span>
                      </div>
                    </li>
                  )}
                  <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border border-gray-200 gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200">
                    <div className="flex items-center justify-between w-full">
                      <span>Montant total sélectionné</span>
                      <span>{amountFormater(totalAmount)} Fcfa</span>
                    </div>
                  </li>
                  <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border border-gray-200 gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200">
                    <div className="flex items-center justify-between w-full">
                      <span>Penalité</span>
                      <span>{amountFormater(penality)} Fcfa</span>
                    </div>
                  </li>
                  <li className="inline-flex items-center px-4 py-3 -mt-px text-sm font-semibold text-gray-800 border border-gray-200 gap-x-2 bg-gray-50 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200">
                    <div className="flex items-center justify-between w-full">
                      <span>Frais</span>
                      <span>{fees} Fcfa</span>
                    </div>
                  </li>
                  <li className="inline-flex items-center px-4 py-3 -mt-px text-sm font-semibold text-gray-800 bg-gray-100 border border-gray-200 gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200">
                    <div className="flex items-center justify-between w-full">
                      <span>Net à payé</span>
                      <span>{amountFormater(totalAmountWithFess)} Fcfa</span>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="flex justify-end my-5 gap-x-2">
                <div
                  onClick={() => setShowPinCodeModal(true)}
                  className="inline-flex items-center px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-lg cursor-pointer gap-x-2 hover:bg-red-700 focus:outline-hidden focus:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                >
                  Payé ({amountFormater(totalAmountWithFess)} Fcfa)
                </div>
              </div>

              {/* <div className="mt-5 sm:mt-10">
                <div className="text-sm text-gray-500 dark:text-neutral-500">
                  Si vous avez des questions, veuillez nous contacter à
                  l'adresse{" "}
                  <div className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline focus:outline-hidden focus:underline font-medium dark:text-blue-500">
                    contact@sankbusiness.com
                  </div>{" "}
                  ou nous appeler au{" "}
                  <a
                    className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline focus:outline-hidden focus:underline font-medium dark:text-blue-500"
                    href="tel:+22668202034"
                  >
                    +226 68-20-20-34
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        )}
        <Modal
          isOpen={showPinCodeModal}
          maxSize="md"
          closeModal={() => setShowPinCodeModal(false)}
        >
          <div className="flex justify-center">
            <CodePinComponent
              title={` Entrez votre code pin pour payé ${amountFormater(
                totalAmountWithFess
              )} FCFA`}
              onSubmit={onSubmitInvoices}
              disabled={isCodePinBtnDesabled}
              isLoad={isLoad}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default PaidPostPaidInvoices;
