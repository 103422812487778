import {
  FunnelIcon,
  ArrowDownTrayIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import DashboardComponent from "../../../../components/DashboardComponents/InvoiceManagmentComponent";
import Modal from "../../../../components/Modal";
import Pagination from "../../../../components/Pagination/Pagination";
import NoContent from "../../../../components/TableNoContentPage/NoContent";
import { transactionCollectionName } from "../../../../config";
import {
  amountFormater,
  classNames,
  getTimestamp,
  parseDate,
} from "../../../../utils";
import TableRowEditMenu from "../../../../components/TableRowEditMenu/TableRowEditMenu";
import { UserContext } from "../../../../contexts";
import { db } from "../../../../firebase";
import { TransactionType } from "../../../../interfaces";
import toast from "react-hot-toast";
import * as htmlToImage from "html-to-image";

const tableHeader = [
  "N°",
  "Date de paiement",
  "Numéro Police",
  "Nom/Prénom",
  "Montant",
  "Action",
];

const AgentPaidSnblPostPaidHistories = () => {
  const [invoices, setInvoices] = useState<any[]>([]);
  const [invoice, setInvoice] = useState<any>();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [numPolicy, setNumPolicy] = useState<string>("");
  const { user } = useContext(UserContext);
  const [pageLimit, setPageLimit] = useState(100);
  const [invoicesPaid, setInvoicesPaid] = useState<number>(0);
  const [amountInvoicesPaid, setAmountInvoicesPaid] = useState<number>(0);

  const [searchWithStartDateValue, setSetsearchWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [searchWithEndDateValue, setSetsearchWithEndDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);

  const handleChangeStartDateValue = (value: any) => {
    setSetsearchWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    setSetsearchWithEndDateValue(value.target.value);
  };

  const retriveInvoices = useCallback(async () => {
    const startTime = getTimestamp(searchWithStartDateValue, true);
    const endTime = getTimestamp(searchWithEndDateValue, false);
    if (startTime > endTime) {
      return toast.error(
        "La date de fin ne doit pas etre inférieur à la date de début."
      );
    }
    if (user) {
      const invoicesSnap = await db
        .collection(transactionCollectionName)
        .orderBy("createdAt", "desc")
        .where("createdAt", ">=", startTime)
        .where("createdAt", "<=", endTime)
        .where("senderID", "==", user.id)
        .where("transactionType", "==", TransactionType.ELECTRIC_BILL_POSTPAID)
        .limitToLast(pageLimit)
        .get();
      if (!invoicesSnap.empty) {
        const data = invoicesSnap.docs.map((d) => {
          const invData = d.data();
          const data = {
            id: d.id,
            transId: invData.snblTransId,
            periode: invData.snblInvoicePeriod,
            amount: invData.amount,
            fee: invData.fees + " FCFA",
            meterNum: invData.receiverPhoneNumber,
            customerName: invData.receiverDisplayName,
            receiverPhoneNumber: invData.receiverPhoneNumber,
            createdAt: d.data()?.createdAt,
            senderPhoneNumber: invData.senderPhoneNumber,
            senderDisplayName: invData.senderDisplayName,
            metterCustomerName: invData.metterCustomerName,
            numExp: invData.snblElectricMeterExplNumber,
          };
          return { id: d.id, data };
        });
        let totalAmount: number = 0;
        data.reduce(
          (acc: number, invoice: any) =>
            (totalAmount = acc + parseInt(invoice.data?.amount)),
          0
        );
        setAmountInvoicesPaid(totalAmount);
        setInvoicesPaid(data.length);
        setInvoices(data);
      } else {
        setInvoices([]);
      }
    }
  }, [pageLimit, searchWithEndDateValue, searchWithStartDateValue, user]);

  const retrieveSecifiqueInvoices = useCallback(
    (numPolicy: string) => {
      if (user) {
        db.collection(transactionCollectionName)
          .where("receiverPhoneNumber", "==", numPolicy)
          .where("senderID", "==", user.id)
          .where(
            "transactionType",
            "==",
            TransactionType.ELECTRIC_BILL_POSTPAID
          )
          .get()
          .then((snap) => {
            if (!snap.empty) {
              const data = snap.docs.map((d) => {
                const invData = d.data();
                const data = {
                  id: d.id,
                  transId: invData.snblTransId,
                  periode: invData.snblInvoicePeriod,
                  amount: invData.amount,
                  fee: invData.fees + " FCFA",
                  meterNum: invData.receiverPhoneNumber,
                  customerName: invData.receiverDisplayName,
                  receiverPhoneNumber: invData.receiverPhoneNumber,
                  createdAt: d.data()?.createdAt,
                  senderPhoneNumber: invData.senderPhoneNumber,
                  senderDisplayName: invData.senderDisplayName,
                  numExp: invData.snblElectricMeterExplNumber,
                };
                return { id: d.id, data };
              });
              setInvoices(data);
            } else {
              setInvoices([]);
            }
          });
      }
    },
    [user]
  );

  const downloadImage = (customerName: string) => {
    const fileName = `${customerName.split(" ").join("_")}.jpeg`;
    const node = document.getElementById("download-invoice");
    htmlToImage
      .toJpeg(node as HTMLElement, { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = fileName;
        link.href = dataUrl;
        link.click();
      });
  };

  useEffect(() => {
    retriveInvoices();
  }, [retriveInvoices]);

  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              Gestions des factures postpayé.
            </h1>
          </div>
          <div className="h-4" />
          <DashboardComponent
            retrieveInvoicesPaid={invoicesPaid}
            retrieveAmountInvoices={amountInvoicesPaid}
          />
          <div className="h-8" />
          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="flex flex-col items-end justify-between py-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
              <div className="w-full md:w-1/2">
                <form className="flex items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    rechercher par telephone
                  </label>
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      onChange={(e) => {
                        setNumPolicy(e.target.value);
                        retrieveSecifiqueInvoices(e.target.value);
                      }}
                      type="text"
                      id="simple-search"
                      className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                      placeholder="Numéro police"
                    />
                  </div>
                </form>
              </div>
              <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                <button
                  type="button"
                  className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-700"
                  onClick={() => retriveInvoices()}
                >
                  <ArrowPathIcon
                    className="self-center flex-shrink-0 w-5 h-5 mr-1"
                    aria-hidden="true"
                  />
                  Refresh
                </button>
                <div className="flex justify-between md:space-x-3 md:w-auto">
                  <input
                    type="date"
                    id="dateDebut"
                    className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                    onChange={handleChangeStartDateValue}
                  />
                  <input
                    type="date"
                    id="dateDebut"
                    className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                    onChange={handleChangeEndDtaeValue}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-8 overflow-x-auto">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm">
                <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      {tableHeader.map((th, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                        >
                          {th}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {invoices && invoices.length > 0 ? (
                      invoices.map((invoice: any, invoiceIdx: number) => (
                        <tr key={invoiceIdx}>
                          <td
                            className={classNames(
                              invoiceIdx !== invoices.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(invoiceIdx as number) + 1}
                          </td>
                          <td
                            className={classNames(
                              invoiceIdx !== invoices.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {parseDate(invoice.data?.createdAt as Timestamp)}
                          </td>
                          <td
                            className={classNames(
                              invoiceIdx !== invoices.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {invoice.data.meterNum
                              ? invoice.data.meterNum
                              : "-"}
                          </td>
                          <td
                            className={classNames(
                              invoiceIdx !== invoices.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {invoice.data.customerName
                              ? invoice.data.customerName
                              : "-"}
                          </td>
                          <td
                            className={classNames(
                              invoiceIdx !== invoices.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {invoice.data.amount
                              ? `${amountFormater(
                                  parseFloat(invoice.data.amount)
                                )} FCFA`
                              : "-"}
                          </td>
                          <td
                            className={classNames(
                              invoiceIdx !== invoices.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                            )}
                          >
                            <TableRowEditMenu
                              rowDetailsHandler={() => {
                                setInvoice(invoice.data);
                                setShowDetailsModal(true);
                              }}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoContent />
                    )}
                  </tbody>
                </table>
                {invoices && invoices.length > 0 && (
                  <Pagination
                    skip={invoices.length}
                    take={invoices.length}
                    total={invoices.length}
                    collectionName={transactionCollectionName}
                    onPageChange={(pageIndex: number) => pageIndex}
                    onPageLimitChange={(pageLimit: number) =>
                      setPageLimit(pageLimit)
                    }
                    isChangedPage={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showDetailsModal}
        maxSize="md"
        closeModal={() => setShowDetailsModal(false)}
      >
        {invoice && (
          <div className="flex justify-center">
            <div className="w-full">
              <div className="m-3 sm:max-w-lg sm:w-full sm:mx-auto">
                <div
                  id="download-invoice"
                  className="relative flex flex-col bg-white shadow-lg pointer-events-auto rounded-xl dark:bg-gray-800"
                >
                  <div className="p-4 overflow-y-auto sm:p-7">
                    <div className="text-center">
                      <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                        Facture Sonabel
                      </h3>
                      <p className="text-sm text-gray-500">
                        Facture #
                        <span className="font-bold">{invoice.transId}</span>
                      </p>
                    </div>

                    <div className="grid grid-cols-2 gap-5 mt-5 sm:mt-10 sm:grid-cols-2">
                      <div>
                        <span className="block text-xs text-gray-500 uppercase">
                          N° du compteur:
                        </span>
                        <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                          {invoice.receiverPhoneNumber}
                        </span>
                      </div>

                      <div>
                        <span className="block text-xs text-gray-500 uppercase">
                          N° d'exploitation:
                        </span>
                        <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                          {invoice.numExp}
                        </span>
                      </div>
                    </div>

                    <div className="mt-5 sm:mt-10">
                      <ul className="flex flex-col mt-3">
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Date de paiement</span>
                            <span>
                              {parseDate(invoice.createdAt as Timestamp)}
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Transaction ID:</span>
                            <span>{invoice.id}</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Période</span>
                            <span>{invoice.periode}</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Nom du client:</span>
                            <span>{invoice.metterCustomerName}</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Nom du Emmeteur</span>
                            <span>{invoice.senderDisplayName}</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>N° du Emmeteur</span>
                            <span>{invoice.senderPhoneNumber}</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Frais</span>
                            <span>{invoice.fee}</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Montant total</span>
                            <span>
                              {amountFormater(parseFloat(invoice.amount))}F
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-5 gap-x-2">
                  <button
                    className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-red-600 border border-transparent rounded-lg gap-x-2 hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    onClick={() => downloadImage(invoice.metterCustomerName)}
                  >
                    <ArrowDownTrayIcon
                      className="self-center flex-shrink-0 w-6 h-6 mr-1 text-white"
                      aria-hidden="true"
                    />
                    Télécharger
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default AgentPaidSnblPostPaidHistories;
