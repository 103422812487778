/* eslint-disable jsx-a11y/no-redundant-roles */
import {
  mapUserAvailableZone,
  userAvailableZonesOptions,
  UserInterface,
  UserRole,
} from "../../../interfaces";
import { useContext, useEffect, useState } from "react";
import {
  Form,
  FormInput,
  FormSelect,
} from "../../../components/FormComponents";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { UseFormReturn } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { db, functions } from "../../../firebase";
import {
  AtSymbolIcon,
  CheckIcon,
  PhotoIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useConfirm } from "../../../hooks/useConfirm";
import {
  classNames,
  getDateTimeWithTimestamp,
  getTimestamp,
} from "../../../utils";
import FormCheckToggle from "../../../components/FormComponents/FormCheckToggle";
import { storage } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  generatedCodeAgentCollectionsName,
  usersCollectionName,
} from "../../../config";
import { EditFormFooter } from "../../../components/EditFormFooter";
import { UserContext } from "../../../contexts";
import moment from "moment";

const collectionName = usersCollectionName;
const mapsGender = new Map<string, string>([
  ["MALE", "Homme"],
  ["FEMALE", "Femme"],
  ["NONBINARY", "Non binaire"],
  ["UNKNOWN", "Inconnu"],
]);
type Certification = Pick<UserInterface, "cnibImageUrl" | "isCertify">;
type AdditionnalInfos = Pick<
  UserInterface,
  | "displayName"
  | "email"
  | "active"
  | "agence"
  | "city"
  | "adresse"
  | "cnibNumber"
  | "createdAt"
  | "gender"
  | "lastName"
  | "firstName"
  | "phoneNumber"
  | "profession"
  | "availableZone"
  | "userRole"
  | "documentIssueDate"
  | "dayOfBirth"
  | "expirationDate"
  | "updatedAt"
>;
type Step = {
  id: "additionnalInfo" | "location" | "certification";
  index: number;
  name: string;
  description: string;
  icon: any;
};
const steps: Step[] = [
  {
    id: "additionnalInfo",
    index: 0,
    name: "Informations additionnelles",
    description: "Renseigner les informations additionnelles de l'utilisateur",
    icon: AtSymbolIcon,
  },
  {
    id: "location",
    index: 1,
    name: "Informations additionnelles",
    description: "Renseigner les informations additionnelles de l'utilisateur",
    icon: AtSymbolIcon,
  },
  {
    id: "certification",
    index: 2,
    name: "Certifier le compte",
    description: "Certifier le compte de l'utilisateur",
    icon: CheckIcon,
  },
];
const EditUserPage = () => {
  const userRole = [
    UserRole.ADMIN,
    UserRole.DEALER,
    UserRole.MARCHAND,
    UserRole.AGENT,
    UserRole.AGENCY,
    UserRole.GASSTATION,
    UserRole.USER,
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = location.state?.entity;
  const onCertify = location.state?.onCertify;
  const { user } = useContext(UserContext);
  const { isConfirmModal } = useConfirm();
  const [currentFormStep, setCurrentFormStep] = useState<Step>(steps[1]);
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageUrlVerso, setImageUrlVerso] = useState<string>();

  const generatedCodeAgentRef = db.collection(
    generatedCodeAgentCollectionsName
  );

  const [additionnalInfosFormReturn, setAdditionnalInfosFormReturn] =
    useState<UseFormReturn<AdditionnalInfos>>();

  const [locationInfosFormReturn, setLocationInfosFormReturn] =
    useState<UseFormReturn<AdditionnalInfos>>();

  const [agencyOrGasStationRole, setAgencyOrGasStationRole] =
    useState<UserRole>();

  const [certificationFormReturn, setCertificationFormReturn] =
    useState<UseFormReturn<Certification>>();

  const [certificationFormSubmitButton, setCertificationFormSubmitButton] =
    useState<HTMLButtonElement | null>(null);

  const [
    additionnalInfosFormSubmitButton,
    setAdditionnalInfosFormSubmitButton,
  ] = useState<HTMLButtonElement | null>(null);

  const [locationInfosFormSubmitButton, setLocationInfosFormSubmitButton] =
    useState<HTMLButtonElement | null>(null);

  const [selectedOption, setSelectedOption] = useState(
    currentUser ? currentUser?.documentType : "CNIB"
  );

  const onSelectedStepChanged = async (step: Step) => {
    let canSwitchTab = true;
    switch (currentFormStep.index) {
      case 0:
        if (additionnalInfosFormReturn?.formState.isValid) {
          if (additionnalInfosFormReturn.formState.isDirty) {
            const isConfirm = await isConfirmModal(
              "Vous avez des modifications non enregistrées.",
              "Voulez vous les sauvegarder avant de quitter cet écran?",
              "Sauvegarder mes modifications",
              false,
              false,
              "Annuler" as any
            );
            if (isConfirm) {
              await additionnalInfosFormReturn.trigger();
              additionnalInfosFormSubmitButton &&
                additionnalInfosFormSubmitButton.click();
            }
          }
        } else {
          canSwitchTab = false;
          additionnalInfosFormReturn?.trigger();
        }
        break;
      case 1:
        if (locationInfosFormReturn?.formState.isValid) {
          if (locationInfosFormReturn.formState.isDirty) {
            const isConfirm = await isConfirmModal(
              "Vous avez des modifications non enregistrées.",
              "Voulez vous les sauvegarder avant de quitter cet écran?",
              "Sauvegarder mes modifications",
              false,
              false,
              "Annuler" as any
            );
            if (isConfirm) {
              await locationInfosFormReturn.trigger();
              locationInfosFormSubmitButton &&
                locationInfosFormSubmitButton.click();
            }
          }
        } else {
          canSwitchTab = false;
          locationInfosFormReturn?.trigger();
        }
        break;
      case 2:
        if (certificationFormReturn?.formState.isValid) {
          if (certificationFormReturn.formState.isDirty) {
            const isConfirm = await isConfirmModal(
              "Vous avez des modifications non enregistrées.",
              "Voulez vous les sauvegarder avant de quitter cet écran?",
              "Sauvegarder mes modifications",
              false,
              false,
              "Annuler" as any
            );
            if (isConfirm) {
              await certificationFormReturn.trigger();
              certificationFormSubmitButton &&
                certificationFormSubmitButton.click();
            }
          }
        } else {
          canSwitchTab = false;
          certificationFormReturn?.trigger();
        }
        break;
      default:
        break;
    }
    if (canSwitchTab) {
      setCurrentFormStep(step);
    }
  };

  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );

  const onSubmitAdditionnalInfo = async (data: any) => {
    if (
      data.userRole === UserRole.AGENCY ||
      data.userRole === UserRole.GASSTATION
    )
      setAgencyOrGasStationRole(data.userRole);
    if (currentUser) {
      const dataValue = {
        id: currentUser.id,
        ...data,
        displayName: data?.firstName + " " + data?.lastName,
        dayOfBirth: dayOfBirth,
        documentIssueDate: documentIssueDate,
        expirationDate: expirationDate,
        documentType: selectedOption,
      };
      if (data.userRole === UserRole.AGENT && !currentUser.agentCode) {
        const generatedAgentCodeSnap = await generatedCodeAgentRef
          .where("isAffected", "==", false)
          .get();
        if (generatedAgentCodeSnap.empty) {
          toast.error(
            "Vous êtes à cours de qr code. Veillez generer de nouveaux code agent puis reessayer !"
          );
          navigate("/users");
          throw new Error(
            "Vous êtes à cours de qr code. Veillez generer de nouveaux code agent puis reessayer !"
          );
        }
        const agentCode = generatedAgentCodeSnap.docs[0].id;
        dataValue.agentCode = agentCode;
        await generatedCodeAgentRef
          .doc(generatedAgentCodeSnap.docs[0].id)
          .delete();
      }
      const response = sankmoneyUpdateAccountFn({
        collectionName: collectionName,
        documentData: dataValue,
        documentId: currentUser.id,
      })
        .then((result) => {
          if (
            data.userRole === UserRole.AGENCY ||
            data.userRole === UserRole.GASSTATION
          ) {
            currentUser && setCurrentFormStep(steps[1]);
          } else {
            setAgencyOrGasStationRole(undefined);
            navigate("/users");
          }
        })
        .catch((err: { details: any; message: any }) =>
          toast.error(err.details.message)
        );
      toast.promise(response, {
        error: "La mise à jour a échoué",
        success: "L'utilisateur a été mise jour avec succès",
        loading: "chargement...",
      });
    } else
      toast.error(
        `L'indentifiant de l'utilisateur est incorrect veuiller réessayer de nouveau !`
      );
  };
  const onCertifyUser = (data: any) => {
    if (currentUser) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: collectionName,
        documentData: {
          isCertify: data.isCertify,
          cnibImageUrl: imageUrl ? imageUrl : currentUser?.cnibImageUrl,
          documentVerso: imageUrlVerso
            ? imageUrlVerso
            : currentUser?.cnibImageUrlVerso,
        },
        documentType: selectedOption,
        documentId: currentUser.id,
      }).then((result) => {
        navigate("/users");
      });
      toast.promise(response, {
        error: "La certificationƒ a échoué",
        success: "L'utilisateur a été certifier avec succès",
        loading: "chargement...",
      });
    } else
      toast.error(
        `L'indentifiant de l'agent est incorrect veuiller réessayer de nouveau !`
      );
  };

  const handlechange = (value: any) => {
    const image = value.target.files[0];
    if (image && currentUser) {
      const imageRef = ref(
        storage,
        `users/${currentUser?.id}/cnibImage/${image.lastModified}`
      );
      uploadBytes(imageRef, image)
        .then(() => {
          getDownloadURL(imageRef)
            .then((url) => {
              setImageUrl(url);
            })
            .catch((error) =>
              toast.error(
                `Une erreur s'est produit lors du chargement de l'url du fichier ${error.message}`
              )
            );
          setImageUrl("");
        })
        .catch((error) =>
          toast.error(
            `Une erreur s'est produit lors de l'upload du fichier ${error.message}`
          )
        );
    }
  };

  const handlechangeVerso = (value: any) => {
    const image = value.target.files[0];
    if (image && currentUser) {
      const imageRef = ref(
        storage,
        `users/${currentUser?.id}/cnibImage/${image.lastModified}}`
      );
      uploadBytes(imageRef, image)
        .then(() => {
          getDownloadURL(imageRef)
            .then((url) => {
              setImageUrlVerso(url);
            })
            .catch((error) =>
              toast.error(
                `Une erreur s'est produit lors du chargement de l'url du fichier ${error.message}`
              )
            );
          setImageUrlVerso("");
        })
        .catch((error) =>
          toast.error(
            `Une erreur s'est produit lors de l'upload du fichier ${error.message}`
          )
        );
    }
  };

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const [expirationDate, setExpirationDate] = useState<any>(
    currentUser && currentUser?.expirationDate
      ? currentUser?.expirationDate
      : "Non_defini"
  );

  const [dayOfBirth, setDayOfBirth] = useState<any>(
    currentUser && currentUser?.dayOfBirth
      ? currentUser?.dayOfBirth
      : "Non_defini"
  );
  const [documentIssueDate, setDocumentIssueDate] = useState<any>(
    currentUser && currentUser?.documentIssueDate
      ? currentUser?.documentIssueDate
      : "Non_defini"
  );

  const handleDayOfBirth = (value: any) => {
    const dayOfBirth = getTimestamp(value.target.value, true);
    setDayOfBirth(dayOfBirth);
  };
  const handleEndDate = (value: any) => {
    const expirationDate = getTimestamp(value.target.value, true);
    setExpirationDate(expirationDate);
  };
  const handleDocumentIssueDate = (value: any) => {
    const documentIssueDate = getTimestamp(value.target.value, true);
    setDocumentIssueDate(documentIssueDate);
  };

  useEffect(() => {
    currentUser && setCurrentFormStep(steps[0]);
    currentUser && onCertify && setCurrentFormStep(steps[2]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="lg:border-t lg:border-b lg:border-gray-200">
        <nav
          className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
          aria-label="Progress"
        >
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
          >
            <li className="relative px-2 py-4 overflow-hidden lg:py-2">
              <button
                className="p-1 border border-transparent rounded-full hover:border-gray-200 hover:bg-gray-50"
                onClick={() => navigate("/users")}
              >
                <XMarkIcon className="w-8 h-8 text-gray-700" />
              </button>
            </li>
            {steps.map((step, stepIdx) => {
              if (!agencyOrGasStationRole) {
                if (step.index !== 1) {
                  return (
                    <li
                      key={stepIdx}
                      className="relative overflow-hidden lg:flex-1"
                    >
                      <div
                        className={classNames(
                          stepIdx === 0 ? "border-b-0 rounded-t-md" : "",
                          stepIdx === steps.length - 1
                            ? "border-t-0 rounded-b-md"
                            : "",
                          "border border-gray-200 overflow-hidden lg:border-0 h-full"
                        )}
                      >
                        <button
                          onClick={(evt) =>
                            currentUser ? onSelectedStepChanged(step) : void 0
                          }
                          className={classNames(
                            !currentUser ? "cursor-not-allowed" : "",
                            currentUser && step.index === 2 ? "" : "",
                            "w-full group h-full"
                          )}
                        >
                          <span
                            className={classNames(
                              step.index !== currentFormStep.index
                                ? "group-hover:bg-gray-200 bg-transparent "
                                : "bg-red-600",
                              "absolute top-0 left-0 w-1 h-full  lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              stepIdx !== 0 ? "lg:pl-9" : "",
                              "px-4 py-4 lg:py-2 flex items-start text-sm font-medium"
                            )}
                          >
                            <span className="flex-shrink-0">
                              <span
                                className={classNames(
                                  step.index < currentFormStep.index
                                    ? "bg-red-600"
                                    : "",
                                  step.index === currentFormStep.index
                                    ? "bg-white border-2 border-red-600"
                                    : "",
                                  step.index > currentFormStep.index
                                    ? "bg-white border-2 border-gray-300"
                                    : "",
                                  "flex items-center justify-center w-10 h-10  rounded-full"
                                )}
                              >
                                <step.icon
                                  className={classNames(
                                    step.index < currentFormStep.index
                                      ? "text-white"
                                      : "",
                                    step.index === currentFormStep.index
                                      ? "text-red-600"
                                      : "",
                                    step.index > currentFormStep.index
                                      ? "text-gray-500"
                                      : "",
                                    "w-6 h-6 "
                                  )}
                                  aria-hidden="true"
                                />
                              </span>
                            </span>
                            <span className="mt-0.5 ml-4 min-w-0 flex-col flex  xl:flex">
                              <span
                                className={classNames(
                                  step.index === currentFormStep.index
                                    ? "text-red-600"
                                    : "",
                                  step.index > currentFormStep.index
                                    ? "text-gray-500"
                                    : "",
                                  "text-xs font-semibold tracking-wide uppercase md:block lg:hidden xl:block"
                                )}
                              >
                                {step.name}
                              </span>
                              <span className="flex items-start text-sm font-medium text-gray-500">
                                {step.description}
                              </span>
                            </span>
                          </span>
                        </button>
                        <div
                          className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                          aria-hidden="true"
                        >
                          <svg
                            className="w-full h-full text-gray-300"
                            viewBox="0 0 12 82"
                            fill="none"
                            preserveAspectRatio="none"
                          >
                            <path
                              d="M0.5 0V31L10.5 41L0.5 51V82"
                              stroke="currentcolor"
                              vectorEffect="non-scaling-stroke"
                            />
                          </svg>
                        </div>
                      </div>
                    </li>
                  );
                }
              } else {
                return (
                  <li
                    key={stepIdx}
                    className="relative overflow-hidden lg:flex-1"
                  >
                    <div
                      className={classNames(
                        stepIdx === 0 ? "border-b-0 rounded-t-md" : "",
                        stepIdx === steps.length - 1
                          ? "border-t-0 rounded-b-md"
                          : "",
                        "border border-gray-200 overflow-hidden lg:border-0 h-full"
                      )}
                    >
                      <button
                        onClick={(evt) =>
                          currentUser ? onSelectedStepChanged(step) : void 0
                        }
                        className={classNames(
                          !currentUser ? "cursor-not-allowed" : "",
                          currentUser && step.index === 2 ? "" : "",
                          "w-full group h-full"
                        )}
                      >
                        <span
                          className={classNames(
                            step.index !== currentFormStep.index
                              ? "group-hover:bg-gray-200 bg-transparent "
                              : "bg-red-600",
                            "absolute top-0 left-0 w-1 h-full  lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                          )}
                          aria-hidden="true"
                        />
                        <span
                          className={classNames(
                            stepIdx !== 0 ? "lg:pl-9" : "",
                            "px-4 py-4 lg:py-2 flex items-start text-sm font-medium"
                          )}
                        >
                          <span className="flex-shrink-0">
                            <span
                              className={classNames(
                                step.index < currentFormStep.index
                                  ? "bg-red-600"
                                  : "",
                                step.index === currentFormStep.index
                                  ? "bg-white border-2 border-red-600"
                                  : "",
                                step.index > currentFormStep.index
                                  ? "bg-white border-2 border-gray-300"
                                  : "",
                                "flex items-center justify-center w-10 h-10  rounded-full"
                              )}
                            >
                              <step.icon
                                className={classNames(
                                  step.index < currentFormStep.index
                                    ? "text-white"
                                    : "",
                                  step.index === currentFormStep.index
                                    ? "text-red-600"
                                    : "",
                                  step.index > currentFormStep.index
                                    ? "text-gray-500"
                                    : "",
                                  "w-6 h-6 "
                                )}
                                aria-hidden="true"
                              />
                            </span>
                          </span>
                          <span className="mt-0.5 ml-4 min-w-0 flex-col flex  xl:flex">
                            <span
                              className={classNames(
                                step.index === currentFormStep.index
                                  ? "text-red-600"
                                  : "",
                                step.index > currentFormStep.index
                                  ? "text-gray-500"
                                  : "",
                                "text-xs font-semibold tracking-wide uppercase md:block lg:hidden xl:block"
                              )}
                            >
                              {step.name}
                            </span>
                            <span className="flex items-start text-sm font-medium text-gray-500">
                              {step.description}
                            </span>
                          </span>
                        </span>
                      </button>
                      <div
                        className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                        aria-hidden="true"
                      >
                        <svg
                          className="w-full h-full text-gray-300"
                          viewBox="0 0 12 82"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M0.5 0V31L10.5 41L0.5 51V82"
                            stroke="currentcolor"
                            vectorEffect="non-scaling-stroke"
                          />
                        </svg>
                      </div>
                    </div>
                  </li>
                );
              }
            })}
          </ol>
        </nav>
      </div>
      <div className="items-center w-full mx-auto md:w-11/12 lg:w-9/12">
        {currentFormStep.id === "additionnalInfo" && (
          <>
            <Form<AdditionnalInfos>
              onSubmit={onSubmitAdditionnalInfo}
              formReturnAware={setAdditionnalInfosFormReturn}
              useCustomFooter
              form={{
                mode: "onChange",
                resolver: yupResolver(
                  yup.object().shape({
                    displayName: yup
                      .string()
                      .required("Le champs est obligatoire"),
                    availableZone: yup.string().optional(),
                    email: yup.string().optional(),
                    city: yup.string().optional(),
                    profession: yup.string().optional(),
                    adresse: yup.string().optional(),
                    cnibNumber: yup.string().optional(),
                    phoneNumber: yup.string().optional(),
                    dayOfBirth: yup.string().optional(),
                    documentIssueDate: yup.string().optional(),
                    expirationDate: yup.string().optional(),
                    lastName: yup.string().optional(),
                    firstName: yup.string().optional(),
                  })
                ),
                defaultValues: {
                  displayName: currentUser
                    ? currentUser?.firstName + " " + currentUser?.lastName
                    : "",
                  city: currentUser ? currentUser.city : "",
                  adresse: currentUser ? currentUser.adresse : "",
                  profession: currentUser ? currentUser.profession : "",
                  phoneNumber: currentUser ? currentUser.phoneNumber : "",
                  cnibNumber: currentUser ? currentUser.cnibNumber : "",
                  email: currentUser ? currentUser.email : "",
                  lastName: currentUser?.lastName
                    ? currentUser?.lastName
                    : currentUser?.displayName
                    ? (currentUser?.displayName as string).split(" ")[0]
                    : "-",

                  firstName: currentUser?.firstName
                    ? currentUser?.firstName
                    : currentUser?.displayName
                    ? (currentUser?.displayName as string).split(" ")[1]
                    : "-",

                  dayOfBirth:
                    currentUser && currentUser?.dayOfBirth // moment().format("YYYY-MM-DD")
                      ? moment(
                          getDateTimeWithTimestamp(currentUser?.dayOfBirth)
                        ).format("YYYY-MM-DD")
                      : "Non_defini",
                  documentIssueDate:
                    currentUser && currentUser?.documentIssueDate
                      ? moment(
                          getDateTimeWithTimestamp(
                            currentUser?.documentIssueDate
                          )
                        ).format("YYYY-MM-DD")
                      : "Non_defini",
                  expirationDate:
                    currentUser && currentUser?.expirationDate
                      ? moment(
                          getDateTimeWithTimestamp(currentUser?.expirationDate)
                        ).format("YYYY-MM-DD")
                      : "Non_defini",
                },
              }}
              submitButtonLabel={`${
                currentUser ? "Ajouter l'" : "Editer l'"
              }utilisateur`}
            >
              <div className="flex flex-col content-center w-full px-5 mt-5 align-top md:flex-row">
                <div className="w-full mx-auto md:w-1/2">
                  <FormInput
                    name="lastName"
                    label="Nom"
                    placeholder=""
                    disabled={
                      !(
                        user?.userRole === UserRole.ADMIN ||
                        user?.userRole === UserRole.TECHNICALSUPPORT
                      )
                        ? true
                        : false
                    }
                  />
                  <FormInput
                    name="displayName"
                    label="Nom/Prénom"
                    placeholder="John Doe"
                    disabled={
                      !(
                        user?.userRole === UserRole.ADMIN ||
                        user?.userRole === UserRole.TECHNICALSUPPORT
                      )
                        ? true
                        : false
                    }
                  />
                  <FormInput
                    name="city"
                    label="Ville"
                    optional
                    placeholder="Ouagadougou"
                  />
                  <FormInput
                    name="adresse"
                    optional
                    label="Adresse"
                    placeholder="Rue 10.34, secteur 30, Ouaga2000"
                  />
                  <FormInput
                    optional
                    name="email"
                    label="E-mail"
                    placeholder="@exemple.com"
                  />
                  <FormInput
                    name="profession"
                    label="Profession"
                    optional
                    placeholder="Agent"
                  />
                  <FormInput
                    type="date"
                    label="Date de naissance"
                    name="dayOfBirth"
                    onChange={handleDayOfBirth}
                  />
                  <FormInput
                    type="date"
                    label="Date de delivrance du document"
                    name="documentIssueDate"
                    onChange={handleDocumentIssueDate}
                  />
                  {(user?.userRole === UserRole.ADMIN ||
                    user?.userRole === UserRole.TECHNICALSUPPORT) && (
                    <div className="flex items-center justify-center p-4">
                      <div className="mx-auto w-full max-w-[550px] bg-slate-200">
                        <div className="">
                          {currentUser && currentUser?.cnibImageUrl ? (
                            <img
                              className="object-cover object-center mx-auto"
                              src={currentUser?.cnibImageUrl}
                              alt={currentUser?.cnibImageUrl}
                            />
                          ) : (
                            <span className="inline-flex rounded border border-[#e0e0e0] cursor-pointer py-2 px-7 text-base font-medium text-[#07074D]">
                              <PhotoIcon
                                className="self-center flex-shrink-0 w-10 h-10 mr-1 text-gray-800"
                                aria-hidden="true"
                              />
                              Aucune image
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* {
                  (user?.userRole === UserRole.ADMIN || user?.userRole === UserRole.TECHNICALSUPPORT) && (
                    <div className="flex items-center justify-center p-4">
                      <div className="mx-auto w-full max-w-[550px] bg-slate-200">
                        <div className="">
                          {(currentUser && currentUser?.cnibImageUrl) ? (
                            <img
                              className="object-cover object-center mx-auto"
                              src={currentUser?.cnibImageUrl}
                              alt={currentUser?.cnibImageUrl}
                            />
                          ) : (
                            <span className="inline-flex rounded border border-[#e0e0e0] cursor-pointer py-2 px-7 text-base font-medium text-[#07074D]">
                              <PhotoIcon
                                className="self-center flex-shrink-0 w-10 h-10 mr-1 text-gray-800"
                                aria-hidden="true"
                              />
                              Aucune image
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                } */}
                </div>
                <div className="w-10 h-5"></div>
                <div className="w-full mx-auto md:w-1/2">
                  <FormInput
                    name="firstName"
                    label="Prénom"
                    placeholder=""
                    disabled={
                      !(
                        user?.userRole === UserRole.ADMIN ||
                        user?.userRole === UserRole.TECHNICALSUPPORT
                      )
                        ? true
                        : false
                    }
                  />
                  <FormInput
                    name="phoneNumber"
                    label="Numéro de téléphone"
                    placeholder="7X XX XX XX"
                    disabled={currentUser ? true : false}
                  />
                  <FormInput
                    name="cnibNumber"
                    label={`Numéro de ${selectedOption}`}
                    placeholder="BXXXXXXX"
                    disabled={
                      user &&
                      (user?.userRole === UserRole.ADMIN ||
                        user?.userRole === UserRole.TECHNICALSUPPORT)
                        ? false
                        : true
                    }
                  />
                  {currentUser && (
                    <FormSelect<string>
                      options={userRole}
                      selectedOption={
                        currentUser ? currentUser.userRole : "Choisir..."
                      }
                      name="userRole"
                      label="Role"
                      disabled={
                        user && user?.userRole !== UserRole.ADMIN ? true : false
                      }
                    />
                  )}

                  <FormSelect<string>
                    label={"Zone"}
                    name="availableZone"
                    selectedOption={
                      currentUser ? currentUser.availableZone : "Choisir..."
                    }
                    options={userAvailableZonesOptions}
                    optionLabel={(option) =>
                      (mapUserAvailableZone.get(option) as string) ??
                      "Choisir..."
                    }
                  />

                  <FormSelect<string>
                    label={"Genre"}
                    name="gender"
                    selectedOption={
                      currentUser ? currentUser.gender : "Choisir..."
                    }
                    options={["FEMALE", "MALE", "NONBINARY", "UNKNOWN"]}
                    optionLabel={(option) =>
                      (mapsGender.get(option) as string) ?? "Choisir..."
                    }
                  />
                  <FormInput
                    type="date"
                    label="Date d'expiration"
                    name="expirationDate"
                    onChange={handleEndDate}
                  />
                  <div className="flex text-gray-700">
                    <nav className="grid grid-cols-4 gap-4 p-2 font-sans text-base font-normal md:grid-cols-2 text-blue-gray-700">
                      <div
                        role="button"
                        className="flex items-center w-full p-0 leading-tight transition-all rounded-lg outline-none text-start hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
                      >
                        <label
                          htmlFor="horizontal-list-react"
                          className="flex items-center w-full px-3 py-2 cursor-pointer"
                        >
                          <div className="grid mr-3 place-items-center">
                            <div className="inline-flex items-center">
                              <label
                                className="relative flex items-center p-0 rounded-full cursor-pointer"
                                htmlFor="horizontal-list-react"
                              >
                                <input
                                  name="horizontal-list"
                                  id="horizontal-list-react"
                                  type="radio"
                                  value="CNIB"
                                  checked={selectedOption === "CNIB"}
                                  onChange={handleOptionChange}
                                  className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-blue-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                                />
                                <span className="absolute text-blue-500 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-3.5 w-3.5"
                                    viewBox="0 0 16 16"
                                    fill="currentColor"
                                  >
                                    <circle
                                      data-name="ellipse"
                                      cx="8"
                                      cy="8"
                                      r="8"
                                    ></circle>
                                  </svg>
                                </span>
                              </label>
                            </div>
                          </div>
                          <p className="block font-sans text-base antialiased font-medium leading-relaxed text-blue-gray-400">
                            CNIB
                          </p>
                        </label>
                      </div>
                      <div
                        role="button"
                        className="flex items-center w-full p-0 leading-tight transition-all rounded-lg outline-none text-start hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
                      >
                        <label
                          htmlFor="horizontal-list-vue"
                          className="flex items-center w-full px-3 py-2 cursor-pointer"
                        >
                          <div className="grid mr-3 place-items-center">
                            <div className="inline-flex items-center">
                              <label
                                className="relative flex items-center p-0 rounded-full cursor-pointer"
                                htmlFor="horizontal-list-vue"
                              >
                                <input
                                  name="horizontal-list"
                                  id="horizontal-list-vue"
                                  type="radio"
                                  value="PERMIS"
                                  checked={selectedOption === "PERMIS"}
                                  onChange={handleOptionChange}
                                  className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-blue-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                                />
                                <span className="absolute text-blue-500 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-3.5 w-3.5"
                                    viewBox="0 0 16 16"
                                    fill="currentColor"
                                  >
                                    <circle
                                      data-name="ellipse"
                                      cx="8"
                                      cy="8"
                                      r="8"
                                    ></circle>
                                  </svg>
                                </span>
                              </label>
                            </div>
                          </div>
                          <p className="block font-sans text-base antialiased font-medium leading-relaxed text-blue-gray-400">
                            PERMIS
                          </p>
                        </label>
                      </div>
                      <div
                        role="button"
                        className="flex items-center w-full p-0 leading-tight transition-all rounded-lg outline-none text-start hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
                      >
                        <label
                          htmlFor="horizontal-list-svelte"
                          className="flex items-center w-full px-3 py-2 cursor-pointer"
                        >
                          <div className="grid mr-3 place-items-center">
                            <div className="inline-flex items-center">
                              <label
                                className="relative flex items-center p-0 rounded-full cursor-pointer"
                                htmlFor="horizontal-list-svelte"
                              >
                                <input
                                  name="horizontal-list"
                                  id="horizontal-list-svelte"
                                  type="radio"
                                  value="PASSPORT"
                                  checked={selectedOption === "PASSPORT"}
                                  onChange={handleOptionChange}
                                  className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-blue-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                                />
                                <span className="absolute text-blue-500 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-3.5 w-3.5"
                                    viewBox="0 0 16 16"
                                    fill="currentColor"
                                  >
                                    <circle
                                      data-name="ellipse"
                                      cx="8"
                                      cy="8"
                                      r="8"
                                    ></circle>
                                  </svg>
                                </span>
                              </label>
                            </div>
                          </div>
                          <p className="block font-sans text-base antialiased font-medium leading-relaxed text-blue-gray-400">
                            PASSPORT
                          </p>
                        </label>
                      </div>
                      <div
                        role="button"
                        className="flex items-center w-full p-0 leading-tight transition-all rounded-lg outline-none text-start hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
                      >
                        <label
                          htmlFor="horizontal-list-react"
                          className="flex items-center w-full px-3 py-2 cursor-pointer"
                        >
                          <div className="grid mr-3 place-items-center">
                            <div className="inline-flex items-center">
                              <label
                                className="relative flex items-center p-0 rounded-full cursor-pointer"
                                htmlFor="horizontal-list-react"
                              >
                                <input
                                  name="horizontal-list"
                                  id="horizontal-list-react"
                                  type="radio"
                                  value="AUTRE"
                                  checked={selectedOption === "AUTRE"}
                                  onChange={handleOptionChange}
                                  className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-blue-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                                />
                                <span className="absolute text-blue-500 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-3.5 w-3.5"
                                    viewBox="0 0 16 16"
                                    fill="currentColor"
                                  >
                                    <circle
                                      data-name="ellipse"
                                      cx="8"
                                      cy="8"
                                      r="8"
                                    ></circle>
                                  </svg>
                                </span>
                              </label>
                            </div>
                          </div>
                          <p className="block font-sans text-base antialiased font-medium leading-relaxed text-blue-gray-400">
                            AUTRE
                          </p>
                        </label>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
              <EditFormFooter<AdditionnalInfos>
                currentStep={currentFormStep}
                hasNext={currentUser ? false : true}
                hasPrevious={currentUser ? false : true}
                steps={steps}
                onStepChanged={onSelectedStepChanged}
                setSubmitButtonRef={setAdditionnalInfosFormSubmitButton}
                submitBtnLabel={
                  !currentUser ? "Créer un nouveau utilisateur" : "Enregistrer"
                }
                canSubmit
              />
            </Form>
          </>
        )}
        {currentFormStep.id === "certification" && (
          <Form<Certification>
            onSubmit={onCertifyUser}
            formReturnAware={setCertificationFormReturn}
            useCustomFooter
            form={{
              mode: "onChange",
              resolver: yupResolver(
                yup.object().shape({
                  isCertify: yup.boolean(),
                })
              ),
              defaultValues: {
                isCertify: currentUser ? currentUser.isCertify : false,
              },
            }}
            submitButtonLabel={"Certifier l'utilisateur"}
          >
            <div className="flex items-center justify-center p-12">
              <div className="mx-auto w-full max-w-[550px] bg-white">
                <div className="pt-4 mb-6">
                  <label className="mb-5 block text-xl font-semibold text-[#07074D]">
                    <FormCheckToggle
                      name="isCertify"
                      label="Certifier le compte"
                    />
                  </label>
                  <div className="mb-8">
                    <input
                      type="file"
                      id="file"
                      className="sr-only"
                      onChange={handlechange}
                    />
                    <label
                      htmlFor="file"
                      className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                    >
                      <div>
                        {currentUser.cnibImageUrl || imageUrl ? (
                          <img
                            className="object-cover object-center mx-auto"
                            src={imageUrl || currentUser.cnibImageUrl}
                            alt={imageUrl || currentUser.displayName}
                          />
                        ) : (
                          <span className="inline-flex rounded border border-[#e0e0e0] cursor-pointer py-2 px-7 text-base font-medium text-[#07074D]">
                            Choisir une image(Recto)
                          </span>
                        )}
                      </div>
                    </label>
                  </div>
                  <div className="mb-8">
                    <input
                      type="file"
                      id="files"
                      className="sr-only"
                      onChange={handlechangeVerso}
                    />
                    <label
                      htmlFor="files"
                      className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                    >
                      <div>
                        {currentUser?.documentVerso || imageUrlVerso ? (
                          <img
                            className="object-cover object-center mx-auto"
                            src={imageUrlVerso || currentUser.documentVerso}
                            alt={imageUrlVerso || currentUser.displayName}
                          />
                        ) : (
                          <span className="inline-flex rounded border border-[#e0e0e0] cursor-pointer py-2 px-7 text-base font-medium text-[#07074D]">
                            Choisir une image(Verso)
                          </span>
                        )}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <EditFormFooter<Certification>
              currentStep={currentFormStep}
              hasNext={currentUser ? false : true}
              hasPrevious={currentUser ? false : true}
              steps={steps}
              onStepChanged={onSelectedStepChanged}
              setSubmitButtonRef={setCertificationFormSubmitButton}
              submitBtnLabel={"Certifier"}
              canSubmit
            />
          </Form>
        )}
      </div>
    </>
  );
};

export default EditUserPage;
