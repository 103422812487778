import React, { useContext } from "react";
import DisplayItemDetails from "../../components/DisplayItemComponent";
import { useLocation } from "react-router-dom";
import UserDetailsPageComponent from "../../components/UserDetailsComponent";
import { UserRole } from "../../interfaces";
import { UserContext } from "../../contexts";

const AgentsDetailsPage = () => {
  const location = useLocation();
  const currentUser = location.state?.entity;
  const { user } = useContext(UserContext);

  return user?.userRole === UserRole.RESPONSABLEZONE ? (
    <UserDetailsPageComponent
      userDetailsData={currentUser}
      makeDealer={false}
    />
  ) : (
    <DisplayItemDetails currentUser={currentUser} />
  );
};

export default AgentsDetailsPage;
