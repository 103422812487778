/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../contexts";
import useHasRoles from "../hooks/useHasRoles";
import LoadingSkeleton from "./LoadingSkeleton";
import { UserRole } from "../interfaces";
export interface PrivateRouteProps {
  path: string;
  userRole?: (
    | "SUDO"
    | "ADMIN"
    | "MERCHANT"
    | "DEALER"
    | "USER"
    | "GASSTATION"
    | "AGENCY"
    | "EMPLOYMENT"
    | "AGENT"
    | "GASSTATIONSTAFF"
    | "AGENCYSTAFF"
    | "SUPERVISOR"
    | "SUPERMERCHANT"
    | "SUPERSTATION"
    | "COMPANY"
    | "COMPANYSTAFF"
    | "TECHNICALSUPPORT"
    | "COLLECTOR"
    | "COLLECTIONPOINT"
    | "RESPONSABLEZONE"
    | "SUPERDEALER"
    | "SUPERVISEURSUPERDEALER"
  )[];
  [x: string]: any;
  children: JSX.Element;
}
export default function AuthRoute(props: PrivateRouteProps): JSX.Element {
  const { user, isLoading, setIsLoading } = useContext(UserContext);
  const { isAllowed } = useHasRoles();
  const { path } = props;

  const hasRequiredRoles = isAllowed(props.userRole);
  if (isLoading) {
    if (!user && !hasRequiredRoles) {
      if (["/"].includes(path)) {
        return props.children;
      }
    }
    if (["/login-with-otp", "/login-with-email"].includes(path)) {
      setIsLoading(false);
    } else {
      return <LoadingSkeleton />;
    }
  }
  if (!user && !hasRequiredRoles) {
    if (["/", "/login-with-otp", "/login-with-email"].includes(path)) {
      return props.children;
    } else {
      return <Navigate to={{ pathname: "/login-with-otp" }} />;
    }
  }
  if (user && hasRequiredRoles) {
    if (["/login-with-otp", "/log in-with-email"].includes(path)) {
      return (
        <Navigate
          to={{
            pathname: `${
              user.userRole === UserRole.SUPERMERCHANT
                ? "/superMerchants/home"
                : user.userRole === UserRole.SUPERSTATION
                ? "/superStation/home"
                : user.userRole === UserRole.COLLECTOR
                ? "/collectors/home"
                : "/dashboard"
            }`,
          }}
        />
      );
    } else return props.children;
  }

  // not allowed
  if (!hasRequiredRoles) {
    setIsLoading(false);
    return (
      <Navigate
        to={{
          pathname:
            user && user.userRole === UserRole.SUPERMERCHANT
              ? "/superMerchants/home"
              : user && user.userRole === UserRole.SUPERSTATION
              ? "/superStation/home"
              : "/forbidden",
        }}
        state={{ title: "Tableau de bord" }}
      />
    );
  }
  //redirect if there is no user
  //toast.error('Please logic to access to this page');
  return (
    <Navigate
      to={`${
        user && user.userRole === UserRole.SUPERMERCHANT
          ? "/superMerchants/home"
          : user && user.userRole === UserRole.SUPERSTATION
          ? "/superStation/home"
          : "/dashboard"
      }`}
    />
  );
}
