import { BanknotesIcon, TicketIcon } from "@heroicons/react/24/outline";
import { useNavigate, Link, Outlet, useLocation } from "react-router-dom";
import { useRouter } from "../../../hooks/useRouter";
import { classNames } from "../../../utils";
import { useEffect } from "react";

const ActivityPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dealer = location.state?.entity;
  const router = useRouter();

  useEffect(() => {
    if (router.pathname.endsWith("/activity")) {
      navigate("stocks", {
        state: { entity: dealer },
        replace: true,
      });
    }
  }, [router.pathname, navigate, dealer]);

  const subNavigation = [
    {
      name: `Transactions Super-Dealer et Dealer`,
      to: "stocks",
      icon: BanknotesIcon,
    },
    {
      name: `Transactions`,
      to: "dealer_transactions",
      icon: TicketIcon,
    },
  ];

  return (
    <div className="divide-y divide-gray-200 dark:divide-gray-700 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
      <div className=" lg:col-span-12 xl:col-span-11 2xl:col-span-10">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select <menu></menu>
          </label>
          <select
            id="tabs"
            name="tabs"
            onChange={(e) =>
              navigate(e.target.value, { state: { entity: dealer } })
            }
            className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
            defaultValue={
              subNavigation.find((tab) => router.pathname.includes(`${tab.to}`))
                ?.name
            }
          >
            {subNavigation.map((tab) => (
              <option key={tab.name} value={tab.to}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden mb-2 sm:block">
          <div className="border-b border-gray-200">
            <nav className="flex -mb-px space-x-8" aria-label="Tabs">
              {subNavigation.map((tab) => (
                <Link
                  key={tab.name}
                  to={tab.to}
                  state={{ entity: dealer }}
                  className={classNames(
                    router.pathname.includes(`${tab.to}`)
                      ? "border-red-500 text-red-600 dark:border-red-800 dark:text-red-700"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:hover:text-gray-300",
                    "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium"
                  )}
                  aria-current={
                    router.pathname.includes(`${tab.to}`) ? "page" : undefined
                  }
                >
                  <tab.icon
                    className={classNames(
                      router.pathname.includes(`${tab.to}`)
                        ? "text-red-500 dark:text-red-600"
                        : "text-gray-400 group-hover:text-gray-500",
                      "-ml-0.5 mr-2 h-5 w-5 hidden xl:block"
                    )}
                    aria-hidden="true"
                  />
                  {tab.name}
                </Link>
              ))}
            </nav>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default ActivityPage;
