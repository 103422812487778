import { Timestamp } from "firebase-admin/firestore";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../../../components/FirestoreTable";
import { transactionMap, TransactionType } from "../../../../interfaces";
import { amountFormater, getTimestamp, parseDate } from "../../../../utils";
import moment from "moment";
import { UserContext } from "../../../../contexts";
import toast from "react-hot-toast";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  FunnelIcon,
} from "@heroicons/react/24/outline";
import DashboardComponent from "../../../../components/DashboardComponents/InvoiceManagmentComponent";
import Modal from "../../../../components/Modal";
import { transactionCollectionName } from "../../../../config";
import { db } from "../../../../firebase";
import * as htmlToImage from "html-to-image";

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "senderPhoneNumber",
    label: "Expéditeur",
  },
  {
    field: "oneaMeterNumber",
    label: "N abonne",
  },
  {
    field: "amount",
    label: "Montant",
  },
  {
    field: "penality",
    label: "Penalité",
  },
  // {
  //   field: "oneaInvoiceIds",
  //   label: "ID factures payé",
  // },
  {
    field: "transactionType",
    label: "Type d'opération",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return parseDate(value as Timestamp);
    case "oneaInvoicePeriodes":
      return value ?? "-";
    case "senderPhoneNumber":
      return <span>{value}</span>;
    case "oneaMeterNumber":
      return <span>{value}</span>;
    case "amount":
      return <span>{new Intl.NumberFormat().format(value)} Fcfa</span>;
    case "penality":
      return <span>{new Intl.NumberFormat().format(value ?? 0)} Fcfa</span>;
    case "transactionType":
      return <span>{transactionMap.get(value as string)} </span>;
    default:
      return value as string;
  }
};

const AgentPaidOneaInvoiceHistories = () => {
  const { user } = useContext(UserContext);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [metterNum, setMetterNum] = useState<string>();
  const [invoice, setInvoice] = useState<any>();
  const [invoicesPaid, setInvoicesPaid] = useState<number>(0);
  const [amountInvoicesPaid, setAmountInvoicesPaid] = useState<number>(0);
  const [searchWithStartDateValue, setSetsearchWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [searchWithEndDateValue, setSetsearchWithEndDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);

  const handleChangeStartDateValue = (value: any) => {
    setSetsearchWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    setSetsearchWithEndDateValue(value.target.value);
  };

  const [startTime, setStartTime] = useState<any>(undefined);
  const [endTime, setEndTime] = useState<any>(undefined);

  const getFilteredByDate = useCallback(async () => {
    if (searchWithStartDateValue) {
      setStartTime(getTimestamp(searchWithStartDateValue, true));
    }

    if (searchWithEndDateValue) {
      setEndTime(getTimestamp(searchWithEndDateValue, false));
    }
  }, [searchWithStartDateValue, searchWithEndDateValue]);

  const onRowDetails = (id: any, entity: any) => {
    if (entity) {
      setInvoice(entity);
      setShowDetailsModal(true);
    }
  };

  const invoicesManagment = useCallback(async (data: any) => {
    let totalAmount: number = 0;
    data.reduce(
      (acc: number, invoice: any) =>
        (totalAmount = acc + parseInt(invoice?.amount)),
      0
    );
    setAmountInvoicesPaid(totalAmount);
    setInvoicesPaid(data.length);
  }, []);

  const firestoreTableQueriesWhere = useMemo(() => {
    let queries: {
      fieldPath: string;
      opStr: string;
      value: string | any;
    }[] = [];

    if (startTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: ">=",
        value: startTime,
      });
    }

    if (endTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: "<=",
        value: endTime,
      });
    }

    if (metterNum) {
      queries = [
        {
          fieldPath: "receiverPhoneNumber",
          opStr: "==",
          value: metterNum,
        },
      ];
    }

    return queries;
  }, [startTime, endTime, metterNum]);

  const downloadImage = (customerName: string) => {
    const fileName = `${customerName.split(" ").join("_")}.jpeg`;
    const node = document.getElementById("download-invoice");
    htmlToImage
      .toJpeg(node as HTMLElement, { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = fileName;
        link.href = dataUrl;
        link.click();
      });
  };

  useEffect(() => {
    getFilteredByDate();
  }, [getFilteredByDate]);

  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              L'historiques des factures Onea
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Vous trouverez ci-dessous tous l'historiques des factures Onea.
            </p>
          </div>
          <div className="h-4" />
          <DashboardComponent
            retrieveInvoicesPaid={invoicesPaid}
            retrieveAmountInvoices={amountInvoicesPaid}
          />
          <div className="h-6" />
          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="flex flex-col items-end justify-between py-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
              <div className="w-full md:w-1/2">
                <form className="flex items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    rechercher par telephone
                  </label>
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      onChange={(e) => {
                        setMetterNum(e.target.value);
                        // retrieveSecifiqueInvoices(e.target.value);
                      }}
                      type="text"
                      id="simple-search"
                      className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                      placeholder="N° Abonné"
                    />
                  </div>
                </form>
              </div>
              <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                <div className="flex justify-between md:space-x-3 md:w-auto">
                  <input
                    type="date"
                    id="dateDebut"
                    className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                    onChange={handleChangeStartDateValue}
                  />
                  <input
                    type="date"
                    id="dateDebut"
                    className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                    onChange={handleChangeEndDtaeValue}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-6 overflow-x-auto">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm">
                <FirestoreTable
                  collectionRef={db.collection(transactionCollectionName)}
                  condensed
                  collectionQueriesWhere={[
                    {
                      fieldPath: "senderID",
                      opStr: "==",
                      value: user?.id,
                    },
                    {
                      fieldPath: "transactionType",
                      opStr: "==",
                      value: TransactionType.WATER_BILL_POSTPAID,
                    },
                    ...firestoreTableQueriesWhere,
                  ]}
                  columns={columns}
                  rowRender={renderRow}
                  rowDetailsHandler={onRowDetails}
                  retrieveData={(data) => invoicesManagment(data)}
                  displayDetails
                  shouldAddEditColumn
                  paginate={{
                    pageIndex: 0,
                    limit: 100,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showDetailsModal}
        maxSize="md"
        closeModal={() => setShowDetailsModal(false)}
      >
        {invoice && (
          <div className="flex justify-center">
            <div className="w-full">
              <div className="m-3 sm:max-w-lg sm:w-full sm:mx-auto">
                <div
                  id="download-invoice"
                  className="relative flex flex-col bg-white shadow-lg pointer-events-auto rounded-xl dark:bg-gray-800"
                >
                  <div className="p-4 overflow-y-auto sm:p-7">
                    <div className="text-center">
                      <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                        Facture Onea
                      </h3>
                    </div>

                    <div className="grid grid-cols-1 gap-5 mt-5">
                      <div>
                        <span className="block text-xs text-gray-500 uppercase">
                          Nom du client:
                        </span>
                        <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                          {invoice.receiverDisplayName}
                        </span>
                      </div>

                      <div>
                        <span className="block text-xs text-gray-500 uppercase">
                          N° du compteur:
                        </span>
                        <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                          {invoice.oneaMeterNumber}
                        </span>
                      </div>
                    </div>

                    <div className="mt-5 sm:mt-10">
                      <h4 className="text-xs font-semibold text-gray-800 uppercase dark:text-gray-200">
                        Facture
                      </h4>
                      <ul className="flex flex-col mt-3">
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Transaction ID:</span>
                            <span>{invoice.documentId}</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Montant</span>
                            <span>{amountFormater(invoice.amount)} Fcfa</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Penalité</span>
                            <span>
                              {amountFormater(invoice.penality ?? 0)}F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span className="mr-1">
                              Identifiant des factures payé
                            </span>
                            <div className="flex justify-end w-full">
                              <span>
                                {invoice?.oneaInvoiceIds
                                  ? invoice?.oneaInvoiceIds.join(" ")
                                  : "-"}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span className="mr-1">Periodes</span>
                            <span>
                              {invoice.oneaInvoicePeriodes
                                .split("-")
                                .join(" ") ?? "-"}
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span className="mr-1">Frais</span>
                            <span>{invoice.fees} Fcfa</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-5 gap-x-2">
                  <button
                    className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-red-600 border border-transparent rounded-lg gap-x-2 hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    onClick={() => downloadImage(invoice.receiverDisplayName)}
                  >
                    <ArrowDownTrayIcon
                      className="self-center flex-shrink-0 w-6 h-6 mr-1 text-white"
                      aria-hidden="true"
                    />
                    Télécharger
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default AgentPaidOneaInvoiceHistories;
