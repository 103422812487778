import { Menu, Transition } from "@headlessui/react";
import {
  ArrowDownCircleIcon,
  ArrowPathIcon,
  Bars3Icon,
  CheckIcon,
  CreditCardIcon,
  FingerPrintIcon,
  LinkIcon,
  ListBulletIcon,
  MapIcon,
  NoSymbolIcon,
  PencilIcon,
  UserIcon,
  TicketIcon,
  LockOpenIcon,
  LockClosedIcon,
  ViewfinderCircleIcon,
  PrinterIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline";
import { EyeIcon } from "@heroicons/react/24/solid";
import { MouseEventHandler, Fragment } from "react";
import { useConfirm } from "../../hooks/useConfirm";
import { classNames } from "../../utils";
import HasRoles from "../HasRoles";

export interface TableEditMenuProps {
  rowEditHandler?: MouseEventHandler;
  rowDetailsHandler?: MouseEventHandler;
  rowDeleteHandler?: MouseEventHandler;
  rowTranfertMoneyHandler?: MouseEventHandler;
  rowCertifyUserHandler?: MouseEventHandler;
  rowEditMapHandler?: MouseEventHandler;
  rowAddUserHandler?: MouseEventHandler;
  rowAffiliateUserHandler?: MouseEventHandler;
  rowFirstReloadHandler?: MouseEventHandler;
  rowCancelledTransHandler?: MouseEventHandler;
  rowCancelledPendingTransHandler?: MouseEventHandler;
  rowBlockedTransHandler?: MouseEventHandler;
  rowReturnTransHandler?: MouseEventHandler;
  rowResetPasswordHandler?: MouseEventHandler;
  rowTransDetailsHandler?: MouseEventHandler;
  rowRefreshDetailsHandler?: MouseEventHandler;
  rowHistoriqueHandler?: MouseEventHandler;
  rowTicketHandler?: MouseEventHandler;
  rowBlockedEventHandler?: MouseEventHandler;
  rowDeblockedEventHandler?: MouseEventHandler;
  rowValidatedEventHandler?: MouseEventHandler;
  rowMemberToScanTicketHandler?: MouseEventHandler;
  rowScanTicketHandler?: MouseEventHandler;
  rowBlockedScanTicketHandler?: MouseEventHandler;
  rowPrintHandler?: MouseEventHandler;
  rowSellerHandler?: MouseEventHandler;
  rowDealerHandler?: MouseEventHandler;
  rowShowActivitiesHandler?: MouseEventHandler;
  rowShowDealerAgentHandler?: MouseEventHandler;
}

export function TableRowEditMenu(props: TableEditMenuProps) {
  return (
    <Menu as="div" className="relative flex items-center justify-end">
      <Menu.Button className="inline-flex items-center justify-center w-8 h-8 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
        <span className="sr-only">Actions</span>
        <Bars3Icon className="w-5 h-5" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute top-0 z-10 w-48 mx-3 mt-1 origin-top-right bg-white divide-y divide-gray-200 rounded-md shadow-lg right-7 ring-1 ring-black ring-opacity-5 focus:outline-none">
          {props.rowDetailsHandler && (
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <span
                    onClick={props.rowDetailsHandler}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <EyeIcon
                      className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Détails
                  </span>
                )}
              </Menu.Item>
            </div>
          )}
          <HasRoles
            userRole={["ADMIN", "SUDO", "AGENCYSTAFF", "RESPONSABLEZONE"]}
          >
            <>
              {props.rowAffiliateUserHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowAffiliateUserHandler}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <LinkIcon
                          className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Affilié à un dealer
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["GASSTATIONSTAFF"]}>
            <>
              {props.rowPrintHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowPrintHandler}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <PrinterIcon
                          className="w-5 h-5 mr-3 text-sky-600 group-hover:text-sky-600"
                          aria-hidden="true"
                        />
                        Imprimer
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["RESPONSABLEZONE"]}>
            <>
              {props.rowSellerHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowSellerHandler}
                        data-modal-target="popup-modal"
                        data-modal-toggle="popup-modal"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <UserIcon
                          className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Voir les commerciaux
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["RESPONSABLEZONE"]}>
            <>
              {props.rowDealerHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowDealerHandler}
                        data-modal-target="popup-modal"
                        data-modal-toggle="popup-modal"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <UserIcon
                          className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Voir les Dealers
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["MERCHANT"]}>
            <>
              {props.rowTicketHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowTicketHandler}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <TicketIcon
                          className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Tickets
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["MERCHANT"]}>
            <>
              {props.rowMemberToScanTicketHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowMemberToScanTicketHandler}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <ViewfinderCircleIcon
                          className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Membres
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["MERCHANT"]}>
            <>
              {props.rowScanTicketHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowScanTicketHandler}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <ViewfinderCircleIcon
                          className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Autoriser à scanner
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["MERCHANT"]}>
            <>
              {props.rowBlockedScanTicketHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowBlockedScanTicketHandler}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <LockClosedIcon
                          className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Bloquer le scan
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["ADMIN"]}>
            <>
              {props.rowBlockedEventHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowBlockedEventHandler}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <LockClosedIcon
                          className="w-5 h-5 mr-3 text-red-400 group-hover:text-red-500"
                          aria-hidden="true"
                        />
                        Blocquer
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["ADMIN"]}>
            <>
              {props.rowDeblockedEventHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowDeblockedEventHandler}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <LockOpenIcon
                          className="w-5 h-5 mr-3 text-green-400 group-hover:text-green-500"
                          aria-hidden="true"
                        />
                        Déblocquer
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["ADMIN"]}>
            <>
              {props.rowValidatedEventHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowValidatedEventHandler}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <CheckIcon
                          className="w-5 h-5 mr-3 text-blue-400 group-hover:text-blue-500"
                          aria-hidden="true"
                        />
                        Valider
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["COLLECTOR", "SUPERSTATION"]}>
            <>
              {props.rowHistoriqueHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowHistoriqueHandler}
                        data-modal-target="popup-modal"
                        data-modal-toggle="popup-modal"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <ListBulletIcon
                          className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Historique
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["COMPANYSTAFF"]}>
            <>
              {props.rowRefreshDetailsHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowRefreshDetailsHandler}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <ArrowPathIcon
                          className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Rafraichir les liens
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>

          <HasRoles userRole={["ADMIN", "SUDO"]}>
            <>
              {props.rowEditHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowEditHandler}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <PencilIcon
                          className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Edit
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <>
            {props.rowTransDetailsHandler && (
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <span
                      onClick={props.rowTransDetailsHandler}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                    >
                      <EyeIcon
                        className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Details
                    </span>
                  )}
                </Menu.Item>
              </div>
            )}
          </>
          {props.rowTranfertMoneyHandler && (
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <span
                    onClick={props.rowTranfertMoneyHandler}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <CreditCardIcon
                      className="w-5 h-5 mr-3 text-indigo-400 group-hover:text-indigo-500"
                      aria-hidden="true"
                    />
                    Recharger
                  </span>
                )}
              </Menu.Item>
            </div>
          )}
          <HasRoles userRole={["ADMIN", "SUDO"]}>
            <>
              {props.rowAddUserHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowAddUserHandler}
                        data-modal-target="popup-modal"
                        data-modal-toggle="popup-modal"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <UserIcon
                          className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Ajouter un gérant
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["ADMIN", "SUDO", "AGENCYSTAFF"]}>
            <>
              {props.rowCertifyUserHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowCertifyUserHandler}
                        data-modal-target="popup-modal"
                        data-modal-toggle="popup-modal"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <CheckIcon
                          className="w-5 h-5 mr-3 text-green-400 group-hover:text-green-500"
                          aria-hidden="true"
                        />
                        Certifier
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["ADMIN", "SUDO", "AGENCYSTAFF"]}>
            <>
              {props.rowEditMapHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowEditMapHandler}
                        data-modal-target="popup-modal"
                        data-modal-toggle="popup-modal"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <MapIcon
                          className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Edit localisations
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["AGENT"]}>
            <>
              {props.rowFirstReloadHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowFirstReloadHandler}
                        data-modal-target="popup-modal"
                        data-modal-toggle="popup-modal"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <ArrowPathIcon
                          className="w-5 h-5 mr-3 text-yellow-400 group-hover:text-yellow-500"
                          aria-hidden="true"
                        />
                        Recharger
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["ADMIN"]}>
            <>
              {props.rowCancelledTransHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowCancelledTransHandler}
                        data-modal-target="popup-modal"
                        data-modal-toggle="popup-modal"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <NoSymbolIcon
                          className="w-5 h-5 mr-3 text-red-400 group-hover:text-red-500"
                          aria-hidden="true"
                        />
                        Annuler
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["ADMIN", "TECHNICALSUPPORT"]}>
            <>
              {props.rowCancelledPendingTransHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowCancelledPendingTransHandler}
                        data-modal-target="popup-modal"
                        data-modal-toggle="popup-modal"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <NoSymbolIcon
                          className="w-5 h-5 mr-3 text-red-400 group-hover:text-red-500"
                          aria-hidden="true"
                        />
                        Annuler
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["ADMIN"]}>
            <>
              {props.rowReturnTransHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowReturnTransHandler}
                        data-modal-target="popup-modal"
                        data-modal-toggle="popup-modal"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <ArrowPathIcon
                          className="w-5 h-5 mr-3 text-green-400 group-hover:text-green-500"
                          aria-hidden="true"
                        />
                        Restituer
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles
            userRole={["ADMIN", "SUDO", "AGENCYSTAFF", "GASSTATIONSTAFF"]}
          >
            <>
              {props.rowResetPasswordHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowResetPasswordHandler}
                        data-modal-target="popup-modal"
                        data-modal-toggle="popup-modal"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <FingerPrintIcon
                          className="w-5 h-5 mr-3 text-red-400 group-hover:text-red-500"
                          aria-hidden="true"
                        />
                        Réinitialiser le mot passe
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          <HasRoles userRole={["AGENCYSTAFF", "TECHNICALSUPPORT", "ADMIN"]}>
            <>
              {props.rowBlockedTransHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowBlockedTransHandler}
                        data-modal-target="popup-modal"
                        data-modal-toggle="popup-modal"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <NoSymbolIcon
                          className="w-5 h-5 mr-3 text-red-400 group-hover:text-red-500"
                          aria-hidden="true"
                        />
                        Bloquer
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
          {/* activities */}
          <HasRoles
            userRole={[
              "ADMIN",
              "SUDO",
              "SUPERVISOR",
              "DEALER",
              "RESPONSABLEZONE",
            ]}
          >
            <>
              {props.rowShowActivitiesHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowShowActivitiesHandler}
                        data-modal-target="popup-modal"
                        data-modal-toggle="popup-modal"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <ClipboardDocumentListIcon
                          className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Ses activités
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>

          <HasRoles userRole={["ADMIN", "SUPERVISOR", "RESPONSABLEZONE"]}>
            <>
              {props.rowShowDealerAgentHandler && (
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={props.rowShowDealerAgentHandler}
                        data-modal-target="popup-modal"
                        data-modal-toggle="popup-modal"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <ListBulletIcon
                          className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Ses agents
                      </span>
                    )}
                  </Menu.Item>
                </div>
              )}
            </>
          </HasRoles>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default TableRowEditMenu;
