import { useCallback, useContext, useEffect, useState } from "react";
import { UserInterface, UserRole } from "../../../interfaces";
import { UserContext } from "../../../contexts";
import { db, functions } from "../../../firebase";
import { responsableZoneReferralsCollection, usersCollectionName } from "../../../config";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Skeleton } from "../../../components/LoadingSkeleton/skeleton";

export interface DealersPageProps {
    dealers: UserInterface[];
}

const ChooseDealerPage = () => {
    const { user } = useContext(UserContext);
    const [allDealers, setAllDealers] = useState<any[]>([]);
    const [selectedDealer, setSelectedDealer] = useState<string | null>(null);
    const location = useLocation();
    const currentUser = location.state?.entity;
    const navigate = useNavigate();
    const [loadingDealer, setLoadingDealer] = useState<boolean>(false)

    const sankmoneyUpdateAccountFn = functions.httpsCallable(
        "sankmoneyUpdateAccountFn"
    );

    const getAllSupervisors = useCallback(async () => {
        if (!user) {
            return;
        }
        let supervisors;
        const supervisorOject = await db
            .collection(responsableZoneReferralsCollection)
            .where("responsableZoneId", "==", user?.id)
            .get();

        if (supervisorOject.empty) {
            return supervisors = [];
        }
        supervisors = supervisorOject.docs.map((d) => ({
            id: d.data()?.supervisorId,
        }));

        getAllDealersAffiliated(supervisors);
    }, [user]);

    const getAllDealersAffiliated = async (supervisors: { id: string }[]) => {
        setLoadingDealer(true)
        const dealers: { id: string; data: any }[] = [];
        for (const supervisor of supervisors) {
            const dealerSnapshot = await db
                .collection(usersCollectionName)
                .where("supervisorId", "==", supervisor.id)
                .where("userRole", "==", UserRole.DEALER)
                .get();

            const dealersData = dealerSnapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            }));

            dealers.push(...dealersData);
        }
        setAllDealers(dealers)
        setLoadingDealer(false)
    }

    const onSubmitAffiliateDealer = (id?: string | null) => {
        if (currentUser) {
            const response = sankmoneyUpdateAccountFn({
                collectionName: usersCollectionName,
                documentData: {
                    dealerId: id,
                },
                documentId: currentUser.id,
            })
                .then(() => {
                    navigate("/agents");
                })
                .catch((err: { details: any; message: any }) =>
                    toast.error(err.details.message)
                );
            toast.promise(response, {
                error: "Une erreur s'est produite. Veuillez réessayer !",
                success:
                    "Les informations du distributeur a été mis a jour avec succès",
                loading: "chargement...",
            });
        } else
            toast.error(
                `L'indentifiant du dealer est incorrect veuiller réessayer de nouveau !`
            );
    };

    useEffect(() => {
        user && getAllSupervisors();
    }, [getAllSupervisors, user])

    return (
        <main>
            <div className="max-w-3xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="py-24 text-center">
                    <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                        La liste des dealers affiliés a vos superviseurs
                    </h1>
                    <p className="max-w-3xl mx-auto mt-4 text-base text-gray-500">
                        Sélectionnez un dealer pour affilier l'agent
                    </p>
                </div>

                {/* Product grid */}
                <section aria-labelledby="products-heading" className="mt-8">
                    <h2 id="products-heading" className="sr-only">
                        Dealers
                    </h2>

                    <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
                        {
                            loadingDealer ? (
                                Array.from({ length: 5 }).map((_, i) => (
                                    <Skeleton key={i} className="w-full h-[300px]" />
                                ))
                            ) : allDealers ? allDealers.map((dealer, dealerIdx) => (
                                <div key={dealerIdx} className="group border p-4 rounded-lg">
                                    <input
                                        type="radio"
                                        name="selectedDealer"
                                        value={dealer.id}
                                        checked={selectedDealer === dealer.id}
                                        onChange={() => setSelectedDealer(dealer.id)}
                                        className="mr-2"
                                    />
                                    <label className="flex flex-col items-start">
                                        <img
                                            alt={dealer.data?.displayName}
                                            src={dealer.data?.profilImageUrl ? dealer.data?.profilImageUrl : `https://ui-avatars.com/api/?background=random&name=${dealer.data?.displayName || "S"
                                                }`}
                                            className="aspect-square w-full rounded-lg object-cover group-hover:opacity-75 sm:aspect-[2/3]"
                                        />
                                        <div className="flex items-center justify-between mt-4 text-base font-medium text-gray-900">
                                            <h3>{dealer.data?.displayName}</h3> {" "}
                                            <h3>{dealer.data?.phoneNumber}</h3>
                                        </div>
                                    </label>
                                </div>
                            )) : (
                                <>
                                    <div className="col-span-3 p-10 text-center bg-gray-100">
                                        <h3 className="mt-2 text-lg font-medium text-gray-700">
                                            Aucun Dealers dans votre reseau de superviseurs.
                                        </h3>
                                    </div>
                                </>
                            )
                        }
                    </div>
                    <button
                        className="mt-4 px-4 py-2 bg-red-500 text-white rounded-md"
                        onClick={() => onSubmitAffiliateDealer(selectedDealer)}
                        disabled={!selectedDealer}
                    >
                        Affilier
                    </button>
                </section>
            </div>
        </main>
    )
}
export default ChooseDealerPage;