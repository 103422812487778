import { useCallback, useMemo, useState, useEffect } from "react";
import {
  gasStationCollectionName,
  gasStationMembershipCollectionName,
  usersCollectionName,
} from "../../../config";
import {
  UserIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../../components/FirestoreTable";
import { db, functions } from "../../../firebase";
import { UserRole } from "../../../interfaces";
import Modal from "../../../components/Modal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, FormInput } from "../../../components/FormComponents";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import moment from "moment";
import { parseDate, getTimestamp } from "../../../utils";

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "profilImageUrl",
    label: "Profil",
  },
  {
    field: "displayName",
    label: "Nom/prénom",
  },
  {
    field: "adresse",
    label: "Adresse",
  },
  {
    field: "phoneNumber",
    label: "Téléphone",
  },
  {
    field: "city",
    label: "Ville",
  },
  {
    field: "gasStationCode",
    label: "Code",
  },
  // {
  //   field: "lat",
  //   label: "Latitude",
  // },
  // {
  //   field: "long",
  //   label: "Longitude",
  // },
  {
    field: "active",
    label: "Active",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "displayName":
      return <span>{value ? value : "-"}</span>;
    case "profilImageUrl":
      return (
        <span>
          {" "}
          {value ? (
            <img
              className="w-10 h-10 p-1 border rounded-full"
              src={
                value
                  ? value
                  : `https://ui-avatars.com/api/?background=random&name=${
                      value || "S"
                    }`
              }
              alt=""
            />
          ) : (
            <UserIcon className="w-10 h-10 p-1 border rounded-full" />
          )}
        </span>
      );
    case "adresse":
      return <span>{value ?? "-"}</span>;
    case "gasStationCode":
      return <span>{value}</span>;
    case "city":
      return <span>{value ? value : "-"}</span>;
    case "phoneNumber":
      return <span>{value ? value : "-"}</span>;
    // case "lat":
    //   return <span>{value ? value : "-"}</span>;
    // case "long":
    //   return <span>{value ? value : "-"}</span>;
    case "active":
      return (
        <span>
          {value ? (
            <CheckCircleIcon className="w-6 h-6 text-green-500" />
          ) : (
            <XCircleIcon className="w-6 h-6 text-red-500" />
          )}
        </span>
      );
    default:
      return value ? value : "-";
  }
};
const AllGasStationListPage = () => {
  let refreshDataFn: (() => void) | any = undefined;
  const [code, setCode] = useState<string>("");
  const [changeManager, setChangeManager] = useState<boolean>(false);
  const [gasStationMemberId, setGasStationMemberId] = useState<any[]>([]);
  const [managerPhoneNumber, setManagerPhoneNumber] = useState<any[]>([]);
  const [phoneNumberSearch, setPhoneNumberSearch] = useState<
    string | undefined
  >();

  const sankMoneyUpdateUserNumberOrEmailFn = functions.httpsCallable(
    "sankMoneyUpdateUserNumberOrEmailFn"
  );
  const sankMoneyDeleteGasstionAccountFn = functions.httpsCallable(
    "sankMoneyDeleteGasstionAccountFn"
  );
  const adminResetPasswordFn = functions.httpsCallable("adminResetPasswordFn");

  const onRowChangeManager = async (id: number, entity: any) => {
    const snap = await db
      .collection(gasStationMembershipCollectionName)
      .where("gasStationId", "==", entity.id)
      .get();
    if (snap.empty) {
      toast.error("Cette station ne possède pas de manager");
      setChangeManager(false);
      navigate("/gas-stations");
    } else {
      setChangeManager(true);
      setGasStationMemberId([snap.docs[0].data()]);
    }
  };

  const navigate = useNavigate();

  const onEditRow = (id: number, entity: any) => {
    if (entity) {
      navigate(`/gas-stations/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  const onAddManager = async (id: number, entity: any) => {
    const snap = await db
      .collection(gasStationMembershipCollectionName)
      .where("gasStationId", "==", entity.id)
      .get();
    if (!snap.empty) {
      toast.error("Cette station possède deja un manager");
      navigate("/gas-stations");
    } else {
      if (entity) {
        navigate(`/gas-stations/${entity.id}/edit`, {
          state: { entity: entity, id: entity.id, affiliate: true },
        });
      }
    }
  };
  const onRowDetails = (id: number, entity: any) => {
    if (entity) {
      navigate(`/gas-stations/${entity.id}/details`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  const onDeleteRow = (id: number, row: any) => {
    if (row) {
      toast.promise(
        sankMoneyDeleteGasstionAccountFn({
          gasstationId: row.id,
        }).then(() => {
          if (refreshDataFn) {
            refreshDataFn();
          }
        }),
        {
          loading: "Suppression...",
          success: "La station service a été supprimé avec succès.",
          error: (e) => e.details.message,
        }
      );
    }
  };

  const onResetPassword = useCallback(async () => {
    if (gasStationMemberId) {
      const snap = await db
        .collection(usersCollectionName)
        .where("id", "==", gasStationMemberId[0].memberId)
        .get();
      if (!snap.empty) {
        setManagerPhoneNumber([snap.docs[0].data()]);
      }
    } else {
      toast.error("Le memebre est introuvable");
    }
  }, [gasStationMemberId]);

  const onSubmit = useCallback(
    async (data: any) => {
      if (gasStationMemberId) {
        onResetPassword();
        const response = sankMoneyUpdateUserNumberOrEmailFn({
          userId: gasStationMemberId[0].memberId,
          email: data.email,
        });
        setChangeManager(false);
        navigate("/gas-stations");
        toast.promise(
          response.then(() => {
            const responses = adminResetPasswordFn({
              email: data.email,
              phoneNumber: managerPhoneNumber[0].phoneNumber,
            });
            toast.promise(responses, {
              error: (err) => `${err}`,
              success: "Le mot de passe a été reinitialiser avec succès.",
              loading: "chargement...",
            });
          }),
          {
            error: "Une erreur s'est produite. Veuillez réessayer !",
            success:
              "Les informations du manager ont été mise à jour avec succès.",
            loading: "chargement...",
          }
        );
      } else {
        toast.error("Le memebre est introuvable");
      }
    },
    [
      adminResetPasswordFn,
      gasStationMemberId,
      managerPhoneNumber,
      navigate,
      onResetPassword,
      sankMoneyUpdateUserNumberOrEmailFn,
    ]
  );

  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);

  const firestoreTableQueriesWhere = useMemo(() => {
    const queries = [];
    if (code) {
      queries.push({
        fieldPath: "gasStationCode",
        opStr: "==",
        value: code,
      });
    }
    if (startTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: ">=",
        value: startTime,
      });
    }

    if (endTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: "<=",
        value: endTime,
      });
    }

    queries.push({
      fieldPath: "userRole",
      opStr: "==",
      value: UserRole.GASSTATION,
    });

    return queries;
  }, [code,startTime, endTime]);


  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );

  // function to filter merchants by date
  const getFilteredByDate = useCallback(async () => {
    if (seachWithStartDateValue) {
      setStartTime(getTimestamp(seachWithStartDateValue, true));
    }

    if (seachWithEndDateValue) {
      setEndTime(getTimestamp(seachWithEndDateValue, false));
    }
  }, [seachWithEndDateValue, seachWithStartDateValue]);

  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithEndDateValue(value.target.value);
  };
  
  useEffect(() => {
    getFilteredByDate();
  }, [getFilteredByDate]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* Start section */}
      <div className="mt-5 sm:flex sm:items-center">
        {/* Start Title section */}
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les stations-services
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ici l'ensemble des stations-services.
          </p>
        </div>
        {/* end Title section */}

        <div className="flex mx-1 flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
          {/* start code search box */}
          <div className="my-auto">
            <input
              type="search"
              id="search"
              className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-md h-9 bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
              placeholder="Rechercher par code"
              onChange={(e) => setCode(e.target.value)}
              value={code}
            />
          </div>
          {/* end code search box */}

          {/* add service-station button */}
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-700"
              onClick={() => {
                navigate("/gas-stations/new");
              }}
            >
              + Ajouter une station-service
            </button>
          </div>
          {/* end add service-station button */}

          {/* start filter by data */}
          <div className="flex items-center w-full space-x-3 md:w-auto">
            {/* start date */}
            <input
              type="date"
              id="dateDebut"
              className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
              defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
              onChange={handleChangeStartDateValue}
            />

            {/* end date */}
            <input
              type="date"
              id="dateDebut"
              className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
              defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
              onChange={handleChangeEndDtaeValue}
            />
          </div>
          {/* end filter by date */}
        </div>
      </div>
      {/* End section */}

      <FirestoreTable
        collectionRef={db.collection(gasStationCollectionName)}
        condensed
        collectionQueriesWhere={firestoreTableQueriesWhere}
        columns={columns}
        rowRender={renderRow}
        rowEditHandler={onEditRow}
        rowDeleteHandler={onDeleteRow}
        rowDetailsHandler={onRowDetails}
        rowAddManagerHandler={onAddManager}
        rowChangeManagerHandler={onRowChangeManager}
        shouldAddEditColumn
        displayAddManagerBtn
        displayChangeManagerBtn
        displayDeleteBtn
        displayDetails
        displayEditBtn
        paginate={{ pageIndex: 0, limit: 100 }}
      />
      <Modal
        isOpen={changeManager}
        maxSize="md"
        closeModal={() => setChangeManager(false)}
        modalTitle="Changer un manager"
      >
        <Form<{ email: string }>
          onSubmit={onSubmit}
          form={{
            resolver: yupResolver(
              yup.object().shape({
                email: yup.string().required("Le champs est obligatoire"),
              })
            ),
          }}
          submitButtonFullWidth
          submitButtonLabel="Modifier"
        >
          <div className="space-y-4 text-black md:space-y-6">
            <FormInput name="email" label="Email" />
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default AllGasStationListPage;
