import {
  companyCollectionName,
  companiesMembershipCollectionName,
  usersCollectionName,
} from "../../../config";
import {
  UserIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../../components/FirestoreTable";
import { db, functions } from "../../../firebase";
import Modal from "../../../components/Modal";
import { Form, FormInput } from "../../../components/FormComponents";
import * as yup from "yup";
import { useCallback, useState, useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import FormCheckToggle from "../../../components/FormComponents/FormCheckToggle";
import toast from "react-hot-toast";
import moment from "moment";
import { parseDate, getTimestamp } from "../../../utils";
import { UserRole } from "../../../interfaces";

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "profilImageUrl",
    label: "Profil",
  },
  {
    field: "displayName",
    label: "Nom/prénom",
  },
  {
    field: "email",
    label: "Email",
  },
  {
    field: "phoneNumber",
    label: "Téléphone",
  },
  {
    field: "city",
    label: "Ville",
  },
  {
    field: "active",
    label: "Active",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "displayName":
      return <span>{value}</span>;
    case "profilImageUrl":
      return (
        <span>
          {" "}
          {value ? (
            <img
              className="w-10 h-10 p-1 border rounded-full"
              src={
                value
                  ? value
                  : `https://ui-avatars.com/api/?background=random&name=${
                      value || "S"
                    }`
              }
              alt=""
            />
          ) : (
            <UserIcon className="w-10 h-10 p-1 border rounded-full" />
          )}
        </span>
      );
    case "email":
      return <span>{value}</span>;
    case "city":
      return <span>{value}</span>;
    case "phoneNumber":
      return <span>{value ? value : ""}</span>;
    case "active":
      return (
        <span>
          {value ? (
            <CheckCircleIcon className="w-6 h-6 text-green-500" />
          ) : (
            <XCircleIcon className="w-6 h-6 text-red-500" />
          )}
        </span>
      );
    default:
      return value as string;
  }
};
const AllCompaniesPage = () => {
  const navigate = useNavigate();
  const [showUpdateFeeModal, setShowUpdateFeeModal] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [company, setCompany] = useState<any>();
  const [changeManager, setChangeManager] = useState<boolean>(false);
  const [companyMember, setCompanyMember] = useState<any[]>([]);
  const [managerPhoneNumber, setManagerPhoneNumber] = useState<any[]>([]);

  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );

  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);

  const onEditRow = (id: number, entity: any) => {
    if (entity) {
      navigate(`/companies/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  const onRowDetails = (id: number, entity: any) => {
    if (entity) {
      navigate(`/companies/${entity.id}/details`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  const onRowChangeFees = (id: number, entity: any) => {
    if (entity) {
      setCompanyId(entity.id);
      setCompany(entity);
      setShowUpdateFeeModal(true);
    }
  };
  const onRowChangeManager = async (id: number, entity: any) => {
    const snap = await db
      .collection(companiesMembershipCollectionName)
      .where("companyId", "==", entity.id)
      .get();
    if (snap.empty) {
      toast.error("Cette compagnie ne possède pas de manager");
      setChangeManager(false);
      navigate("/companies");
    } else {
      setChangeManager(true);
      companyMember.push(snap.docs[0].data());
    }
  };
  const onAddManager = async (id: number, entity: any) => {
    const snap = await db
      .collection(companiesMembershipCollectionName)
      .where("companyId", "==", entity.id)
      .get();
    if (!snap.empty) {
      toast.error("Cette compagnie possède deja un manager");
      navigate("/companies");
    } else {
      if (entity) {
        navigate(`/companies/${entity.id}/edit`, {
          state: { entity: entity, id: entity.id, affiliate: true },
        });
      }
    }
  };
  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );
  const sankMoneyUpdateUserNumberOrEmailFn = functions.httpsCallable(
    "sankMoneyUpdateUserNumberOrEmailFn"
  );

  const adminResetPasswordFn = functions.httpsCallable("adminResetPasswordFn");

  const onResetPassword = useCallback(async () => {
    const snap = await db
      .collection(usersCollectionName)
      .where("id", "==", companyMember[0].memberId)
      .get();
    if (!snap.empty) {
      managerPhoneNumber.push(snap.docs[0].data());
    }
  }, [companyMember, managerPhoneNumber]);

  const onSubmitInfo = useCallback(
    async (data: any) => {
      onResetPassword();
      const response = sankMoneyUpdateUserNumberOrEmailFn({
        userId: companyMember[0].memberId,
        email: data.email,
      });
      setChangeManager(false);
      navigate("/companies");
      toast.promise(
        response.then(() => {
          const responses = adminResetPasswordFn({
            email: data.email,
            phoneNumber: managerPhoneNumber[0].phoneNumber,
          });
          toast.promise(responses, {
            error: (err) => `${err}`,
            success: "Le mot de passe a été reinitialiser avec succès.",
            loading: "chargement...",
          });
        }),
        {
          error: "Une erreur s'est produite. Veuillez réessayer !",
          success:
            "Les informations du manager ont été mise à jour avec succès.",
          loading: "chargement...",
        }
      );
    },
    [
      adminResetPasswordFn,
      companyMember,
      managerPhoneNumber,
      navigate,
      onResetPassword,
      sankMoneyUpdateUserNumberOrEmailFn,
    ]
  );

  const onSubmit = (data: any) => {
    const feeData = {
      applyFee: data.applyFee,
      fee: parseFloat(data.fee),
    };
    const response = sankmoneyUpdateAccountFn({
      collectionName: companyCollectionName,
      documentData: feeData,
      documentId: companyId,
    });
    toast.promise(
      response.then(() => {
        navigate("/companies");
      }),
      {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success: "Les informations des frais on été mise à jour avec succès.",
        loading: "chargement...",
      }
    );
  };

  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithEndDateValue(value.target.value);
  };

  // function to filter merchants by date
  const getFilteredByDate = useCallback(async () => {
    if (seachWithStartDateValue) {
      setStartTime(getTimestamp(seachWithStartDateValue, true));
    }

    if (seachWithEndDateValue) {
      setEndTime(getTimestamp(seachWithEndDateValue, false));
    }
  }, [seachWithEndDateValue, seachWithStartDateValue]);

  const firestoreTableQueriesWhere = useMemo(() => {
    const queries = [];

    if (startTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: ">=",
        value: startTime,
      });
    }

    if (endTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: "<=",
        value: endTime,
      });
    }
    return queries;
  }, [startTime, endTime]);

  useEffect(() => {
    getFilteredByDate();
  }, [getFilteredByDate]);

  return (
    // Global container
    <div className="px-4 sm:px-6 lg:px-8">
      {/* Start section */}
      <div className="flex mx-1 flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
        {/* Start Title section */}
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les entreprises
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ici l'ensemble des entreprises.
          </p>
        </div>
        {/* End Title section */}

        {/* add Enterprise button */}
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-700"
            onClick={() => {
              navigate("/companies/new");
            }}
          >
            + Ajouter une entreprise
          </button>
        </div>
        {/* add Enterprise button */}

        {/* start filter by data */}
        <div className="flex items-center w-full space-x-3 md:w-auto">
          {/* start date */}
          <input
            type="date"
            id="dateDebut"
            className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
            defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
            onChange={handleChangeStartDateValue}
          />

          {/* end date */}
          <input
            type="date"
            id="dateDebut"
            className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
            defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
            onChange={handleChangeEndDtaeValue}
          />
        </div>
        {/* end filter by date */}
      </div>
      {/* End section */}

      <FirestoreTable
        collectionRef={db.collection(companyCollectionName)}
        condensed
        collectionQueriesWhere={firestoreTableQueriesWhere}
        columns={columns}
        rowRender={renderRow}
        rowEditHandler={onEditRow}
        rowDetailsHandler={onRowDetails}
        rowAddCompanyFeeHandler={onRowChangeFees}
        rowAddManagerHandler={onAddManager}
        rowChangeManagerHandler={onRowChangeManager}
        shouldAddEditColumn
        displayAddManagerBtn
        displayChangeManagerBtn
        displayDetails
        displayEditBtn
        displayAddCompanyFeeBtn
        paginate={{ pageIndex: 0, limit: 100 }}
      />
      <Modal
        isOpen={showUpdateFeeModal}
        maxSize="md"
        closeModal={() => setShowUpdateFeeModal(false)}
      >
        <Form<{ applyFee: boolean; fee: string }>
          onSubmit={onSubmit}
          form={{
            resolver: yupResolver(
              yup.object().shape({
                applyFee: yup.boolean().required("Le champs est obligatoire"),
                fee: yup.string().required("Le champs est obligatoire"),
              })
            ),
            defaultValues: {
              applyFee: company ? company?.applyFee : "",
              fee: company ? company?.fee : "0.0",
            },
          }}
          submitButtonFullWidth
          submitButtonLabel="Modifier"
        >
          <div className="space-y-4 text-black md:space-y-6">
            <FormCheckToggle name="applyFee" label="Appliquer des frais" />
            <FormInput
              name="fee"
              label="Frais"
              helpLabel="Les frais sont en pourcentage (%). Exemple: 1.5"
            />
          </div>
        </Form>
      </Modal>

      <Modal
        isOpen={changeManager}
        maxSize="md"
        closeModal={() => setChangeManager(false)}
        modalTitle="Changer un manager"
      >
        <Form<{ email: string }>
          onSubmit={onSubmitInfo}
          form={{
            resolver: yupResolver(
              yup.object().shape({
                email: yup.string().required("Le champs est obligatoire"),
              })
            ),
          }}
          submitButtonFullWidth
          submitButtonLabel="Modifier"
        >
          <div className="space-y-4 text-black md:space-y-6">
            <FormInput name="email" label="Email" />
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default AllCompaniesPage;
