import {
  CalendarDaysIcon,
  CheckBadgeIcon,
  CreditCardIcon,
  UserGroupIcon,
  UsersIcon
} from "@heroicons/react/24/outline";
import { DashboardReport } from "./DashboardReport";
import { UserRole, UserInterface } from "../../interfaces";

const HeaderDashboardComponent = (props: {
  transctionCount?: number;
  completedTransactionCount?: number;
  uncompletedTransactionCount?: number;
  transactionOnWeek: number;
  isAdmin: boolean;
  retrieveDailySubscriberUsers?: number;
  retrieveSubscriberUsers?: number;
  retrieveSubscriberAgents?: number;
  retrieveSubscriberDealers?: number;
  retrieveAllTransactions?: number;
  retrieveNumberOfDealerAgent?: number;
  retrieveNumberOfSellerUser?: number;
  currentuser: UserInterface;
}) => {
  const {
    transctionCount,
    completedTransactionCount,
    transactionOnWeek,
    isAdmin,
    retrieveAllTransactions,
    retrieveDailySubscriberUsers,
    retrieveSubscriberUsers,
    retrieveSubscriberAgents,
    retrieveSubscriberDealers,
    retrieveNumberOfDealerAgent,
    retrieveNumberOfSellerUser,
    currentuser,
  } = props;
  return (
    <>
      {isAdmin ? (
        <>
          <div className="m-0 font-sans text-base antialiased font-normal leading-default bg-gray-50 text-slate-500">
            <div className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200">
              <div className="w-full py-0 mx-auto">
                <div className="flex flex-wrap max-md:mx-5 ">
                  <DashboardReport
                    className="m-3 max-md:m-2 max-md:w-full"
                    rightIcon={
                      <UserGroupIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                    }
                    iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-pink-700 to-red-500"
                    title="Inscrit du jour"
                    value={retrieveDailySubscriberUsers}
                  />

                  <DashboardReport
                    className="m-3 max-md:m-2 max-md:w-full"
                    rightIcon={
                      <UserGroupIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                    }
                    iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-pink-700 to-red-500"
                    title="Agents"
                    value={retrieveSubscriberAgents}
                  />

                  <DashboardReport
                    className="m-3 max-md:m-2 max-md:w-full"
                    rightIcon={
                      <UserGroupIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                    }
                    iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-pink-700 to-red-500"
                    title="Dealers"
                    value={retrieveSubscriberDealers}
                  />

                  <DashboardReport
                    className="m-3 max-md:m-2 max-md:w-full"
                    rightIcon={
                      <CalendarDaysIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                    }
                    iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-pink-700 to-red-500"
                    title="Transactions du jour"
                    value={retrieveAllTransactions}
                  />

                  <DashboardReport
                    className="m-3 max-md:m-2 max-md:w-full"
                    rightIcon={
                      <UserGroupIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                    }
                    iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-pink-700 to-red-500"
                    title="Utilisateurs"
                    value={retrieveSubscriberUsers}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        currentuser?.userRole !== UserRole.SUPERVISOR && currentuser?.userRole !== UserRole.RESPONSABLEZONE && currentuser?.userRole !== UserRole.SUPERVISEURSUPERDEALER && (
          <div className="m-0 font-sans text-base antialiased font-normal leading-default bg-gray-50 text-slate-500">
            <div className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200">
              <div className="w-full py-0 mx-auto">
                <div className="flex flex-wrap max-md:mx-5">
                  <DashboardReport
                    className="m-3 max-md:m-2 max-md:w-full"
                    rightIcon={
                      <CreditCardIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                    }
                    iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-pink-700 to-red-500"
                    title="Transactions"
                    value={transctionCount}
                  />

                  <DashboardReport
                    className="m-3 max-md:m-2 max-md:w-full"
                    rightIcon={
                      <CheckBadgeIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                    }
                    iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-pink-700 to-red-500"
                    title="Transactions complètes"
                    value={completedTransactionCount}
                  />

                  <DashboardReport
                    className="m-3 max-md:m-2 max-md:w-full"
                    rightIcon={
                      <CalendarDaysIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                    }
                    iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-pink-700 to-red-500"
                    title="Transaction de la semaine"
                    value={transactionOnWeek}
                  />
                  { currentuser?.userRole === UserRole.DEALER && (
                    <DashboardReport
                     className="m-3 max-md:m-2 max-md:w-full"
                     rightIcon={
                       <UsersIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                     }
                     iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-pink-700 to-red-500 text-black"
                     title="Agents créés"
                     value={retrieveNumberOfDealerAgent}
                   />
                  )}
                  { currentuser?.isSeller && (
                    <DashboardReport
                     className="m-3 max-md:m-2 max-md:w-full"
                     rightIcon={
                       <UsersIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                     }
                     iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-pink-700 to-red-500 text-black"
                     title="Utilisateurs créés"
                     value={retrieveNumberOfSellerUser}
                   />
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default HeaderDashboardComponent;
