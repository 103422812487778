import { classNames, unwindObject } from '../../../utils';
import { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { HelpTooltip } from '../HelpTooltip';

export interface FormRadioElement {
  name: string;
  value?: string;
  description?: string;
}
export interface FormRadioProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'id' | 'className' | 'type'
  > {
  name: string;
  label?: string;
  optional?: boolean;
  helpLabel?: string;
  leadingText?: string;
  trailingText?: string;
  condensed?: boolean;
  tooltip?: string;
  options: FormRadioElement[];
}

export function FormRadio({
  name,
  label,
  optional,
  helpLabel,
  leadingText,
  trailingText,
  condensed = false,
  tooltip,
  options,
  ...rest
}: FormRadioProps): JSX.Element {
  const methods = useFormContext();
  const errorName = unwindObject((methods as any).formState.errors, name);
  return (
    <fieldset className={classNames(condensed ? 'my-1' : 'my-4')}>
      <legend className="sr-only">Plan</legend>
      <div className={classNames(optional ? 'flex justify-between' : 'my-1')}>
        {label && (
          <label
            htmlFor={name}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
            {tooltip && <HelpTooltip value={tooltip} />}
          </label>
        )}
        {optional && <span className="text-sm text-gray-500">Optional</span>}
      </div>
      <div className="flex justify-between mt-2">
        {options.map((option, optionIdx) => (
          <div
            key={`${option.name}-${optionIdx}`}
            className="relative flex items-start"
          >
            <div className="flex items-center h-5">
              <input
                id={option.name}
                aria-describedby={`${optionIdx}-description`}
                {...methods.register(name)}
                type="radio"
                // checked={optionIdx===0}
                value={option.value ?? option.name}
                className="w-4 h-4 text-red-600 border-gray-300 focus:ring-red-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor={option.name}
                className="font-medium text-gray-700"
              >
                {option.name}
              </label>
              <span
                id={`${optionIdx}-description`}
                className="ml-3 text-gray-500"
              >
                {option.description}
              </span>
            </div>
          </div>
        ))}
      </div>
      {helpLabel && !errorName && (
        <p
          className={classNames(
            condensed ? 'mt-1' : 'mt-2',
            'text-sm text-gray-500',
          )}
        >
          {helpLabel}
        </p>
      )}
      {errorName && (
        <p
          className={classNames(
            condensed ? 'mt-1' : 'mt-2',
            'text-sm text-red-600',
          )}
        >
          {errorName.message}
        </p>
      )}
    </fieldset>
  );
}

export default FormRadio;
