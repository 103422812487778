/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLocation, useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import {
  CheckCircleIcon,
  MagnifyingGlassIcon,
  UserIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { UserRole } from "../../interfaces";
import { usersCollectionName } from "../../config";
import Moment from "react-moment";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../components/FirestoreTable";
import { useMemo, useState } from "react";
import Modal from "../../components/Modal";
import PhoneInput from "react-phone-number-input";

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "profilImageUrl",
    label: "Profil",
  },
  {
    field: "displayName",
    label: "Nom/prénom",
  },
  {
    field: "email",
    label: "Email",
  },
  {
    field: "phoneNumber",
    label: "Téléphone",
  },
  {
    field: "city",
    label: "Ville",
  },
  {
    field: "active",
    label: "Active",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "displayName":
      return <span>{value ? value : "-"}</span>;
    case "profilImageUrl":
      return (
        <span>
          {" "}
          {value ? (
            <img
              className="w-10 h-10 p-1 border rounded-full"
              src={
                value
                  ? value
                  : `https://ui-avatars.com/api/?background=random&name=${
                      value || "S"
                    }`
              }
              alt=""
            />
          ) : (
            <UserIcon className="w-10 h-10 p-1 border rounded-full" />
          )}
        </span>
      );
    case "email":
      return <span>{value ? value : "-"}</span>;
    case "city":
      return <span>{value ? value : "-"}</span>;
    case "phoneNumber":
      return <span>{value}</span>;
    case "active":
      return (
        <span>
          {" "}
          {value ? (
            <CheckCircleIcon className="w-6 h-6 text-green-500" />
          ) : (
            <XCircleIcon className="w-6 h-6 text-red-500" />
          )}
        </span>
      );
    default:
      return value as string;
  }
};
const DealerAgentPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dealer = location.state?.entity;
  const [userData, setUserData] = useState<any>();
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const onRowDetails = (id: number, dealer: any) => {
    if (dealer) {
      navigate(`/agents/${dealer.id}/details`, {
        state: { entity: dealer, id: dealer.id },
      });
    }
  };

  // Start Function to redirect "SUPERVISOR" toward activities page
  const onShowAgentActivities = (id: number, dealer: any) => {
    if (dealer) {
      navigate(`/agents/${dealer.id}/activities`, {
        state: { entity: dealer, id: dealer.id },
      });
    }
  };
  // End Function to redirect "SUPERVISOR" toward activities page

  const retrieveDealerPhoneNumber = async (phoneNumber: string) => {
    if (phoneNumber.length >= 12) {
      await db
        .collection(usersCollectionName)
        .where("userRole", "==", UserRole.AGENT)
        .where("phoneNumber", "==", phoneNumber)
        .where("dealerId", "==", dealer.id)
        .get()
        .then((response) => {
          setUserData(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
        });
    } else {
      setUserData([]);
    }
  };

  const firestoreTableQueriesWhere = useMemo(() => {
    const queries = [];
    if (dealer) {
      queries.push({
        fieldPath: "dealerId",
        opStr: "==",
        value: dealer.id,
      });
    }
    return queries;
  }, []);

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les agents appartenant à un dealer
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ci-dessous tous les agents appartenant au dealer{" "}
            <span className="font-semibold">{dealer?.displayName}</span>
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-1 sm:flex-none">
          <button
            type="button"
            className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-700"
            onClick={() => setShowDetailsModal(true)}
          >
            <MagnifyingGlassIcon
              className="self-center flex-shrink-0 w-5 h-5 mr-1"
              aria-hidden="true"
            />
            Rechercher un agent
          </button>
        </div>
      </div>
      <div className="flex flex-col mt-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="shadow-sm">
              <FirestoreTable
                collectionRef={db.collection(usersCollectionName)}
                collectionQueriesWhere={[
                  {
                    fieldPath: "userRole",
                    opStr: "==",
                    value: UserRole.AGENT,
                  },
                  ...firestoreTableQueriesWhere,
                ]}
                condensed
                columns={columns}
                rowRender={renderRow}
                rowDetailsHandler={onRowDetails}
                rowShowActivitiesHandler={onShowAgentActivities}
                displayDetails
                displayShowActivitiesBtn
                shouldAddEditColumn
                paginate={{ pageIndex: 0, limit: 100, sort: "createdAt:desc" }}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showDetailsModal}
        maxSize="md"
        closeModal={() => setShowDetailsModal(false)}
        modalTitle="Rechercher un dealer"
      >
        <div className="flex justify-center">
          <div className="w-full mt-10">
            <PhoneInput
              international={false}
              placeholder="Entrer un numéro de téléphone"
              className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
              defaultCountry="BF"
              onChange={retrieveDealerPhoneNumber}
            />
            <div className="flex justify-center w-full">
              {userData && userData.length > 0 && (
                <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="flex flex-col items-center pb-10">
                    <img
                      className="w-24 h-24 mt-2 mb-3 rounded-full shadow-lg"
                      src={
                        userData[0].data.profilImageUrl
                          ? userData[0].data.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${
                              userData[0].data.displayName || "S"
                            }`
                      }
                      alt=""
                    />
                    <h5 className="mb-1 text-xl font-medium text-center text-gray-900 dark:text-white">
                      {userData[0].data.displayName}
                    </h5>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {userData[0].data.phoneNumber}
                    </span>
                    <div className="flex mt-4 space-x-3 md:mt-6">
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        onClick={() =>
                          navigate(`/agents/${userData[0].id}/details`, {
                            state: {
                              entity: userData[0].data,
                              id: userData[0].id,
                              isSearch: false,
                            },
                          })
                        }
                      >
                        Details
                      </div>
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                        onClick={() => {
                          setShowDetailsModal(false);
                          setUserData([]);
                        }}
                      >
                        Annuler
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DealerAgentPage;
