/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { db, functions } from "../../firebase";
import useCrudDocument from "../../hooks/useCrudDocument";
import toast from "react-hot-toast";
import {
  ArrowDownTrayIcon,
  CheckCircleIcon,
  MagnifyingGlassIcon,
  UserIcon,
  UserPlusIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { AgencyStaffRole, UserInterface, UserRole } from "../../interfaces";
import { accountsCollectionName, usersCollectionName } from "../../config";
import Moment from "react-moment";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../components/FirestoreTable";
import HasRoles from "../../components/HasRoles";
import { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts";
import Modal from "../../components/Modal";
import PhoneInput from "react-phone-number-input";
import * as XLSX from "xlsx";
import { Timestamp } from "firebase-admin/firestore";
import { classNames } from "../../utils";
import { useConfirm } from "../../hooks/useConfirm";

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "profilImageUrl",
    label: "Profil",
  },
  {
    field: "displayName",
    label: "Nom/prénom",
  },
  {
    field: "adresse",
    label: "Adresse",
  },
  {
    field: "phoneNumber",
    label: "Téléphone",
  },
  {
    field: "city",
    label: "Ville",
  },
  {
    field: "active",
    label: "Active",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "displayName":
      return <span>{value ? value : "-"}</span>;
    case "profilImageUrl":
      return (
        <span>
          {" "}
          {value ? (
            <img
              className="w-10 h-10 p-1 border rounded-full"
              src={
                value
                  ? value
                  : `https://ui-avatars.com/api/?background=random&name=${
                      value || "S"
                    }`
              }
              alt=""
            />
          ) : (
            <UserIcon className="w-10 h-10 p-1 border rounded-full" />
          )}
        </span>
      );
    case "adresse":
      return <span>{value ? value : "-"}</span>;
    case "city":
      return <span>{value ? value : "-"}</span>;
    case "phoneNumber":
      return <span>{value}</span>;
    case "active":
      return (
        <span>
          {" "}
          {value ? (
            <CheckCircleIcon className="w-6 h-6 text-green-500" />
          ) : (
            <XCircleIcon className="w-6 h-6 text-red-500" />
          )}
        </span>
      );
    default:
      return value as string;
  }
};

const DealersPage = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState<any>();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [dealersData, setDealersData] = useState<any>();
  const [superDealerData, setSuperDealerData] = useState<any>();
  const [userDetailsData, setUserDetailsData] = useState<UserInterface>();
  const { isConfirmModal } = useConfirm();

  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );

  const onEditRow = (id: number, entity: any) => {
    if (entity) {
      navigate(`/dealers/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  const onRowDetails = (id: number, entity: any) => {
    if (entity) {
      navigate(`/dealers/${entity.id}/details`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };

  const onShowDealerAgent = (id: number, entity: any) => {
    if (entity) {
      navigate(`/dealers/${entity.id}/agents`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };

  // Start Function to redirect "SUPERVISOR" toward activities page
  const onShowDealerActivities = (id: number, entity: any) => {
    if (entity) {
      if (user?.userRole === UserRole.SUPERDEALER) {
        navigate(`/dealers/${entity.id}/activity`, {
          state: { entity: entity, id: entity.id },
        });
      } else {
        navigate(`/dealers/${entity.id}/activities`, {
          state: { entity: entity, id: entity.id },
        });
      }
    }
  };
  // End Function to redirect "SUPERVISOR" toward activities page

  const onAffiliateToSupervisor = async (id: number, entity: any) => {
    if (entity) {
      const isConfirm = await isConfirmModal(
        "Opération",
        "Voulez-vous affilier a un superviseur ?",
        "Oui",
        true
      );
      if (isConfirm) {
        navigate(`/dealers/${entity.id}/edit`, {
          state: { entity: entity, id: entity.id, affiliate: true },
        });
      } else {
        setShowModal(true);
        setUserDetailsData(entity);
      }
    }
  };

  const retrivePhoneNumber = async (phoneNumber: string) => {
    if (phoneNumber.length >= 12) {
      await db
        .collection(usersCollectionName)
        .where("userRole", "==", UserRole.USER)
        .where("phoneNumber", "==", phoneNumber)
        .get()
        .then((response) => {
          if (response.empty) {
            return toast.error("Le numéro n'est pas un utilisateur");
          }
          setUserData(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
        });
    } else {
      setUserData([]);
    }
  };

  const retrivePhoneNumberSuperDealer = async (phoneNumber: string) => {
    if (phoneNumber.length >= 12) {
      await db
        .collection(usersCollectionName)
        .where("phoneNumber", "==", phoneNumber)
        .where("userRole", "==", UserRole.SUPERDEALER)
        .get()
        .then((response) => {
          // response.docs.map((el)=>
          // {
          //   if(el?.data().userRole === UserRole.RESPONSABLEZONE || el?.data().userRole === UserRole.SUPERVISOR){
          //     setIsResponsableOrSupervisor(!isResponsableOrSupervisor)
          //   }
          // }
          // )
          setSuperDealerData(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
        });
    } else {
      setSuperDealerData([]);
    }
  };

  const affiliateDealerToSuperDealer = async (id: string) => {
    const toastId = toast.loading("Affiliation...");
    sankmoneyUpdateAccountFn({
      collectionName: usersCollectionName,
      documentData: {
        superDealerId: id,
      },
      documentId: userDetailsData?.id,
    })
      .then(() => {
        setShowPinCodeModal(false);
        navigate("/dealers");
        toast.success("L'affiliation a été effectuée avec success.", {
          id: toastId,
        });
      })
      .catch((e) => {
        toast.error(e.message, {
          id: toastId,
        });
      });
  };

  const firestoreTableQueriesWhere = () => {
    let queries = [];

    switch (user?.userRole) {
      case UserRole.SUPERVISOR:
        queries.push({
          fieldPath: "userRole",
          opStr: "==",
          value: UserRole.DEALER,
        });
        queries.push({
          fieldPath: "supervisorId",
          opStr: "==",
          value: user?.id,
        });
        break;
      case UserRole.SUPERDEALER:
        queries.push({
          fieldPath: "userRole",
          opStr: "==",
          value: UserRole.DEALER,
        });
        queries.push({
          fieldPath: "superDealerId",
          opStr: "==",
          value: user?.id,
        });
        break;
      default:
        queries.push({
          fieldPath: "userRole",
          opStr: "==",
          value: UserRole.DEALER,
        });
        break;
    }
    return queries;
  };

  const retriveDealerPhoneNumber = async (phoneNumber: string) => {
    if (phoneNumber.length >= 12) {
      await db
        .collection(usersCollectionName)
        .where("userRole", "==", UserRole.DEALER)
        .where("phoneNumber", "==", phoneNumber)
        .get()
        .then((response) => {
          setUserData(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
        });
    } else {
      setUserData([]);
    }
  };
  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        const dealer = await db
          .collection(usersCollectionName)
          .where("supervisorId", "==", user?.id)
          .where("userRole", "==", UserRole.DEALER)
          .get();
        if (!dealer.empty) {
          setDealersData(
            dealer.docs.map((el) => {
              return { id: el.id, data: el.data() };
            })
          );
        } else {
          setDealersData([]);
        }
      };
      fetchData();
    }
  }, []);

  const exportToDealerData = useCallback(async () => {
    const worksheet = XLSX.utils.json_to_sheet(
      await Promise.all(
        (dealersData as [])?.map(async (user: any) => {
          const userRef = db.collection(usersCollectionName).doc(user.id);
          const sankAcountRef = userRef
            .collection(accountsCollectionName)
            .where("accountType", "==", "MAIN");
          const sankAcountsData = await sankAcountRef.get();
          const sankAccountData: any = sankAcountsData.docs[0].data();
          const balance: number = sankAccountData.balance;
          return {
            "Date de création de compte": (user.data.createdAt as Timestamp)
              .toDate()
              .toLocaleString(),
            "Nom prénom": user.data.displayName ?? "-",
            Email: user.data.email ?? "-",
            Ville: user.data.city ?? "-",
            Téléphone: user.data.phoneNumber ?? "-",
            // @ts-ignores
            Solde: balance ?? "0",
          };
        })
      )
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "dealers-liste");
    XLSX.writeFile(
      workbook,
      `La-liste-de-${
        dealersData?.length
      }-distributeurs-${new Date().toLocaleDateString()}.xlsx`,
      { compression: true }
    );
  }, [dealersData]);

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        {/* start page title */}
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les dealers
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ci-dessous tous les dealers.
          </p>
        </div>
        {/* end page title */}

        {/* start 8px of height between title section and next section */}
        <div className="h-8" />
        {/* end 8px of height between title section and next section */}

        {/* buttons section  */}
        <div className="flex mx-1 flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
          {/* Add dealer button */}
          <HasRoles userRole={["ADMIN", "AGENCYSTAFF", "SUPERVISOR"]}>
            <>
              {(user?.userRole === UserRole.ADMIN ||
                user?.userAgencySubRole === AgencyStaffRole.MANAGER) && (
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    type="button"
                    className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-700"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPinCodeModal(true);
                    }}
                  >
                    <UserPlusIcon
                      className="self-center flex-shrink-0 w-5 h-5 mr-1"
                      aria-hidden="true"
                    />
                    Ajouter un dealer
                  </button>
                </div>
              )}
            </>
          </HasRoles>
          {/* search dealer button */}
          <div className="mt-4 sm:mt-0 sm:ml-1 sm:flex-none">
            <button
              type="button"
              className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-700"
              onClick={() => setShowDetailsModal(true)}
            >
              <MagnifyingGlassIcon
                className="self-center flex-shrink-0 w-5 h-5 mr-1"
                aria-hidden="true"
              />
              Rechercher un dealer
            </button>
          </div>
          {/* Export button */}
          <HasRoles userRole={["SUPERVISOR"]}>
            <div className="mx-2">
              <button
                type="button"
                className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                onClick={() => {
                  exportToDealerData();
                }}
              >
                <ArrowDownTrayIcon
                  className={classNames(
                    "self-center flex-shrink-0 w-5 h-5 mr-1"
                  )}
                  aria-hidden="true"
                />
                <span className="sr-only">Exporter en XLS</span>
              </button>
            </div>
          </HasRoles>
        </div>
        {/* End buttons section */}
      </div>

      <FirestoreTable
        collectionRef={db.collection(usersCollectionName)}
        collectionQueriesWhere={firestoreTableQueriesWhere()}
        condensed
        columns={columns}
        rowRender={renderRow}
        rowEditHandler={onEditRow}
        rowDetailsHandler={onRowDetails}
        rowShowDealerAgentHandler={onShowDealerAgent}
        rowShowActivitiesHandler={onShowDealerActivities}
        rowAffiliateUserHandler={onAffiliateToSupervisor}
        displayAffiliateUserBtn
        displayDetails
        shouldAddEditColumn
        displayEditBtn
        displayShowDealerAgentBtn
        displayShowActivitiesBtn
        paginate={{ pageIndex: 0, limit: 100, sort: "createdAt:desc" }}
      />
      <Modal
        isOpen={showDetailsModal}
        maxSize="md"
        closeModal={() => setShowDetailsModal(false)}
        modalTitle="Rechercher un dealer"
      >
        <div className="flex justify-center">
          <div className="w-full mt-10">
            <PhoneInput
              international={false}
              placeholder="Entrer un numéro de téléphone"
              className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
              defaultCountry="BF"
              onChange={retriveDealerPhoneNumber}
            />
            <div className="flex justify-center w-full">
              {userData && userData.length > 0 && (
                <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="flex flex-col items-center pb-10">
                    <img
                      className="w-24 h-24 mt-2 mb-3 rounded-full shadow-lg"
                      src={
                        userData[0].data.profilImageUrl
                          ? userData[0].data.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${
                              userData[0].data.displayName || "S"
                            }`
                      }
                      alt=""
                    />
                    <h5 className="mb-1 text-xl font-medium text-center text-gray-900 dark:text-white">
                      {userData[0].data.displayName}
                    </h5>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {userData[0].data.phoneNumber}
                    </span>
                    <div className="flex mt-4 space-x-3 md:mt-6">
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        onClick={() =>
                          navigate(`/users/${userData[0].id}/details`, {
                            state: {
                              entity: userData[0].data,
                              id: userData[0].id,
                              isSearch: true,
                            },
                          })
                        }
                      >
                        Details
                      </div>
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                        onClick={() => {
                          setShowDetailsModal(false);
                          setUserData([]);
                        }}
                      >
                        Annuler
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showPinCodeModal}
        maxSize="lg"
        closeModal={() => setShowPinCodeModal(false)}
        modalTitle="Renseigner le numéro de téléphone"
      >
        <div className="flex justify-center">
          <div className="w-full mt-10">
            <PhoneInput
              international={false}
              placeholder="Entrer un numéro de téléphone"
              className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
              defaultCountry="BF"
              onChange={retrivePhoneNumber}
            />
            <div className="flex justify-center w-full">
              {userData && userData.length > 0 && (
                <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="flex flex-col items-center pb-10">
                    <img
                      className="w-24 h-24 mt-2 mb-3 rounded-full shadow-lg"
                      src={
                        userData[0].data.profilImageUrl
                          ? userData[0].data.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${
                              userData[0].data.displayName || "S"
                            }`
                      }
                      alt=""
                    />
                    <h5 className="mb-1 text-xl font-medium text-center text-gray-900 dark:text-white">
                      {userData[0].data.displayName}
                    </h5>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {userData[0].data.phoneNumber}
                    </span>
                    <div className="flex mt-4 space-x-3 md:mt-6">
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        onClick={() =>
                          navigate(`/dealers/make_user_to_dealer`, {
                            state: {
                              entity: userData[0].data,
                              id: userData[0].id,
                            },
                          })
                        }
                      >
                        Continuer
                      </div>
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                        onClick={() => {
                          setShowPinCodeModal(false);
                          setUserData([]);
                        }}
                      >
                        Annuler
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showModal}
        maxSize="lg"
        closeModal={() => setShowModal(false)}
        modalTitle={`Rechercher un Super Dealer`}
      >
        <div className="flex justify-center">
          <div className="w-full mt-10">
            <PhoneInput
              international={false}
              placeholder="Entrer un numéro de téléphone"
              className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
              defaultCountry="BF"
              onChange={retrivePhoneNumberSuperDealer}
            />
            <div className="flex justify-center w-full">
              {superDealerData && superDealerData.length > 0 && (
                <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="flex flex-col items-center pb-10">
                    <img
                      className="w-24 h-24 mt-2 mb-3 rounded-full shadow-lg"
                      src={
                        superDealerData[0].data.profilImageUrl
                          ? superDealerData[0].data.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${
                              superDealerData[0].data.displayName || "S"
                            }`
                      }
                      alt=""
                    />
                    <h5 className="mb-1 text-xl font-medium text-center text-gray-900 dark:text-white">
                      {superDealerData[0].data.displayName}
                    </h5>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {superDealerData[0].data.phoneNumber}
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Membre SankMoney depuis :{" "}
                      {new Date(
                        superDealerData[0].data.createdAt.seconds * 1000 +
                          superDealerData[0].data.createdAt.nanoseconds /
                            1000000
                      ).toLocaleDateString()}
                    </span>
                    <div className="flex mt-4 space-x-3 md:mt-5">
                      <button
                        className={classNames(
                          "inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        )}
                        // disabled={isResponsableOrSupervisor}
                        onClick={() =>
                          affiliateDealerToSuperDealer(superDealerData[0].id)
                        }
                      >
                        Affilier
                      </button>
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                        onClick={() => {
                          setShowPinCodeModal(false);
                          setSuperDealerData([]);
                        }}
                      >
                        Annuler
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DealersPage;
