/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLocation } from "react-router-dom";
import { db } from "../../../firebase";
import {
  sankmoneyDealerAccountRefillsAwaitingValidation,
  usersCollectionName,
} from "../../../config";
import Moment from "react-moment";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../../components/FirestoreTable";
import {
  SankMoneyReloadProcessStatusenum,
  sankMoneyReloadProcessStatusMap,
} from "../../../interfaces";
import { useMemo, useState, useCallback, useEffect } from "react";
import Modal from "../../../components/Modal";
import moment from "moment";
import { getTimestamp } from "../../../utils";
import RefillsOverview from "../../../components/DashboardComponents/DealerActivitiesOverview/RefillsOverview";

const columns: IColumn[] = [
  {
    field: "id",
    label: "ID de la transaction",
  },
  {
    field: "amount",
    label: "Montant",
  },
  {
    field: "status",
    label: "Statut",
  },
  {
    field: "createdAt",
    label: "Date de création",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "id":
      return <span>{value ? value : "-"}</span>;
    case "amount":
      return (
        <span>
          {new Intl.NumberFormat().format(parseInt(value ?? "0"))} FCFA
        </span>
      );
    case "status":
      return (
        <>
          {value === SankMoneyReloadProcessStatusenum.PENDING ? (
            <span className="p-2 text-yellow-700 border border-yellow-300 rounded-md">
              {sankMoneyReloadProcessStatusMap.get(value)}
            </span>
          ) : value === SankMoneyReloadProcessStatusenum.VALIDETED ? (
            <span className="p-2 text-green-700 border border-green-300 rounded-md">
              {sankMoneyReloadProcessStatusMap.get(value)}
            </span>
          ) : (
            <span className="p-2 text-red-700 border border-red-300 rounded-md">
              {sankMoneyReloadProcessStatusMap.get(value)}
            </span>
          )}
        </>
      );
    default:
      return value as string;
  }
};
const RefillsPage = () => {
  const location = useLocation();
  const dealer = location.state?.entity;
  const [reloadReceiveCount, setReloadReceiveCount] = useState<number>(0);
  const [totalReloadReceiveAmount, setTotalReloadReceiveAmount] =
    useState<number>(0);
  const [
    showrefillsAwaitingValidationModal,
    setShowrefillsAwaitingValidationModal,
  ] = useState(false);
  const [refillsAwaitingValidation, setRefillsAwaitingValidation] =
    useState<any>();
  const [participantUsers, setParticipantUsers] = useState<any[]>([]);
  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithEndDateValue(value.target.value);
  };

  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );

  const [startTime, setStartTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  );
  const [endTime, setEndTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, false)
  );

  //   Function to get
  const getFilteredByDate = useCallback(async () => {
    if (seachWithStartDateValue) {
      setStartTime(getTimestamp(seachWithStartDateValue, true));
    }

    if (seachWithEndDateValue) {
      setEndTime(getTimestamp(seachWithEndDateValue, false));
    }

    // Calculate number and total amount of  deposits
    try {
      const depositsRef = db
        .collection(sankmoneyDealerAccountRefillsAwaitingValidation)
        .where("dealerId", "==", dealer.id)
        .where("createdAt", ">=", getTimestamp(seachWithStartDateValue, true))
        .where("createdAt", "<=", getTimestamp(seachWithEndDateValue, false))
        .where("status", "==", SankMoneyReloadProcessStatusenum.VALIDETED);

      const snapshot = await depositsRef.get();
      let total = 0;
      snapshot.forEach((doc) => {
        total += parseInt(doc.data().amount || "0");
      });

      setReloadReceiveCount(snapshot.size);
      setTotalReloadReceiveAmount(total);
    } catch (error) {
      console.error("Error fetching deposits:", error);
    }
  }, [seachWithEndDateValue, seachWithStartDateValue, dealer.id]);

  const firestoreTableQueriesWhere = useMemo(() => {
    const queries = [];
    if (dealer) {
      queries.push({
        fieldPath: "dealerId",
        opStr: "==",
        value: dealer.id,
      });
    }
    if (startTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: ">=",
        value: startTime,
      });
    }

    if (endTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: "<=",
        value: endTime,
      });
    }
    return queries;
  }, [dealer, startTime, endTime]);

  useEffect(() => {
    getFilteredByDate();
  }, [getFilteredByDate]);

  const onRowDetails = async (id: number, entity: any) => {
    setRefillsAwaitingValidation(entity);
    const participantUsers = [];
    for (const id of entity.participantIds) {
      const user = await db.collection(usersCollectionName).doc(id).get();
      if (user.exists) {
        participantUsers.push(user.data());
      }
    }
    setParticipantUsers(participantUsers);

    setShowrefillsAwaitingValidationModal(true);
  };

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="flex flex-col">
        <RefillsOverview
          recoverReloadReceive={reloadReceiveCount}
          recoverAmountReloadReceive={totalReloadReceiveAmount}
        />
        <div className="h-4" />
        <div className="sm:flex sm:items-center">
          {/* Page Title */}
          <div className="sm:flex-auto">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              Les refills du dealer
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Vous trouverez ci-dessous toutes les recharges reçues par le
              dealer{" "}
              <span className="font-semibold">{dealer?.displayName}</span>
            </p>
          </div>

          <div className="flex mx-1 flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
            {/* Filter deposits by date */}
            <div className="flex items-center w-full space-x-3 md:w-auto">
              {/* start date */}
              <input
                type="date"
                id="dateDebut"
                className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                onChange={handleChangeStartDateValue}
              />

              {/* end date */}
              <input
                type="date"
                id="dateDebut"
                className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                onChange={handleChangeEndDtaeValue}
              />
            </div>
            {/* End filter deposits by date */}
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="shadow-sm">
              <FirestoreTable
                collectionRef={db.collection(
                  sankmoneyDealerAccountRefillsAwaitingValidation
                )}
                collectionQueriesWhere={[
                  {
                    fieldPath: "status",
                    opStr: "==",
                    value: SankMoneyReloadProcessStatusenum.VALIDETED,
                  },
                  ...firestoreTableQueriesWhere,
                ]}
                condensed
                columns={columns}
                rowRender={renderRow}
                rowDetailsHandler={onRowDetails}
                displayDetails
                paginate={{ pageIndex: 0, limit: 100 }}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showrefillsAwaitingValidationModal}
        maxSize="md"
        closeModal={() => setShowrefillsAwaitingValidationModal(false)}
        modalTitle={`Detail de la validation : ${refillsAwaitingValidation?.id}`}
      >
        <div className="p-2 my-4 bg-white border rounded-lg shadow-sm">
          <div className="">
            <ul className="-mb-8">
              <li>
                <div className="pb-8 ">
                  <div className="flex ">
                    <div>
                      <span className="flex items-center justify-center w-6 h-6 rounded-full ring-8 ring-white dark:ring-slate-800"></span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm text-gray-500">
                          <span className="font-semibold text-gray-700">
                            Détails de la Recharge
                          </span>{" "}
                        </p>
                        <p className="mt-2 text-sm text-gray-500">
                          <span className="font-bold text-gray-700 dark:text-gray-400">
                            Compte :
                          </span>{" "}
                          <span className="text-sm text-gray-900 dark:text-gray-300">
                            compte principal Sank-Money
                          </span>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-bold text-gray-700 dark:text-gray-400">
                            Montant de la Recharge :
                          </span>{" "}
                          <span className="text-sm text-gray-900 dark:text-gray-300">
                            {new Intl.NumberFormat().format(
                              parseInt(refillsAwaitingValidation?.amount ?? "0")
                            )}{" "}
                            FCFA
                          </span>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-bold text-gray-700 dark:text-gray-400">
                            Date et Heure de la Demande :
                          </span>{" "}
                          <span className="text-sm text-gray-900 dark:text-gray-300">
                            <Moment
                              locale="fr"
                              format="dddd DD MMMM YYYY à HH:mm:ss"
                            >
                              {
                                refillsAwaitingValidation?.createdAt.toDate() as Date
                              }
                            </Moment>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="pb-8 ">
                  <div className="flex ">
                    <div>
                      <span className="flex items-center justify-center w-6 h-6 rounded-full ring-8 ring-white dark:ring-slate-800"></span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm text-gray-500">
                          <span className="font-semibold text-gray-700">
                            Les validateurs
                          </span>{" "}
                        </p>
                        {participantUsers.length > 0 &&
                          participantUsers.map((user, index) => (
                            <p
                              key={index}
                              className="flex justify-between mt-1 text-sm text-gray-500"
                            >
                              <span className="font-bold text-gray-700 dark:text-gray-400">
                                {user?.phoneNumber}
                              </span>{" "}
                              <span className="items-center ml-5 text-sm text-gray-900 dark:text-gray-300">
                                {refillsAwaitingValidation[`${user?.id}`] ? (
                                  <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                ) : (
                                  <XCircleIcon className="w-6 h-6 text-red-500" />
                                )}
                              </span>
                            </p>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RefillsPage;
