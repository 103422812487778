/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLocation } from "react-router-dom";
import { db } from "../../firebase";
import { usersCollectionName, transactionCollectionName, commissionsCollectionsName, accountsCollectionName } from "../../config";
import Moment from "react-moment";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../components/FirestoreTable";
import { AccountType, transactionMap, TransactionType } from "../../interfaces";
import { useMemo, useState, useCallback, useEffect } from "react";
import moment from "moment";
import { getTimestamp } from "../../utils";
// import ReloadAndWithDrawOverview from "../../components/DashboardComponents/ReloadAndWithDrawOverview";
import TransactionsOverview from "../../components/DashboardComponents/DealerActivitiesOverview/TransactionsOverview";

const columns: IColumn[] = [
  {
    field: "isComplete",
    label: "Statut",
  },
  {
    field: "amount",
    label: "Montant",
  },
  {
    field: "senderDisplayName",
    label: "Nom de l'expéditeur",
  },
  {
    field: "senderPhoneNumber",
    label: "Expéditeur",
  },
  {
    field: "receiverPhoneNumber",
    label: "Destinataire",
  },
  {
    field: "transactionType",
    label: "Type d'opération",
  },
  {
    field: "createdAt",
    label: "Date de création",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "senderPhoneNumber":
      return <span>{value}</span>;
    case "receiverPhoneNumber":
      return <span>{value}</span>;
    case "amount":
      return <span>{new Intl.NumberFormat().format(value)} Fcfa</span>;
    case "transactionType":
      return <span>{transactionMap.get(value)} </span>;
    case "isComplete":
      return value ? (
        <CheckCircleIcon className="h-6 w-6 text-green-500" />
      ) : (
        <XCircleIcon className="h-6 w-6 text-red-500" />
      );
    default:
      return value as string;
  }
};
const AgentActivitiesPage = () => {
  const location = useLocation();
  const agent = location.state?.entity;
  const [depositCount, setDepositCount] = useState<number>(0);
  const [totalDepositAmount, setTotalDepositAmount] = useState<number>(0);
  const [withDrawalCount, setwithDrawalCount] = useState<number>(0);
  const [totalWithDrawalAmount, setTotalWithDrawalAmount] = useState<number>(0);
  const [commissionPerDay, setCommissionPerDay] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [participantUsers, setParticipantUsers] = useState<any[]>([]);
  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithEndDateValue(value.target.value);
  };

  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );

  const [startTime, setStartTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  );
  const [endTime, setEndTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, false)
  );

  //   Function to get filter by date
  const getFilteredByDate = useCallback(async () => {
    if (seachWithStartDateValue) {
      setStartTime(getTimestamp(seachWithStartDateValue, true));
    }

    if (seachWithEndDateValue) {
      setEndTime(getTimestamp(seachWithEndDateValue, false));
    }

    // Calculer les dépôts
    try {
      const DepositRef = db
        .collection(transactionCollectionName)
        .where("participantsIds", "array-contains", agent.id)
        .where("transactionType", "==", TransactionType.RELOAD)
        .where("createdAt", ">=", getTimestamp(seachWithStartDateValue, true))
        .where("createdAt", "<=", getTimestamp(seachWithEndDateValue, false));

      const depositSnapshot = await DepositRef.get();
      let totalDeposit = 0;
      depositSnapshot.forEach((doc) => {
        totalDeposit += parseInt(doc.data().amount || "0");
      });

      setDepositCount(depositSnapshot.size);
      setTotalDepositAmount(totalDeposit);

      // Calculer les retraits
      const WithdrawalRef = db
        .collection(transactionCollectionName)
        .where("receiverID", "==", agent.id)
        .where("transactionType", "==", TransactionType.WITHDRAW)
        .where("createdAt", ">=", getTimestamp(seachWithStartDateValue, true))
        .where("createdAt", "<=", getTimestamp(seachWithEndDateValue, false));

      const withdrawalSnapshot = await WithdrawalRef.get();
      let totalWithdrawal = 0;
      withdrawalSnapshot.forEach((doc) => {
        totalWithdrawal += parseInt(doc.data().amount || "0");
      });
      setwithDrawalCount(withdrawalSnapshot.size);
      setTotalWithDrawalAmount(totalWithdrawal);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  }, [seachWithEndDateValue, seachWithStartDateValue, agent.id]);

  const getCommissionPerDay = async () => {
    if (!agent) {
      return;
    } else {
      const id = agent?.id;
      await db
        .collection(commissionsCollectionsName)
        .orderBy("createdAt", "desc")
        .where("userId", "==", id)
        .where("commissionPaid", "==", false)
        .get()
        .then((response) => {
          let sum = 0;
          response.docs.forEach((el) => {
            sum += el.data().amount;
          });
          setCommissionPerDay(sum);
        });

        const userRef = db.collection(usersCollectionName).doc(agent?.id);
        const sankAcountRef = userRef
          .collection(accountsCollectionName)
          .where("accountType", "==", AccountType.MAIN);
    
          const sankAcountsData = await sankAcountRef.get();
          const sankAccountData: any = sankAcountsData.docs[0].data()?.balance;
          setBalance(sankAccountData)
    }
  };

  const firestoreTableQueriesWhere = useMemo(() => {
    const queries = [];
    if (agent) {
      queries.push({
        fieldPath: "participantsIds",
        opStr: "array-contains",
        value: agent.id,
      });
      queries.push({
        fieldPath: "transactionType",
        opStr: "in",
        value: [TransactionType.RELOAD, TransactionType.WITHDRAW],
      });
    }

    if (startTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: ">=",
        value: startTime,
      });
    }

    if (endTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: "<=",
        value: endTime,
      });
    }
    return queries;
  }, [agent, startTime, endTime]);

  useEffect(() => {
    getFilteredByDate();
    getCommissionPerDay();
  }, [getFilteredByDate]);

  const onRowDetails = async (id: number, entity: any) => {
    const participantUsers = [];
    for (const id of entity.participantIds) {
      const user = await db.collection(usersCollectionName).doc(id).get();
      if (user.exists) {
        participantUsers.push(user.data());
      }
    }
    setParticipantUsers(participantUsers);
  };

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="flex flex-col">
        <TransactionsOverview
          recoverDeposit={depositCount}
          recoverAmountDeposit={totalDepositAmount}
          recoverWithDraw={withDrawalCount}
          recoverAmountWithDraw={totalWithDrawalAmount}
          recoverCommission={commissionPerDay}
          recoverBalance={balance}
          displayCommissionBtn
        />
        <div className="h-4" />
        <div className="sm:flex sm:items-center">
          {/* Page Title */}
          <div className="sm:flex-auto">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              Les activités de l'agent
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Vous trouverez ci-dessous tous les retraits et recharges effectués
              par l'agent{" "}
              <span className="font-semibold">{agent?.displayName}</span>
            </p>
          </div>

          <div className="flex mx-1 flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
            {/* Filter reloads by date */}
            <div className="flex items-center w-full space-x-3 md:w-auto">
              {/* start date */}
              <input
                type="date"
                id="dateDebut"
                className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                onChange={handleChangeStartDateValue}
              />

              {/* end date */}
              <input
                type="date"
                id="dateDebut"
                className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                onChange={handleChangeEndDtaeValue}
              />
            </div>
            {/* End filter reloads by date */}
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="shadow-sm">
              <FirestoreTable
                collectionRef={db.collection(transactionCollectionName)}
                collectionQueriesWhere={[...firestoreTableQueriesWhere]}
                condensed
                columns={columns}
                rowRender={renderRow}
                rowDetailsHandler={onRowDetails}
                paginate={{ pageIndex: 0, limit: 100 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentActivitiesPage;
