import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { db } from "../../firebase";
import { referralsCollectionName, usersCollectionName } from "../../config";
import NoContent from "../../components/TableNoContentPage/NoContent";
import {
  ArrowDownTrayIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";
import { classNames, parseDate } from "../../utils";
import * as XLSX from "xlsx";
import Pagination from "../../components/Pagination/Pagination";

const tableHeader = [
  "N°",
  "Nom/prénom",
  "Email",
  "Ville",
  "Téléphone",
  "Active",
  "Date de création",
];

const SponsorDetailsPage = () => {
  const location = useLocation();
  const currentUser = location.state?.entity;
  const [sponsor, setSponsor] = useState<any>();
  const [affiliatedUsers, setAffiliatedUsers] = useState<any[]>([]);
  const [defaultLimitQuery, setDefaultLimitQuery] = useState(100);

  const retriveSponsorInfos = useCallback(async () => {
    if (!currentUser) {
      return;
    }
    const snap = await db
      .collection(usersCollectionName)
      .doc(currentUser?.referralId)
      .get();
    if (!snap.exists) setSponsor(undefined);
    setSponsor({ id: snap.id, data: snap.data() });
  }, [currentUser]);

  const retriveAffiliatedUser = useCallback(async () => {
    if (!currentUser) {
      return;
    }
    const data: any[] = [];
    for (const id of currentUser?.userIds) {
      const snap = await db.collection(usersCollectionName).doc(id).get();
      if (snap.exists) {
        data.push({
          ...snap.data(),
        });
      }
    }
    setAffiliatedUsers(data);
  }, [currentUser]);

  const exportToXLSData = useCallback(async () => {
    const data: any[] = [];
    for (const id of currentUser?.userIds) {
      const snap = await db.collection(usersCollectionName).doc(id).get();
      if (snap.exists) {
        data.push({
          ...snap.data(),
        });
      }
    }
    // setAffiliatedUsers(data);
    const worksheet = XLSX.utils.json_to_sheet(
      data.map((referral, index: number) => {
        return {
          "N°": index + 1,
          "Nom/Prénom": referral.displayName,
          Email: referral.email,
          Ville: referral.city,
          Téléphone: referral.phoneNumber,
          "Date de création": (referral.createdAt as Timestamp)
            .toDate()
            .toLocaleString(),
        };
      })
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "export");

    XLSX.writeFile(
      workbook,
      `Details_comptes_creer_${new Date().toLocaleDateString()}.xlsx`,
      { compression: true }
    );
  }, [currentUser?.userIds]);

  useEffect(() => {
    if (currentUser) {
      retriveSponsorInfos();
      retriveAffiliatedUser();
    }
  }, [currentUser, retriveAffiliatedUser, retriveSponsorInfos]);

  return (
    <section className="flex items-center py-16 bg-gray-100 md:py-20 font-poppins dark:bg-gray-800 ">
      <div className="justify-center flex-1 max-w-6xl px-4 py-4 mx-auto bg-white border rounded-md dark:border-gray-900 dark:bg-gray-900 md:py-10 md:px-10">
        <div>
          <div className="flex items-stretch justify-start w-full h-full px-4 mb-8 border-b border-gray-200 dark:border-gray-700 md:flex-row xl:flex-col md:space-x-6 lg:space-x-8 xl:space-x-0">
            <div className="flex items-start justify-start flex-shrink-0">
              <div className="flex items-center justify-center w-full pb-6 space-x-4 md:justify-start">
                <img
                  src={
                    sponsor?.data.profilImageUrl
                      ? sponsor?.data.profilImageUrl
                      : `https://ui-avatars.com/api/?background=random&name=${
                          sponsor?.data.displayName || "S"
                        }`
                  }
                  className="object-cover w-16 h-16 rounded-md"
                  alt="avatar"
                />
                <div className="flex flex-col items-start justify-start space-y-2">
                  <p className="text-lg font-semibold leading-4 text-left text-gray-800 dark:text-gray-400">
                    {sponsor?.data.displayName}
                  </p>
                  <p className="text-sm leading-4 text-gray-600 dark:text-gray-400">
                    {sponsor?.data.city}
                  </p>
                  <p className="text-sm leading-4 cursor-pointer dark:text-gray-400">
                    {sponsor?.data.email}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center pb-4 mb-3 border-b border-gray-200 dark:border-gray-700">
            <div className="w-full px-4 mb-4 md:w-1/4">
              <p className="mb-2 text-sm leading-5 text-gray-600 dark:text-gray-400 ">
                Téléphone:{" "}
              </p>
              <p className="text-base font-semibold leading-4 text-gray-800 dark:text-gray-400">
                {sponsor?.data.phoneNumber}
              </p>
            </div>
            <div className="w-full px-4 mb-4 md:w-1/4">
              <p className="mb-2 text-sm font-medium leading-5 text-gray-600 dark:text-gray-400 ">
                Total:{" "}
              </p>
              <p className="text-base font-semibold leading-4 text-blue-600 dark:text-gray-400">
                {currentUser?.allAffiliate}
              </p>
            </div>
          </div>
          <div className="px-4 mb-10">
            <button
              type="button"
              className={classNames(
                affiliatedUsers.length > 0
                  ? "text-green-700 border-green-300 hover:bg-green-50 focus:ring-green-500 mb-2"
                  : "text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-gray-500 cursor-not-allowed mb-2",
                "inline-flex items-center h-10 px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
              )}
              onClick={() => {
                affiliatedUsers.length > 0 && exportToXLSData();
              }}
              disabled={affiliatedUsers.length > 0 ? false : true}
            >
              <ArrowDownTrayIcon
                className={classNames(
                  affiliatedUsers.length > 0
                    ? "text-green-500"
                    : "text-gray-500",
                  "self-center flex-shrink-0 w-5 h-5 mr-1"
                )}
                aria-hidden="true"
              />
              <span className="sr-only">Exporter en XLS</span>
            </button>
            <div className="flex flex-col items-stretch justify-center w-full space-y-4 md:flex-row md:space-y-0 md:space-x-8">
              <table
                className="min-w-full border-separate"
                style={{ borderSpacing: 0 }}
              >
                <thead className="bg-gray-50">
                  <tr>
                    {tableHeader.map((th, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                      >
                        {th}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {affiliatedUsers && affiliatedUsers.length > 0 ? (
                    affiliatedUsers.map((user: any, userIdx: number) => (
                      <tr key={userIdx}>
                        <td
                          className={classNames(
                            userIdx !== affiliatedUsers.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {(userIdx as number) + 1}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== affiliatedUsers.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {user.displayName ? user.displayName : "-"}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== affiliatedUsers.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {user.email ? user.email : "-"}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== affiliatedUsers.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {user.city ? user.city : "-"}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== affiliatedUsers.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {user.phoneNumber ?? "-"}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== affiliatedUsers.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {user.active ? (
                            <CheckCircleIcon className="w-6 h-6 text-green-500" />
                          ) : (
                            <XCircleIcon className="w-6 h-6 text-red-500" />
                          )}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== affiliatedUsers.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {parseDate(user?.createdAt as Timestamp)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoContent />
                  )}
                </tbody>
              </table>
            </div>
            {/* <Pagination
              skip={affiliatedUsers.length}
              take={defaultLimitQuery}
              total={affiliatedUsers.length}
              collectionName={referralsCollectionName}
              onPageChange={(pageIndex: number) => pageIndex}
              onPageLimitChange={(pageLimit: number) =>
                setDefaultLimitQuery(pageLimit)
              }
              isChangedPage={false}
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SponsorDetailsPage;
