/* eslint-disable jsx-a11y/anchor-is-valid */
import { Menu, Transition } from "@headlessui/react";
import {
  PencilIcon,
  Bars3Icon,
  ArrowsRightLeftIcon,
  CheckIcon,
  CreditCardIcon,
  EyeIcon,
  UserIcon,
  NoSymbolIcon,
  ShoppingBagIcon,
  BanknotesIcon,
  LinkIcon,
  MapIcon,
  ListBulletIcon,
  CircleStackIcon,
  PaperClipIcon,
  ArrowPathIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../../utils";
import { Fragment, MouseEventHandler } from "react";
import HasRoles from "../HasRoles";
import { useConfirm } from "../../hooks/useConfirm";
import { FingerPrintIcon } from "@heroicons/react/24/solid";

export interface TableEditMenuProps {
  rowEditHandler?: MouseEventHandler;
  rowChangeAgencyHandler?: MouseEventHandler;
  rowDetailsHandler?: MouseEventHandler;
  rowDeleteHandler?: MouseEventHandler;
  rowDisableHandler?: MouseEventHandler;
  rowTranfertMoneyHandler?: MouseEventHandler;
  rowCertifyUserHandler?: MouseEventHandler;
  rowAddUserHandler?: MouseEventHandler;
  rowAddManagerHandler?: MouseEventHandler;
  rowAffectedSupervisorHandler?: MouseEventHandler;
  rowAffiliateHandler?: MouseEventHandler;
  rowChangeManagerHandler?: MouseEventHandler;
  rowUpdateUserPINHandler?: MouseEventHandler;
  rowResetPasswordHandler?: MouseEventHandler;
  rowAddMerchantsForSuperMerchantHandler?: MouseEventHandler;
  rowAffiliatedCollectionToCollerctorHandler?: MouseEventHandler;
  rowAddStationsForSuperStationHandler?: MouseEventHandler;
  rowAddCompanyFeeHandler?: MouseEventHandler;
  rowAffiliateUserHandler?: MouseEventHandler;
  rowEditMapHandler?: MouseEventHandler;
  rowShowDealerAgentHandler?: MouseEventHandler;
  rowShowActivitiesHandler?: MouseEventHandler;
  rowAddDepositHandler?: MouseEventHandler;
  rowRetractToUserHandler?: MouseEventHandler;
  rowShowSuperDealerDealersHandler?: MouseEventHandler;
  displayDepositBtn?: boolean;
  displayEditBtn: boolean;
  displayChangeAgencyBtn?: boolean;
  displayCertificationBtn: boolean;
  displayDisableBtn: boolean;
  displayDeleteBtn: boolean;
  displayDetails: boolean;
  displayUpdateUserPINBtn: boolean;
  displayAddUserBtn: boolean;
  displayAddManagerBtn: boolean;
  displayChangeManagerBtn: boolean;
  displayTranfertBtn: boolean;
  displayRetractToUserBtn: boolean;
  displayResetPasswordBtn: boolean;
  displayAddMerchantsForSuperMerchantBtn?: boolean;
  displayAffiliatedCollectionToCollerctorBtn?: boolean;
  displayAddStationsForSuperStationBtn?: boolean;
  displayAddCompanyFeeBtn?: boolean;
  displayAffiliateUserBtn?: boolean;
  displayEditMapBtn?: boolean;
  displayShowDealerAgentBtn?: boolean;
  displayShowActivitiesBtn?: boolean;
  displayAffectedSupervisorBtn?: boolean;
  displayAffiliateBtn?: boolean;
  displayShowSuperDealerDealersBtn?: boolean;
  children?: any;
}

export function TableRowEditMenu(props: TableEditMenuProps) {
  const { isConfirmModal } = useConfirm();
  const onDisable = async (event: any) => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir desactiver cet utilisateur",
      "Oui desactiver",
      true
    );

    if (isConfirm && props.rowDisableHandler) props.rowDisableHandler(event);
  };
  const onDelete = async (event: any) => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir continuer la suppression ?",
      "Oui supprimer",
      true
    );

    if (isConfirm && props.rowDeleteHandler) props.rowDeleteHandler(event);
  };
  const onUpdateUserPIN = async (event: any) => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir réinitialiser le code pin de l'utilisateur",
      "Oui réinitialiser",
      true
    );

    if (isConfirm && props.rowUpdateUserPINHandler)
      props.rowUpdateUserPINHandler(event);
  };
  const onResetPassword = async (event: any) => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir reset le mot de passe de l'utilisateur",
      "Oui reset",
      true
    );

    if (isConfirm && props.rowResetPasswordHandler)
      props.rowResetPasswordHandler(event);
  };

  return (
    <Menu as="div" className="relative flex items-center justify-end">
      <Menu.Button className="inline-flex items-center justify-center w-8 h-8 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
        <span className="sr-only">Actions</span>
        <Bars3Icon className="w-5 h-5" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute top-0 z-10 w-48 mx-3 mt-1 origin-top-right bg-white divide-y divide-gray-200 rounded-md shadow-lg right-7 ring-1 ring-black ring-opacity-5 focus:outline-none">
          {/* Actions */}
          <>
            {props.children ? props.children : <></>}

            {props.rowDetailsHandler && props.displayDetails && (
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <span
                      onClick={props.rowDetailsHandler}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                    >
                      <EyeIcon
                        className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Détails
                    </span>
                  )}
                </Menu.Item>
              </div>
            )}
            <HasRoles userRole={["ADMIN", "SUDO", "TECHNICALSUPPORT"]}>
              <>
                {props.rowEditHandler && props.displayEditBtn && (
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          onClick={props.rowEditHandler}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "group flex items-center px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          <PencilIcon
                            className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          Edit
                        </span>
                      )}
                    </Menu.Item>
                  </div>
                )}
              </>
            </HasRoles>

            <HasRoles userRole={["ADMIN", "SUDO"]}>
              <>
                {props.rowRetractToUserHandler &&
                  props.displayRetractToUserBtn && (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={props.rowRetractToUserHandler}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm cursor-pointer"
                            )}
                          >
                            <ArrowPathIcon
                              className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Retracter
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  )}
              </>
            </HasRoles>

            {props.rowTranfertMoneyHandler && props.displayTranfertBtn && (
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <span
                      onClick={props.rowTranfertMoneyHandler}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                    >
                      <CreditCardIcon
                        className="w-5 h-5 mr-3 text-indigo-400 group-hover:text-indigo-500"
                        aria-hidden="true"
                      />
                      Recharger
                    </span>
                  )}
                </Menu.Item>
              </div>
            )}
            <HasRoles userRole={["ADMIN", "SUDO"]}>
              <>
                {props.rowAddUserHandler && props.displayAddUserBtn && (
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          onClick={props.rowAddUserHandler}
                          data-modal-target="popup-modal"
                          data-modal-toggle="popup-modal"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "group flex items-center px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          <UserIcon
                            className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          Ajouter un gérant
                        </span>
                      )}
                    </Menu.Item>
                  </div>
                )}
              </>
            </HasRoles>
            <HasRoles userRole={["ADMIN"]}>
              <>
                {props.rowAffectedSupervisorHandler &&
                  props.displayAffectedSupervisorBtn && (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={props.rowAffectedSupervisorHandler}
                            data-modal-target="popup-modal"
                            data-modal-toggle="popup-modal"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm cursor-pointer"
                            )}
                          >
                            <UserIcon
                              className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Affecter un superviseur
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  )}
              </>
            </HasRoles>
            <HasRoles userRole={["ADMIN"]}>
              <>
                {props.rowAffiliateHandler && props.displayAffiliateBtn && (
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          onClick={props.rowAffiliateHandler}
                          data-modal-target="popup-modal"
                          data-modal-toggle="popup-modal"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "group flex items-center px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          <UserIcon
                            className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          Affilier a un responsable Zone
                        </span>
                      )}
                    </Menu.Item>
                  </div>
                )}
              </>
            </HasRoles>
            <HasRoles userRole={["ADMIN", "SUDO"]}>
              <>
                {props.rowAddManagerHandler && props.displayAddManagerBtn && (
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          onClick={props.rowAddManagerHandler}
                          data-modal-target="popup-modal"
                          data-modal-toggle="popup-modal"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "group flex items-center px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          <UserIcon
                            className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          Ajouter un manager
                        </span>
                      )}
                    </Menu.Item>
                  </div>
                )}
              </>
            </HasRoles>
            <HasRoles userRole={["ADMIN", "SUDO"]}>
              <>
                {props.rowChangeManagerHandler &&
                  props.displayChangeManagerBtn && (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={props.rowChangeManagerHandler}
                            data-modal-target="popup-modal"
                            data-modal-toggle="popup-modal"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm cursor-pointer"
                            )}
                          >
                            <UserIcon
                              className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Changer de manager
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  )}
              </>
            </HasRoles>
            <HasRoles
              userRole={["ADMIN", "SUDO", "AGENCYSTAFF", "TECHNICALSUPPORT"]}
            >
              <>
                {props.rowCertifyUserHandler &&
                  props.displayCertificationBtn && (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={props.rowCertifyUserHandler}
                            data-modal-target="popup-modal"
                            data-modal-toggle="popup-modal"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm cursor-pointer"
                            )}
                          >
                            <CheckIcon
                              className="w-5 h-5 mr-3 text-green-400 group-hover:text-green-500"
                              aria-hidden="true"
                            />
                            Certifier
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  )}
              </>
            </HasRoles>
            <HasRoles userRole={["ADMIN", "SUDO"]}>
              <>
                {props.rowUpdateUserPINHandler &&
                  props.displayUpdateUserPINBtn && (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={(event) => onUpdateUserPIN(event)}
                            data-modal-target="popup-modal"
                            data-modal-toggle="popup-modal"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm cursor-pointer"
                            )}
                          >
                            <FingerPrintIcon
                              className="w-5 h-5 mr-3 text-red-400 group-hover:text-red-500"
                              aria-hidden="true"
                            />
                            Réinitialiser code PIN
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  )}
              </>
            </HasRoles>
            <HasRoles userRole={["ADMIN", "SUDO"]}>
              <>
                {props.rowResetPasswordHandler &&
                  props.displayResetPasswordBtn && (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={(event) => onResetPassword(event)}
                            data-modal-target="popup-modal"
                            data-modal-toggle="popup-modal"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm cursor-pointer"
                            )}
                          >
                            <FingerPrintIcon
                              className="w-5 h-5 mr-3 text-red-400 group-hover:text-red-500"
                              aria-hidden="true"
                            />
                            Réinitialiser le mot passe
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  )}
              </>
            </HasRoles>
            <HasRoles userRole={["ADMIN", "SUDO", "AGENCYSTAFF"]}>
              <>
                {props.rowAddDepositHandler && props.displayDepositBtn && (
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          onClick={props.rowAddDepositHandler}
                          data-modal-target="popup-modal"
                          data-modal-toggle="popup-modal"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "group flex items-center px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          <PaperClipIcon
                            className="w-5 h-5 mr-3 text-red-400 group-hover:text-red-500"
                            aria-hidden="true"
                          />
                          Déposé une caution
                        </span>
                      )}
                    </Menu.Item>
                  </div>
                )}
              </>
            </HasRoles>
            <HasRoles userRole={["ADMIN", "SUDO"]}>
              <>
                {props.rowDisableHandler && props.displayDisableBtn && (
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          onClick={(event) => onDisable(event)}
                          data-modal-target="popup-modal"
                          data-modal-toggle="popup-modal"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "group flex items-center px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          <NoSymbolIcon
                            className="w-5 h-5 mr-3 text-red-400 group-hover:text-red-500"
                            aria-hidden="true"
                          />
                          Desactiver
                        </span>
                      )}
                    </Menu.Item>
                  </div>
                )}
              </>
            </HasRoles>
            <HasRoles userRole={["ADMIN", "SUDO"]}>
              <>
                {props.rowDeleteHandler && props.displayDeleteBtn && (
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          onClick={(event) => onDelete(event)}
                          data-modal-target="popup-modal"
                          data-modal-toggle="popup-modal"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "group flex items-center px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          <NoSymbolIcon
                            className="w-5 h-5 mr-3 text-red-400 group-hover:text-red-500"
                            aria-hidden="true"
                          />
                          Supprimer
                        </span>
                      )}
                    </Menu.Item>
                  </div>
                )}
              </>
            </HasRoles>
            <HasRoles userRole={["ADMIN", "SUDO"]}>
              <>
                {props.rowChangeAgencyHandler &&
                  props.displayChangeAgencyBtn && (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={props.rowChangeAgencyHandler}
                            data-modal-target="popup-modal"
                            data-modal-toggle="popup-modal"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm cursor-pointer"
                            )}
                          >
                            <ArrowsRightLeftIcon
                              className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Réaffecter
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  )}
              </>
            </HasRoles>
            <HasRoles userRole={["ADMIN", "SUDO", "TECHNICALSUPPORT"]}>
              <>
                {props.rowAddMerchantsForSuperMerchantHandler &&
                  props.displayAddMerchantsForSuperMerchantBtn && (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={
                              props.rowAddMerchantsForSuperMerchantHandler
                            }
                            data-modal-target="popup-modal"
                            data-modal-toggle="popup-modal"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm cursor-pointer"
                            )}
                          >
                            <ShoppingBagIcon
                              className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Affecter un marchants
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  )}
              </>
            </HasRoles>
            <HasRoles userRole={["ADMIN"]}>
              <>
                {props.rowAffiliatedCollectionToCollerctorHandler &&
                  props.displayAffiliatedCollectionToCollerctorBtn && (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={
                              props.rowAffiliatedCollectionToCollerctorHandler
                            }
                            data-modal-target="popup-modal"
                            data-modal-toggle="popup-modal"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm cursor-pointer"
                            )}
                          >
                            <CircleStackIcon
                              className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Affecter un point de collecte
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  )}
              </>
            </HasRoles>
            <HasRoles userRole={["ADMIN", "SUDO", "TECHNICALSUPPORT"]}>
              <>
                {props.rowAddStationsForSuperStationHandler &&
                  props.displayAddStationsForSuperStationBtn && (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={props.rowAddStationsForSuperStationHandler}
                            data-modal-target="popup-modal"
                            data-modal-toggle="popup-modal"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm cursor-pointer"
                            )}
                          >
                            <ShoppingBagIcon
                              className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Affecter une station
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  )}
              </>
            </HasRoles>
            <HasRoles userRole={["ADMIN", "SUDO"]}>
              <>
                {props.rowAddCompanyFeeHandler &&
                  props.displayAddCompanyFeeBtn && (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={props.rowAddCompanyFeeHandler}
                            data-modal-target="popup-modal"
                            data-modal-toggle="popup-modal"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm cursor-pointer"
                            )}
                          >
                            <BanknotesIcon
                              className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Frais
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  )}
              </>
            </HasRoles>
            <HasRoles userRole={["ADMIN", "TECHNICALSUPPORT"]}>
              <>
                {props.rowAffiliateUserHandler &&
                  props.displayAffiliateUserBtn && (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={props.rowAffiliateUserHandler}
                            data-modal-target="popup-modal"
                            data-modal-toggle="popup-modal"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm cursor-pointer"
                            )}
                          >
                            <LinkIcon
                              className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Affilier
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  )}
              </>
            </HasRoles>
            <HasRoles
              userRole={[
                "ADMIN",
                "SUPERVISOR",
                "AGENCYSTAFF",
                "TECHNICALSUPPORT",
              ]}
            >
              <>
                {props.rowEditMapHandler && props.displayEditMapBtn && (
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          onClick={props.rowEditMapHandler}
                          data-modal-target="popup-modal"
                          data-modal-toggle="popup-modal"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "group flex items-center px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          <MapIcon
                            className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          Modifier la carte
                        </span>
                      )}
                    </Menu.Item>
                  </div>
                )}
              </>
            </HasRoles>
            {/* its agents */}
            <HasRoles userRole={["ADMIN", "SUPERVISOR", "RESPONSABLEZONE"]}>
              <>
                {props.rowShowDealerAgentHandler &&
                  props.displayShowDealerAgentBtn && (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={props.rowShowDealerAgentHandler}
                            data-modal-target="popup-modal"
                            data-modal-toggle="popup-modal"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm cursor-pointer"
                            )}
                          >
                            <ListBulletIcon
                              className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Ses agents
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  )}
              </>
            </HasRoles>

          {/* activities */}
          <HasRoles userRole={["ADMIN", "SUDO", "SUPERVISOR", "SUPERDEALER", "DEALER"]}>
            <>
              {props.rowShowActivitiesHandler &&
                props.displayShowActivitiesBtn && (
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          onClick={props.rowShowActivitiesHandler}
                          data-modal-target="popup-modal"
                          data-modal-toggle="popup-modal"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "group flex items-center px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          <ClipboardDocumentListIcon
                            className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          Ses activités
                        </span>
                      )}
                    </Menu.Item>
                  </div>
                )}
            </>
          </HasRoles>
            {/* activities */}
            <HasRoles
              userRole={[
                "ADMIN",
                "SUDO",
                "SUPERVISOR",
                "SUPERDEALER",
                "RESPONSABLEZONE",
              ]}
            >
              <>
                {props.rowShowActivitiesHandler &&
                  props.displayShowActivitiesBtn && (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={props.rowShowActivitiesHandler}
                            data-modal-target="popup-modal"
                            data-modal-toggle="popup-modal"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm cursor-pointer"
                            )}
                          >
                            <ClipboardDocumentListIcon
                              className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Ses activités
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  )}
              </>
            </HasRoles>

            {/* ses dealers */}
            <HasRoles userRole={["ADMIN", "SUDO", "SUPERVISEURSUPERDEALER"]}>
              <>
                {props.rowShowSuperDealerDealersHandler &&
                  props.displayShowSuperDealerDealersBtn && (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={props.rowShowSuperDealerDealersHandler}
                            data-modal-target="popup-modal"
                            data-modal-toggle="popup-modal"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm cursor-pointer"
                            )}
                          >
                            <UserIcon
                              className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Ses dealers
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  )}
              </>
            </HasRoles>
          </>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default TableRowEditMenu;
