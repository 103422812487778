import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { transactionCollectionName, usersCollectionName } from "../../config";
import { TransactionType } from "../../interfaces";
import { db } from "../../firebase";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import toast from "react-hot-toast";
import { UserContext } from "../../contexts";
import SalesPeopleInvoicePayment from "../../components/DashboardComponents/SalesPeopleInvoicePayment";
import Pagination, {
  PaginationDto,
} from "../../components/Pagination/Pagination";

interface SellerTransactionData {
  [sellerPhoneNumber: string]: {
    oneaCount: number;
    oneaAmount: number;
    sonabelPrepaidCount: number;
    sonabelPrepaidAmount: number;
    sonabelPostpaidCount: number;
    sonabelPostpaidAmount: number;
  };
}

const columns = [
  {
    field: "displayName",
    label: "Nom",
  },
  {
    field: "phoneNumber",
    label: "Téléphone",
  },
  {
    field: "city",
    label: "Ville",
  },
  {
    field: "oneaCount",
    label: "Nombre ONEA",
  },
  {
    field: "oneaAmount",
    label: "Montant ONEA",
  },
  {
    field: "sonabelPrepaidCount",
    label: "Nombre SONABEL Prepayées",
  },
  {
    field: "sonabelPrepaidAmount",
    label: "Montant SONABEL Prepayées",
  },
  {
    field: "sonabelPostpaidCount",
    label: "Nombre SONABEL Postpayées",
  },
  {
    field: "sonabelPostpaidAmount",
    label: "Montant SONABEL Postpayées",
  },
  {
    field: "totalCount",
    label: "Nombre Total",
  },
  {
    field: "totalAmount",
    label: "Montant Total",
  },
];

const PaymentInvoicesPage = () => {
  const { user } = useContext(UserContext);
  const currentSupervisorId = user?.id;
  const [retriveUserByPhoneNumber, setRetriveUserByPhoneNumber] =
    useState<string>("");
  const [oneaInvoicesPaid, setOneaInvoicesPaid] = useState<number>(0);
  const [amountOneaInvoicesPaid, setAmountOneaInvoicesPaid] =
    useState<number>(0);
  const [sonabelInvoicesPrepaid, setSonabelInvoicesPrepaid] =
    useState<number>(0);
  const [amountSonabelInvoicesPrepaid, setAmountSonabelInvoicesPrepaid] =
    useState<number>(0);
  const [sonabelInvoicesPostpaid, setSonabelInvoicesPostpaid] =
    useState<number>(0);
  const [amountSonabelInvoicesPostpaid, setAmountSonabelInvoicesPostpaid] =
    useState<number>(0);
  const [startDate, setStartDate] = useState<string>(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(moment().format("YYYY-MM-DD"));
  const [sellerTransactions, setSellerTransactions] =
    useState<SellerTransactionData>({});
  const [allTableData, setAllTableData] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [paginationState, setPaginationState] = useState<PaginationDto>(
    new PaginationDto()
  );
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const renderRow = (row: any, column: any, value: any) => {
    if (
      column.field === "oneaAmount" ||
      column.field === "sonabelPrepaidAmount" ||
      column.field === "sonabelPostpaidAmount" ||
      column.field === "totalAmount"
    ) {
      return value ? `${value.toLocaleString()} FCFA` : "0 FCFA";
    }

    if (
      column.field === "oneaCount" ||
      column.field === "sonabelPrepaidCount" ||
      column.field === "sonabelPostpaidCount" ||
      column.field === "totalCount"
    ) {
      return value || "0";
    }

    return value as string;
  };

  const applyPagination = (data: any[]) => {
    const startIndex = currentPage * pageSize;
    const endIndex = startIndex + pageSize;

    const paginatedData = data.slice(startIndex, endIndex);

    const newPaginationState = new PaginationDto();
    newPaginationState.total = data.length;
    newPaginationState.limit = pageSize;
    newPaginationState.offset = startIndex;
    setPaginationState(newPaginationState);

    return paginatedData;
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setCurrentPage(0);
  };

  const fetchTotalTransactions = async () => {
    if (!currentSupervisorId) return;

    try {
      const sellerUsersSnapshot = await db
        .collection(usersCollectionName)
        .where("isSeller", "==", true)
        .where("supervisorId", "==", currentSupervisorId)
        .get();

      // Prepare seller data
      const sellers = sellerUsersSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          displayName: data.displayName,
          phoneNumber: data.phoneNumber,
          city: data.city || "Non spécifiée",
        };
      });

      const sellerPhoneNumbers = sellers.map((seller) => seller.phoneNumber);

      // If no sellers found, reset data
      if (sellerPhoneNumbers.length === 0) {
        resetFilteredData();
        setAllTableData([]);
        setTableData([]);
        return;
      }

      // Fetch transactions for these sellers within the date range
      const startTimestamp = moment(startDate).startOf("day").toDate();
      const endTimestamp = moment(endDate).endOf("day").toDate();

      // Create separate queries for each transaction type
      const oneaQuery = db
        .collection(transactionCollectionName)
        .where("transactionType", "==", TransactionType.WATER_BILL_POSTPAID)
        .where("senderPhoneNumber", "in", sellerPhoneNumbers)
        .where("createdAt", ">=", startTimestamp)
        .where("createdAt", "<=", endTimestamp);

      const sonabelPrepaidQuery = db
        .collection(transactionCollectionName)
        .where("transactionType", "==", TransactionType.ELECTRIC_BILL_PREPAID)
        .where("senderPhoneNumber", "in", sellerPhoneNumbers)
        .where("createdAt", ">=", startTimestamp)
        .where("createdAt", "<=", endTimestamp);

      const sonabelPostpaidQuery = db
        .collection(transactionCollectionName)
        .where("transactionType", "==", TransactionType.ELECTRIC_BILL_POSTPAID)
        .where("senderPhoneNumber", "in", sellerPhoneNumbers)
        .where("createdAt", ">=", startTimestamp)
        .where("createdAt", "<=", endTimestamp);

      // Execute all queries
      const [oneaSnap, sonabelPrepaidSnap, sonabelPostpaidSnap] =
        await Promise.all([
          oneaQuery.get(),
          sonabelPrepaidQuery.get(),
          sonabelPostpaidQuery.get(),
        ]);

      // Initialize data structure for seller transactions
      const sellerData: SellerTransactionData = {};

      // Initialize each seller with zero values
      sellerPhoneNumbers.forEach((phoneNumber) => {
        sellerData[phoneNumber] = {
          oneaCount: 0,
          oneaAmount: 0,
          sonabelPrepaidCount: 0,
          sonabelPrepaidAmount: 0,
          sonabelPostpaidCount: 0,
          sonabelPostpaidAmount: 0,
        };
      });

      // Process ONEA transactions
      oneaSnap.docs.forEach((doc) => {
        const data = doc.data();
        const phoneNumber = data.senderPhoneNumber;
        const amount = parseInt(data.amount) || 0;

        if (sellerData[phoneNumber]) {
          sellerData[phoneNumber].oneaCount += 1;
          sellerData[phoneNumber].oneaAmount += amount;
        }
      });

      // Process SONABEL Prepaid transactions
      sonabelPrepaidSnap.docs.forEach((doc) => {
        const data = doc.data();
        const phoneNumber = data.senderPhoneNumber;
        const amount = parseInt(data.amount) || 0;

        if (sellerData[phoneNumber]) {
          sellerData[phoneNumber].sonabelPrepaidCount += 1;
          sellerData[phoneNumber].sonabelPrepaidAmount += amount;
        }
      });

      // Process SONABEL Postpaid transactions
      sonabelPostpaidSnap.docs.forEach((doc) => {
        const data = doc.data();
        const phoneNumber = data.senderPhoneNumber;
        const amount = parseInt(data.amount) || 0;

        if (sellerData[phoneNumber]) {
          sellerData[phoneNumber].sonabelPostpaidCount += 1;
          sellerData[phoneNumber].sonabelPostpaidAmount += amount;
        }
      });

      // Calculate global summary values
      let totalOneaCount = 0;
      let totalOneaAmount = 0;
      let totalSonabelPrepaidCount = 0;
      let totalSonabelPrepaidAmount = 0;
      let totalSonabelPostpaidCount = 0;
      let totalSonabelPostpaidAmount = 0;

      Object.values(sellerData).forEach((data) => {
        totalOneaCount += data.oneaCount;
        totalOneaAmount += data.oneaAmount;
        totalSonabelPrepaidCount += data.sonabelPrepaidCount;
        totalSonabelPrepaidAmount += data.sonabelPrepaidAmount;
        totalSonabelPostpaidCount += data.sonabelPostpaidCount;
        totalSonabelPostpaidAmount += data.sonabelPostpaidAmount;
      });

      // Update global state
      setOneaInvoicesPaid(totalOneaCount);
      setAmountOneaInvoicesPaid(totalOneaAmount);
      setSonabelInvoicesPrepaid(totalSonabelPrepaidCount);
      setAmountSonabelInvoicesPrepaid(totalSonabelPrepaidAmount);
      setSonabelInvoicesPostpaid(totalSonabelPostpaidCount);
      setAmountSonabelInvoicesPostpaid(totalSonabelPostpaidAmount);

      // Store seller transaction data
      setSellerTransactions(sellerData);

      // Prepare table data by combining seller info with transaction data
      const combinedTableData = sellers.map((seller) => {
        const transactions = sellerData[seller.phoneNumber] || {
          oneaCount: 0,
          oneaAmount: 0,
          sonabelPrepaidCount: 0,
          sonabelPrepaidAmount: 0,
          sonabelPostpaidCount: 0,
          sonabelPostpaidAmount: 0,
        };

        // Calculate totals for each seller
        const totalCount =
          transactions.oneaCount +
          transactions.sonabelPrepaidCount +
          transactions.sonabelPostpaidCount;

        const totalAmount =
          transactions.oneaAmount +
          transactions.sonabelPrepaidAmount +
          transactions.sonabelPostpaidAmount;

        return {
          ...seller,
          ...transactions,
          totalCount,
          totalAmount,
        };
      });

      // Filter by specific phone number if one is set
      if (retriveUserByPhoneNumber) {
        const filteredData = combinedTableData.filter(
          (item) => item.phoneNumber === retriveUserByPhoneNumber
        );

        setAllTableData(filteredData);
        setTableData(applyPagination(filteredData));
      } else {
        setAllTableData(combinedTableData);
        setTableData(applyPagination(combinedTableData));
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des transactions :", error);
      toast.error("Impossible de récupérer les transactions");
      resetFilteredData();
      setAllTableData([]);
      setTableData([]);
    }
  };

  // Retrieve and filter transactions for a specific phone number
  const retrivePhoneNumber = async (phoneNumber: string) => {
    setRetriveUserByPhoneNumber(phoneNumber);

    if (phoneNumber && isValidPhoneNumber(phoneNumber, "BF")) {
      // Filter existing data if we already have loaded data
      if (Object.keys(sellerTransactions).length > 0) {
        const filteredData = allTableData.filter(
          (item) => item.phoneNumber === phoneNumber
        );

        if (filteredData.length > 0) {
          setAllTableData(filteredData);
          setTableData(applyPagination(filteredData));

          // Also update the summary values
          const transactions = sellerTransactions[phoneNumber] || {
            oneaCount: 0,
            oneaAmount: 0,
            sonabelPrepaidCount: 0,
            sonabelPrepaidAmount: 0,
            sonabelPostpaidCount: 0,
            sonabelPostpaidAmount: 0,
          };

          setOneaInvoicesPaid(transactions.oneaCount);
          setAmountOneaInvoicesPaid(transactions.oneaAmount);
          setSonabelInvoicesPrepaid(transactions.sonabelPrepaidCount);
          setAmountSonabelInvoicesPrepaid(transactions.sonabelPrepaidAmount);
          setSonabelInvoicesPostpaid(transactions.sonabelPostpaidCount);
          setAmountSonabelInvoicesPostpaid(transactions.sonabelPostpaidAmount);
          return;
        }
      }

      // Otherwise do a fresh fetch
      await fetchTotalTransactions();
    } else {
      // If phone number is invalid or empty, fetch total transactions for all sellers
      await fetchTotalTransactions();
    }
  };

  // Reset filtered data
  const resetFilteredData = () => {
    setRetriveUserByPhoneNumber("");
    setOneaInvoicesPaid(0);
    setAmountOneaInvoicesPaid(0);
    setSonabelInvoicesPrepaid(0);
    setAmountSonabelInvoicesPrepaid(0);
    setSonabelInvoicesPostpaid(0);
    setAmountSonabelInvoicesPostpaid(0);
    setSellerTransactions({});
  };

  // Handle start date change
  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    // Re-fetch data with new date range
    fetchTotalTransactions();
  };

  // Handle end date change
  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    // Re-fetch data with new date range
    fetchTotalTransactions();
  };

  // Apply pagination when page or page size changes
  useEffect(() => {
    if (allTableData.length > 0) {
      setTableData(applyPagination(allTableData));
    }
  }, [currentPage, pageSize]);

  // Fetch total transactions when component mounts or supervisor changes
  useEffect(() => {
    fetchTotalTransactions();
  }, [currentSupervisorId]);

  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="">
              <h1 className="py-2 text-xl font-semibold text-gray-900">
                Paiements de factures
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Recherchez les paiements de factures ONEA et SONABEL par numéro
                de commercial
              </p>
            </div>
            <div className="h-2" />
            <div className="flex flex-col space-y-4 md:flex-row md:items-center md:justify-end md:space-x-4 md:space-y-0">
              <div className="w-full md:w-auto">
                <PhoneInput
                  international={false}
                  placeholder="Numéro de téléphone du commercial"
                  className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                  defaultCountry="BF"
                  onChange={retrivePhoneNumber}
                />
              </div>

              {/* Filter by date */}
              <div className="flex flex-col space-y-2 w-full md:flex-row md:items-center md:space-x-3 md:space-y-0 md:w-auto">
                {/* start date */}
                <input
                  type="date"
                  id="dateDebut"
                  className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  defaultValue={startDate}
                  onChange={handleStartDateChange}
                />

                {/* end date */}
                <input
                  type="date"
                  id="dateFin"
                  className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  defaultValue={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
              {/* End filter by date */}
            </div>
          </div>
          <div className="h-6" />
          <SalesPeopleInvoicePayment
            retrieveOneaInvoicesPaid={oneaInvoicesPaid}
            retrieveOneaAmountInvoices={amountOneaInvoicesPaid}
            retrieveSonabelInvoicesPrepaid={sonabelInvoicesPrepaid}
            retrieveSonabelPrepaidAmountInvoices={amountSonabelInvoicesPrepaid}
            retrieveSonabelInvoicesPostpaid={sonabelInvoicesPostpaid}
            retrieveSonabelPostpaidAmountInvoices={
              amountSonabelInvoicesPostpaid
            }
          />
          <div className="h-2" />
          <div className="mt-4 overflow-x-auto ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map((column) => (
                    <th
                      key={column.field}
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {tableData.map((item, index) => (
                  <tr key={index}>
                    {columns.map((column) => (
                      <td
                        key={`${index}-${column.field}`}
                        className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                      >
                        {renderRow(item, column, item[column.field])}
                      </td>
                    ))}
                  </tr>
                ))}
                {tableData.length === 0 && (
                  <tr>
                    <td
                      colSpan={columns.length}
                      className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center"
                    >
                      Aucune donnée disponible
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {allTableData.length > 0 && (
            <div className="mt-4">
              <Pagination
                skip={paginationState.offset}
                take={pageSize}
                total={paginationState.total}
                collectionName="transactions"
                onPageChange={handlePageChange}
                onPageLimitChange={handlePageSizeChange}
                isChangedPage={false}
                balance={0}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentInvoicesPage;
