import { BanknotesIcon, TicketIcon } from "@heroicons/react/24/outline";
import { DashboardReport } from "./DashboardReport";

const SalesPeopleInvoicePayment = (props: {
  retrieveOneaInvoicesPaid?: number;
  retrieveOneaAmountInvoices?: number;
  retrieveSonabelInvoicesPrepaid?: number;
  retrieveSonabelPrepaidAmountInvoices?: number;
  retrieveSonabelInvoicesPostpaid?: number;
  retrieveSonabelPostpaidAmountInvoices?: number;
}) => {
  const {
    retrieveOneaInvoicesPaid,
    retrieveSonabelInvoicesPrepaid,
    retrieveSonabelInvoicesPostpaid,
    retrieveOneaAmountInvoices,
    retrieveSonabelPrepaidAmountInvoices,
    retrieveSonabelPostpaidAmountInvoices,
  } = props;

  return (
    <>
      {/* ONEA */}
      <div className="m-0 font-sans text-base antialiased font-normal leading-default bg-gray-50 text-slate-500">
        <div className="">
          <h1 className="py-2 text-l font-semibold text-gray-900 px-4">ONEA</h1>
        </div>
        <div className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200">
          <div className="w-full py-0 mx-auto">
            <div className="flex flex-wrap max-md:mx-5 ">
              <DashboardReport
                className="m-3 max-md:m-2 max-md:w-full"
                rightIcon={
                  <TicketIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                }
                iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-red-700 to-red-500"
                title="Nombre de factures ONEA payées "
                value={retrieveOneaInvoicesPaid}
              />

              <DashboardReport
                className="m-3 max-md:m-2 max-md:w-full"
                rightIcon={
                  <BanknotesIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                }
                iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-red-700 to-red-500"
                title="Montant Total de factures ONEA payées "
                value={`${new Intl.NumberFormat().format(
                  retrieveOneaAmountInvoices as number
                )} fcfa`}
              />
            </div>
          </div>
        </div>
      </div>

      {/* SONABEL PREPAID */}
      <div className="m-0 font-sans text-base antialiased font-normal leading-default bg-gray-50 text-slate-500">
        <div className="">
          <h1 className="py-2 text-l font-semibold text-gray-900 px-4">
            SONABEL prépayées
          </h1>
        </div>
        <div className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200">
          <div className="w-full py-0 mx-auto">
            <div className="flex flex-wrap max-md:mx-5 ">
              <DashboardReport
                className="m-3 max-md:m-2 max-md:w-full"
                rightIcon={
                  <TicketIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                }
                iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-red-700 to-red-500"
                title="Nombre de factures SONABEL prépayées "
                value={retrieveSonabelInvoicesPrepaid}
              />

              <DashboardReport
                className="m-3 max-md:m-2 max-md:w-full"
                rightIcon={
                  <BanknotesIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                }
                iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-red-700 to-red-500"
                title="Montant Total de factures SONABEL prépayées "
                value={`${new Intl.NumberFormat().format(
                  retrieveSonabelPrepaidAmountInvoices as number
                )} fcfa`}
              />
            </div>
          </div>
        </div>
      </div>

      {/* SONABEL POSTPAID */}
      <div className="m-0 font-sans text-base antialiased font-normal leading-default bg-gray-50 text-slate-500">
        <div className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200">
          <div className="w-full py-0 mx-auto">
            <div className="">
              <h1 className="py-2 text-l font-semibold text-gray-900 px-4">
                SONABEL postpayées
              </h1>
            </div>
            <div className="flex flex-wrap max-md:mx-5 ">
              <DashboardReport
                className="m-3 max-md:m-2 max-md:w-full"
                rightIcon={
                  <TicketIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                }
                iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-red-700 to-red-500"
                title="Nombre de factures SONABEL postpayées "
                value={retrieveSonabelInvoicesPostpaid}
              />

              <DashboardReport
                className="m-3 max-md:m-2 max-md:w-full"
                rightIcon={
                  <BanknotesIcon className="relative w-6 h-6 text-xl text-white opacity-100 top-31/100" />
                }
                iconClasses="flex items-center justify-center w-10 h-10 text-center rounded-lg bg-gradient-to-tl from-red-700 to-red-500"
                title="Montant Total de factures SONABEL postpayées "
                value={`${new Intl.NumberFormat().format(
                  retrieveSonabelPostpaidAmountInvoices as number
                )} fcfa`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesPeopleInvoicePayment;
